import { WORD_EXTENSIONS, EXCEL_EXTENSIONS, POWERPOINT_EXTENSIONS, PDF_EXTENSIONS, TXT_EXTENSIONS, XML_EXTENSIONS, ZIP_EXTENSIONS } from "lib"

import excel from "../../assets/images/excel.svg"
import pdf from "../../assets/images/pdf.svg"
import powerpoint from "../../assets/images/powerpoint.svg"
import txt from "../../assets/images/txt.svg"
import word from "../../assets/images/word.svg"
import xml from "../../assets/images/xml.svg"
import zip from "../../assets/images/zip.svg"


export const getDocumentIcon = (document: string) => {
    const extension = document.split(".").pop()
    if (WORD_EXTENSIONS.findIndex(ext => ext === extension) > -1) {
        return word
    }
    if (EXCEL_EXTENSIONS.findIndex(ext => ext === extension) > -1) {
        return excel
    }
    if (POWERPOINT_EXTENSIONS.findIndex(ext => ext === extension) > -1) {
        return powerpoint
    }
    if (PDF_EXTENSIONS.findIndex(ext => ext === extension) > -1) {
        return pdf
    }
    if (TXT_EXTENSIONS.findIndex(ext => ext === extension) > -1) {
        return txt
    }
    if (XML_EXTENSIONS.findIndex(ext => ext === extension) > -1) {
        return xml
    }
    if (ZIP_EXTENSIONS.findIndex(ext => ext === extension) > -1) {
        return zip
    }
    return ""
}

export const isWordFile = (extension: string) => {
    return WORD_EXTENSIONS.findIndex(ext => ext === extension) > -1
}

export const isExcelFile = (extension: string) => {
    return EXCEL_EXTENSIONS.findIndex(ext => ext === extension) > -1
}

export const isPowerPointFile = (extension: string) => {
    return POWERPOINT_EXTENSIONS.findIndex(ext => ext === extension) > -1
}

export const isPdfFile = (extension: string) => {
    return PDF_EXTENSIONS.findIndex(ext => ext === extension) > -1
}

export const isTxtFile = (extension: string) => {
    return TXT_EXTENSIONS.findIndex(ext => ext === extension) > -1
}

export const isXmlFile = (extension: string) => {
    return XML_EXTENSIONS.findIndex(ext => ext === extension) > -1
}

export const isZipFile = (extension: string) => {
    return ZIP_EXTENSIONS.findIndex(ext => ext === extension) > -1
}

export const getFileIcon = (fileValue: string[]): string => {
    const fileStructure = fileValue[1].split(".")
    const fileExtension = fileStructure.slice(-1).pop()
    let icon: string = "file__defaultIcon"
    if (fileExtension && isWordFile(fileExtension)) {
        icon = "file-word-icon"
    }
    if (fileExtension && isExcelFile(fileExtension)) {
        icon = "file-excel-icon"
    }
    if (fileExtension && isPowerPointFile(fileExtension)) {
        icon = "file-power-point-icon"
    }
    if (fileExtension && isTxtFile(fileExtension)) {
        icon = "file-txt-icon"
    }
    if (fileExtension && isPdfFile(fileExtension)) {
        icon = "file-pdf-icon"
    }
    if (fileExtension && isXmlFile(fileExtension)) {
        icon = "file-xml-icon"
    }
    if (fileExtension && isZipFile(fileExtension)) {
        icon = "file-zip-icon"
    }
    return icon
}