import { getFileIcon } from 'lib'
import React from 'react'

interface Props {
    value: any,
    filesContext: string,
    style: React.CSSProperties,
    darkBg: boolean
    borderRadiusUnit: string
}

const File = React.memo((props: Props) => {
    const getTransforms = React.useCallback(() => { if (props.style.transform) { return JSON.parse(props.style.transform) } }, [props.style.transform])
    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
                transform: `rotate(${getTransforms()[0]}deg) scaleX(${getTransforms()[1]})`,
                justifyContent: "center",
                alignItems: "center",
                flexDirection: props.style.flexDirection,
                pointerEvents: "none"
            }}
        >
            <div className={`file-icon bg-center bg-no-repeat bg-contain ${getFileIcon(props.value)}`} />
            <div style={{ flexGrow: 1, height: "100%", width: "100%" }}>
                <p className={`file-text flex justify-start items-center text-center ${props.darkBg && "file-dark-contrast-text"}`}>
                    <span style={{ padding: 5, backgroundColor: "#333", color: "#FFF", borderRadius: 4, marginRight: 5, marginLeft: 5 }}>{"D"}</span>
                    {props.value[2]}
                </p>
            </div>
        </div>
    )
})

export default File
