import { CardContainerValues, ICardPersonality, ICardPerxona } from 'components/CardProps/CardProps'
import { getCardsPersonality } from 'lib'
import React from 'react'


type PracticeCard = ICardPerxona | ICardPersonality
export type FilterMode = "filterOnly" | "filterAndSort" | "all" | "none"


interface CardProps<Card> {

          filterCallBack: (card: Card) => boolean;
          value: CardContainerValues;
          perxonaId: number
}

interface Hook<Card> {
          cards: Card[]
          filterCard: (filterMode: FilterMode, customFilter?: ((card: Card) => boolean) | undefined) => void
          getCards: () => void
}


export const useCard = <Card extends PracticeCard>(cardProps: CardProps<Card>): Hook<Card> => {

          const { filterCallBack, value, perxonaId } = cardProps
          const { perxonaId: ownerCardsId, cardsChosen: cardIdsChosen } = value

          const [cards, setCards] = React.useState<Card[]>([]);
          const [cardsToShow, setCardsToShow] = React.useState<Card[]>([]);

          const sortedCallBack = React.useCallback((first: Card, second: Card) => {
                    const firstIndex = cardIdsChosen.indexOf(first.cardId)
                    const secondIndex = cardIdsChosen.indexOf(second.cardId)
                    return firstIndex - secondIndex
          }, [cardIdsChosen])


          const filterCard = React.useCallback((filterMode: FilterMode, customFilter?: (card: Card) => boolean) => {
                    if (filterMode === "filterOnly" && customFilter === undefined) {

                              setCardsToShow(cards.filter(filterCallBack))
                    } else if (filterMode === "filterAndSort" && customFilter === undefined) {
                              setCardsToShow(cards.filter(filterCallBack).sort(sortedCallBack))
                    } else if (filterMode === "all" && customFilter === undefined) {
                              setCardsToShow([...cards])
                    } else if (filterMode === "none" && customFilter === undefined) {
                              setCardsToShow([])
                    } else if (customFilter !== undefined && filterMode === "filterOnly") {
                              setCardsToShow(cards.filter(customFilter))
                    }

          }, [cards, filterCallBack, sortedCallBack])


          const getCards = React.useCallback(async () => {
                    if (ownerCardsId && cards.length === 0) {
                              const result = await getCardsPersonality(ownerCardsId)
                              const cardsResult = result?.data as Card[]
                              setCardsToShow(cards)
                              setCards(cardsResult)
                    } else if (ownerCardsId != null && cards.length === 0) {
                              const result = await getCardsPersonality(perxonaId)
                              const cardsResult = result?.data as Card[]
                              setCards(cardsResult)
                              setCardsToShow(cards)

                    } else {
                              setCardsToShow(cards)
                    }
          }, [ownerCardsId, perxonaId, cards])








          return (
                    {
                              cards: cardsToShow,
                              filterCard,
                              getCards,

                    }
          )
}
