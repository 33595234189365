import React from 'react'

interface Props {
    value: any,
    style: React.CSSProperties,
    borderRadiusUnit: string
}

const Line = React.memo((props: Props) => {
    const getTransforms = React.useCallback(() => { if (props.style.transform) { return JSON.parse(props.style.transform) } }, [props.style.transform])
    return (
        <div
            style={{
                ...props.style,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                transform: `rotate(${getTransforms()[0]}deg) scaleX(${getTransforms()[1]})`,
                pointerEvents: "none"
            }}
        >
            <div
                style={{
                    ...props.value,
                    width: props.value?.orientation === undefined || props.value?.orientation === "h" ? "98%" : props.value?.height ? parseInt(props.value.height) : 2,
                    height: props.value?.orientation === "v" ? "98%" : props.value?.height ? parseInt(props.value?.height) : 2,
                    backgroundColor: props.value ? props.value.backgroundColor ? props.value.backgroundColor : "rgba(0,0,0,1)" : "rgba(0,0,0,1)"
                }}
            />
        </div>
    )
})

export default Line
