import { Box, Tooltip, Typography } from '@material-ui/core'

type Props = {
  img: string
  communicationTypeDescription: string
  title: string
  countMembers: number
  endAdornment?: JSX.Element
}
export const ConferenceCard = (props: Props) => {
  const { img, communicationTypeDescription, title, countMembers, endAdornment } = props
  return (
    <Box
      style={{
        width: 'auto',
        height: 55,
        maxWidth: 270,
        maxHeight: 55,
        minWidth: 270,
        minHeight: 55,
        backgroundColor: '#ffffff',
        borderRadius: '0px',
        borderTopRightRadius: '20px',
        borderBottomLeftRadius: '20px',
        borderColor: '#e5e5e3',
        borderStyle: 'solid',
        borderWidth: '1px',
        boxSizing: 'content-box',
      }}
    >
      <Box
        style={{
          width: '100%',
          height: '100%',
          minHeight: '100%',
          minWidth: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          gap: 2,
        }}
      >
        <Box
          style={{
            backgroundImage: `url(${img})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            minWidth: '55px',
            minHeight: '55px',
            width: '55px',
            height: '55px',
            maxWidth: '55px',
            maxHeight: '55px',
            borderBottomLeftRadius: '20px',
          }}
        />
        <Box
          style={{
            width: 'auto',
            minWidth: '140px',
            height: '100%',
            minHeight: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="caption" style={{ fontSize: 10 }}>
            {communicationTypeDescription}
          </Typography>

          <Tooltip title={title} arrow>
            <Typography variant="h6" noWrap style={{ fontSize: 14, fontWeight: 600 }}>
              {title}
            </Typography>
          </Tooltip>

          <Typography variant="caption" style={{ fontSize: 10 }}>
            {countMembers} Miembros
          </Typography>
        </Box>
        {endAdornment && endAdornment}
      </Box>
    </Box>
  )
}
