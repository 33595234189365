import { Tooltip } from '@material-ui/core'
import { LocationContext, PageContext } from 'context'
import { useKeyPress } from 'hooks'
import { ELEMENT_TYPES, openObjectInfo } from 'lib'
import React from 'react'
import ReactPlayer from 'react-player'
import { ISize } from 'types'

interface Props {
    id: string,
    value: any,
    filesContext: string,
    style: React.CSSProperties,
    size: ISize,
    borderRadiusUnit: string
    exported?: boolean
}

const AudioView = React.memo((props: Props) => {
    const { id: pageId } = React.useContext(PageContext)
    const controlPress: boolean = useKeyPress("Control")
    const getTransforms = React.useCallback(() => { if (props.style.transform) { return JSON.parse(props.style.transform) } }, [props.style.transform])
    const { tabId } = React.useContext(LocationContext)

    const handleClick = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e.ctrlKey) {
            await openObjectInfo(pageId, props.value[0], tabId)
            return
        }
    }
    return (
        <div
            id={`${ELEMENT_TYPES.audio}_${props.id}`}
            style={{
                ...props.style,
                transform: `rotate(${getTransforms()[0]}deg) scaleX(${getTransforms()[1]})`,
                borderTopLeftRadius: props.style.borderTopLeftRadius + props.borderRadiusUnit,
                borderBottomLeftRadius: props.style.borderBottomLeftRadius + props.borderRadiusUnit,
                borderTopRightRadius: props.style.borderTopRightRadius + props.borderRadiusUnit,
                borderBottomRightRadius: props.style.borderBottomRightRadius + props.borderRadiusUnit,
            }}
        >
            {
                props.value[1].includes("ivoox") ?
                    <iframe
                        id={"audio" + Math.random()}
                        allowFullScreen
                        height={props.size.height}
                        src={props.value[1]}
                    />
                    :
                    <ReactPlayer
                        url={`${props.value[1].includes("http") ? "" : props.filesContext + "/"}${props.value[1].replace("https://w.soundcloud.com/player/?url=", "")}`}
                        width={props.size.width}
                        height={props.size.height}
                        controls={true}
                    />
            }
            {
                (controlPress && !props.exported) &&
                <Tooltip arrow title="Ver información">
                    <div
                        onClick={handleClick}
                        style={{
                            width: props.size.width,
                            height: props.size.height,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                            top: 0,
                            cursor: "pointer",
                            backgroundColor: "transparent"
                        }}
                    />
                </Tooltip>
            }
        </div>
    )
})

export default AudioView
