import { Box, Button, ButtonGroup, Icon, IconButton, InputAdornment, Snackbar, TextField, Tooltip, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { ConfigContext, LocationContext } from 'context'
import { findLink } from 'lib'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { IElement } from 'types'

export type LinkHeaderType = {
  label: string
  link: string
}
export type HeaderValues = {
  entityId: number
  serviceId: number
  objectId: number
  links: LinkHeaderType[]
  link: string
  minimize: boolean
  transparent: boolean
}

export const DEFAULT_VALUES_HEADER = {
  entityId: '',
  serviceId: '',
  objectId: '',
  links: [],
  minimize: false,
  transparent: false,
}
type Props = {
  element: IElement
  updateElement: (newElement: IElement) => void
  pageId: number
}
enum ValidLinkPerxona {
  PERXONAPRO = 'perxona.com',
  PERXONADEV = 'dev.perxona.com',
  PERXONA = 'perxona',
}
export const HeaderProps = (props: Props) => {
  const { element, updateElement, pageId } = props
  const { tabId } = React.useContext(LocationContext)
  const { checkLink } = React.useContext(ConfigContext)

  const { value } = element
  const [keyLink, setKeyLink] = React.useState<string>('')
  const [pasteLink, setPasteLink] = React.useState<{ link: string; paste: boolean }>({ link: '', paste: false })
  const [alertConfig, setAlertConfig] = React.useState<{ open: boolean; message: string; severity: 'success' | 'error' }>({
    open: false,
    message: '',
    severity: 'success',
  })
  const [addLink, setAddLink] = React.useState<boolean>(false)
  const handleClose = () => {
    setAlertConfig({ open: false, message: '', severity: 'success' })
  }

  const handleChangeHeaderVisibleMode = React.useCallback(
    (mode: boolean) => {
      const elementUpdate = {
        ...element,
        value: {
          ...value,
          minimize: mode,
        },
      }
      updateElement(elementUpdate)
    },
    [element, updateElement, value]
  )

  const handleLinkExternal = React.useCallback(async () => {
    const existKey = (props.element.value as HeaderValues).links.find((link) => link.label === keyLink) === undefined ? false : true
    if (keyLink.length > 0 && !existKey) {
      await findLink(pageId, (props.element.value as HeaderValues).link, props.element.id, tabId)
      setAddLink(true)
    } else {
      setAlertConfig({ open: true, message: 'Los labels no pueden estar duplicados', severity: 'error' })
    }
  }, [keyLink, pageId, props.element.id, tabId])

  const handleDeleteLink = React.useCallback(
    (label: string) => {
      const elementUpdate = {
        ...props.element,
        value: {
          ...props.element.value,
          links: (props.element.value as HeaderValues).links.filter((link) => link.label !== label),
        },
      }
      updateElement(elementUpdate)
    },
    [props.element]
  )

  React.useEffect(() => {
    const endResponse = props.element.updateLink !== undefined ? props.element.updateLink : false
    if (addLink && checkLink && endResponse) {
      const elementUpdate = {
        ...props.element,
        value: {
          ...props.element.value,
          links: [...(props.element.value as HeaderValues).links, { label: keyLink, link: props.element.link }],
        },
        updateLink: false,
      }
      updateElement(elementUpdate)
    }
  }, [props.element.link, keyLink, addLink, checkLink])

  React.useEffect(() => {
    if (addLink) {
      setAddLink(false)
      setKeyLink('')
    }
  }, [(value as HeaderValues).links])

  const handleOnPasteLink = React.useCallback(
    (e) => {
      const linkPaste = e.clipboardData.getData('Text')
      const isValidLink =
        linkPaste.includes(ValidLinkPerxona.PERXONA) || linkPaste.includes(ValidLinkPerxona.PERXONADEV) || linkPaste.includes(ValidLinkPerxona.PERXONAPRO)
      if (isValidLink) {
        setPasteLink({ link: linkPaste, paste: true })
      } else {
        setAlertConfig({ open: true, message: 'El link no es valido', severity: 'error' })
        setPasteLink({ link: '', paste: false })
      }
    },
    [element]
  )

  const handleLinkPaste = React.useCallback(() => {
    const existKey = (props.element.value as HeaderValues).links.find((link) => link.label === keyLink) === undefined ? false : true

    if (pasteLink.paste && keyLink.length > 0 && !existKey) {
      const elementUpdate = {
        ...props.element,
        value: {
          ...props.element.value,
          links: [...(props.element.value as HeaderValues).links, { label: keyLink, link: pasteLink.link }],
        },
      }
      updateElement(elementUpdate)
      setPasteLink({ link: '', paste: false })
      setKeyLink('')
    } else {
      setAlertConfig({ open: true, message: 'Los labels no pueden estar duplicados', severity: 'error' })
    }
  }, [pasteLink, keyLink, props.element])

  const handleDeleteLinkPaste = () => {
    setPasteLink({ link: '', paste: false })
  }

  return (
    <React.Fragment>
      <Snackbar open={alertConfig.open} autoHideDuration={1000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alertConfig.severity}>
          {alertConfig.message}
        </Alert>
      </Snackbar>
      <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', paddingLeft: 6, paddingBottom: 6 }}>
        <Box style={{ flex: 1, flexDirection: 'column', alignItems: 'center' }}>
          <Typography color="primary" style={{ fontSize: '0.7em' }}>
            {'Cabecera Visible '}
          </Typography>
          <ButtonGroup disableElevation>
            <Tooltip arrow title="Cabecera siempre visible">
              <Button
                style={{ padding: 0 }}
                size="small"
                color={!(value as HeaderValues).minimize ? 'primary' : 'default'}
                variant={!(value as HeaderValues).minimize ? 'contained' : 'outlined'}
                onClick={() => {
                  handleChangeHeaderVisibleMode(false)
                }}
              >
                {'Si'}
              </Button>
            </Tooltip>
            <Tooltip arrow title="Cabecera oculta">
              <Button
                style={{ padding: 0 }}
                size="small"
                color={(value as HeaderValues).minimize ? 'primary' : 'default'}
                variant={(value as HeaderValues).minimize ? 'contained' : 'outlined'}
                onClick={() => {
                  handleChangeHeaderVisibleMode(true)
                }}
              >
                {'No'}
              </Button>
            </Tooltip>
          </ButtonGroup>
        </Box>
      </Box>
      <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', paddingLeft: 6, paddingBottom: 6 }}>
        <Box style={{ flex: 1, flexDirection: 'column', alignItems: 'center' }}>
          <Typography color="primary" style={{ fontSize: '0.7em', paddingBottom: 4 }}>
            {'Links Asociados '}
          </Typography>
          {(value as HeaderValues).links.map((link, index) => {
            return (
              <Box style={{ display: 'flex', flexDirection: 'row', marginTop: 4 }} key={index}>
                <TextField
                  variant="outlined"
                  size="small"
                  classes={{ root: 'adorned-text-input-sm' }}
                  label="Label Link "
                  value={link.label}
                  style={{ marginRight: 4 }}
                />
                <Tooltip arrow title={link.link}>
                  <TextField
                    variant="outlined"
                    size="small"
                    classes={{ root: 'adorned-text-input-sm' }}
                    label=" Link "
                    value={link.link}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip arrow title="Eliminar Link">
                            <IconButton
                              size="small"
                              onClick={() => {
                                handleDeleteLink(link.label)
                              }}
                            >
                              <Icon style={{ fontSize: 20, color: 'red' }}>remove_circle</Icon>
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Tooltip>
              </Box>
            )
          })}

          <Box style={{ display: 'flex', flexDirection: 'row', marginTop: 4, marginBottom: 4, paddingBottom: 4 }}>
            <TextField
              variant="outlined"
              size="small"
              classes={{ root: 'adorned-text-input-sm' }}
              label="Label Link "
              onChange={(e) => {
                setKeyLink(e.target.value)
              }}
              value={keyLink}
              style={{ marginRight: 4 }}
            />

            <TextField
              variant="outlined"
              size="small"
              classes={{ root: 'adorned-text-input-sm' }}
              label=" Link "
              onPaste={handleOnPasteLink}
              value={pasteLink.link}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip arrow title="Agregar Link">
                      <IconButton
                        size="small"
                        onClick={() => {
                          pasteLink.paste ? handleLinkPaste() : handleLinkExternal()
                        }}
                      >
                        <Icon style={{ fontSize: 20 }}>add_circle</Icon>
                      </IconButton>
                    </Tooltip>
                    {pasteLink.paste && (
                      <Tooltip arrow title="Eliminar Link Pegado">
                        <IconButton
                          size="small"
                          onClick={() => {
                            handleDeleteLinkPaste()
                          }}
                        >
                          <Icon style={{ fontSize: 20 }}>remove_circle</Icon>
                        </IconButton>
                      </Tooltip>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  )
}
