import { Icon, IconButton, Popover, TextField, Tooltip, Typography } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import React from 'react'
import { SketchPicker } from 'react-color'
import { IElement, IRGBAColor } from 'types'

interface Props {
    element: IElement,
    updateElement: (newElement: IElement) => void,
}

interface PopoverConfig {
    element: HTMLButtonElement | null,
    id: string
}

type Orientation = "v" | "h"

export interface ILineOrientation {
    orientation: Orientation
}

const popoverElements = {
    bgColor: "bgColor",
}

const LineProps = React.memo((props: Props) => {
    const { updateElement } = props
    const [value, setValue] = React.useState<React.CSSProperties & ILineOrientation>(props.element.value)
    const [bgColor, setBgColor] = React.useState<IRGBAColor>( {
        value:value['backgroundColor'] as string
    } as IRGBAColor)
    const [popover, setPopover] = React.useState<PopoverConfig>({} as PopoverConfig)
    const handleClosePopover = () => setPopover({} as PopoverConfig)
    React.useEffect(() => {
        if (props.element.value !== value) { setValue(props.element.value) }
    }, [props.element.value, value])

    return (
        <div className="panel-container px-2 pt-4 pb-2">
            <Typography color="primary" className="panel-label" variant="caption">{"Línea"}</Typography>
            <div className="flex items-center my-1">
                <Tooltip arrow title="Color de línea" placement="left">
                    <ToggleButton size="small" value="bg_color" onClick={(e) => setPopover({ element: e.currentTarget, id: popoverElements.bgColor })}>
                        <Icon fontSize="small">format_color_fill</Icon>
                        <Icon fontSize="small">expand_more</Icon>
                    </ToggleButton>
                </Tooltip>
                <TextField
                    size="small"
                    label="Grosor"
                    variant="outlined"
                    classes={{ root: "text-input-sm" }}
                    value={value?.height ? value.height : 2}
                    style={{ marginLeft: 5 }}
                    fullWidth
                    type="number"
                    onChange={(e) => {
                        updateElement({
                            ...props.element,
                            value: { ...value, height: e.target.value },
                        })
                    }}
                />
                <Popover
                    id={popoverElements.bgColor}
                    anchorEl={popover.element}
                    open={popover.id === popoverElements.bgColor && Boolean(popover.element)}
                    onClose={handleClosePopover}
                >
                    <div className={"flex items-center mt-1"}>
                        <Typography align="center" className="pl-4 w-full flex-grow" variant="caption" style={{ letterSpacing: 0.5 }}>
                            {"Color de línea"}
                        </Typography>
                        <IconButton style={{ padding: 4 }} onMouseDown={handleClosePopover}>
                            <Icon style={{ fontSize: 15 }}>close</Icon>
                        </IconButton>
                    </div>
                    <SketchPicker
                        color={bgColor.value}
                        onChange={(color) => {
                            setBgColor({
                                r: color.rgb.r,
                                g: color.rgb.g,
                                b: color.rgb.g,
                                a: color.rgb.a,
                                value: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
                            })
                        }}
                        onChangeComplete={(color) => {
                            updateElement({
                                ...props.element,
                                value: { ...value, backgroundColor: color.hex },
                            })
                        }}
                    />
                </Popover>
            </div>
            <div className="flex items-center my-1">
                <ToggleButtonGroup value={value.orientation}>
                    <ToggleButton
                        size="small"
                        value="h"
                        onClick={(e) => {
                            updateElement({
                                ...props.element,
                                value: {
                                    ...value,
                                    orientation: "h",
                                },
                                size: { width: props.element.size.height, height: props.element.size.width }
                            })
                        }}
                    >
                        <Icon fontSize="small">swap_horizontal</Icon>
                        <Typography
                            style={{ color: "#626262", lineHeight: "inherit", textTransform: "capitalize" }}
                            variant="caption"
                            className="px-2"
                        >
                            {"Horizontal"}
                        </Typography>
                    </ToggleButton>
                    <ToggleButton
                        size="small"
                        value="v"
                        onClick={(e) => {
                            updateElement({
                                ...props.element,
                                value: {
                                    ...value,
                                    orientation: "v",
                                },
                                size: { width: props.element.size.height, height: props.element.size.width }
                            })
                        }}
                    >
                        <Icon fontSize="small">swap_vertical</Icon>
                        <Typography
                            style={{ color: "#626262", lineHeight: "inherit", textTransform: "capitalize" }}
                            variant="caption"
                            className="px-2"
                        >
                            {"Vertical"}
                        </Typography>
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
        </div>
    )
})

export default LineProps
