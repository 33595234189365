import { IFont, IUsedFont, useFonts } from 'hooks'
import { getConfigurations, getFiltersEntidades, getLocationInfo } from 'lib'
import React from 'react'
import WebfontLoader from '@dr-kobros/react-webfont-loader'
import { IPosition } from 'types'
import { Filters, HookFilterRequest, TerritorialOrganization, useFilterObject } from 'components/Text/Filter'

export interface Props {
  filesContext: string
  fonts: IFont[]
  defaultFont: IFont
  addUsedFont: (newFont: IFont) => void
  addStoredFont: (newFont: IUsedFont) => void
  usedFonts: IUsedFont[]
  setUsedFonts: React.Dispatch<IUsedFont[]>
  selectionState: any
  setSelectionState: React.Dispatch<any>
  setCopyExternalState: React.Dispatch<IPosition | undefined>
  copyExternalState: IPosition | undefined
  generalLink: boolean
  setGeneralLink: React.Dispatch<boolean>
  checkLink: boolean
  setCheckLink: React.Dispatch<boolean>
  territorialOrganization: TerritorialOrganization[]
  entityFilters: Filters[]
  hookFilterRequest: HookFilterRequest
}

export const ConfigContext = React.createContext({} as Props)

const Context = ({ children }: any) => {
  const [filesContext, setFilesContext] = React.useState<string>('')
  const { fonts, defaultFont, addUsedFont, usedFonts, addStoredFont, setUsedFonts } = useFonts()
  const [fontsConfig, setFontsConfig] = React.useState<any>({ google: { families: 'Montserrat:400' } })
  const [selectionState, setSelectionState] = React.useState<any>()
  const [checkLink, setCheckLink] = React.useState(false)
  const [copyExternalState, setCopyExternalState] = React.useState<IPosition | undefined>()
  const [generalLink, setGeneralLink] = React.useState<boolean>(false)
  const { editValueForRequestEntidadesObject, request: requestEntity, changeRequest, setDefaultRequest } = useFilterObject()

  React.useEffect(() => {
    if (usedFonts.length > 0) {
      const families: string[] = []
      for (const font of usedFonts) {
        families.push(`${font.font}:${font.weights.join(',')}`)
      }
      setFontsConfig({ google: { families } })
    }
  }, [usedFonts])

  React.useEffect(() => {
    const loadInitialConfiguration = async () => {
      const result = await getConfigurations()
      setFilesContext(result[0])
    }
    loadInitialConfiguration()
  }, [])

  const [territorialOrganization, setTerritorialOrganization] = React.useState<TerritorialOrganization[]>([] as TerritorialOrganization[])
  const [entityFilters, setEntityFilters] = React.useState<Filters[]>([] as Filters[])

  React.useEffect(() => {
    const loadInitTerritorialOrganization = async () => {
      const result = await getLocationInfo()
      setTerritorialOrganization(result as TerritorialOrganization[])
    }
    loadInitTerritorialOrganization()
  }, [])

  React.useEffect(() => {
    const loadInitEntityFilters = async () => {
      const result = await getFiltersEntidades()
      setEntityFilters(result as Filters[])
    }
    loadInitEntityFilters()
  }, [])

  return (
    <ConfigContext.Provider
      value={{
        filesContext,
        defaultFont,
        fonts,
        addUsedFont,
        addStoredFont,
        usedFonts,
        setUsedFonts,
        selectionState,
        setSelectionState,
        copyExternalState,
        setCopyExternalState,
        generalLink,
        setGeneralLink,
        checkLink,
        setCheckLink,
        territorialOrganization,
        entityFilters,
        hookFilterRequest: {
          editValueForRequestEntidadesObject,
          request: requestEntity,
          changeRequest,
          setDefaultRequest,
        },
      }}
    >
      <WebfontLoader config={fontsConfig}>{children}</WebfontLoader>
      {/* {
                usedFonts.length > 0 &&
                <GoogleFontLoader fonts={usedFonts} />

            } */}
    </ConfigContext.Provider>
  )
}

export default Context
