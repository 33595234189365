import { Box, Checkbox } from '@material-ui/core'
import { LocationContext } from 'context'
import { useGetConferenceHelper } from '../hook'
import React from 'react'
import { ConferenceCard } from './ConferenceCard'
import { Pagination, Skeleton } from '@material-ui/lab'
import { useConferenceProvider } from '../hook/useConferenceProvider'

type Props = {
  updateSelectedConference: (selectedConferenceId: number) => void
  selectedConferenceId: number
}
export const ConferenceSelector = (props: Props) => {
  const { updateSelectedConference, selectedConferenceId } = props
  const { userId } = React.useContext(LocationContext)

  const { paginationResponse, getConferencesByUserId, loading } = useGetConferenceHelper()

  const { result } = paginationResponse

  const { getMessages, resetMessages } = useConferenceProvider()

  React.useEffect(() => {
    if (userId && userId > 0) {
      getConferencesByUserId(userId, 1)
    }
  }, [userId])

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'space-around',
        gap: 10,
        paddingTop: 10,
        paddingLeft: 10,
        height: '100%',
      }}
      id="conferenceSelector"
    >
      <Box
        style={{
          width: '100%',
          minWidth: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'flex-start',
        }}
      >
        <Pagination
          count={Math.ceil(paginationResponse.count / paginationResponse.limit)}
          page={paginationResponse.page + 1}
          style={{
            height: 50,
            maxHeight: 50,
          }}
          onChange={(event, page) => {
            getConferencesByUserId(userId, page)
          }}
        />
      </Box>

      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 10,
          overflowY: 'auto',
          justifyContent: 'center',
        }}
      >
        {loading &&
          Array.from(Array(10).keys()).map((index) => (
            <Skeleton
              key={index}
              variant="rect"
              animation="wave"
              style={{
                height: 55,
                maxWidth: 270,
                maxHeight: 55,
                minWidth: 270,
                width: 270,
                minHeight: 55,
                backgroundColor: '#ffffff',
                borderRadius: '0px',
                borderTopRightRadius: '20px',
                borderBottomLeftRadius: '20px',
                borderColor: '#e5e5e3',
                borderStyle: 'solid',
                borderWidth: '1px',
                boxSizing: 'content-box',
              }}
            />
          ))}
        {!loading &&
          result.map((conference) => {
            return (
              <ConferenceCard
                key={conference.conferenceId}
                communicationTypeDescription={conference.typeCommunicationDescription}
                countMembers={conference.countMembers}
                img={conference.img}
                title={conference.title}
                endAdornment={
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <Checkbox
                      checked={selectedConferenceId === conference.conferenceId}
                      onChange={() => {
                        resetMessages()

                        if (selectedConferenceId === conference.conferenceId) {
                          updateSelectedConference(0)
                        } else {
                          updateSelectedConference(conference.conferenceId)
                          getMessages(conference.conferenceId, 1)
                        }
                      }}
                    />
                  </Box>
                }
              />
            )
          })}
      </Box>

      <Box
        style={{
          width: '100%',
          minWidth: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'flex-end',
        }}
      >
        <Pagination
          count={Math.ceil(paginationResponse.count / paginationResponse.limit)}
          page={paginationResponse.page + 1}
          style={{
            height: 50,
            maxHeight: 50,
          }}
          onChange={(event, page) => {
            getConferencesByUserId(userId, page)
          }}
        />
      </Box>
    </Box>
  )
}
