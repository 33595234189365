import { Box, Button, ButtonGroup, InputAdornment, MenuItem, TextField, Tooltip, Typography } from "@material-ui/core";
import { Pagination } from "components/FolderProps/FolderProps";

import { Fragment } from "react";
import { IElement } from "types";
import React from 'react'
import { ELEMENT_TYPES } from "lib";
export interface Certifier {
  name: string;
  lastName: string;
  avatar: string;
}

export interface ICardPerxona {
  certifier: Certifier | null;
  name: string;
  lastName: string | null;
  country: string;
  cardColor: string;
  backgroundColor: string;
  description: string;
  avatar: string;
  affiliateId: string;
  cardName: string;
  cardId: number;
  cardType: number;
}

export type IPerxona = {
  idPerxona: number
  namePerxona: string
  avatar: string
}
export interface IPerxonaResponse {
  perxonas: IPerxona[];
  groupId: number;
}

export type ICardGroup = {
  cardId: number
  groupName: string
  countPerxonas: number
  countSubGroup: number
  typeGroup: string
  insertDate: Date
  updateDate: Date
  subGroupsId: number[]
  favorite: boolean
  groupIcon: string
  groupOriginId: number
  parentGroupId: number
  description: string
}


export interface ICardPersonality {
  cardId: number;
  personality: string;
  title: string;
  phrase: string;
  description: string;
  image: string;
}

export interface CardViewMinimizeProps {
  minimize: boolean;
  enableMinimize: boolean;
}

export interface CardContainerValues {
  cardViewMinimizeProps: CardViewMinimizeProps;
  cardPaginationProps: CardPaginationProps;
  cardsChosen: number[];
  tittle: string;
  minWidth?: string;
  minHeight?: string;
  maxHeight?: string;
  perxonaId?: number;
  paginationPosition?: Pagination
}


export type CardViewMode = "vertical" | "horizontal";
export type EditionCardMode = "selection" | "reorder" | "none";

interface Props {
  element: IElement,
  updateElement: (newElement: IElement) => void
}

interface CardPaginationProps {
  itemsPerPage: number;
  cardViewMode: CardViewMode;
}



export type RelationShipStatus = "ya son amigos!" | "no son amigos!" | "son la misma perxona!"



const CardProps = React.memo((props: Props) => {

  const { element, updateElement } = props;
  const { value } = element;
  const { cardViewMinimizeProps, cardPaginationProps, tittle } = value
  const { enableMinimize } = cardViewMinimizeProps
  const { itemsPerPage, cardViewMode } = cardPaginationProps
  const [tittleCard, setTittleCard] = React.useState<string>(tittle ?? "Tarjeta")
  React.useEffect(() => {
    setTittleCard(tittle)
  }, [tittle])
  const handleChangeViewMode = React.useCallback((viewMode: CardViewMode) => {
    const updatedElement = {
      ...element,
      value: {
        ...value,
        cardPaginationProps: {
          ...value.cardPaginationProps,
          cardViewMode: viewMode
        }
      }
    }
    updateElement(updatedElement);
  }, [element, value])

  const handleEnableMinimize = React.useCallback((enable: boolean) => {
    const updatedElement = {
      ...element,
      value: {
        ...value,
        cardViewMinimizeProps: {
          ...value.cardViewMinimizeProps,
          enableMinimize: enable,
          minimize: enable
        }
      }
    };

    updateElement(updatedElement);
  }, [element, value])

  const handleChangeTittle = React.useCallback(() => {
    console.log((tittleCard.length > 0) ? tittleCard : element.type === ELEMENT_TYPES.practiceCard ? "Mis Tarjetas" : "Mis Personas")
    console.log(tittleCard.length, tittleCard.length > 0)
    const updatedElement = {
      ...element, value: {
        ...value,
        tittle: (tittleCard.length > 0 || tittle.length > 0) ? tittleCard : element.type === ELEMENT_TYPES.practiceCard ? "Mis Tarjetas" : "Mis Personas"
      }
    }
    updateElement(updatedElement)
  }, [element, value, tittleCard, tittle])

  return (
    <Fragment>
      <div className="panel-container px-0 pt-4">
        <Typography color="primary" className="panel-label" variant="caption"
          style={{ marginTop: 4 }}>{"Propiedades Tarjeta"}</Typography>
      </div>
      <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', paddingLeft: 6 }}>

        {element.type === ELEMENT_TYPES.practiceCard && 
        <Box style={{ flex: 1, flexDirection: 'column', alignItems: 'center' }}>
          <Typography color="primary" style={{ fontSize: "0.7em" }}>{"Vista Tarjetas"}</Typography>
          <ButtonGroup disableElevation>
            <Tooltip arrow title="Tarjetas vista vertical">
              <Button style={{ padding: 0 }} size="small"
                color={cardViewMode === "vertical" ? "primary" : "default"}
                variant={cardViewMode === "vertical" ? "contained" : "outlined"}
                onClick={() => handleChangeViewMode("vertical")}>
                {"V"}
              </Button>
            </Tooltip>
            <Tooltip arrow title="Tarjetas vista Horizontal">
              <Button style={{ padding: 0 }} size="small"
                color={cardViewMode === "horizontal" ? "primary" : "default"}
                variant={cardViewMode === "horizontal" ? "contained" : "outlined"}
                onClick={() => handleChangeViewMode("horizontal")}>
                {"H"}</Button>
            </Tooltip>
          </ButtonGroup>
        </Box>}
        {
          <Box style={{ flex: 1, flexDirection: 'column', alignItems: 'center', display: element.type === ELEMENT_TYPES.practiceCard ? 'inline' : 'flex' }}>
            <Typography color="primary" style={{ fontSize: "0.7em" }}>{"Vista Inicial"}</Typography>
            <ButtonGroup disableElevation>
              <Tooltip arrow title="El práctico se mostrara Minimizado ">
                <Button style={{ padding: 0 }} size="small"
                  color={enableMinimize ? "primary" : "default"}
                  variant={enableMinimize ? "contained" : "outlined"}
                  onClick={() => handleEnableMinimize(true)}>
                  {"Min"}</Button>
              </Tooltip>
              <Tooltip arrow title="El práctico se mostrara Maximizado">
                <Button style={{ padding: 0 }} size="small"
                  color={!enableMinimize ? "primary" : "default"}
                  variant={!enableMinimize ? "contained" : "outlined"}
                  onClick={() => handleEnableMinimize(false)}>
                  {"Max"}</Button>
              </Tooltip>
            </ButtonGroup>
          </Box>}
      </Box>


      <Box className="pt-2 " style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', paddingRight: 8, paddingLeft: 6 }}>
        <TextField
          label="Paginación"
          value={value.paginationPosition ?? ""}
          fullWidth
          variant="outlined"
          size="small"
          classes={{ root: "text-input-sm" }}
          onChange={(e) => {
            const updatedElement = {
              ...element,
              value: {
                ...element.value,
                paginationPosition: e.target.value as Pagination
              }
            };

            updateElement(updatedElement);
          }}
          select
        >
          <MenuItem value={"top"}>{"Arriba"}</MenuItem>
          <MenuItem value={"bottom"}>{"Abajo"}</MenuItem>
          <MenuItem value={"both"}>{"Ambas"}</MenuItem>
        </TextField>

        <Tooltip arrow title="Cantidad de tarjetas por pagina">
          <TextField
            fullWidth
            label="# tarjetas"
            variant="outlined"
            size="small"
            classes={{ root: "adorned-text-input-sm" }}
            style={{ marginLeft: 8, }}
            value={itemsPerPage}
            type="number"

            onChange={(e) => {
              const value = e.target.value

              if (parseInt(value) > 0) {
                const updatedElement = {
                  ...element,
                  value: {
                    ...element.value,
                    cardPaginationProps: {
                      ...element.value.cardPaginationProps,
                      itemsPerPage: parseInt(value)
                    }
                  }
                }
                updateElement(updatedElement)
              }
            }}
            onBlur={(e) => {
              const value = e.target.value

              if (value === "" || parseInt(value) <= 0) {
                const updatedElement = {
                  ...element, value: {
                    ...element.value,
                    cardPaginationProps: {
                      ...element.value.cardPaginationProps,
                      itemsPerPage: 1
                    }
                  }
                }
                updateElement(updatedElement)
              }
            }}
          />
        </Tooltip>
      </Box>



      <Box className="pt-2 pb-2" style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-center', paddingLeft: 6, justifyContent: "center" }}>
        <Box style={{ flex: 1, flexDirection: 'column', alignItems: 'flex-start', paddingRight: 8 }}>
          <Tooltip arrow title="Título que se mostrara en el práctico">
            <TextField
              fullWidth
              label="Título"
              variant="outlined"
              size="small"
              classes={{ root: "adorned-text-input-sm" }}
              style={{ marginRight: 15, }}
              value={tittleCard}
              type="text"
              inputProps={{ maxLength: 40 }}
              onChange={(e) => setTittleCard(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault()
                  handleChangeTittle()
                }
              }}
              onBlur={handleChangeTittle}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" >
                    <Typography variant="overline" display="block">
                      {tittleCard.length}/40
                    </Typography>
                  </InputAdornment>
                ),
              }}

            />
          </Tooltip>
        </Box>
      </Box>


    </Fragment>
  )
})


export default CardProps;


