import { Checkbox, FormControl, FormControlLabel, FormGroup, Icon, IconButton, Menu, MenuItem, Popover, TextField, Tooltip, Typography } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { DEEPLINK_TAG, DEFAULT_CURTAIN_VALUE, findPage, HASH_PARAM } from 'lib'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { IElement } from 'types'

interface Props {
    element: IElement,
    updateElement: (newElement: IElement) => void,
    pageId: number
}

export type CurtainType = "appear" | "disappear"

export interface ICurtainValue {
    tooltip?: string
    type: CurtainType
    link?: string
    newTab?: boolean
}

interface PopoverConfig {
    element: HTMLButtonElement | null,
    id: string
}

const popoverElements = {
    link: "link",
    tooltip: "tooltip"
}

const CurtainProps = React.memo((props: Props) => {
    const { updateElement } = props
    const [popover, setPopover] = React.useState<PopoverConfig>({} as PopoverConfig)
    const [tooltip, setTooltip] = React.useState<string>(props.element.value.tooltip)
    const [enabled, setEnabled] = React.useState<boolean>(Boolean(props.element.curtain))
    const [type, setType] = React.useState<CurtainType>(props.element.curtainValue?.type ?? "appear")
    const handleClosePopover = () => setPopover({} as PopoverConfig)
    const handleCommitChanges = () => { updateElement({ ...props.element, curtainValue: { tooltip, type } }) }

    React.useEffect(() => {
        setEnabled(Boolean(props.element.curtain))
    }, [props.element.curtain])

    React.useEffect(() => {
        if (props.element.curtainValue?.type) { setType(props.element.curtainValue?.type) }
    }, [props.element.curtainValue?.type])

    React.useEffect(() => {
        if (props.element.curtainValue?.tooltip) { setTooltip(props.element.curtainValue?.tooltip) }
    }, [props.element.curtainValue?.tooltip])

    return (
        <div className="panel-container px-2 pt-4 pb-2 mt-2">
            <Typography color="primary" className="panel-label" variant="caption">{"Cortina"}</Typography>
            <div className="flex">
                <FormControl className="flex-1" component="fieldset" style={{ display: "flex", justifyContent: "center" }}>
                    <FormGroup>
                        <div className="flex flex-row items-center">
                            <Checkbox
                                onChange={(e) => {
                                    updateElement({ ...props.element, curtain: e.target.checked })
                                }}
                                color="primary"
                                size="small"
                                checked={enabled}
                                name="newTab" />
                            <Typography variant="caption">{"Habilitar cortina"}</Typography>
                        </div>
                    </FormGroup>
                </FormControl>
            </div>
            <div className="mt-2 flex items-center">
                <ToggleButtonGroup>
                    <Tooltip arrow title="Comentario" placement="left">
                        <ToggleButton disabled={!enabled} size="small" value="tooltip" onClick={(e) => setPopover({ element: e.currentTarget, id: popoverElements.tooltip })}>
                            <Icon fontSize="small">chat_bubble</Icon>
                            <Icon fontSize="small">expand_more</Icon>
                        </ToggleButton>
                    </Tooltip>
                </ToggleButtonGroup>
                <div className="flex-grow ml-2">
                    <TextField
                        disabled={!enabled}
                        label="Efecto"
                        value={type}
                        fullWidth
                        variant="outlined"
                        size="small"
                        classes={{ root: "text-input-sm" }}
                        onChange={(e) => {
                            const selected = e.target.value
                            const curtainValue = props.element.curtainValue
                            updateElement({
                                ...props.element,
                                curtainValue: { ...curtainValue, type: (selected as CurtainType) }
                            })
                        }}
                        select
                    >
                        <MenuItem value={"appear"}>{"Aparecer"}</MenuItem>
                        <MenuItem value={"disappear"}>{"Desaparacer"}</MenuItem>
                    </TextField>
                </div>
            </div>
            <Popover
                id={popoverElements.tooltip}
                anchorEl={popover.element}
                open={popover.id === popoverElements.tooltip && Boolean(popover.element)}
                onClose={handleClosePopover}
            >
                <div className="px-2" style={{ height: 60 }}>
                    <div className={"flex items-center mt-1"}>
                        <Typography align="center" className="pl-4 w-full flex-grow" variant="caption" style={{ letterSpacing: 0.5 }}>
                            {"Comentario"}
                        </Typography>
                        <IconButton style={{ padding: 4 }} onMouseDown={handleClosePopover}>
                            <Icon style={{ fontSize: 15 }}>close</Icon>
                        </IconButton>
                    </div>
                    <TextField
                        size="small"
                        placeholder="Comentario"
                        variant="outlined"
                        classes={{ root: "text-input-sm" }}
                        fullWidth
                        value={tooltip}
                        style={{ flex: 1 }}
                        onChange={(e) => setTooltip(e.target.value)}
                        onBlur={handleCommitChanges}
                        onKeyDown={(e) => { if (e.key === "Enter") { handleCommitChanges() } }}
                    />
                </div>
            </Popover>
        </div>
    )
})

export default CurtainProps
