import React from 'react'
export interface HookOrder {
  handleChangeOrder: (move: Move, id: number) => number[]
  handleAddChosen: (id: number, onAdd: (result: number[]) => void) => void
  handleRemoveChosen: (id: number, onRemove: (result: number[]) => void) => void
  getOrder: () => number[]
  order: number[]

}
interface Props {
  toOrder: number[]
}
export type Move = "RIGHT" | "LEFT"

export const useOrder = (props: Props): HookOrder => {

  const [order, setOrder] = React.useState<number[]>(props.toOrder)

  const handleAddChosen = (id: number, onAdd: (result: number[]) => void) => {
    setOrder(prev => {
      const res = [...prev, id]
      onAdd(res)
      return res
    })


  }
  const handleRemoveChosen = (id: number, onRemove: (result: number[]) => void) => {

    setOrder(prev => {
      const res = prev.filter(item => item !== id)
      onRemove(res)
      return res
    })
  }

  const getOrder = React.useCallback(() => {
    return order
  }, [order])
  const handleChangeOrder = React.useCallback((move: Move, id: number) => {

    const index = props.toOrder.indexOf(id)
    switch (move) {
      case "LEFT":
        const nextCard = props.toOrder[index - 1]
        const newOrderCardsChossen = [...props.toOrder]
        newOrderCardsChossen[index] = nextCard
        newOrderCardsChossen[index - 1] = id
        setOrder(newOrderCardsChossen.filter(item => item !== null && item !== undefined))
        return newOrderCardsChossen.filter(item => item !== null && item !== undefined)
      case "RIGHT":
        const nextCardRight = props.toOrder[index + 1]
        const newOrderCardsChossenRight = [...props.toOrder]
        newOrderCardsChossenRight[index] = nextCardRight
        newOrderCardsChossenRight[index + 1] = id
        setOrder(newOrderCardsChossenRight)
        return newOrderCardsChossenRight

    }

  }, [props.toOrder])


  return {
    handleChangeOrder,
    handleAddChosen,
    handleRemoveChosen,
    getOrder,
    order
  }
}