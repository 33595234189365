import React from 'react'
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, Icon, IconButton, Menu, MenuItem, Popover, Tooltip, Typography } from '@material-ui/core'
import { IElement } from 'types'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import { PopoverConfig } from 'components/ImageProps/ImageProps'
import { DEEPLINK_TAG, findLink, findObject, HASH_PARAM, ID_LINK_TAG } from 'lib'
import { useHistory } from 'react-router'
import { ConfigContext, LocationContext } from 'context'

interface Props {
    element: IElement,
    updateElement: (newElement: IElement) => void,
    pageId: any
}

const popoverElements = {
    link: "link",
}

const TextProps = React.memo((props: Props) => {
    const { setGeneralLink } = React.useContext(ConfigContext)
    const { tabId } = React.useContext(LocationContext)
    const { updateElement, pageId } = props
    const [style, setStyle] = React.useState<React.CSSProperties>(props.element.style)
    const [popover, setPopover] = React.useState<PopoverConfig>({} as PopoverConfig)
    const handleClosePopover = () => setPopover({} as PopoverConfig)
    const [submenu, setSubmenu] = React.useState<null | HTMLElement>(null)
    const history = useHistory()
    const [link, setLink] = React.useState<string>(props.element.link ?? "")
    const [openNewTab, setOpenNewTab] = React.useState<boolean>(props.element.openNewTab ?? false)
    const handleExternal = () => {
        setGeneralLink(true)
        findObject(pageId, tabId)
    }
    const handleInternal = () => {
        setGeneralLink(true)
        history.push({ pathname: "/selection", state: { element: props.element } })
    }
    const handleLink = () => {
        findLink(pageId, -1, props.element.id, tabId)
    }
    const handleDeleteLink = () => {
        updateElement({
            ...props.element,
            style,
            link: "",
            openNewTab
        })
    }
    React.useEffect(() => {
        if (props.element.style !== style) { setStyle(props.element.style) }
    }, [props.element.style])
    React.useEffect(() => {
        setOpenNewTab(Boolean(props.element.openNewTab))
    }, [props.element.openNewTab])
    React.useEffect(() => {
        setLink(props.element.link ?? "")
    }, [props.element.link])

    React.useEffect(() => {
        if (props.element.newInstance ?? false) {

            updateElement({ ...props.element, ...{ openNewTab: true, newInstance: false } })

        } else if (props.element.newInstance === undefined) {
            updateElement({ ...props.element, ...{ openNewTab: true, newInstance: false } })

        }
    }, [])
    return (
        <div className="panel-container px-2 pt-4 pb-2">
            <Typography color="primary" className="panel-label" variant="caption">{"Texto"}</Typography>
            <div className="flex items-center">
                <ToggleButtonGroup value={style.justifyContent} size="small">
                    <Tooltip arrow title="Arriba">
                        <ToggleButton selected={style.justifyContent === 'flex-start'} value="flex-start" onClick={() => updateElement({ ...props.element, style: { ...style, justifyContent: "flex-start" }, curtainValue: { ...props.element.curtainValue, link, type: props.element.curtainValue?.type ?? "appear" } })} size="small">
                            <Icon style={{ transform: "rotate(90deg)" }} fontSize="small">flip</Icon>
                        </ToggleButton>
                    </Tooltip>
                    <Tooltip arrow title="Centro">
                        <ToggleButton selected={style.justifyContent === 'center'} value="center" onClick={() => updateElement({ ...props.element, style: { ...style, justifyContent: "center", }, curtainValue: { ...props.element.curtainValue, link, type: props.element.curtainValue?.type ?? "appear" } })} size="small">
                            <Icon fontSize="small">center_focus_strong</Icon>
                        </ToggleButton>
                    </Tooltip>
                    <Tooltip arrow title="Abajo">
                        <ToggleButton selected={style.justifyContent === 'flex-end'} value="flex-end" onClick={() => updateElement({ ...props.element, style: { ...style, justifyContent: "flex-end", }, curtainValue: { ...props.element.curtainValue, link, type: props.element.curtainValue?.type ?? "appear" } })} size="small">
                            <Icon style={{ transform: "rotate(270deg)" }} fontSize="small">flip</Icon>
                        </ToggleButton>
                    </Tooltip>
                </ToggleButtonGroup>
            </div>
            <Typography color="primary" className="mt-8" variant="caption">{"Link"}</Typography>
            <div className="flex items-center">
                <div className="flex items-center">
                    <Tooltip arrow title={Boolean(link) ? link.split(ID_LINK_TAG)[0] : ""}>
                        <div>
                            <Button
                                size="small"
                                variant="outlined"
                                fullWidth
                                style={{ marginRight: 10, width: 140, maxWidth: 140 }}
                                onClick={handleLink}
                                disabled={Boolean(link)}
                            >
                                <p className="truncate" style={{ textTransform: "none" }}>{Boolean(link) ? link.split(ID_LINK_TAG)[0] : "Seleccionar link"}</p>
                            </Button>
                        </div>
                    </Tooltip>
                    {
                        Boolean(link) &&
                        <Tooltip arrow title="Eliminar link">
                            <IconButton
                                onClick={handleDeleteLink}
                                style={{ marginRight: 10 }}
                                size="small"
                            >
                                <Icon
                                    fontSize="small"
                                    color="action"
                                >
                                    close
                                </Icon>
                            </IconButton>
                        </Tooltip>
                    }
                    <ToggleButtonGroup>
                        <Tooltip arrow title="Deeplink" placement="left">
                            <ToggleButton
                                selected={link?.includes(DEEPLINK_TAG) || link?.includes(HASH_PARAM)}
                                size="small"
                                value="restore"
                                onClick={(e) => setSubmenu(e.currentTarget)}
                                disabled={Boolean(link)}
                            >
                                <Icon fontSize="small">language</Icon>
                            </ToggleButton>
                        </Tooltip>
                        <Menu
                            anchorEl={submenu}
                            keepMounted
                            open={Boolean(submenu)}
                            onClose={() => setSubmenu(null)}
                        >
                            <MenuItem
                                onClick={handleInternal}
                                dense
                            >
                                <Icon color="disabled" className="mr-1" fontSize="small">dashboard</Icon>
                                {"Interno"}
                            </MenuItem>
                            <MenuItem
                                dense
                                onClick={handleExternal}
                            >
                                <Icon color="disabled" className="mr-1" fontSize="small">web</Icon>
                                {"Externo"}
                            </MenuItem>
                        </Menu>
                    </ToggleButtonGroup>
                </div>
            </div>
            <div className="flex">
                <FormControl className="flex-1" component="fieldset" style={{ display: "flex", justifyContent: "center" }}>
                    <FormGroup className="pl-2">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={!link}
                                    onChange={(e) => {
                                        const { checked } = e.target
                                        updateElement({ ...props.element, link, openNewTab: checked, style: { ...style }, curtainValue: { ...props.element.curtainValue, link, type: props.element.curtainValue?.type ?? "appear" } })

                                    }}
                                    color="primary"
                                    size="small"
                                    checked={openNewTab}
                                    name="newTab"
                                />
                            }
                            label="Abrir en una nueva pestaña"
                        />
                    </FormGroup>
                </FormControl>
            </div>
            <Popover
                id={popoverElements.link}
                anchorEl={popover.element}
                open={popover.id === popoverElements.link && Boolean(popover.element)}
                onClose={handleClosePopover}
            >
                <div className="px-2" style={{ height: 80 }}>
                    <div className={"flex items-center mt-1 mb-2"}>
                        <Typography align="center" className="pl-4 w-full flex-grow" variant="caption" style={{ letterSpacing: 0.5 }}>
                            {"Link general del texto"}
                        </Typography>
                        <IconButton style={{ padding: 4 }} onMouseDown={handleClosePopover}>
                            <Icon style={{ fontSize: 15 }}>close</Icon>
                        </IconButton>
                    </div>
                </div>
            </Popover>
        </div>
    )
})

export default TextProps
