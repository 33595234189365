import React from 'react'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import { Snackbar } from '@material-ui/core'

interface Props {
    open: boolean,
    message: string,
    severity: AlertProps["severity"],
    handleClose: (event?: React.SyntheticEvent, reason?: string) => void
}

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Message = React.memo((props: Props) => {
    const { message, severity, open, handleClose } = props
    const onClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }
        handleClose()
    }
    return (
        <div>
            <Snackbar style={{ position: "fixed" }} anchorOrigin={{ horizontal: "right", vertical: "top" }} open={open} autoHideDuration={2000} onClose={onClose}>
                <Alert onClose={onClose} severity={severity}>{message}</Alert>
            </Snackbar>
        </div>
    )
})

export default Message
