import React, { useCallback, useMemo, useState } from 'react'
import { Editable, withReact, Slate } from 'slate-react'
import {
    createEditor,
    Node,
} from 'slate'
import { withHistory } from 'slate-history'
import { ISize } from 'types'
import { DEEPLINK_TAG, ELEMENT_TYPES, HASH_PARAM, ID_LINK_TAG } from 'lib'
import { ICurtainValue } from 'components'
import { Tooltip } from '@material-ui/core'

interface Props {
    id: string,
    value: any,
    style: React.CSSProperties,
    size: ISize,
    borderRadiusUnit: string,
    curtain?: boolean,
    curtainValue?: ICurtainValue
    link?: string
    openNewTab?: boolean
    exported?: boolean
}

const Text = (props: Props) => {
    const [open, setOpen] = React.useState(false)
    const [value, setValue] = useState<Node[]>(props.value)
    const renderElement = useCallback(elementProps => <Element {...elementProps} />, [])
    const renderLeaf = useCallback(leafProps => <Leaf {...leafProps} />, [])
    const editor = useMemo(() => withHistory(withReact(createEditor())), [])
    const getTransforms = React.useCallback(() => { if (props.style.transform) { return JSON.parse(props.style.transform) } else { return [0, 1] } }, [props.style.transform])
    const handleClick = () => {
        if (props.link) {
            if (props.link.includes(DEEPLINK_TAG)) {
                const element = document.getElementById(props.link.replace(DEEPLINK_TAG, ""))
                if (element) {
                    const y = element.getBoundingClientRect().top + window.scrollY
                    const x = element.getBoundingClientRect().left + window.scrollX
                    window.scroll({
                        top: y,
                        left: x,
                        behavior: 'smooth'
                    })
                }
                return
            }
            const link = props.link.split(ID_LINK_TAG)[0]
            if (!Boolean(props.openNewTab)) {
                window.parent.location.href = link
            } else {
                const win = window.open(link, '_blank')
                win?.focus()
            }
        }
    }
    return (
        <Tooltip open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(Boolean((props.curtain && props.curtainValue?.tooltip) ?? false))} arrow title={props.curtainValue?.tooltip ?? ""}>
            <div
                className={props.curtain ? (props.curtainValue?.type === "appear" || !Boolean(props.curtainValue?.type)) ? "curtain-appear-view" : "curtain-disappear-view" : ""}
                style={{
                    width: "100%",
                    height: "100%",
                    cursor: props.link ? "pointer" : "default"
                }}
                onClick={props.link ? () => handleClick() : undefined}
            >
                <div
                    id={`${ELEMENT_TYPES.text}_${props.id}`}
                    style={{
                        ...props.style,
                        height: props.size.height,
                        borderTopLeftRadius: props.style.borderTopLeftRadius + props.borderRadiusUnit,
                        borderBottomLeftRadius: props.style.borderBottomLeftRadius + props.borderRadiusUnit,
                        borderTopRightRadius: props.style.borderTopRightRadius + props.borderRadiusUnit,
                        borderBottomRightRadius: props.style.borderBottomRightRadius + props.borderRadiusUnit,
                        transform: `rotate(${getTransforms()[0]}deg) scaleX(${getTransforms()[1]})`,
                    }}
                >
                    <Slate editor={editor} value={value} onChange={(newValue: Node[]) => setValue(newValue)}>
                        <Editable
                            key={Math.random()}
                            renderElement={renderElement}
                            renderLeaf={renderLeaf}
                            placeholder=""
                            style={{ height: "100%", fontFamily: "Montserrat", display: "flex", flexDirection: "column", justifyContent: props.style.justifyContent }}
                            spellCheck
                            readOnly={true}
                        />
                    </Slate>
                </div>
            </div>
        </Tooltip>
    )
}

const Element = ({ attributes, children, element }) => {
    const handleClick = (url: string) => {
        const element = document.getElementById(url.replace(DEEPLINK_TAG, ""))
        if (element) {
            const y = element.getBoundingClientRect().top + window.scrollY
            const x = element.getBoundingClientRect().left + window.scrollX
            window.scroll({
                top: y,
                left: x,
                behavior: 'smooth'
            })
        }
    }
    switch (element.type) {
        case 'block-quote':
            return <blockquote {...attributes}>{children}</blockquote>
        case 'bulleted-list':
            return <ul {...attributes}>{children}</ul>
        case 'heading-one':
            return <h1 {...attributes}>{children}</h1>
        case 'heading-two':
            return <h2 {...attributes}>{children}</h2>
        case 'list-item':
            return <li {...attributes}>{children}</li>
        case 'numbered-list':
            return <ol {...attributes}>{children}</ol>
        case 'link':
            const href = (element.url as string).split(ID_LINK_TAG)[0]
            const tag = !element.url.includes(DEEPLINK_TAG) ?
                <a target="_blank" className={element.url.includes(HASH_PARAM) && element.url.includes("perxona") ? "internal-link link-no-hover" : element.entitySearch ? "entity-link entity-link-no-hover " : ""} {...attributes} href={href}

                >
                    {children}
                </a> :
                <u className="internal-link cursor-pointer" onClick={(e) => handleClick(element.url)}>{children}</u>
            return tag
        case 'align-center':
            return <p style={{ textAlign: "center" }} {...attributes}>{children}</p>
        case 'align-right':
            return <p style={{ textAlign: "right" }} {...attributes}>{children}</p>
        case 'align-justify':
            return <p style={{ textAlign: "justify" }} {...attributes}>{children}</p>
        default:
            return <p {...attributes}>{children}</p>
    }
}

const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    if (leaf.color) {
        children = <span style={{ color: leaf.color }}>{children}</span>
    }

    if (leaf.backgroundColor) {
        children = <span style={{ backgroundColor: leaf.backgroundColor }}>{children}</span>
    }

    if (leaf.fontFamily) {
        children = <span style={{ fontFamily: leaf.fontFamily }}>{children}</span>
    }

    if (leaf.fontSize) {
        children = <span style={{ fontSize: leaf.fontSize }}>{children}</span>
    }

    if (leaf.lineHeight) {
        children = <span style={{ lineHeight: leaf.lineHeight }}>{children}</span>
    }

    if (leaf.strike) {
        children = <span style={{ textDecoration: "line-through" }}>{children}</span>
    }

    if (leaf.textIndent) {
        children = <span style={{ textIndent: leaf.textIndent }}>{children}</span>
    }

    return <span {...attributes}>{children}</span>
}

export default Text
