import { Box, Checkbox, Grid, Icon, Paper, Tooltip, Typography } from '@material-ui/core'
import { ICardGroup } from 'components/CardProps/CardProps'
import { HookOrder, Move } from 'hooks'
import React from 'react'

const backgroundUrl = 'https://developer.perxona.com/perxona/javax.faces.resource/images/grupo.png.xhtml?ln=perxona'

type SelectionMode = 'selection' | 'reorder' | 'none' | 'perxonas'

type Props = {
  handleOnClickGroup: () => void
  card: ICardGroup
  mode: SelectionMode
  hookOrder: HookOrder
  handleOnCheck: (checked: boolean, cardId: number) => void
  handleOnReorder: (move: Move, cardId: number) => void
}
export const CardGroup = (props: Props) => {
  const { handleOnClickGroup, card, mode, hookOrder, handleOnCheck, handleOnReorder } = props
  const { order } = hookOrder
  const { groupName, favorite, countPerxonas, countSubGroup, typeGroup, groupOriginId } = card

  const [open, setOpen] = React.useState(false)
  const OnMouseEnter = () => {
    setOpen(true)
  }
  const OnMouseLeave = () => {
    setOpen(false)
  }
  return (
    <Paper
      elevation={3}
      variant="outlined"
      component="div"
      onMouseEnter={OnMouseEnter}
      onMouseLeave={OnMouseLeave}
      style={{
        backgroundImage: `url(${backgroundUrl})`,
        width: '190px',
        height: '210px',
        padding: 0,
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundColor: groupOriginId !== 0 ? '#EBFBFB' : '#fff',
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '20px',
        borderBottomLeftRadius: '20px',
        borderBottomRightRadius: '0px',
      }}
    >
      <Grid container spacing={0} style={{ width: '100%', height: '100%' }} direction="column" wrap="nowrap">
        <Grid item xs={12} style={{ maxHeight: '15px', marginTop: 8 }}>
          <Box fontSize={13} textAlign="center" style={{ fontFamily: 'Roboto' }}>
            {groupName}
          </Box>
        </Grid>
        <Grid item xs={12} style={{ maxHeight: '15px', marginTop: 16 }}>
          <Box fontSize={13} textAlign="center">
            {typeGroup} {countPerxonas} | {countSubGroup}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: 'flex', justifyContent: 'center', overflow: 'hidden', flexDirection: 'column', flexWrap: 'nowrap', marginTop: 16 }}
        >
          {(open || mode === 'selection' || mode === 'reorder') && (
            <Box
              width="100%"
              height="40px"
              style={{
                fontFamily: 'Roboto',
                alignSelf: 'flex-start',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#bdbdbd',
                opacity: '65%',
                maxHeight: '40px',
                minHeight: '40px',
              }}
            >
              {open && mode !== 'selection' && mode !== 'reorder' && (
                <Tooltip title="Ver perxonas del grupo" placement="top">
                  <Icon onClick={handleOnClickGroup}>contacts</Icon>
                </Tooltip>
              )}
              {mode === 'selection' && (
                <Checkbox
                  size="medium"
                  checked={order.includes(card.cardId)}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const { checked } = event.target
                    handleOnCheck(checked, card.cardId)
                  }}
                />
              )}
              {mode === 'reorder' && (
                <Box width="100%" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100%' }}>
                  <Box>
                    <Icon
                      fontSize="large"
                      onClick={() => {
                        handleOnReorder('LEFT', card.cardId)
                      }}
                    >
                      {order.indexOf(card.cardId) !== 0 && 'keyboard_double_arrow_left'}
                    </Icon>
                  </Box>
                  <Box>
                    <Icon
                      fontSize="large"
                      onClick={() => {
                        handleOnReorder('RIGHT', card.cardId)
                      }}
                    >
                      {order.indexOf(card.cardId) !== order.length - 1 && 'keyboard_double_arrow_right'}
                    </Icon>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          <Box width="100%" height="100%" style={{ display: 'flex', justifyContent: 'center' }}>
            <Box width="100px" height="85px" style={{ fontFamily: 'Roboto', alignSelf: 'flex-end', display: 'flex', justifyContent: 'center' }}>
              <img src={card.groupIcon} style={{ objectFit: 'fill' }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}
