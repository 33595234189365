import { useInView } from 'react-intersection-observer'
import React from 'react'
import { Box } from '@material-ui/core'
import { useMessagesHelper } from '../hook'
import { useConferenceProvider } from '../hook/useConferenceProvider'
import { IConferenceTheme } from 'types'

type Props = {
  listItems: React.ReactNode[]
  reverse?: boolean
  conferenceId: number
  selectedTheme: IConferenceTheme | undefined
  refresh: boolean
  onEndRefresh: () => void
}

export const InfiniteScroll = (props: Props) => {
  const { listItems, reverse, conferenceId, selectedTheme, refresh } = props
  const [lastRowRef, lastRowInView] = useInView()
  const { getMessages, handlePageChange, paginationResponse, loading, resetMessages } = useConferenceProvider()
  React.useEffect(() => {
    getMessages(conferenceId, 1, selectedTheme?.conferenceThemeId)
  }, [])

  React.useEffect(() => {
    if (!refresh) {
      return
    }
    resetMessages()
    getMessages(conferenceId, 1, selectedTheme?.conferenceThemeId)
    props.onEndRefresh()
  }, [refresh])

  React.useEffect(() => {
    const needToLoadMore = lastRowInView && !loading
    if (!needToLoadMore) return
    console.log('needToLoadMore', needToLoadMore)
    const page = paginationResponse.page === 0 ? 1 : paginationResponse.page + 1
    const isLastPage = page === Math.ceil(paginationResponse.count / paginationResponse.limit)
    console.log('isLastPage', isLastPage)
    if (isLastPage) return
    handlePageChange(conferenceId, page + 1, selectedTheme?.conferenceThemeId)
  }, [lastRowInView, conferenceId, paginationResponse, loading, selectedTheme])

  return (
    <Box
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: reverse ? 'column-reverse' : 'column',
        flexWrap: 'nowrap',
        overflowY: 'auto',
        gap: 20,
      }}
      pb={2}
    >
      {listItems.map((listItem, i) => {
        const props = { key: i }
        i === listItems.length - 1 && (props['ref'] = lastRowRef)
        return <div {...props}>{listItem}</div>
      })}
    </Box>
  )
}
