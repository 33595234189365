import { IMessage } from 'types'
type Hook = {
  isSameDate: (firstDate: Date, secondDate: Date) => boolean
  getTagDate: (dateFormat: Date) => string
  getAlign: (message: IMessage, viewerId: number) => 'center' | 'flex-end' | 'flex-start'
}

export const useMessageConfigHelper = (): Hook => {
  const isSameDate = (firstDate: Date, secondDate: Date) => {
    const date1 = new Date(firstDate)
    const date2 = new Date(secondDate)
    const sameDate = date1.getDate() === date2.getDate()
    return sameDate
  }
  const getTagDate = (dateFormat: Date) => {
    const date = new Date(dateFormat).toLocaleDateString('es-ES', {
      dateStyle: 'full',
    })
    return date
  }
  const getAlign = (message: IMessage, viewerId: number) => {
    if (message.messageType !== 'U') {
      return 'center'
    }
    if (message.perxonId === viewerId) {
      return 'flex-end'
    }
    if (message.perxonId !== viewerId) {
      return 'flex-start'
    }
    return 'center'
  }

  return {
    isSameDate,
    getTagDate,
    getAlign,
  }
}
