import { PracticeGroups } from 'components/Practice/Groups/PracticeGroups';
import React from 'react'



const Test = () => {



    return (
        <div>
        </div>
    );
}

export default Test
