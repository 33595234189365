import React from 'react'
import { Routes } from 'routes'

const App = () => {
  const versionInteligenxia=require('./../package.json').version
  console.log(`%cINTELIGENXIA VERSION: ${versionInteligenxia}`, 'color: #ff0000; font-size: 60px; font-weight: bold;')
  return (
    <div>
      <Routes />
    </div>
  )
}

export default App 