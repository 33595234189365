import { Box, Icon, IconButton, Paper } from '@material-ui/core'
import { LocationContext, ConfigContext } from 'context'
import React from 'react'
import { IConferenceTheme } from 'types'
import { useMessagesHelper } from '../hook'
import { useConferenceProvider } from '../hook/useConferenceProvider'
import { MessageConferenxia } from './MessageConferenxia'

type Props = {
  width: number
  height?: number
  selectedTheme?: IConferenceTheme
  conferenceId: number
  showMembers?: boolean
  showDate?: boolean
  refresh: boolean
  onEndRefresh: () => void
}

export const ConferenceHorizontalView = (props: Props) => {
  const { width, height, selectedTheme, conferenceId, refresh, onEndRefresh } = props
  const navigationRowSize = 60
  const cardSize = 235
  const [limit, setLimit] = React.useState<number>(1)
  const [actualPage, setActualPage] = React.useState<number>(1)
  const { userId: viewerId } = React.useContext(LocationContext)
  const { filesContext } = React.useContext(ConfigContext)
  const { getMessages, paginationResponse } = useConferenceProvider()

  React.useEffect(() => {
    setActualPage(1)
  }, [selectedTheme])

  React.useEffect(() => {
    if (!props.refresh) {
      return
    }
    const rowLimit = Math.floor((width - navigationRowSize) / cardSize)
    setLimit(rowLimit)
    if (selectedTheme) {
      getMessages(conferenceId, 1, selectedTheme.conferenceThemeId, rowLimit)
    } else {
      getMessages(conferenceId, 1, undefined, rowLimit)
    }
    onEndRefresh()
  }, [refresh])

  React.useEffect(() => {
    const rowLimit = Math.floor((width - navigationRowSize) / cardSize)
    setLimit(rowLimit)
    if (selectedTheme) {
      getMessages(conferenceId, 1, selectedTheme.conferenceThemeId, rowLimit)
    } else {
      getMessages(conferenceId, 1, undefined, rowLimit)
    }
  }, [width, navigationRowSize, cardSize, conferenceId, selectedTheme])

  const totalPage = React.useMemo(() => {
    return Math.ceil(paginationResponse.count / Math.floor((width - navigationRowSize) / cardSize))
  }, [paginationResponse, width, navigationRowSize, cardSize])

  return (
    <Box
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
        marginBottom: 10,
      }}
      id={'conferenceHorizontalView'}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: '100%',
          width: navigationRowSize / 2,
          maxWidth: navigationRowSize / 2,
        }}
      >
        {actualPage !== 1 && (
          <IconButton
            style={{
              backgroundColor: actualPage === 1 ? '#4b4b4b' : '#0288d1',
              opacity: actualPage === totalPage /*  */ ? 0.5 : 1,
              borderRadius: '50%',
              color: 'white',
              marginLeft: 10,
              height: 30,
              width: 30,
            }}
            disabled={actualPage === 1}
            onClick={() => {
              if (actualPage === 1) {
                return
              }
              getMessages(conferenceId, actualPage - 1, selectedTheme?.conferenceThemeId, limit)
              setActualPage((prev) => prev - 1)
            }}
          >
            <Icon>chevron_left</Icon>
          </IconButton>
        )}
      </Box>

      <Box
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          gap: 10,
          justifyContent: 'center',
          overflowY: 'auto',
        }}
      >
        {paginationResponse.result.slice(0, limit).map((message, index) => (
          <Box
            key={message.conferenceDetailId}
            style={{
              width: cardSize,
              maxWidth: cardSize,
              minWidth: cardSize,
              height: '100%',
            }}
          >
            <Box
              style={{
                width: cardSize,
                maxWidth: cardSize,
                minWidth: cardSize,
                height: 'auto',
              }}
            >
              <Box
                style={{
                  width: '100%',
                  height: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <MessageConferenxia
                  showCardImage
                  message={message}
                  onlyExistOneTheme={false}
                  idUser={viewerId}
                  hiddenDate={!props.showDate}
                  hiddenName={!props.showMembers}
                  showPreviewImage
                  showLabelDate
                />
              </Box>
            </Box>
          </Box>
        ))}
      </Box>

      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: '100%',
          width: navigationRowSize / 2,
          maxWidth: navigationRowSize / 2,
        }}
      >
        {actualPage !== totalPage && (
          <IconButton
            style={{
              backgroundColor: actualPage === totalPage /*  */ ? '#4b4b4b' : '#0288d1',
              opacity: actualPage === totalPage /*  */ ? 0.5 : 1,
              borderRadius: '50%',
              color: 'white',
              marginRight: 10,
              height: 30,
              width: 30,
            }}
            disabled={actualPage === totalPage /*  */}
            onClick={() => {
              if (actualPage === totalPage /*  */) {
                return
              }
              getMessages(conferenceId, actualPage + 1, selectedTheme?.conferenceThemeId, limit)
              setActualPage((prev) => prev + 1)
            }}
          >
            <Icon
              style={{
                borderRadius: '50%',
              }}
            >
              chevron_right
            </Icon>
          </IconButton>
        )}
      </Box>
    </Box>
  )
}
