import { CardContainerValues } from "components/CardProps/CardProps"

const defaultValueLogin = {
          addShowPasswordButton: true,
          addForgotButton: true,
          addShowQRButton: true,
          addShowPinButton: true,
          buttonsBgColor: '#009688',
          showPasswordBgColor: '#009688',
          fontColor: "#000",
          inputsStyle: {
                    inputStyle: "outlined",
                    size: "small",
                    backgroundColor: "#fff",
                    fontColor: "#000",
                    labelColor: "#000",
                    borderColor: "#009688",
                    placeHolderColor: "#000",
          },
          labelInputsText: {
                    labelUser: "Usuario",
                    labelPassword: "Contraseña",
                    labelPin: "PIN",
                    labelOlvide: "Olvidé mi contraseña",
                    labelFotoQR: "Foto QR",
                    labelIngresoPin: "Ingreso PIN",
                    labelValidarUsuario: "Validar ",
                    labelEnviarPin: "Enviar PIN",
          },
          colorFontButtons: {
                    buttonValidar: "#fff",
                    buttonForgot: "#000",
                    buttonQR: "#000",
                    buttonPin: "#000",
                    buttonEnviarPin: "#fff",
          },
          minWidth: "346",
          minHeight: "230",
          maxHeight: "230",
}
const defaultValueCardPerxona=
{
          cardViewMinimizeProps: { minimize: true, enableMinimize: true },
          cardPaginationProps: { itemsPerPage: 1, cardViewMode: "vertical" },
          cardsChosen: [] as number[],
          tittle: "Mis Tarjetas Personales",
          minHeight: "450px",
          minWidth: "300px",
          paginationPosition:"bottom",
}  as CardContainerValues 


export const DEFAULT_VALUES = {
          login: defaultValueLogin,
          cardPerxona: defaultValueCardPerxona,
}