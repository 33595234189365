import { Box, Checkbox, FormControlLabel, TextField, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { ConferencePracticeValues, ConferencePracticeViewMode } from 'components/Practice/Conference/types'
import { IElement } from 'types/Element'
import React from 'react'
type Props = {
  element: IElement
  updateElement: (newElement: IElement) => void
}
export const ConferenceProps = (props: Props) => {
  const { element, updateElement } = props
  const { value } = element

  const valueMinimized = React.useMemo(() => {
    return (value as ConferencePracticeValues).initMinimized
  }, [value])
  const valueShowGeneralChat = React.useMemo(() => {
    return (value as ConferencePracticeValues).showGeneralChat
  }, [value])
  const valueShowDates = React.useMemo(() => {
    return (value as ConferencePracticeValues).showDates
  }, [value])
  const valueShowMembersNames = React.useMemo(() => {
    return (value as ConferencePracticeValues).showMembersNames
  }, [value])

  const updateElementValue = React.useCallback(
    (key: keyof ConferencePracticeValues, value: ConferencePracticeValues[keyof ConferencePracticeValues]) => {
      const elementUpdate = {
        ...element,
        value: {
          ...element.value,
          [key]: value,
        } as ConferencePracticeValues,
      }
      updateElement(elementUpdate)
    },
    [element, updateElement]
  )

  const parseStringToViewMode = (viewMode: string): ConferencePracticeViewMode => {
    switch (viewMode) {
      case 'Chat':
        return ConferencePracticeViewMode.chat
      case 'Columna por temas':
        return ConferencePracticeViewMode.column
      case 'Temas':
        return ConferencePracticeViewMode.themeList
      case 'Horizontal':
        return ConferencePracticeViewMode.horizontal
      default:
        return ConferencePracticeViewMode.chat
    }
  }
  const geViewMode = React.useMemo(() => {
    const viewMode = (value as ConferencePracticeValues).conferencePracticeViewMode
    switch (viewMode) {
      case ConferencePracticeViewMode.chat:
        return 'Chat'
      case ConferencePracticeViewMode.column:
        return 'Columna por temas'
      case ConferencePracticeViewMode.themeList:
        return 'Temas'
      case ConferencePracticeViewMode.horizontal:
        return 'Horizontal'
      default:
        return 'Chat'
    }
  }, [value])
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box pb={'0.7em'} ml={0.5}>
        <Typography color="primary" style={{ fontSize: '0.7em' }}>
          Modo de visualización
        </Typography>
      </Box>
      <Box
        mb={2}
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Autocomplete
          options={['Chat', 'Columna por temas', 'Temas', 'Horizontal']}
          fullWidth
          value={geViewMode}
          onChange={(event, newValue) => {
            if (newValue === null) return
            const viewMode = parseStringToViewMode(newValue as string)
            updateElementValue('conferencePracticeViewMode', viewMode)
          }}
          renderInput={(params) => <TextField {...params} margin="none" variant="outlined" size="small" classes={{ root: 'text-input-sm' }} fullWidth />}
        />
      </Box>
      <Box ml={2}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: 30,
          }}
        >
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                color="primary"
                checked={valueMinimized}
                onChange={(event) => {
                  updateElementValue('initMinimized', event.target.checked)
                }}
              />
            }
            label={
              <Typography style={{ fontSize: '1em', lineHeight: 0.5 }}>
                Iniciar
                <br /> <br />
                minimizado
              </Typography>
            }
            labelPlacement="end"
          />
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                color="primary"
                checked={valueShowGeneralChat}
                onChange={(event) => {
                  updateElementValue('showGeneralChat', event.target.checked)
                }}
              />
            }
            label={
              <Typography style={{ fontSize: '1em', lineHeight: 0.5 }}>
                Ver tema
                <br />
                <br /> general
              </Typography>
            }
            labelPlacement="end"
          />
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: 38,
          }}
        >
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                color="primary"
                checked={valueShowDates}
                onChange={(event) => {
                  updateElementValue('showDates', event.target.checked)
                }}
              />
            }
            label={<Typography style={{ fontSize: '1em', lineHeight: 0.5 }}>Ver fechas</Typography>}
            labelPlacement="end"
          />
          <FormControlLabel
            value="bottom"
            control={
              <Checkbox
                color="primary"
                checked={valueShowMembersNames}
                onChange={(event) => {
                  updateElementValue('showMembersNames', event.target.checked)
                }}
              />
            }
            label={
              <Typography style={{ fontSize: '1em', lineHeight: 0.5 }}>
                Ver nombre
                <br />
                <br /> miembros
              </Typography>
            }
            labelPlacement="end"
          />
        </Box>
      </Box>
    </Box>
  )
}
