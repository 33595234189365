import React from 'react'
import { Checkbox, FormControl, FormControlLabel, FormGroup, Typography } from '@material-ui/core'
import { IElement } from 'types'

interface Props {
    element: IElement,
    updateElement: (newElement: IElement) => void,
}

const PageProps = React.memo((props: Props) => {
    const { updateElement } = props
    const [value, setValue] = React.useState<any[]>(props.element.value)

    React.useEffect(() => {
        let newValue = [...value]
        newValue.length === 3 && newValue.push(true);
        if (props.element.newInstance ?? false) {
            updateElement({ ...props.element, value: newValue,newInstance: false })
            return
        } else if (props.element.newInstance === undefined) {
            updateElement({ ...props.element, newInstance: false, value: newValue })
            return
        }
    }, [])

    React.useEffect(() => {
        if (props.element.value !== value) { setValue(props.element.value) }
    }, [props.element.value, value])
    return (
        <div className="panel-container px-2 pt-4 pb-2">
            <Typography color="primary" className="panel-label" variant="caption">{"Página"}</Typography>
            <FormControl className="flex-1" component="fieldset" style={{ display: "flex", justifyContent: "center" }}>
                <FormGroup className="pl-2">
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    let newValue = [...value]
                                    newValue.length === 4 ? newValue.pop() : newValue.push(true)
                                    updateElement({ ...props.element, value: newValue })
                                }}
                                color="primary"
                                size="small"
                                checked={value.length === 4}
                                name="newTab" />
                        }
                        label="Abrir en una nueva pestaña"
                    />
                </FormGroup>
            </FormControl>
        </div>
    )
})

export default PageProps
