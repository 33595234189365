import React from 'react'
import { FormControl, FormGroup, Button, Checkbox, FormControlLabel, Icon, IconButton, MenuItem, Popover, Slider, TextField, Tooltip, Typography, Menu } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { BACKGROUND_POSITION_OPTIONS, BACKGROUND_REPEAT_OPTIONS, BACKGROUND_SIZE_OPTIONS, DEEPLINK_TAG, DEFAULT_IMAGE_STYLE, findObject, getFilterArray, HASH_PARAM, findLink, ID_LINK_TAG } from 'lib'
import { IElement } from 'types'
import { useHistory } from 'react-router-dom'
import { LocationContext } from 'context'

interface Props {
    element: IElement,
    updateElement: (newElement: IElement) => void,
    filesContext: string,
    pageId: number
}

export interface PopoverConfig {
    element: HTMLButtonElement | null,
    id: string
}

const marginRight = 8
const popoverElements = {
    bgPosition: "bgPosition",
    filters: "filters",
    link: "link",
}

const ImageProps = React.memo((props: Props) => {
    const { updateElement, pageId } = props
    const history = useHistory()
    const [popover, setPopover] = React.useState<PopoverConfig>({} as PopoverConfig)
    const { tabId } = React.useContext(LocationContext)
    const [submenu, setSubmenu] = React.useState<null | HTMLElement>(null)
    const handleClosePopover = () => setPopover({} as PopoverConfig)
    const [style, setStyle] = React.useState<React.CSSProperties>(props.element.style)
    const [brightness, setBrightness] = React.useState<number>(100)
    const [grayscale, setGrayscale] = React.useState<number>(0)
    const [blur, setBlur] = React.useState<number>(0)
    const [opacity, setOpacity] = React.useState<number>(100)
    const [sepia, setSepia] = React.useState<number>(0)
    const [saturate, setSaturate] = React.useState<number>(100)
    const [link, setLink] = React.useState<string>(props.element.link ?? "")
    const [openNewTab, setOpenNewTab] = React.useState(Boolean(props.element.openNewTab))
    const [value, setValue] = React.useState<any[]>(props.element.value)

    React.useEffect(() => {
        console.log('a')
        if (props.element.newInstance ?? false) {
            updateElement({ ...props.element, openNewTab: true,newInstance:false })
        } else if (props.element.newInstance === undefined) {
            updateElement({ ...props.element, newInstance: false,openNewTab: true })
        }
    }, [])


    const handleCommitChanges = () => {
        updateElement({
            ...props.element,
            style: {
                ...style,
                filter: `brightness(${brightness}%) grayscale(${grayscale}%) blur(${blur}px) opacity(${opacity}%) sepia(${sepia}%) saturate(${saturate}%)`,
            },
            value,
            link,
            openNewTab
        })
    }
    const handleExternal = () => {
        findObject(pageId, tabId)
    }
    const handleLink = () => {
        findLink(pageId, props.element.value[0], props.element.id, tabId)
    }
    const handleDeleteLink = () => {
        updateElement({
            ...props.element,
            style: {
                ...style,
                filter: `brightness(${brightness}%) grayscale(${grayscale}%) blur(${blur}px) opacity(${opacity}%) sepia(${sepia}%) saturate(${saturate}%)`,
            },
            value,
            link: "",
            openNewTab
        })
    }
    const handleInternal = () => {
        history.push({ pathname: "/selection", state: { element: props.element } })
    }
    React.useEffect(() => {
        if (props.element.style !== style) { setStyle(props.element.style) }
        if (props.element.style.filter) {
            const filterArr = getFilterArray(props.element.style.filter?.toString())
            if (filterArr.length > 0) {
                setBrightness(parseInt(filterArr[0]))
                setGrayscale(parseInt(filterArr[1]))
                setBlur(parseInt(filterArr[2]))
                setOpacity(parseInt(filterArr[3]))
                setSepia(parseInt(filterArr[4]))
                setSaturate(parseInt(filterArr[5]))
            }
        }
    }, [props.element.style, style])

    React.useEffect(() => {
        if (value !== props.element.value) {
            setValue(props.element.value)
            setOpenNewTab(Boolean(props.element.value[3]))
        }
    }, [props.element.value, value])
    React.useEffect(() => {
        if (link !== props.element.link) { setLink(props.element.link ?? "") }
    }, [props.element.link])
    React.useEffect(() => {
        setOpenNewTab(Boolean(props.element.openNewTab))
    }, [props.element.openNewTab])
    return (
        <div className="panel-container px-2 pt-4 pb-2">
            <Typography color="primary" className="panel-label" variant="caption">{"Imagen"}</Typography>
            <div className="flex items-center">
                <TextField
                    size="small"
                    label="Repetir"
                    variant="outlined"
                    classes={{ root: "text-input-sm" }}
                    select
                    SelectProps={{ IconComponent: (iconProps) => <Icon {...iconProps} style={{ paddingTop: 2 }} fontSize="small">expand_more</Icon> }}
                    fullWidth
                    value={style.backgroundRepeat}
                    style={{ flex: 1, marginRight }}
                    onChange={(e) => {
                        updateElement({
                            ...props.element,
                            link,
                            openNewTab,
                            value: value,
                            style: {
                                ...style,
                                backgroundRepeat: e.target.value,
                                filter: `brightness(${brightness}%) grayscale(${grayscale}%) blur(${blur}px) opacity(${opacity}%) sepia(${sepia}%) saturate(${saturate}%)`
                            }
                        })
                    }}
                >
                    {BACKGROUND_REPEAT_OPTIONS.map((option) => (
                        <MenuItem
                            key={option.value}
                            value={option.value}
                            dense
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    size="small"
                    label="Tamaño"
                    variant="outlined"
                    classes={{ root: "text-input-sm" }}
                    select
                    value={style.backgroundSize}
                    SelectProps={{ IconComponent: (iconProps) => <Icon {...iconProps} style={{ paddingTop: 2 }} fontSize="small">expand_more</Icon> }}
                    fullWidth
                    style={{ flex: 1 }}
                    onChange={(e) => {
                        updateElement({
                            ...props.element,
                            value: value,
                            link,
                            openNewTab,
                            style: {
                                ...style,
                                backgroundSize: e.target.value,
                                filter: `brightness(${brightness}%) grayscale(${grayscale}%) blur(${blur}px) opacity(${opacity}%) sepia(${sepia}%) saturate(${saturate}%)`
                            }
                        })
                    }}
                >
                    {BACKGROUND_SIZE_OPTIONS.map((option) => (
                        <MenuItem
                            key={option.value}
                            value={option.value}
                            dense
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            <div>
                <Typography color="primary" className="pt-4" variant="caption">{"Link"}</Typography>
                <div className="flex items-center">
                    <Tooltip arrow title={Boolean(link) ? link.split(ID_LINK_TAG)[0] : ""}>
                        <div>

                            <Button
                                size="small"
                                variant="outlined"
                                fullWidth
                                style={{ marginRight: 10, width: 140, maxWidth: 140 }}
                                onClick={handleLink}
                                disabled={Boolean(link)}
                            >
                                <p className="truncate" style={{ textTransform: "none" }}>{Boolean(link) ? link.split(ID_LINK_TAG)[0] : "Seleccionar link"}</p>
                            </Button>
                        </div>
                    </Tooltip>
                    {
                        Boolean(link) &&
                        <Tooltip arrow title="Eliminar link">
                            <IconButton
                                onClick={handleDeleteLink}
                                style={{ marginRight: 10 }}
                                size="small"
                            >
                                <Icon
                                    fontSize="small"
                                    color="action"
                                >
                                    close
                                </Icon>
                            </IconButton>
                        </Tooltip>
                    }
                    <ToggleButtonGroup>
                        <Tooltip arrow title="Deeplink" placement="left">
                            <ToggleButton disabled={Boolean(link)}
                                selected={link?.includes(DEEPLINK_TAG) || link?.includes(HASH_PARAM)} size="small" value="restore" onClick={(e) => setSubmenu(e.currentTarget)}>
                                <Icon fontSize="small">language</Icon>
                            </ToggleButton>
                        </Tooltip>
                        <Menu
                            anchorEl={submenu}
                            keepMounted
                            open={Boolean(submenu)}
                            onClose={() => setSubmenu(null)}
                        >
                            <MenuItem
                                onClick={handleInternal}
                                dense
                            >
                                <Icon color="disabled" className="mr-1" fontSize="small">dashboard</Icon>
                                {"Interno"}
                            </MenuItem>
                            <MenuItem
                                dense
                                onClick={handleExternal}
                            >
                                <Icon color="disabled" className="mr-1" fontSize="small">web</Icon>
                                {"Externo"}
                            </MenuItem>
                        </Menu>
                    </ToggleButtonGroup>
                </div>
                <div className="flex">
                    <FormControl className="flex-1" component="fieldset" style={{ display: "flex", justifyContent: "center" }}>
                        <FormGroup className="pl-2">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={!link}
                                        onChange={(e) => {
                                            updateElement({
                                                ...props.element,
                                                style: {
                                                    ...style,
                                                    filter: `brightness(${brightness}%) grayscale(${grayscale}%) blur(${blur}px) opacity(${opacity}%) sepia(${sepia}%) saturate(${saturate}%)`,
                                                },
                                                link,
                                                openNewTab: e.target.checked
                                            })
                                        }}
                                        color="primary"
                                        size="small"
                                        checked={openNewTab}
                                        name="newTab" />
                                }
                                label="Abrir en una nueva pestaña"
                            />
                        </FormGroup>
                    </FormControl>
                </div>
            </div>
            <div className="mt-1 flex">
                <ToggleButtonGroup>
                    <Tooltip arrow title="Posición" placement="left">
                        <ToggleButton size="small" value="bg_position" onClick={(e) => setPopover({ element: e.currentTarget, id: popoverElements.bgPosition })}>
                            <Icon fontSize="small">apps</Icon>
                            <Icon fontSize="small">expand_more</Icon>
                        </ToggleButton>
                    </Tooltip>
                    <Tooltip arrow title="Filtros" placement="left">
                        <ToggleButton size="small" value="filters" onClick={(e) => setPopover({ element: e.currentTarget, id: popoverElements.filters })}>
                            <Icon fontSize="small">filter</Icon>
                            <Icon fontSize="small">expand_more</Icon>
                        </ToggleButton>
                    </Tooltip>
                    <Tooltip arrow title="Restaurar a valores por defecto" placement="left">
                        <ToggleButton style={{ color: "#ef9a9a" }} size="small" value="restore" onClick={(e) => {
                            updateElement({
                                ...props.element,
                                value,
                                style: DEFAULT_IMAGE_STYLE,
                                link: "",
                                openNewTab: false
                            })
                        }}>
                            <Icon fontSize="small">restore</Icon>
                        </ToggleButton>
                    </Tooltip>
                </ToggleButtonGroup>
                <Popover
                    id={popoverElements.bgPosition}
                    anchorEl={popover.element}
                    open={popover.id === popoverElements.bgPosition && Boolean(popover.element)}
                    onClose={handleClosePopover}
                >
                    <div className={"flex items-center mt-1"}>
                        <Typography align="center" className="pl-4 w-full flex-grow" variant="caption" style={{ letterSpacing: 0.5 }}>
                            {"Posición de imagen"}
                        </Typography>
                        <IconButton style={{ padding: 4 }} onMouseDown={handleClosePopover}>
                            <Icon style={{ fontSize: 15 }}>close</Icon>
                        </IconButton>
                    </div>
                    <div className="p-2 bg-white flex-wrap flex justify-center items-center" style={{ width: 150 }}>
                        {BACKGROUND_POSITION_OPTIONS.map((position) => {
                            return (
                                <IconButton
                                    key={position}
                                    size="small"
                                    color={props.element.style.backgroundPosition === position ? "primary" : undefined}
                                    style={{ width: 40 }}
                                    onClick={() =>
                                        updateElement({
                                            ...props.element,
                                            value: value,
                                            style: {
                                                ...style,
                                                backgroundPosition: position,
                                                filter: `brightness(${brightness}%) grayscale(${grayscale}%) blur(${blur}px) opacity(${opacity}%) sepia(${sepia}%) saturate(${saturate}%)`
                                            }
                                        })
                                    }
                                >
                                    <Icon fontSize="small">fiber_manual_record</Icon>
                                </IconButton>
                            )
                        })}
                    </div>
                </Popover>
                <Popover
                    id={popoverElements.filters}
                    anchorEl={popover.element}
                    open={popover.id === popoverElements.filters && Boolean(popover.element)}
                    onClose={handleClosePopover}
                >
                    <div className={"flex items-center mt-1"}>
                        <Typography align="center" className="pl-4 w-full flex-grow" variant="caption" style={{ letterSpacing: 0.5 }}>
                            {"Filtros"}
                        </Typography>
                        <IconButton style={{ padding: 4 }} onMouseDown={handleClosePopover}>
                            <Icon style={{ fontSize: 15 }}>close</Icon>
                        </IconButton>
                    </div>
                    <div className="items-center p-3 flex" style={{ width: 700, height: 360 }}>
                        <div className="w-1/2 p-5 h-full">
                            <div
                                className="w-full h-full bg-no-repeat bg-center bg-contain"
                                style={{
                                    backgroundImage: `url(${props.filesContext}/${props.element.value[1]})`,
                                    filter: `brightness(${brightness}%) grayscale(${grayscale}%) blur(${blur}px) opacity(${opacity}%) sepia(${sepia}%) saturate(${saturate}%)`
                                }}
                            />
                        </div>
                        <div className="w-1/2">
                            <div className="flex flex-col">
                                <Typography className="w-full flex-grow" variant="caption" style={{ fontSize: "0.55em" }}>
                                    {"Brillo"}
                                </Typography>
                                <div className="flex items-center">
                                    <Icon fontSize="small">brightness_4</Icon>
                                    <div className="flex flex-grow mx-2">
                                        <Slider
                                            className="mr-2"
                                            value={brightness}
                                            min={0}
                                            max={200}
                                            step={1}
                                            onChange={(e, newValue) => {
                                                setBrightness(newValue as number)
                                            }}
                                        />
                                    </div>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        classes={{ root: "text-input-sm" }}
                                        style={{ flex: 0.5 }}
                                        value={brightness}
                                        onChange={(e) => {
                                            let value = parseInt(e.target.value)
                                            if (value < 0) value = 0
                                            if (value > 200) value = 200
                                            setBrightness(value)
                                        }}
                                        type="number"
                                        InputProps={{ inputProps: { min: 0, max: 100 } }}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <Typography className="w-full flex-grow" variant="caption" style={{ fontSize: "0.55em" }}>
                                    {"Grises"}
                                </Typography>
                                <div className="flex items-center">
                                    <Icon fontSize="small">brightness_2</Icon>
                                    <div className="flex flex-grow mx-2">
                                        <Slider
                                            className="mr-2"
                                            value={grayscale}
                                            min={0}
                                            max={100}
                                            step={1}
                                            onChange={(e, newValue) => {
                                                setGrayscale(newValue as number)
                                            }}
                                        />
                                    </div>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        classes={{ root: "text-input-sm" }}
                                        style={{ flex: 0.5 }}
                                        value={grayscale}
                                        onChange={(e) => {
                                            let value = parseInt(e.target.value)
                                            if (value < 0) value = 0
                                            if (value > 100) value = 100
                                            setGrayscale(value)
                                        }}
                                        type="number"
                                        InputProps={{ inputProps: { min: 0, max: 100 } }}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <Typography className="w-full flex-grow" variant="caption" style={{ fontSize: "0.55em" }}>
                                    {"Difuminar"}
                                </Typography>
                                <div className="flex items-center">
                                    <Icon fontSize="small">blur_on</Icon>
                                    <div className="flex flex-grow mx-2">
                                        <Slider
                                            className="mr-2"
                                            value={blur}
                                            min={0}
                                            max={10}
                                            step={1}
                                            onChange={(e, newValue) => {
                                                setBlur(newValue as number)
                                            }}
                                        />
                                    </div>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        classes={{ root: "text-input-sm" }}
                                        style={{ flex: 0.5 }}
                                        value={blur}
                                        onChange={(e) => {
                                            let value = parseInt(e.target.value)
                                            if (value < 0) value = 0
                                            if (value > 10) value = 10
                                            setBlur(value)
                                        }}
                                        type="number"
                                        InputProps={{ inputProps: { min: 0, max: 10 } }}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <Typography className="w-full flex-grow" variant="caption" style={{ fontSize: "0.55em" }}>
                                    {"Opacidad"}
                                </Typography>
                                <div className="flex items-center">
                                    <Icon fontSize="small">opacity</Icon>
                                    <div className="flex flex-grow mx-2">
                                        <Slider
                                            className="mr-2"
                                            value={opacity}
                                            min={0}
                                            max={100}
                                            step={1}
                                            onChange={(e, newValue) => {
                                                setOpacity(newValue as number)
                                            }}
                                        />
                                    </div>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        classes={{ root: "text-input-sm" }}
                                        style={{ flex: 0.5 }}
                                        value={opacity}
                                        onChange={(e) => {
                                            let value = parseInt(e.target.value)
                                            if (value < 0) value = 0
                                            if (value > 100) value = 100
                                            setOpacity(value)
                                        }}
                                        type="number"
                                        InputProps={{ inputProps: { min: 0, max: 100 } }}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <Typography className="w-full flex-grow" variant="caption" style={{ fontSize: "0.55em" }}>
                                    {"Sepia"}
                                </Typography>
                                <div className="flex items-center">
                                    <Icon fontSize="small">filter_hdr</Icon>
                                    <div className="flex flex-grow mx-2">
                                        <Slider
                                            className="mr-2"
                                            value={sepia}
                                            min={0}
                                            max={100}
                                            step={1}
                                            onChange={(e, newValue) => {
                                                setSepia(newValue as number)
                                            }}
                                        />
                                    </div>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        classes={{ root: "text-input-sm" }}
                                        style={{ flex: 0.5 }}
                                        value={sepia}
                                        onChange={(e) => {
                                            let value = parseInt(e.target.value)
                                            if (value < 0) value = 0
                                            if (value > 100) value = 100
                                            setSepia(value)
                                        }}
                                        type="number"
                                        InputProps={{ inputProps: { min: 0, max: 100 } }}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <Typography className="w-full flex-grow" variant="caption" style={{ fontSize: "0.55em" }}>
                                    {"Saturación"}
                                </Typography>
                                <div className="flex items-center">
                                    <Icon fontSize="small">photo_filter</Icon>
                                    <div className="flex flex-grow mx-2">
                                        <Slider
                                            className="mr-2"
                                            value={saturate}
                                            min={0}
                                            max={200}
                                            step={1}
                                            onChange={(e, newValue) => {
                                                setSaturate(newValue as number)
                                            }}
                                        />
                                    </div>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        classes={{ root: "text-input-sm" }}
                                        style={{ flex: 0.5 }}
                                        value={saturate}
                                        onChange={(e) => {
                                            let value = parseInt(e.target.value)
                                            if (value < 0) value = 0
                                            if (value > 200) value = 200
                                            setSaturate(value)
                                        }}
                                        type="number"
                                        InputProps={{ inputProps: { min: 0, max: 200 } }}
                                    />
                                </div>
                            </div>
                            <div className="mt-3 flex">
                                <ToggleButton
                                    value="restore"
                                    size="small"
                                    onClick={() => {
                                        updateElement({
                                            ...props.element,
                                            value: value,
                                            style: {
                                                ...style,
                                                filter: `brightness(${100}%) grayscale(${0}%) blur(${0}px) opacity(${100}%) sepia(${0}%) saturate(${100}%)`
                                            }
                                        })
                                    }}
                                >
                                    <Typography
                                        style={{ color: "#626262", lineHeight: "inherit", textTransform: "capitalize" }}
                                        variant="caption"
                                        className="px-2"
                                    >
                                        {"Restaurar valores por defecto"}
                                    </Typography>
                                    <Icon style={{ color: "#626262" }} fontSize="small">restore</Icon>
                                </ToggleButton>
                                <Button
                                    color="primary"
                                    size="small"
                                    variant="outlined"
                                    style={{ marginLeft: marginRight, paddingLeft: 12, paddingRight: 12 }}
                                    onClick={handleCommitChanges}
                                >
                                    <Typography
                                        style={{ lineHeight: "inherit", textTransform: "capitalize" }}
                                        variant="caption"
                                        className="px-2"
                                        color="primary"
                                    >
                                        {"Aplicar"}
                                    </Typography>
                                    <Icon className="ml-2" fontSize="small">check</Icon>
                                </Button>
                            </div>
                        </div>
                    </div>
                </Popover>
            </div>
        </div >
    )
})

export default ImageProps
