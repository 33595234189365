import { Box, Button, Divider, Icon, IconButton, makeStyles, Modal, RootRef, Tooltip, Typography } from '@material-ui/core'
import { getCommandDetailLinkService } from 'lib'
import { Extensions, objectImageByExtension } from 'lib/utils/objectImage'
import React from 'react'
import { IObject, IObjectType } from 'types'
import { ObjectPreview } from './ObjectPreview'

type Props = {
  objects: IObject[]
  open: boolean
  onClose: () => void
  extraComponent?: React.ReactNode
}

const useStyles = makeStyles({
  root: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 950,
    backgroundColor: '#fff',
    border: '2px solid #000',
    p: 4,
    borderTopRightRadius: '25px',
    borderBottomLeftRadius: '25px',
    maxHeight: '85vh',
  },
})

const useHoverStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: '#4b4b4b',
      color: 'white',
      opacity: 0.8,
    },
  },
})

const useScrollStyles = makeStyles({
  root: {
    '&::-webkit-scrollbar': {
      width: '5px',
      borderRadius: '100%',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
    },
  },
})

type ButtonShow = {
  previous: boolean
  next: boolean
}

type Dimensions = {
  width: number | string
  height: number | string
}

export const ImageGallery = (props: Props) => {
  const { objects, open, onClose, extraComponent } = props

  const [currentImageIndex, setCurrentImageIndex] = React.useState<number>(-2)

  const style = useStyles()
  const scrollStyle = useScrollStyles()
  const hoverStyle = useHoverStyles()

  const imgRef = React.useRef<HTMLImageElement>(null)

  React.useEffect(() => {
    setCurrentImageIndex(0)
  }, [])

  const currentViewObject = React.useMemo(() => objects[currentImageIndex < 0 ? 0 : currentImageIndex], [currentImageIndex, objects])

  const [buttonShow, setButtonShow] = React.useState<ButtonShow>({
    previous: false,
    next: false,
  } as ButtonShow)

  const isLastImage = React.useMemo(() => currentImageIndex === objects.length - 1, [currentImageIndex])
  const isFirstImage = React.useMemo(() => currentImageIndex === 0, [currentImageIndex])

  React.useEffect(() => {
    setButtonShow((prev) => ({ next: !isLastImage, previous: !isFirstImage }))
  }, [isLastImage, isFirstImage])

  const handleNavigate = (action: 'next' | 'previous', maxSize: number) => {
    setCurrentImageIndex((prev) => {
      const minSize = 0

      if (action === 'next' && prev < maxSize) {
        console.log('siguiente')
        return prev + 1
      }

      if (action === 'previous' && prev >= minSize) {
        console.log('atras')

        return prev - 1
      }
      console.log('ninguna')

      return prev
    })
  }

  const onPreviewImageClick = (index: number) => {
    setCurrentImageIndex(index)
  }

  const [dimension, setDimension] = React.useState<Dimensions>({ width: '485px', height: 'auto' })

  const calculateDimension = React.useCallback(() => {
    if (!imgRef.current) {
      return
    }
    const width = imgRef.current?.naturalWidth
    const height = imgRef.current?.naturalHeight
    if (width && height) {
      const isHeightBigger = height > width
      if (isHeightBigger) {
        const isLimitHeight = height > 350
        setDimension({ width: 'auto', height: isLimitHeight ? '350px' : height })
      } else {
        const isLimitWidth = width > 500
        setDimension({ width: isLimitWidth ? '500px' : width, height: 'auto' })
      }
    }
  }, [currentImageIndex, imgRef])

  React.useEffect(() => {
    calculateDimension()
  }, [calculateDimension])

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={style.root}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: 2,
          }}
        >
          <Box
            style={{
              minWidth: '220px',
              width: '220px',
              maxWidth: '220px',
              height: '100%',
              maxHeight: '80vh',
              overflowY: 'auto',
              overflowX: 'hidden',
              paddingTop: 20,
              paddingBottom: 20,
            }}
            className={scrollStyle.root}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                alignItems: 'center',
                gap: 1,
              }}
            >
              {objects.map((object, index) => (
                <React.Fragment key={object.id}>
                  <ObjectPreview object={object} width={'100px'} height={'100px'} handleOnClick={() => onPreviewImageClick(index)} />
                </React.Fragment>
              ))}
            </Box>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box
            style={{
              flexGrow: 1,
              flexShrink: 1,
              maxWidth: '50px',

              height: '100%',
              minHeight: '100%',
            }}
          >
            {buttonShow.previous && (
              <Box
                style={{
                  maxWidth: '50px',
                  paddingTop: 20,
                  paddingRight: 20,
                }}
              >
                <IconButton
                  onClick={() => handleNavigate('previous', objects.length - 1)}
                  style={{
                    backgroundColor: '#4b4b4b',
                    color: 'white',
                    borderRadius: '50%',
                  }}
                  classes={{ root: hoverStyle.root }}
                >
                  <Icon>chevron_left</Icon>
                </IconButton>
              </Box>
            )}
          </Box>
          <Box
            style={{
              flexGrow: 3,
              flexShrink: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '600px',
              minWidth: '600px',
              maxWidth: '600px',
              gap: 2,
            }}
          >
            <Button
              style={{
                backgroundColor: '#4b4b4b',
                color: 'white',
                borderTopRightRadius: '15px',
                borderBottomLeftRadius: '15px',
                paddingLeft: 10,
                paddingRight: 10,
                marginTop: 20,
              }}
              classes={{ root: hoverStyle.root }}
              onClick={() => {
                const executeCommand = async (id: number) => {
                  const commandLink = await getCommandDetailLinkService(id)
                  window.open(commandLink, '_blank')
                }

                executeCommand(objects[currentImageIndex].id)
              }}
              startIcon={
                <Icon
                  style={{
                    color: 'white',
                  }}
                >
                  archive
                </Icon>
              }
            >
              Ver info Caxillero
            </Button>
            <Tooltip title={`Detalle`} arrow>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  {currentViewObject.fileTypeId === IObjectType.LINK && (
                    <Box
                      style={{
                        borderTopRightRadius: '25px',
                        borderBottomLeftRadius: '25px',
                        width: '100px',
                        height: '100px',
                        backgroundColor: '#4b4b4b',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                      }}
                      onClick={() => {
                        window.open(currentViewObject.url, '_blank')
                      }}
                    >
                      <Box
                        style={{
                          position: 'absolute',
                          top: '5px',
                          right: '10px',
                        }}
                      >
                        <Icon
                          style={{
                            color: 'white',
                          }}
                        >
                          link
                        </Icon>
                      </Box>

                      <Box
                        style={{
                          backgroundImage: `url(${currentViewObject.icon})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                          width: '40px',
                          minWidth: '40px',
                          maxWidth: '40px',
                          height: '40px',
                          maxHeight: '40px',
                          cursor: 'pointer',
                        }}
                        className={'linkGalleryPreview'}
                      />
                    </Box>
                  )}

                  {currentViewObject.fileTypeId === IObjectType.IMAGE && (
                    <RootRef rootRef={imgRef}>
                      <img
                        src={currentViewObject.url}
                        style={{
                          width: dimension.width,
                          height: dimension.height,
                          maxWidth: '600px',
                          maxHeight: '350px',
                        }}
                        className={'imageGalleryPreview'}
                        onClick={() => {
                          window.open(currentViewObject.url, '_blank')
                        }}
                      />
                    </RootRef>
                  )}

                  {currentViewObject.fileTypeId === IObjectType.VIDEO && currentViewObject.onlyUrl && currentViewObject.url !== 'none' && (
                    <object className={'videoExternalGalleryPreview'} width={'500px'} height={'350px'} data={`${currentViewObject.urlPlayer}`}></object>
                  )}
                  {currentViewObject.fileTypeId === IObjectType.VIDEO && currentViewObject.onlyUrl && currentViewObject.url === 'none' && (
                    <object
                      className={'videoExternalGalleryPreview'}
                      width={'500px'}
                      height={'350px'}
                      // maxWidth={'600px'}
                      // maxHeight={'350px'}
                      data={`${currentViewObject.urlPlayer}`}
                      onCanPlay={() => {}}
                    ></object>
                  )}
                  {currentViewObject.fileTypeId === IObjectType.VIDEO && !currentViewObject.onlyUrl && (
                    <Box width={dimension.width} height={dimension.height} maxWidth={'600px'} maxHeight={'350px'}>
                      <video
                        width={'500px'}
                        height={'350px'}
                        style={{ cursor: 'pointer', pointerEvents: 'none' }}
                        className={'videoGalleryPreview'}
                        onClick={() => currentViewObject.urlPlayer && window.open(currentViewObject.urlPlayer, '_blank')}
                      >
                        <source src={`${currentViewObject.url}`} type="video/mp4" />
                      </video>
                    </Box>
                  )}

                  {currentViewObject.fileTypeId === IObjectType.DOCUMENT && (
                    <Box
                      className={'documentGalleryPreview'}
                      onClick={() => {
                        //download file from url
                        const downloadFile = async (url: string) => {
                          try {
                            const response = await fetch(url)
                            const blob = await response.blob()
                            const urlBlob = window.URL.createObjectURL(blob)
                            const a = document.createElement('a')
                            a.href = urlBlob
                            a.download = currentViewObject.name
                            a.click()
                            a.remove()
                          } catch (e) {
                            console.log(e)
                          }
                        }
                        downloadFile(currentViewObject.downloadUrl).then((res) => {
                          console.log(res)
                        })
                      }}
                      style={{
                        backgroundImage: `url(${objectImageByExtension(currentViewObject.extension as Extensions)})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        width: '150px',
                        minWidth: '150px',
                        maxWidth: '150px',
                        height: '150px',
                        maxHeight: '150px',
                      }}
                    />
                  )}

                  {currentViewObject.fileTypeId === IObjectType.PAGE && (
                    <Box
                      onClick={() => {
                        window.open(currentViewObject.pageUrl, '_blank')
                      }}
                      style={{
                        backgroundImage: `url(${currentViewObject.url})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        width: '150px',
                        minWidth: '150px',
                        maxWidth: '150px',
                        height: '150px',
                        maxHeight: '150px',
                        cursor: 'pointer',
                        borderTopRightRadius: '15px',
                        borderBottomLeftRadius: '15px',
                      }}
                    />
                  )}
                  {currentViewObject.fileTypeId === IObjectType.AUDIO && (
                    <Box
                      style={{
                        backgroundImage: `url(${objectImageByExtension('audio')})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        width: '150px',
                        minWidth: '150px',
                        maxWidth: '150px',
                        height: '150px',
                        maxHeight: '150px',
                        cursor: 'pointer',
                      }}
                    />
                  )}
                  {currentViewObject.fileTypeId === IObjectType.FOLDER && (
                    <Box
                      style={{
                        backgroundImage: `url(${objectImageByExtension('folder')})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        width: '150px',
                        minWidth: '150px',
                        maxWidth: '150px',
                        height: '150px',
                        maxHeight: '150px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        const executeCommand = async (id: number) => {
                          const commandLink = await getCommandDetailLinkService(id)
                          window.open(commandLink, '_blank')
                        }

                        executeCommand(objects[currentImageIndex].id)
                      }}
                    />
                  )}
                </Box>
                <Typography
                  style={{
                    fontSize: 16,
                    textAlign: 'center',
                  }}
                >
                  {currentViewObject.name}
                </Typography>
              </Box>
            </Tooltip>
            <Box>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <Box
                  style={{
                    maxHeight: '100px',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    paddingBottom: 10,
                    paddingTop: 1,
                    paddingLeft: 1,
                    paddingRight: 1,
                  }}
                  className={scrollStyle.root}
                >
                  {extraComponent && extraComponent}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            style={{
              flexGrow: 1,
              flexShrink: 1,
              maxWidth: '50px',
              paddingTop: 20,
              paddingRight: 20,
            }}
          >
            {buttonShow.next && (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <IconButton
                  style={{
                    backgroundColor: '#4b4b4b',
                    color: 'white',
                    borderRadius: '50%',
                  }}
                  classes={{ root: hoverStyle.root }}
                  onClick={() => handleNavigate('next', objects.length - 1)}
                >
                  <Icon>chevron_right</Icon>
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
