import React from 'react'
import { BoardContext, ConfigContext, LocationContext, PageContext } from 'context'
import {
  Attributes,
  CloneIcon,
  Element,
  Image,
  Video,
  Audio,
  Page,
  Line,
  File,
  Text,
  Accordion,
  IAccordion,
  Folder,
  Master,
  Login,
  PracticePersonality,
  PracticeGroups,
  Conference,
} from 'components'
import { IElement, IPosition } from 'types'
import { Button, Icon, IconButton, Paper, Popper, Tooltip, Typography, Slide, Snackbar } from '@material-ui/core'
import {
  BORDER_RADIUS_UNITS_OPTIONS,
  ELEMENT_TYPES,
  findAudio,
  findDocument,
  findImage,
  findPage,
  findVideo,
  ID_LINK_TAG,
  isTrustedEvent,
  openObjectInfo,
  sendPosition,
  updatePage,
} from 'lib'
import { useEventListener, useInterval, useStorage } from 'hooks'
import Lottie from 'react-lottie'

import done from '../../assets/animations/done.json'
import REST, { MAX_BEACON_STRING_SIZE } from 'lib/constants/REST'
import { useHistory, useLocation } from 'react-router'
import { Alert } from '@material-ui/lab'
import PracticePerxona from 'components/Practice/Perxona/PracticePerxona'
import { Header } from 'components/Practice/Header/Header'
import { HeaderValues } from 'components/HeaderProps/HeaderProps'
import ConferenceProvider from 'components/Practice/Conference/context/ConferenceProvider'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: done,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

const editingLocalStorage = 'inteligenxia_editing'

const AUTO_SAVE_INTERVAL = 60000 * 3

const classes = {
  page: 'page',
  board: 'board',
}

const Design = () => {
  const {
    designElements,
    size,
    position,
    style: pageStyle,
    updateElement,
    deleteElement,
    moveElement,
    cloneElement,
    addElement,
    id,
    src,
    updateElements,
    properties,
    darkBg,
    accordions,
    addAccordion,
    updateAccordion,
    deleteAccordion,
    applyAccordion,
    savePosition,
    cancelSavePositions,
    restoreOriginalPositions,
    cards,
    getDataForHeader,
    headerData,
  } = React.useContext(PageContext)

  const boardEleRef = React.useRef<HTMLDivElement>(null)
  const history = useHistory()
  const location = useLocation()
  const { userId, tabId } = React.useContext(LocationContext)
  const { saved, setSaved, saveItems } = useStorage({ key: id.toString() })
  const { style: boardStyle } = React.useContext(BoardContext)
  const { fonts, defaultFont, addUsedFont, filesContext, usedFonts, copyExternalState, selectionState, setSelectionState, checkLink, setCheckLink } =
    React.useContext(ConfigContext)
  const [selectedElement, setSelectedElement] = React.useState<IElement | undefined>()
  const [selectedAccordion, setSelectedAccordion] = React.useState<IAccordion | undefined>()
  const [attributesAnchorEl, setAttributesAnchorEl] = React.useState<null | HTMLElement>(null)
  const [confirmLinkAnchorEl, setConfirmLinkAnchorEl] = React.useState<null | HTMLElement>(null)
  const [deleteAnchorEl, setDeleteAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleClickDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDeleteAnchorEl(event.currentTarget)
    setConfirmLinkAnchorEl(null)
  }
  const handleCloseDelete = () => setDeleteAnchorEl(null)
  const [offsetY, setOffsetY] = React.useState<number>(0)
  const [lastClickPosition, setLastClickPosition] = React.useState<IPosition>({} as IPosition)
  const [openOptions, setOpenOptions] = React.useState<boolean>(false)
  const [openAttributes, setOpenAttributes] = React.useState<boolean>(false)
  const [openMaster, setOpenMaster] = React.useState<boolean>(false)
  const [masterPosition, setMasterPosition] = React.useState<IPosition>({} as IPosition)
  const [openMessage, setOpenMessage] = React.useState<boolean>(false)
  const [mouseOnBoard, setMouseOnBoard] = React.useState<boolean>(false)
  const [openSavePositionsMessage, setOpenSavePositionsMessage] = React.useState<boolean>(false)
  const [existLoginElement, setExistLoginElement] = React.useState<boolean>(false)
  const [selectionAndReorderActive, setSelectionAndReorderActive] = React.useState<boolean>(false)
  React.useEffect(() => {
    const existHeader = designElements.find((e) => e.type === ELEMENT_TYPES.header) !== undefined ? true : false
    if (existHeader && headerData === undefined) {
      const getValues = async () => {
        const { entityId, objectId, serviceId } = designElements.find((e) => e.type === ELEMENT_TYPES.header)!.value as HeaderValues
        try {
          await getDataForHeader(entityId, serviceId, objectId)
        } catch (error) {
          console.log(JSON.stringify(error))
        }
      }
      getValues()
    }
  }, [designElements, headerData])
  const handleCloseMaster = () => setOpenMaster(false)
  const handleDoubleClick = (e: any) => {
    if (savePosition.active) {
      setOpenSavePositionsMessage(true)
      return
    }
    if (e.target.className === classes.page || e.target.className === classes.board) {
      setOpenMaster(true)
      setMasterPosition({ x: e.pageX, y: e.pageY })
    }
  }
  const handleOpenAttributes = () => {
    setOpenAttributes(true)
    setOpenOptions(false)
    handleCloseDelete()
  }

  const handleElementClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, element: IElement) => {
    if (selectedElement && !openOptions && !openAttributes) {
      return
    }
    if (selectedAccordion) {
      return
    }
    setOpenOptions(true)
    setSelectedElement(element)
    setSelectedAccordion(undefined)
    setAttributesAnchorEl(event.currentTarget)
    setOffsetY(event.pageY - (position.y + element.position.y))
    setLastClickPosition({ x: event.pageX, y: event.pageY - position.y })
    handleCloseDelete()
  }
  const handleAccordionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, accordion: IAccordion) => {
    if (selectedElement && !openOptions && !openAttributes) {
      return
    }
    setSelectedAccordion(accordion)
    setSelectedElement(undefined)
    handleCloseDelete()
  }
  const handleBoardClick = (event: any) => {
    if (selectedAccordion) {
      return
    }
    if (event.target.className === classes.page || event.target.className === classes.board) {
      setSelectionAndReorderActive(false)
      setSelectedElement(undefined)
      setSelectedAccordion(undefined)
      setAttributesAnchorEl(null)
      setConfirmLinkAnchorEl(null)
      setOpenAttributes(false)
      setOpenOptions(false)
      handleCloseDelete()
    }
  }
  const handleDeleteElement = () => {
    if (selectedElement) {
      deleteElement(selectedElement)
    }
    setSelectedElement(undefined)
    setSelectedAccordion(undefined)
    handleCloseDelete()
  }
  const handleClone = (withLink: boolean) => {
    if (selectedElement) {
      const clonedElement =  cloneElement(selectedElement, withLink)
      
      setSelectedElement(clonedElement)
      setConfirmLinkAnchorEl(null)
    }
  }

  const handleSelectionAndReorder = () => setSelectionAndReorderActive((prev) => !prev)

  const isTextElement = React.useCallback(() => {
    return selectedElement?.type === ELEMENT_TYPES.text
  }, [selectedElement])
  const isLineElement = React.useCallback(() => {
    return selectedElement?.type === ELEMENT_TYPES.line
  }, [selectedElement])
  const isFolderElement = React.useCallback(() => {
    return selectedElement?.type === ELEMENT_TYPES.folder
  }, [selectedElement])
  const isLoginElement = React.useCallback(() => {
    return selectedElement?.type === ELEMENT_TYPES.login
  }, [selectedElement])
  const isHeaderElement = React.useCallback(() => {
    return selectedElement?.type === ELEMENT_TYPES.header
  }, [selectedElement])

  const isPracticeElement = React.useCallback(() => {
    return (
      selectedElement?.type === ELEMENT_TYPES.practiceCard ||
      selectedElement?.type === ELEMENT_TYPES.practiceCardPersonality ||
      selectedElement?.type === ELEMENT_TYPES.practiceCardGroups ||
      selectedElement?.type === ELEMENT_TYPES.practiceConference
    )
  }, [selectedElement])
  // const isPageElement = React.useCallback(() => { return selectedElement?.type === ELEMENT_TYPES.page }, [selectedElement])
  const isLastElement = React.useCallback(() => {
    return designElements.findIndex((d) => d.id === selectedElement?.id) + 1 === designElements.length
  }, [selectedElement, designElements])
  const isFirstElement = React.useCallback(() => {
    return designElements.findIndex((d) => d.id === selectedElement?.id) === 0
  }, [selectedElement, designElements])
  const handleSuccessSave = () => setOpenMessage(true)

  React.useEffect(() => {
    const handleUpdateSelectedElement = () => {
      if (selectedElement) {
        const newSelectedElement = designElements.find((d) => d.id === selectedElement.id)
        if (newSelectedElement !== selectedElement) {
          setSelectedElement(newSelectedElement)
        }
      }
    }
    handleUpdateSelectedElement()
  }, [designElements, selectedElement])

  const handleAutoSave = async () => {
    if (saved) return
    const req = {
      idObjetoPaginaRest: id,
      dataDiseno: JSON.stringify({ nodes: designElements, fonts: usedFonts, properties: properties }),
      revisarLink: checkLink,
      usuarioId: userId,
    }

    const res = await updatePage(req)

    if (res !== null) {
      setSaved(true)
      setCheckLink(false)
      handleSuccessSave()
    } else {
      const divError = `
            <div style='display:flex;justify-content:center; align-items:center ; width:100vw;height:100vh' >
            <img src='https://developer.perxona.com/perxona/javax.faces.resource/images/logo-menu-def.png.xhtml?ln=perxona'
                style='width:200px;height:'100%'
            />
            <div style='font-size:15px;font-weight:bold'>CIERRA LA PAGINA E INICIA SESIÓN  PARA CONTINUAR EDITANDO EN INTELIGENXIA</div>                        
            </div>
            `
      document.body.innerHTML = divError
    }
  }

  const handleSave = async () => {
    await updatePage({
      idObjetoPaginaRest: id,
      dataDiseno: JSON.stringify({ nodes: designElements, fonts: usedFonts, properties: properties }),
      revisarLink: checkLink,
      usuarioId: userId,
    })
    setSaved(true)
    setCheckLink(false)
    handleSuccessSave()
  }

  const handleSelection = (data: any) => {
    if (Boolean(copyExternalState)) {
      history.push({ pathname: '/selection', state: { element: selectedElement, external: true, id: data.id, url: data.url } })
      return
    }
    if (selectedElement) {
      history.push({ pathname: '/selection', state: { element: selectedElement, external: true, id: data.id, url: data.url } })
    }
  }

  const handlePosition = async () => {
    const x = document.documentElement.scrollLeft
    const y = document.documentElement.scrollTop
    await sendPosition(id, Math.floor(x), Math.floor(y), tabId)
  }

  const handleDeleteAccordion = (deleted: IAccordion) => {
    deleteAccordion(deleted)
    setSelectedAccordion(undefined)
  }
  /**
   *
   * para manejar el link del evento que llega desde perxona ee
   */
  const handleLink = (link: any) => {
    setCheckLink(true)
    if (selectionState?.selection && selectionState?.url) {
      setSelectionState({})
      history.push(`/${selectionState.url}`, {
        state: {
          id: selectionState.id,
          selection: selectionState.selection,
          link: link.url + `${ID_LINK_TAG}${link.id}`,
        },
      })
      return
    }
    if (selectedElement) {
      updateElement({
        ...selectedElement,
        link: link.url + `${selectedElement.type !== ELEMENT_TYPES.login ? ID_LINK_TAG : ''}${selectedElement.type !== ELEMENT_TYPES.login ? link.id : ''}`,
        updateLink: true,
      })
    }
  }

  const handleInfo = async () => {
    if (selectedElement) {
      await openObjectInfo(id, selectedElement.value[0], tabId)
    }
  }

  /**
   * llama al pop up en perxonaee
   */
  const handleChange = () => {
    if (selectedElement) {
      switch (selectedElement.type) {
        case ELEMENT_TYPES.image:
          findImage(id, tabId)
          break
        case ELEMENT_TYPES.page:
          findPage(id, tabId)
          break
        case ELEMENT_TYPES.video:
          findVideo(id, tabId)
          break
        case ELEMENT_TYPES.audio:
          findAudio(id, tabId)
          break
        case ELEMENT_TYPES.file:
          findDocument(id, tabId)
          break
      }
    }
  }

  const handleValue = (data: any) => {
    if (selectedElement) {
      updateElement({ ...selectedElement, value: data.value })
    }
  }

  React.useEffect(() => {
    saveItems(designElements)
  }, [designElements])

  const positionsChanged = React.useCallback(() => {
    let changed = false
    for (const element of designElements) {
      const saved = savePosition.positions.find((s) => s.id === element.id)
      if (saved && (saved.position.x !== element.position.x || saved.position.y !== element.position.y)) {
        changed = true
      }
    }
    return changed
  }, [designElements, savePosition.positions])

  /**escucha los eventos del java ee */
  const handler = React.useCallback(
    (event) => {
      if (isTrustedEvent(event.origin)) {
        const { message } = JSON.parse(event.data)
        if (message === 'save') {
          handleSave()
        }
        if (message === 'update') {
          updateElements()
        }
        if (message === 'openPage') {
          handleSelection(JSON.parse(event.data))
        }
        if (message === 'selectLink') {
          handleLink(JSON.parse(event.data))
        }
        if (message === 'position') {
          handlePosition()
        }
        if (message === 'changeValue') {
          handleValue(JSON.parse(event.data))
        }
      }
    },
    [designElements, selectedElement, copyExternalState, position, location, selectionState, history, checkLink, properties, userId]
  )

  React.useEffect(() => {
    if (designElements) {
      const existLoginElements = designElements.filter((e) => e.type === ELEMENT_TYPES.login).length > 0
      setExistLoginElement(existLoginElements)
    }
  }, [designElements])

  useEventListener('message', handler)
  useInterval(handleAutoSave, AUTO_SAVE_INTERVAL)

  React.useEffect(() => {
    window.onbeforeunload = (event: any) => {
      //esto guarda la pagina que estaba en edicion
      const savedItems: string[] = JSON.parse(localStorage.getItem(editingLocalStorage) || '[]')
      localStorage.setItem(editingLocalStorage, JSON.stringify(savedItems.filter((e) => e !== id.toString())))
      //aqui creo el request que sera enviado al last save
      const req = {
        idObjetoPaginaRest: id,
        dataDiseno: JSON.stringify({ nodes: designElements, fonts: usedFonts, properties: properties }),
        revisarLink: false,
        usuarioId: userId,
      }
      //  const req = {
      //      ...src,
      //      dataDiseno: JSON.stringify({ nodes: designElements, fonts: usedFonts, properties: properties }),
      //      porPublicar: true,
      //      usuarioId: userId
      //  }
      //aqui parseo el objeto request a un string
      const data = JSON.stringify(req)
      if (data.length < MAX_BEACON_STRING_SIZE) {
        //en el caso de ser menor a 2^16 se envia por el metodo propio del navegador
        navigator.sendBeacon(REST.baseURL + REST.saveOnClosePage, data)
      } else {
        //caso contrario envia por el metodo normal de guardado
        handleSave()
        return 'Guardando elementos...'
      }
    }
  }, [designElements, usedFonts, properties, location.pathname, id, userId])

  const Elements = () =>
    React.useMemo(() => {
      return (
        <React.Fragment>
          {designElements &&
            designElements.map((element) => (
              <React.Fragment key={`element` + element.id}>
                {!element.float && (
                  <Element
                    onClick={handleElementClick}
                    selected={selectedElement}
                    updateElement={updateElement}
                    closeOptions={() => {
                      setOpenOptions(false)
                      setOpenAttributes(false)
                    }}
                    element={element}
                    mouseOnBoard={mouseOnBoard}
                    pageSize={size}
                    pagePosition={position}
                    headerVisibleMode={
                      designElements.find((e) => e.type === ELEMENT_TYPES.header) !== undefined
                        ? (designElements.find((e) => e.type === ELEMENT_TYPES.header)!.value as HeaderValues).minimize
                          ? false
                          : true
                        : false
                    }
                  >
                    {element.type === ELEMENT_TYPES.text ? (
                      <Text
                        id={element.id}
                        addUsedFont={addUsedFont}
                        defaultFont={defaultFont}
                        fonts={fonts}
                        value={element.value}
                        onValueChange={(newValue) => updateElement({ ...element, value: newValue })}
                        align={element.style.justifyContent}
                        selected={selectedElement?.id === element.id}
                        pageId={id}
                        link={element.link}
                      />
                    ) : undefined}
                    {element.type === ELEMENT_TYPES.image ? (
                      <Image
                        value={element.value}
                        filesContext={filesContext}
                        style={element.style}
                        borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                      />
                    ) : undefined}
                    {element.type === ELEMENT_TYPES.video ? (
                      <Video
                        value={element.value}
                        filesContext={filesContext}
                        style={element.style}
                        size={element.size}
                        borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                      />
                    ) : undefined}
                    {element.type === ELEMENT_TYPES.audio ? (
                      <Audio value={element.value} filesContext={filesContext} style={element.style} size={element.size} />
                    ) : undefined}
                    {element.type === ELEMENT_TYPES.page ? (
                      <Page
                        value={element.value}
                        filesContext={filesContext}
                        style={element.style}
                        borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                      />
                    ) : undefined}
                    {element.type === ELEMENT_TYPES.line ? <Line value={element.value} style={element.style} /> : undefined}
                    {element.type === ELEMENT_TYPES.file ? (
                      <File value={element.value} style={element.style} filesContext={filesContext} darkBg={darkBg} />
                    ) : undefined}
                    {element.type === ELEMENT_TYPES.folder ? <Folder filesContext={filesContext} value={element.value} style={element.style} /> : undefined}

                    {element.type === ELEMENT_TYPES.login ? (
                      <Login
                        props={{
                          value: element.value,
                          style: element.style,
                          design: true,
                          size: element.size,
                        }}
                      ></Login>
                    ) : undefined}
                    {element.type === ELEMENT_TYPES.practiceCard ? (
                      <PracticePerxona
                        cards={cards}
                        value={element.value}
                        style={element.style}
                        modePage={'design'}
                        size={element.size}
                        selectionAndReorderActive={selectionAndReorderActive && selectedElement?.id === element.id}
                        element={element}
                      />
                    ) : undefined}
                    {element.type === ELEMENT_TYPES.practiceCardPersonality ? (
                      <PracticePersonality
                        selectionAndReorderActive={selectionAndReorderActive && selectedElement?.id === element.id}
                        element={element}
                        isDesign={true}
                      />
                    ) : undefined}

                    {element.type === ELEMENT_TYPES.practiceCardGroups ? (
                      <PracticeGroups
                        element={element}
                        selectionAndReorderActive={selectionAndReorderActive && selectedElement?.id === element.id}
                        isDesign={true}
                      />
                    ) : undefined}

                    {element.type === ELEMENT_TYPES.practiceConference ? (
                      <ConferenceProvider>
                        <Conference element={element} isDesign selectionConferenceMode={selectionAndReorderActive && selectedElement?.id === element.id} />
                      </ConferenceProvider>
                    ) : undefined}
                  </Element>
                )}
              </React.Fragment>
            ))}
        </React.Fragment>
      )
    }, [designElements, selectedElement, mouseOnBoard, cards, selectionAndReorderActive])

  const FloatElements = () =>
    React.useMemo(() => {
      return (
        <React.Fragment>
          {designElements &&
            designElements.map((element, index) => (
              <React.Fragment key={`element` + element.id + index}>
                {element.float && (
                  <Element
                    onClick={handleElementClick}
                    selected={selectedElement}
                    updateElement={updateElement}
                    closeOptions={() => {
                      setOpenOptions(false)
                      setOpenAttributes(false)
                    }}
                    element={element}
                    mouseOnBoard={mouseOnBoard}
                    pageSize={size}
                    pagePosition={position}
                    headerVisibleMode={
                      designElements.find((e) => e.type === ELEMENT_TYPES.header) !== undefined
                        ? (designElements.find((e) => e.type === ELEMENT_TYPES.header)!.value as HeaderValues).minimize
                          ? false
                          : true
                        : false
                    }
                  >
                    {element.type === ELEMENT_TYPES.text ? (
                      <Text
                        id={element.id}
                        addUsedFont={addUsedFont}
                        defaultFont={defaultFont}
                        fonts={fonts}
                        value={element.value}
                        onValueChange={(newValue) => updateElement({ ...element, value: newValue })}
                        align={element.style.justifyContent}
                        selected={selectedElement?.id === element.id}
                        pageId={id}
                        link={element.link}
                      />
                    ) : undefined}
                    {element.type === ELEMENT_TYPES.image || element.type === ELEMENT_TYPES.footer ? (
                      <Image
                        value={element.value}
                        filesContext={filesContext}
                        style={element.style}
                        borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                      />
                    ) : undefined}
                    {element.type === ELEMENT_TYPES.video ? (
                      <Video
                        value={element.value}
                        filesContext={filesContext}
                        style={element.style}
                        size={element.size}
                        borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                      />
                    ) : undefined}
                    {element.type === ELEMENT_TYPES.audio ? (
                      <Audio value={element.value} filesContext={filesContext} style={element.style} size={element.size} />
                    ) : undefined}
                    {element.type === ELEMENT_TYPES.page ? (
                      <Page
                        value={element.value}
                        filesContext={filesContext}
                        style={element.style}
                        borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                      />
                    ) : undefined}
                    {element.type === ELEMENT_TYPES.line ? <Line value={element.value} style={element.style} /> : undefined}
                    {element.type === ELEMENT_TYPES.file ? (
                      <File value={element.value} style={element.style} filesContext={filesContext} darkBg={darkBg} />
                    ) : undefined}
                    {element.type === ELEMENT_TYPES.folder ? <Folder filesContext={filesContext} value={element.value} style={element.style} /> : undefined}

                    {element.type === ELEMENT_TYPES.login ? (
                      <Login
                        props={{
                          value: element.value,
                          style: element.style,
                          design: true,
                          size: element.size,
                        }}
                      ></Login>
                    ) : undefined}
                    {element.type === ELEMENT_TYPES.practiceCard && (
                      <PracticePerxona
                        cards={cards}
                        value={element.value}
                        style={element.style}
                        modePage={'design'}
                        size={element.size}
                        selectionAndReorderActive={selectionAndReorderActive && selectedElement?.id === element.id}
                        element={element}
                      />
                    )}
                    {element.type === ELEMENT_TYPES.practiceCardPersonality ? (
                      <PracticePersonality
                        element={element}
                        selectionAndReorderActive={selectionAndReorderActive && selectedElement?.id === element.id}
                        isDesign={true}
                      />
                    ) : undefined}

                    {element.type === ELEMENT_TYPES.practiceCardGroups ? (
                      <PracticeGroups
                        element={element}
                        selectionAndReorderActive={selectionAndReorderActive && selectedElement?.id === element.id}
                        isDesign={true}
                      />
                    ) : undefined}
                    {element.type === ELEMENT_TYPES.header ? (
                      <Header element={element} pageSize={size} headerData={headerData} filesContext={filesContext} />
                    ) : undefined}
                    {element.type === ELEMENT_TYPES.practiceConference ? (
                      <ConferenceProvider>
                        <Conference element={element} isDesign selectionConferenceMode={selectionAndReorderActive && selectedElement?.id === element.id} />
                      </ConferenceProvider>
                    ) : undefined}
                  </Element>
                )}
              </React.Fragment>
            ))}
        </React.Fragment>
      )
    }, [designElements, selectedElement, mouseOnBoard, cards, selectionAndReorderActive, headerData])

  return (
    <div className={classes.board} style={{ ...boardStyle }} onClick={handleBoardClick} onDoubleClick={handleDoubleClick} ref={boardEleRef}>
      <div
        className={classes.page}
        style={{
          ...pageStyle,
          width: size.width,
          height: size.height,
          left: position.x,
          top: position.y,
          overflow: 'hidden',
        }}
        onMouseLeave={() => setMouseOnBoard(true)}
        onMouseOver={() => setMouseOnBoard(false)}
      >
        {Elements()}
      </div>
      <div
        className={classes.page}
        style={{
          ...pageStyle,
          width: size.width,
          height: size.height,
          left: position.x,
          top: position.y,
          pointerEvents: 'none',
          backgroundColor: 'transparent',
          position: 'absolute',
        }}
        onMouseLeave={() => setMouseOnBoard(true)}
        onMouseOver={() => setMouseOnBoard(false)}
      >
        {FloatElements()}

        {accordions.map((accordion, index) => {
          return (
            <Accordion
              key={accordion.id + index.toString()}
              accordion={accordion}
              updateAccordion={updateAccordion}
              onClick={handleAccordionClick}
              selected={selectedAccordion}
              pagePosition={position}
              pageSize={size}
              deleteAccordion={handleDeleteAccordion}
              applyAccordion={applyAccordion}
            />
          )
        })}
      </div>
      <Popper
        open={Boolean(selectedElement && selectedElement.type !== ELEMENT_TYPES.footer) && !openAttributes && openOptions}
        anchorEl={attributesAnchorEl}
        transition
        placement="right-start"
        disablePortal={false}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
          offset: {
            enabled: true,
            offset: `${offsetY}, 6`,
          },
        }}
      >
        <Paper className="flex items-center">
          {!selectionAndReorderActive ? (
            <Tooltip title="Atributos" arrow>
              <IconButton color="primary" style={{ padding: 4, marginRight: 2, marginLeft: 2 }} onClick={handleOpenAttributes}>
                <Icon style={{ fontSize: '0.7em' }}>settings</Icon>
              </IconButton>
            </Tooltip>
          ) : isPracticeElement() ? null : (
            <Tooltip title="Atributos" arrow>
              <IconButton color="primary" style={{ padding: 4, marginRight: 2, marginLeft: 2 }} onClick={handleOpenAttributes}>
                <Icon style={{ fontSize: '0.7em' }}>settings</Icon>
              </IconButton>
            </Tooltip>
          )}
          {!isLoginElement() && !isHeaderElement() && ((!selectionAndReorderActive && isPracticeElement()) || !isPracticeElement()) ? (
            <Tooltip title={isTextElement() || isLineElement() ? 'Copiar' : 'Clonar'} arrow>
              <IconButton
                color="primary"
                style={{ padding: 4, marginRight: 2, marginLeft: 2 }}
                onClick={(event) => {
                  setDeleteAnchorEl(null)
                  if (!Boolean(selectedElement?.link)) {
                    handleClone(false)
                    return
                  }
                  setConfirmLinkAnchorEl(event.currentTarget)
                }}
              >
                {isTextElement() || isLineElement() ? <Icon style={{ fontSize: '0.65em' }}>file_copy</Icon> : <CloneIcon style={{ fontSize: '0.7em' }} />}
              </IconButton>
            </Tooltip>
          ) : null}

          {((!selectionAndReorderActive && isPracticeElement()) || (!isPracticeElement() && !isHeaderElement())) && (
            <Tooltip title="Eliminar" arrow>
              <IconButton color="primary" style={{ padding: 4, marginRight: 2, marginLeft: 2 }} onClick={handleClickDelete}>
                <Icon style={{ fontSize: '0.7em' }}>delete</Icon>
              </IconButton>
            </Tooltip>
          )}
          {!isLoginElement() && !isPracticeElement() && !isLineElement() && !isTextElement() && !isFolderElement() && !isHeaderElement() && (
            <Tooltip title="Cambiar" arrow>
              <IconButton color="primary" style={{ padding: 4, marginRight: 2, marginLeft: 2 }} onClick={handleChange}>
                <Icon style={{ fontSize: '0.7em' }}>search</Icon>
              </IconButton>
            </Tooltip>
          )}
          {isPracticeElement() && (
            <Tooltip title={selectionAndReorderActive ? 'Volver a visualizar' : 'Seleccionar'} arrow>
              <IconButton
                color="primary"
                style={{
                  padding: 4,
                  marginRight: 2,
                  marginLeft: 2,
                  color: selectionAndReorderActive !== undefined ? (selectionAndReorderActive ? '#bdbdbd' : '#0288d1') : '#bdbdbd',
                }}
                onClick={handleSelectionAndReorder}
              >
                <Icon
                  style={{
                    fontSize: '0.7em',
                  }}
                >
                  {' '}
                  {selectionAndReorderActive ? 'preview' : 'recent_actors'}
                </Icon>
              </IconButton>
            </Tooltip>
          )}
          {!isLoginElement() && !isPracticeElement && !isLineElement() && !isTextElement() && (
            <Tooltip title="Información" arrow>
              <IconButton color="primary" style={{ padding: 4, marginRight: 2, marginLeft: 2 }} onClick={handleInfo}>
                <Icon style={{ fontSize: '0.7em' }}>info</Icon>
              </IconButton>
            </Tooltip>
          )}
        </Paper>
      </Popper>
      <Popper open={Boolean(deleteAnchorEl)} anchorEl={deleteAnchorEl}>
        <Paper className="p-2 pb-3">
          <Typography className="block" align="center" style={{ letterSpacing: 1 }} variant="caption">
            {'¿Eliminar objeto?'}
          </Typography>
          <div className="flex items-center mt-2">
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={handleDeleteElement}
              style={{
                fontSize: '0.7em',
                padding: 1,
                textTransform: 'capitalize',
              }}
            >
              <Icon style={{ fontSize: '0.85rem', marginRight: 2 }}>check</Icon>
              {'Si'}
            </Button>
            <Button
              size="small"
              variant="contained"
              style={{
                backgroundColor: '#f44336',
                fontSize: '0.7em',
                padding: 1,
                color: '#FFF',
                marginLeft: 8,
                textTransform: 'capitalize',
              }}
              onClick={handleCloseDelete}
            >
              <Icon style={{ fontSize: '0.85rem', marginRight: 2 }}>close</Icon>
              {'No'}
            </Button>
          </div>
        </Paper>
      </Popper>
      <Popper open={Boolean(confirmLinkAnchorEl)} anchorEl={confirmLinkAnchorEl}>
        <Paper className="p-2 pb-3">
          <Typography className="block" align="center" style={{ letterSpacing: 1 }} variant="caption">{`¿${
            isTextElement() || isLineElement() ? 'Copiar' : 'Clonar'
          } link?`}</Typography>
          <div className="flex items-center mt-2">
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => handleClone(true)}
              style={{
                fontSize: '0.7em',
                padding: 1,
                textTransform: 'capitalize',
              }}
            >
              <Icon style={{ fontSize: '0.85rem', marginRight: 2 }}>check</Icon>
              {'Si'}
            </Button>
            <Button
              size="small"
              variant="contained"
              style={{
                backgroundColor: '#f44336',
                fontSize: '0.7em',
                padding: 1,
                color: '#FFF',
                marginLeft: 8,
                textTransform: 'capitalize',
              }}
              onClick={() => handleClone(false)}
            >
              <Icon style={{ fontSize: '0.85rem', marginRight: 2 }}>close</Icon>
              {'No'}
            </Button>
          </div>
        </Paper>
      </Popper>

      <Master
        open={openMaster}
        onClose={handleCloseMaster}
        position={masterPosition}
        pagePosition={position}
        addElement={addElement}
        addAccordion={addAccordion}
        setSelectedAccordion={setSelectedAccordion}
        pageId={id}
        existLoginElement={existLoginElement}
      />
      <Slide in={openMessage}>
        <Lottie
          options={defaultOptions}
          isStopped={!openMessage}
          height={80}
          width={80}
          style={{ position: 'fixed', top: 10, left: window.screen.width / 2 - 40 }}
          eventListeners={[
            {
              eventName: 'loopComplete',
              callback: () => setTimeout(() => setOpenMessage(false), 1200),
            },
          ]}
        />
      </Slide>
      {selectedElement && selectedElement.type !== ELEMENT_TYPES.footer && openAttributes && (
        <Attributes
          element={selectedElement}
          updateElement={updateElement}
          moveElement={moveElement}
          onClose={() => {
            setOpenAttributes(false)
            setOpenOptions(true)
          }}
          lastClickPosition={lastClickPosition}
          pageSize={size}
          isFirstElement={isFirstElement()}
          isLastElement={isLastElement()}
          filesContext={filesContext}
          pagePosition={position}
          pageId={id}
          handleChange={handleChange}
          handleInfo={handleInfo}
          handleSelectionAndReorder={handleSelectionAndReorder}
        />
      )}
      {savePosition.active && (
        <div className="selection-menu flex flex-col px-4 rounded flex flex-col items-center justify-center pb-2">
          <Typography className="py-2" variant="caption">
            {'Reposicionar elementos'}
          </Typography>
          <div>
            {positionsChanged() ? (
              <Button
                size="small"
                variant="contained"
                style={{
                  fontSize: '0.7em',
                  padding: 1,
                  textTransform: 'capitalize',
                  paddingRight: 10,
                  paddingLeft: 10,
                }}
                color="primary"
                onClick={restoreOriginalPositions}
              >
                <Icon style={{ fontSize: '0.85rem', marginRight: 2 }}>save_alt</Icon>
                {'Reposicionar'}
              </Button>
            ) : (
              <Button
                size="small"
                variant="contained"
                style={{
                  backgroundColor: '#f44336',
                  fontSize: '0.7em',
                  padding: 1,
                  color: '#FFF',
                  textTransform: 'capitalize',
                  paddingRight: 10,
                  paddingLeft: 10,
                }}
                onClick={cancelSavePositions}
              >
                <Icon style={{ fontSize: '0.85rem', marginRight: 2 }}>close</Icon>
                {'Cancelar'}
              </Button>
            )}
          </div>
        </div>
      )}
      <Snackbar open={openSavePositionsMessage} autoHideDuration={5000} onClose={() => setOpenSavePositionsMessage(false)}>
        <Alert onClose={() => setOpenSavePositionsMessage(false)} severity="info">
          No se pueden agregar elementos en el modo guardar posiciones.
        </Alert>
      </Snackbar>
    </div>
  )
}

export default Design
