import React from 'react'
import { Tooltip } from '@material-ui/core'
import TextSelection from 'components/Text/TextSelection'
import { ISize } from 'types'
import { ICurtainValue } from 'components/CurtainProps/CurtainProps'
import { BORDER_RADIUS_UNITS_OPTIONS } from 'lib'

interface Props {
    value: ICurtainValue
    style: React.CSSProperties,
    size: ISize
    borderRadiusUnit: string
}

const Curtain = (props: Props) => {
    return (
        <React.Fragment>
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    pointerEvents: "none"
                }}
                className={`curtain-view`}
            >
                
            </div>
        </React.Fragment>
    )
}

export default Curtain
