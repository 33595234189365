import React from 'react'
import { Button, ButtonGroup, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, MenuItem, Radio, RadioGroup, TextField, Tooltip, Typography } from '@material-ui/core'
import { IElement } from 'types'

interface Props {
    element: IElement,
    updateElement: (newElement: IElement) => void,
}

export type View = "G" | "L"

export type Pagination = "top" | "bottom" | "both"

export type Permission = "P" | "F"

const marginLeft = 8

const FolderProps = (props: Props) => {
    const [showcase, setShowcase] = React.useState<boolean>(false)
    const [itemsPerPage, setItemsPerPage] = React.useState<number>(4)
    const [view, setView] = React.useState<View>("G")
    const [permissions, setPermissions] = React.useState<Permission>("P")
    const [pagination, setPagination] = React.useState<Pagination>("bottom")
    const { updateElement } = props
    React.useEffect(() => {
        setShowcase(Boolean(props.element.value[2]))
        setItemsPerPage(props.element.value[3] ?? 4)
        setView(props.element.value[4] ?? "G")
        setPagination(props.element.value[5] ?? "bottom")
        setPermissions(props.element.value[6] ?? "P")
    }, [props.element.value])
    const handleCommitChanges = () => {
        const value = [...props.element.value]
        value[3] = itemsPerPage
        value[4] = view
        value[5] = pagination
        value[6] = permissions
        updateElement({
            ...props.element,
            value
        })
    }
    const handleChangeView = (v: View) => {
        const value = [...props.element.value]
        value[3] = itemsPerPage
        value[4] = v
        value[5] = pagination
        value[6] = permissions
        updateElement({
            ...props.element,
            value
        })
    }
    const handleChangePermissions = (p: Permission) => {
        const value = [...props.element.value]
        value[3] = itemsPerPage
        value[4] = view
        value[5] = pagination
        value[6] = p
        updateElement({
            ...props.element,
            value
        })
    }
    return (
        <div className="panel-container px-2 pt-4 pb-2">
            <Typography color="primary" className="panel-label" variant="caption">{"Folder"}</Typography>
            <div className="flex items-center">
                <FormControl component="fieldset" style={{ display: "flex", justifyContent: "center", flex: 1 }}>
                    <FormGroup style={{ marginLeft }}>
                        <div className="flex flex-row items-center">
                            <Checkbox
                                onChange={(e) => {
                                    const checked = e.target.checked
                                    const value = [...props.element.value]
                                    value[2] = checked
                                    updateElement({
                                        ...props.element,
                                        value
                                    })
                                }}
                                color="primary"
                                size="small"
                                checked={showcase}
                                name="float"
                            />
                            <Typography variant="caption">{"Caxillero"}</Typography>
                        </div>
                    </FormGroup>
                </FormControl>
            </div>
            <div className="flex items-end my-1">
                <div className="w-2/5 mr-6 flex">
                    <TextField
                        label="Items"
                        value={itemsPerPage}
                        disabled={!showcase}
                        fullWidth
                        variant="outlined"
                        size="small"
                        classes={{ root: "text-input-sm" }}
                        style={{ marginLeft }}
                        type="number"
                        onChange={(e) => setItemsPerPage(Math.round(parseFloat(e.target.value)))}
                        onBlur={handleCommitChanges}
                        onKeyDown={(e) => { if (e.key === "Enter") { handleCommitChanges() } }}
                    />
                </div>
                <div>
                    <Typography color="textSecondary" style={{ fontSize: "0.7em" }}>{"Vista"}</Typography>
                    <ButtonGroup disabled={!showcase} disableElevation>
                        <Tooltip arrow title="Grid">
                            <Button onClick={() => handleChangeView("G")} style={{ padding: 0 }} size="small" color={view === "G" ? "primary" : "default"} variant={view === "G" ? "contained" : "outlined"}>{"G"}</Button>
                        </Tooltip>
                        <Tooltip arrow title="Lista">
                            <Button onClick={() => handleChangeView("L")} style={{ padding: 0 }} size="small" color={view === "L" ? "primary" : "default"} variant={view === "L" ? "contained" : "outlined"}>{"L"}</Button>
                        </Tooltip>
                    </ButtonGroup>
                </div>
            </div>
            <div className="flex items-end">
                <div className="w-2/5 mr-6">
                    <TextField
                        label="Paginación"
                        value={pagination}
                        fullWidth
                        variant="outlined"
                        size="small"
                        classes={{ root: "text-input-sm" }}
                        style={{ marginLeft }}
                        onChange={(e) => {
                            const selected = e.target.value
                            const value = [...props.element.value]
                            value[5] = selected
                            updateElement({
                                ...props.element,
                                value
                            })
                        }}
                        select
                    >
                        <MenuItem value={"top"}>{"Arriba"}</MenuItem>
                        <MenuItem value={"bottom"}>{"Abajo"}</MenuItem>
                        <MenuItem value={"both"}>{"Ambas"}</MenuItem>
                    </TextField>
                </div>
                <div>
                    <Typography color="textSecondary" style={{ fontSize: "0.7em" }}>{"Permisos"}</Typography>
                    <ButtonGroup disabled={!showcase} disableElevation>
                        <Tooltip arrow title="Página">
                            <Button onClick={() => handleChangePermissions("P")} style={{ padding: 0 }} size="small" color={permissions === "P" ? "primary" : "default"} variant={permissions === "P" ? "contained" : "outlined"}>{"P"}</Button>
                        </Tooltip>
                        <Tooltip arrow title="Folder">
                            <Button onClick={() => handleChangePermissions("F")} style={{ padding: 0 }} size="small" color={permissions === "F" ? "primary" : "default"} variant={permissions === "F" ? "contained" : "outlined"}>{"F"}</Button>
                        </Tooltip>
                    </ButtonGroup>
                </div>
            </div>
        </div>
    )
}

export default FolderProps
