import { ICardPersonality, ICardPerxona, ICardGroup } from 'components/CardProps/CardProps'
import React from 'react'


type PracticeCard = ICardPerxona | ICardPersonality | ICardGroup
export type FilterMode = "filterOnly" | "filterAndSort" | "all" | "none"


interface CardProps<Card> {
          chosen: number[];
          cards: Card[]
}

interface Hook<Card> {
          cards: Card[]
          filterCard: (filterMode: FilterMode, customFilter?: ((card: Card) => boolean) | undefined) => void
}


export const useFilterAndSort = <Card extends PracticeCard>(cardProps: CardProps<Card>): Hook<Card> => {

          const { chosen: cardIdsChosen, cards } = cardProps

          const [cardsToShow, setCardsToShow] = React.useState<Card[]>([]);

          React.useEffect(() => {
                    setCardsToShow(cards)
                    filterCard('filterOnly')
          },[cards])

          const sortedCards = React.useCallback((first: Card, second: Card) => {
                    const firstIndex = cardIdsChosen.indexOf(first.cardId)
                    const secondIndex = cardIdsChosen.indexOf(second.cardId)
                    return firstIndex - secondIndex
          }, [cardIdsChosen])

          const filterOnlyChosen = React.useCallback((card: Card) => {
                    return cardIdsChosen.includes(card.cardId)
          }, [cardIdsChosen])



          const filterCard = React.useCallback((filterMode: FilterMode, customFilter?: (card: Card) => boolean) => {
                    if (filterMode === "filterOnly" && customFilter === undefined) {

                              setCardsToShow(cards.filter(filterOnlyChosen))
                    } else if (filterMode === "filterAndSort" && customFilter === undefined) {
                              setCardsToShow(cards.filter(filterOnlyChosen).sort(sortedCards))
                    } else if (filterMode === "all" && customFilter === undefined) {
                              setCardsToShow([...cards])
                    } else if (filterMode === "none" && customFilter === undefined) {
                              setCardsToShow([])
                    } else if (customFilter !== undefined && filterMode === "filterOnly") {
                              const a=cards.filter(customFilter)
                              setCardsToShow(cards.filter(customFilter))
                    }

          }, [cards, filterOnlyChosen, sortedCards])

          return (
                    {
                              cards: cardsToShow,
                              filterCard,

                    }
          )
}
