import React from 'react'
import ReactPlayer from 'react-player'
import { ISize } from 'types'

interface Props {
    value: any,
    filesContext: string,
    style: React.CSSProperties,
    size: ISize,
    borderRadiusUnit: string
}

const Video = React.memo((props: Props) => {
    return (
        <ReactPlayer
            url={`${props.value[1].includes("http") ? "" : props.filesContext + "/"}${props.value[1]}`}
            width={props.size.width}
            height={props.size.height}
            controls={true}
            style={{
                ...props.style,
                borderWidth: props.style.borderWidth + "px",
                transform: `rotate(${JSON.parse(props.style.transform?.toString() ? props.style.transform?.toString() : "[]")[0]}deg) scaleX(${JSON.parse(props.style.transform?.toString() ? props.style.transform?.toString() : "[]")[1]})`,
                pointerEvents: "none",
                boxShadow: props.style.boxShadow
            }}
        />
    )
})

export default Video