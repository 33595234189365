import { Box, Breadcrumbs, Button, ButtonGroup, Grid, Link, makeStyles, Modal, Paper, TextField, Tooltip, } from "@material-ui/core"
import React from 'react'
import { Autocomplete, Pagination, Skeleton } from "@material-ui/lab"
import { CardGroup, CardPerxonaGroup, IPaginationConfigPerxona, ViewModePerxona } from "components"
import { ICardGroup, ICardPerxona, IPerxonaResponse } from "components/CardProps/CardProps"
import { getCardPerxona, stylesButtonPerxona } from 'lib'
import { IElement } from "types"
import { Pagination as IPagination } from "components/FolderProps/FolderProps";
import { HookOrder, IPageConfig, Move } from "hooks"
import { CardHorizontal } from "../Perxona/CardHorizontalPerxona"
import { SubGroupNavigation } from "./PracticeGroups"

type FilterMode = "filterOnly" | "filterAndSort" | "all" | "none"
type SelectionMode = 'selection' | 'reorder' | 'none' | 'perxonas'

type Props = {
  filterCard: (filterMode: FilterMode, customFilter?: ((card: ICardGroup) => boolean) | undefined) => void
  changeSelectionMode: (selectionMode: SelectionMode, cardId?: number | undefined) => void
  handleSelectGroup: (cardId: number) => void
  handleChangePage: (page: number) => void
  handleOnCheck: (checked: boolean, cardId: number) => void
  handleOnReorder: (move: Move, cardId: number) => void
  viewModePerxona: ViewModePerxona
  getSubGroupsData: (card: ICardGroup) => ISubGroup[]
  perxonaViewContainerRef: React.RefObject<HTMLDivElement>
  paginationConfigPerxona: IPaginationConfigPerxona
  originGroup: ICardGroup | undefined
  perxonas: IPerxonaResponse
  cards: ICardGroup[]
  mode: SelectionMode
  pageConfig: IPageConfig
  paginationPosition: IPagination
  element: IElement
  hookOrder: HookOrder
  loading: Boolean
  isDesign: boolean
  selectionAndReorderActive: boolean
  tittleContainer: string
  minimize: boolean
  cardsChosen: number[];
  calculateItemsPerxonasView: (viewMode: 'grid' | 'list') => void
  subGroupNavigation: SubGroupNavigation


}

const useStyles = makeStyles({

  minimizeButton: {
    color: 'black',
    '&:hover': {
      cursor: 'zoom-out',
    }
  },
  maximizeButton: {
    color: 'black',
    '&:hover': {
      cursor: 'zoom-in',
    }
  }


})

export type ISubGroup = {
  subGroupName: string
  subGroupId: number
  parentGroupId: number
}

type PropsViewPerxona = {
  groupName: string
  countPerxonas: number
  countSubGroup: number
  subGroups: ISubGroup[]
  description: string
  dateCreation: string
}

const HeaderViewPerxona = (props: PropsViewPerxona) => {
  const { groupName, countPerxonas, countSubGroup, subGroups, description, dateCreation } = props
  return (
    <Grid container direction="row" style={{ flexWrap: 'nowrap', }} >
      <Grid item xs={12}>
        <Box style={{ paddingLeft: 8, paddingTop: 0 }}>
          <Grid container direction="row" style={{ flexWrap: 'wrap', }} >
            <Grid item xs={12}  >
              <Box style={{ display: 'flex' }}>
                <Box>
                  <Paper style={{
                    borderTopLeftRadius: '0px', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px',
                    borderBottomRightRadius: '0px', borderWidth: '2px', width: '95px', maxWidth: '95px',
                  }} variant="outlined">
                    <Box fontSize={20} textAlign="center" style={{ paddingBottom: 2 }}>
                      {countPerxonas} {countSubGroup > 0 && ` | ${countSubGroup}`}
                    </Box>
                  </Paper>
                </Box>

                <Box>
                  <Box fontSize={11} style={{ paddingLeft: 8, paddingTop: 0 }}>
                    {`Creado el  ${dateCreation}`}
                  </Box>
                  <Box fontSize={17} style={{ paddingLeft: 8, paddingTop: 0 }}>
                    {description}
                  </Box>
                </Box>

              </Box>



            </Grid>

          </Grid>
        </Box>

      </Grid>
    </Grid>
  )
}
export const ContainerCardGroups = (props: Props) => {
  const { cards, filterCard, loading, element, selectionAndReorderActive, mode, changeSelectionMode,
    handleSelectGroup, pageConfig, handleChangePage, hookOrder, handleOnCheck, handleOnReorder,
    getSubGroupsData, perxonas, perxonaViewContainerRef: perxonaView, originGroup,
    paginationConfigPerxona, tittleContainer, paginationPosition, viewModePerxona, isDesign, minimize,
    cardsChosen, calculateItemsPerxonasView, subGroupNavigation } = props
  const { handleAddSubGroupNavigation, subGroupNavigation: subGroups, resetSubGroupNavigation,
    handleRemovePreviousSubGroupNavigation } = subGroupNavigation
  const { handleChangeViewMode, viewMode: viewPerxonas } = viewModePerxona
  const classes = useStyles()
  const [minimizeContainer, setMinimizeContainer] = React.useState<Boolean>(!isDesign ? minimize : false)
  const handleMinimizeContainer = () => setMinimizeContainer(prevState => !prevState)

  const { height: heightElement, width: widthElement } = element.size

  const { pageConfigPerxonas, slice } = paginationConfigPerxona

  const [open, setOpen] = React.useState(false)
  const [perxonaCard, setPerxonaCard] = React.useState<ICardPerxona>({} as ICardPerxona)
  const showCardPerxona = async (perxonaId) => {
    const response = await getCardPerxona(perxonaId)
    const data = response?.data as ICardPerxona
    setPerxonaCard(data)
  }

  const [nameFilter, setNameFilter] = React.useState<string | null>(null)

  React.useEffect(() => {
    if (!isDesign && minimizeContainer === false && cardsChosen.length === 1 && mode === 'none' && cards[0]?.hasOwnProperty('cardId')) {
      calculateItemsPerxonasView(viewPerxonas)
      changeSelectionMode('perxonas', cards[0].cardId);
      handleAddSubGroupNavigation({
        subGroupName: cards[0].groupName,
        subGroupId: cards[0].cardId,
        parentGroupId: cards[0].parentGroupId
      })

    }
  })

  return (
    <React.Fragment>
      <Grid
        container
        direction="column"
        style={{ height: !minimizeContainer ? element.size.height : '100%' }}
        wrap="nowrap"
        spacing={0}
      >
        <Grid item xs={12} style={{ width: "100%", flexBasis: 0 }}>
          <Box >
            <Grid container direction="row" style={{ flexWrap: 'wrap', }} >

              <Grid item xs={mode === 'perxonas' || selectionAndReorderActive ? widthElement < 466 ? 10 : 8 : 12}>

                <Box>

                  <Grid container direction="column" style={{ flexWrap: 'nowrap', width: '100%' }} >
                    <Grid item xs={12} style={{ width: "100%", flexBasis: 0 }} className={classes.maximizeButton}
                      onClick={() => {
                        calculateItemsPerxonasView(viewPerxonas)
                        handleMinimizeContainer();
                        (mode === 'none' && !isDesign && cards[0].hasOwnProperty('cardId') && cardsChosen.length === 1 && minimizeContainer) &&
                          changeSelectionMode('perxonas', cards[0].cardId);

                        if (cardsChosen.length === 1 && !isDesign) {
                          handleSelectGroup(cards[0].cardId);
                          handleAddSubGroupNavigation({
                            subGroupName: cards[0].groupName,
                            subGroupId: cards[0].cardId,
                            parentGroupId: cards[0].parentGroupId
                          })
                        }


                      }}
                    >
                      <Box fontSize={24} style={{ paddingLeft: 8, paddingBottom: 4, paddingTop: mode === 'perxonas' ? 2 : 8 }}>
                        {mode === 'perxonas' ? cards[0].groupName : tittleContainer}
                      </Box>
                    </Grid>


                  </Grid>
                </Box>


              </Grid>

              {(((mode === 'perxonas' || selectionAndReorderActive) && !minimizeContainer)) && <Grid item xs={widthElement < 466 ? 2 : 4}>
                <Box>
                  <Grid container direction="row" style={{ flexWrap: 'nowrap', }} >
                    <Grid item xs={12}>
                      <Box style={{ display: 'flex', flexWrap: widthElement < 466 ? 'wrap' : 'nowrap', flexDirection: 'row', justifyContent: 'flex-end ' }}>
                        {!(cardsChosen.length === 1 && !isDesign) && <Button style={{ ...stylesButtonPerxona.buttonPerxona, ...{ marginRight: 8, marginTop: 8, minWidth: "100px", minHeight: "30px", } }}
                          color={mode === 'selection' ? 'primary' : 'default'}
                          variant="contained" onClick={() => {
                            if (cardsChosen.length === 1 && !isDesign) {
                              changeSelectionMode('none')
                              handleChangePage(1)
                              resetSubGroupNavigation()
                              return
                            }
                            if (selectionAndReorderActive) {
                              changeSelectionMode('selection')
                              handleChangePage(1)
                            } else {
                              changeSelectionMode('none')
                              handleChangePage(1)
                              resetSubGroupNavigation()
                            }


                          }} >
                          <Box fontSize={11} >
                            {selectionAndReorderActive ? 'Seleccionar'  : 'Grupos'}
                          </Box>
                        </Button>}

                        {selectionAndReorderActive &&
                          <Button style={{ ...stylesButtonPerxona.buttonPerxona, ...{ marginRight: 8, marginTop: 8, minWidth: "100px", minHeight: "30px", } }}
                            color={mode === 'reorder' ? 'primary' : 'default'} variant="contained"
                            onClick={() => {
                              changeSelectionMode('reorder')
                              handleChangePage(1)
                            }}>
                            <Box fontSize={11}>
                              {selectionAndReorderActive ? 'Ordenar' : viewPerxonas === 'grid' ? 'Lista ' : 'Baldosas'}
                            </Box>
                          </Button>}

                        {(!selectionAndReorderActive && mode === 'perxonas') && <ButtonGroup disableElevation variant="contained" style={{ padding: 8 }}>
                          <Button
                            style={{ borderBottomLeftRadius: '15px', borderTopLeftRadius: '0px', maxWidth: 80, minWidth: 80 }}
                            color={viewPerxonas === 'grid' ? "primary" : "default"}
                            variant={viewPerxonas === 'grid' ? "contained" : "contained"}
                            onClick={() => handleChangeViewMode("grid")}>
                            <Box fontSize={11}>
                              {"Baldosa"}
                            </Box>
                          </Button>
                          <Button
                            style={{ borderBottomRightRadius: '0px', borderTopRightRadius: '15px', maxWidth: 80, minWidth: 80 }}
                            color={viewPerxonas === 'list' ? "primary" : "default"}
                            variant={viewPerxonas === 'list' ? "contained" : "contained"}
                            onClick={() => handleChangeViewMode("list")}>
                            <Box fontSize={11} >
                              {"Lista"}
                            </Box>
                          </Button>
                        </ButtonGroup>}


                      </Box>
                    </Grid>

                  </Grid>
                </Box>
              </Grid>}

            </Grid>

            {(mode === 'perxonas' && !minimizeContainer) && <HeaderViewPerxona
              countSubGroup={cards[0].countSubGroup}
              countPerxonas={cards[0].countPerxonas}
              groupName={cards[0].groupName}
              subGroups={getSubGroupsData(cards[0])}
              description={cards[0].description}
              dateCreation={new Date(cards[0].insertDate).toLocaleDateString("es", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
            />}

          </Box>
        </Grid >


        {(mode === 'perxonas' && !minimizeContainer) &&
          <Grid item xs={12} style={{ width: "100%", flexBasis: 0 }} >
            {(mode === 'perxonas' && perxonas.perxonas !== undefined && perxonas.perxonas?.length > 0) &&
              <Grid item xs={6}>
                <Box style={{ paddingLeft: 8, paddingTop: 2, paddingBottom: 2 }}>
                  <Autocomplete
                    freeSolo
                    disableClearable
                    style={{ minWidth: '175px', backgroundColor: '#FFFCD6' }}
                    onInputChange={(event, newInputValue) => {
                      setNameFilter(newInputValue.length > 0 ? newInputValue : null)
                    }}
                    options={perxonas.perxonas.map((option) => option.namePerxona)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Buscar Perxonas ..."
                        margin="none"
                        variant="outlined"
                        size="small"
                        classes={{ root: "adorned-text-input-sm" }}
                        InputProps={{ ...params.InputProps, type: 'search' }}
                      />
                    )}
                  />
                </Box>
              </Grid>}
            <Box style={{ padding: 8, display: "flex", flexWrap: 'wrap' }}>
              <Box >
                <Autocomplete
                  freeSolo
                  disabled={!(cards[0].countSubGroup > 0)}
                  options={getSubGroupsData(cards[0]).map((option) => option)}
                  getOptionLabel={(option) => option.subGroupName}
                  onChange={(e, v, reason) => {
                    if (reason === 'select-option') {
                      handleSelectGroup((v as ISubGroup).subGroupId);
                      changeSelectionMode('perxonas', (v as ISubGroup).subGroupId)
                      handleAddSubGroupNavigation(v as ISubGroup)
                      handleChangePage(1)
                    }
                  }}
                  style={{ minWidth: '175px', backgroundColor: '#FFFCD6' }}
                  renderInput={(params) => <TextField {...params}
                    label="Sub grupos"
                    variant="outlined"
                    classes={{ root: "adorned-text-input-sm" }}
                    size="small"
                    margin="none"

                  />}


                />

              </Box>

              {(subGroups.length > 0) && <Box style={{ paddingLeft: 8, paddingTop: 6 }}>
                <Breadcrumbs >
                  {subGroups.map((subGroup) =>
                    <Tooltip key={subGroup.subGroupId} title={subGroup.subGroupName}  >
                      <Link color="inherit" href="#" onClick={(e) => {
                        e.preventDefault();
                        handleSelectGroup(subGroup.subGroupId);
                        changeSelectionMode('perxonas', subGroup.subGroupId)
                        handleRemovePreviousSubGroupNavigation(subGroup)
                        handleChangePage(1)

                      }}>
                        {subGroup.subGroupName.length > 10 ? subGroup.subGroupName.substring(0, 10) + "..." : subGroup.subGroupName}
                      </Link>
                    </Tooltip>
                  )
                  }
                </Breadcrumbs>
              </Box>}
            </Box>

          </Grid>}

        {
          (paginationPosition !== 'bottom' && !minimizeContainer && ((mode === 'perxonas' && pageConfigPerxonas.totalPages > 1) || (mode !== 'perxonas' && pageConfig.totalPages > 1))) &&
          <Grid item xs={12} style={{ width: "100%", display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', flexBasis: '0%' }}>
            <Box style={{ marginTop: 8, marginBottom: 2, display: 'flex', justifyContent: 'center' }}>

              <Pagination
                color="primary"
                count={mode === 'perxonas' ? pageConfigPerxonas.totalPages : pageConfig.totalPages}
                page={mode === 'perxonas' ? pageConfigPerxonas.page : pageConfig.page}
                onChange={(_, newPage: number) => {
                  handleChangePage(newPage)
                }}
                siblingCount={0}
                showFirstButton showLastButton />
            </Box>
          </Grid>
        }

        {
          !minimizeContainer && <Grid item xs={12} style={{ height: '100%', overflow: "hidden" }} >
            {mode !== 'perxonas' && <Grid container spacing={3} justify="center" direction="row" wrap='nowrap' alignItems='center' alignContent='center' style={{ height: '100%' }}>
              {loading && <Box style={{
                display: 'flex', flexDirection: 'column',
                flexWrap: 'nowrap', alignItems: 'center', justifyContent: 'center'
                , maxWidth: 'max-content', height: '400px'
              }}>
                <Skeleton variant="rect" width={210} height='210px' />
              </Box>}
              {cards.map((card, index) => (
                <Grid item xs={12} key={index} style={{
                  display: 'flex', flexDirection: 'column',
                  flexWrap: 'nowrap', alignItems: 'center', justifyContent: 'center'
                  , maxWidth: 'max-content', height: '400px'
                }}>
                  <CardGroup
                    card={card}
                    mode={mode}
                    handleOnClickGroup={() => {
                      handleSelectGroup(card.cardId)
                      changeSelectionMode('perxonas', card.cardId)
                      handleAddSubGroupNavigation({
                        subGroupName: card.groupName,
                        subGroupId: card.cardId,
                        parentGroupId: card.parentGroupId
                      })
                    }}
                    hookOrder={hookOrder}
                    handleOnCheck={handleOnCheck}
                    handleOnReorder={handleOnReorder}
                  />
                </Grid>))}

            </Grid>}
            <div
              ref={perxonaView}
              style={{
                width: '100%', height: '100%'
              }}>
              {(mode === 'perxonas' && perxonas !== undefined && perxonas !== null && perxonas.perxonas !== undefined) &&

                <Box
                  style={{
                    height: 'fit-content', width: '100%', display: "flex", flexWrap: 'wrap',
                    justifyContent: 'flex-start',
                  }}>
                  {
                    (!loading && perxonas.perxonas !== undefined) && perxonas.perxonas.filter(perxona => nameFilter !== null ?
                      perxona.namePerxona.toLowerCase() === nameFilter.toLowerCase() : true).
                      slice(slice.initSlice, slice.endSlice).map((perxona, index) => (
                        <Box key={index} style={{ padding: '5px' }} >
                          <CardPerxonaGroup
                            perxonaData={perxona}
                            viewMode={viewPerxonas}
                            handleOnClick={(perxonaId: number) => {
                              setOpen(true)
                              showCardPerxona(perxonaId)

                            }}
                          />
                        </Box>
                      ))
                  }
                  {loading && Array.from(Array(cards[0].countPerxonas).keys()).map((_, index) => (
                    <Box key={index} p={1} >
                      <Skeleton key={index} variant="rect" width='80px' height='80px' style={{
                        borderTopLeftRadius: '0px', borderTopRightRadius: '20px',
                        borderBottomLeftRadius: '20px', borderBottomRightRadius: '0px', borderWidth: '1px'
                      }} />
                    </Box>
                  ))}
                </Box>
              }
            </div>

          </Grid>
        }
        {
          (paginationPosition !== 'top' && !minimizeContainer && ((mode === 'perxonas' && pageConfigPerxonas.totalPages > 1) || (mode !== 'perxonas' && pageConfig.totalPages > 1))) &&
          <Grid item xs={12} style={{ width: "100%", display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', flexBasis: '0%' }}>

            <Box style={{ marginTop: 8, display: 'flex', justifyContent: 'center' }}>

              <Pagination
                color="primary"
                count={mode === 'perxonas' ? pageConfigPerxonas.totalPages : pageConfig.totalPages}
                page={mode === 'perxonas' ? pageConfigPerxonas.page : pageConfig.page}
                onChange={(_, newPage: number) => {
                  handleChangePage(newPage)
                }}
                showFirstButton showLastButton
                siblingCount={0}
              />

            </Box>

          </Grid>
        }
      </Grid >
      <Box height="100%" width="100%">
        <Modal
          open={open}
          onClose={() => setOpen(false)}

          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}
        >
          {
            <CardHorizontal
              card={perxonaCard}
            />
          }
        </Modal>
      </Box>

    </React.Fragment >
  )
}


