import React, { useCallback, useMemo, useState } from 'react'
import isHotkey from 'is-hotkey'
import { Editable, withReact, Slate, ReactEditor } from 'slate-react'
import {
    Editor,
    Transforms,
    createEditor,
    Node,
    Element as SlateElement,
    Range
} from 'slate'
import { withHistory } from 'slate-history'
import { Icon, IconButton, Paper, Popper, PopperProps, Typography, ListItem, ListItemText, Tooltip, MenuItem, Modal, Box } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import isUrl from 'is-url'
import { SketchPicker } from 'react-color'
import { IFont } from 'hooks'
import { FixedSizeList as List, ListChildComponentProps } from "react-window"
import { DEEPLINK_TAG, ELEMENT_TYPES, getFontSizes, getLineHeights, getTextIdentations, findObject, HASH_PARAM, findLink, repeatedUrl, restartCounter } from 'lib'
import rgbHex from 'rgb-hex'
import hexRgb from 'hex-rgb'
import isHexcolor from 'is-hexcolor'
import { Rnd } from 'react-rnd'
import { useHistory, useLocation } from 'react-router-dom'
import { ConfigContext, LocationContext } from 'context'
import { FilterOptions, FilterRequest } from './Filter'
import REST from 'lib/constants/REST'


const entitySearchUrl = "react.perxona.com" === window.location.host ? "https://perxona.com/perxona/public/paginasAmarillas?" :
    "https://developer.perxona.com/perxona/public/paginasAmarillas?"

interface Props {
    value: any,
    fonts: IFont[],
    defaultFont: IFont,
    addUsedFont: (newFont: IFont) => void,
    onValueChange: (newValue: any) => void,
    align?: string,
    id: string,
    selected: boolean,
    pageId: number,
    link?: string
}

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']

const FONT_SIZES = getFontSizes()

const LINE_HEIGHTS = getLineHeights()

const TEXT_IDENTATIONS = getTextIdentations()

const TextElement = React.memo((props: Props) => {
    const backgroundColorRef = React.useRef<any>()
    const { setSelectionState, setCheckLink, hookFilterRequest } = React.useContext(ConfigContext)
    const { request } = hookFilterRequest
    const { tabId } = React.useContext(LocationContext)
    const location = useLocation()
    const history = useHistory()
    const { fonts, defaultFont, addUsedFont, onValueChange, pageId } = props
    const [value, setValue] = useState<Node[]>(props.value)
    const renderElement = useCallback(elementProps => <Element {...elementProps} />, [])
    const renderLeaf = useCallback(leafProps => <Leaf {...leafProps} />, [])
    const editor = useMemo(() => withLinks(withHistory(withReact(createEditor()))), [])
    const [readOnly, setReadOnly] = React.useState<boolean>(true)
    const [openTools, setOpenTools] = React.useState<boolean>(false)
    const [anchorEl, setAnchorEl] = React.useState<PopperProps['anchorEl']>(null)
    const [textAlignAnchorEl, setTextAlignAnchorEl] = React.useState<null | HTMLElement>(null)
    const [colorAnchorEl, setColorAnchorEl] = React.useState<null | HTMLElement>(null)
    const [backgroundAnchorEl, setBackgroundAnchorEl] = React.useState<null | HTMLElement>(null)
    const [fontFamilyAnchorEl, setFontFamilyAnchorEl] = React.useState<null | HTMLElement>(null)
    const [fontSizeAnchorEl, setFontSizeAnchorEl] = React.useState<null | HTMLElement>(null)
    const [lineHeightAnchorEl, setLineHeightAnchorEl] = React.useState<null | HTMLElement>(null)
    const [textIdentationAnchorEl, setTextIdentationAnchorEl] = React.useState<null | HTMLElement>(null)
    const [deeplinkAnchorEl, setDeeplinkAnchorEl] = React.useState<null | HTMLElement>(null)
    const [lastSelection, setLastSelecion] = React.useState<any>()
    const [openFilters, setOpenFilters] = React.useState<boolean>(false)

    const handleExternal = () => {
        setSelectionState({ id: props.id, type: ELEMENT_TYPES.text, selection: editor.selection, url: location.search })
        findObject(pageId, tabId)
    }
    const handleLink = () => {
        setSelectionState({ id: props.id, type: ELEMENT_TYPES.text, selection: editor.selection, url: location.search })
        findLink(pageId, -1, props.id, tabId)
    }
    const handleInternal = () => {
        history.push({ pathname: "/selection", state: { element: { id: props.id, type: ELEMENT_TYPES.text, selection: editor.selection, url: location.search } } })
    }

    const [entidadSearch, setEntidadSearch] = React.useState<string>(entitySearchUrl)

    const [editorSearch, setEditorSearch] = React.useState<any>(undefined)

    const handleSelect = () => {
        const selection = window.getSelection()
        if (!selection || selection.anchorOffset === selection.focusOffset) {
            setOpenTools(false)
            return
        }
        if (lastSelection === editor.selection) {
            Transforms.select(editor, { path: [editor.selection?.anchor.path[0]], offset: editor.selection?.anchor.offset as number })
            setOpenTools(false)
            setLastSelecion({})
            return
        }
        const getBoundingClientRect = () => selection.getRangeAt(0).getBoundingClientRect()
        setOpenTools(true)
        setTextAlignAnchorEl(null)
        setColorAnchorEl(null)
        setBackgroundAnchorEl(null)
        setFontFamilyAnchorEl(null)
        setFontSizeAnchorEl(null)
        setLineHeightAnchorEl(null)
        setDeeplinkAnchorEl(null)
        setLastSelecion(editor.selection)
        setEditorSearch({ ...editor })
        setAnchorEl({
            clientWidth: getBoundingClientRect().width,
            clientHeight: getBoundingClientRect().height,
            getBoundingClientRect,
        })
    }
    const handleBlur = () => {
        setOpenTools(false)
        setReadOnly(true)
        setTextAlignAnchorEl(null)
        setColorAnchorEl(null)
        setBackgroundAnchorEl(null)
        setFontFamilyAnchorEl(null)
        setFontSizeAnchorEl(null)
        setLineHeightAnchorEl(null)
        setTextIdentationAnchorEl(null)
        setDeeplinkAnchorEl(null)
        Transforms.deselect(editor)
    }
    const getTextAlignIcon = React.useCallback(() => {
        if (isBlockActive(editor, "align-center")) {
            return "format_align_center"
        }
        if (isBlockActive(editor, "align-right")) {
            return "format_align_right"
        }
        if (isBlockActive(editor, "align-justify")) {
            return "format_align_justify"
        }
        return "format_align_left"
    }, [editor])
    React.useEffect(() => { if (!readOnly) { ReactEditor.focus(editor) } }, [readOnly, editor])
    const MarkButton = ({ format, icon }) => {
        return (
            <ToggleButton
                selected={isMarkActive(editor, format)}
                onMouseDown={event => {
                    event.preventDefault()
                    toggleMark(editor, format)
                }}
                size="small"
                style={{ color: "#626262" }}
                value={format}
            >
                <Icon fontSize="small">{icon}</Icon>
            </ToggleButton>
        )
    }
    const BlockButton = ({ format, icon }) => {
        return (
            <ToggleButton
                selected={isBlockActive(editor, format)}
                onMouseUp={event => {
                    event.preventDefault()
                    toggleBlock(editor, format)
                }}
                onMouseDown={event => event.preventDefault()}
                onClick={event => event.preventDefault()}
                size="small"
                style={{ color: "#626262" }}
                value={format}
            >
                <Icon fontSize="small">{icon}</Icon>
            </ToggleButton>
        )
    }
    const LinkButton = () => {
        return (
            <ToggleButton
                onMouseDown={async (event) => {
                    await restartCounter()
                    event.preventDefault()
                    handleLink()
                }}
                style={{ color: "#626262" }}
                size="small"
                value="link"
            >
                <Icon fontSize="small">{"link"}</Icon>
            </ToggleButton>
        )
    }

    const FontRow = (rowProps: ListChildComponentProps) => {
        const { index, style } = rowProps
        const value = getValue(editor, "fontFamily") ?? defaultFont.family
        const font = fonts.find(f => f.family === value)
        const selected = fonts.findIndex(f => f.family === font?.family) === index
        return (
            <ListItem
                key={index}
                style={{ ...style, backgroundColor: selected ? "#eee" : undefined }}
                button
                onMouseDown={(e) => {
                    e.preventDefault()
                    addValue(editor, "fontFamily", fonts[index].family)
                    addUsedFont(fonts[index])
                    setFontFamilyAnchorEl(null)
                }}
            >
                <ListItemText classes={{ primary: "list-text-sm" }} primary={fonts[index].family} style={{ letterSpacing: 0.5 }} />
            </ListItem>
        )
    }

    const SizeRow = (rowProps: ListChildComponentProps) => {
        const { index, style } = rowProps
        const value = getValue(editor, "fontSize") ?? "1em"
        const size = FONT_SIZES.find(f => f === value)
        const selected = FONT_SIZES.findIndex(f => f === size) === index
        return (
            <ListItem
                key={index}
                style={{ ...style, backgroundColor: selected ? "#eee" : undefined }}
                button
                onMouseDown={(e) => {
                    e.preventDefault()
                    addValue(editor, "fontSize", FONT_SIZES[index])
                    setFontSizeAnchorEl(null)
                }}
            >
                <ListItemText classes={{ primary: "list-text-sm" }} primary={FONT_SIZES[index]} style={{ letterSpacing: 0.5 }} />
            </ListItem>
        )
    }

    const LineRow = (rowProps: ListChildComponentProps) => {
        const { index, style } = rowProps
        const value = getValue(editor, "lineHeight") ?? "1em"
        const size = LINE_HEIGHTS.find(l => l === value)
        const selected = LINE_HEIGHTS.findIndex(l => l === size) === index
        return (
            <ListItem
                key={index}
                style={{ ...style, backgroundColor: selected ? "#eee" : undefined }}
                button
                onMouseDown={(e) => {
                    e.preventDefault()
                    addMark(editor, "lineHeight", LINE_HEIGHTS[index])
                    setLineHeightAnchorEl(null)
                }}
            >
                <ListItemText classes={{ primary: "list-text-sm" }} primary={LINE_HEIGHTS[index]} style={{ letterSpacing: 0.5 }} />
            </ListItem>
        )
    }

    const IdentationRow = (rowProps: ListChildComponentProps) => {
        const { index, style } = rowProps
        const value = getValue(editor, "textIndent") ?? "0px"
        const size = TEXT_IDENTATIONS.find(t => t === value)
        const selected = TEXT_IDENTATIONS.findIndex(t => t === size) === index
        return (
            <ListItem
                key={index}
                style={{ ...style, backgroundColor: selected ? "#eee" : undefined }}
                button
                onMouseDown={(e) => {
                    e.preventDefault()
                    addMark(editor, "textIndent", TEXT_IDENTATIONS[index])
                    setLineHeightAnchorEl(null)
                }}
            >
                <ListItemText classes={{ primary: "list-text-sm" }} primary={TEXT_IDENTATIONS[index]} style={{ letterSpacing: 0.5 }} />
            </ListItem>
        )
    }

    React.useEffect(() => {
        try {
            if ((location.state as any)?.state?.id && (location.state as any).state?.id === props.id) {
                Transforms.select(editor, (location.state as any)?.state?.selection)
                const element = document.getElementById((location.state as any)?.state?.id)
                if (element) {
                    const y = element.getBoundingClientRect().top + window.scrollY
                    const x = element.getBoundingClientRect().left + window.scrollX
                    window.scroll({
                        top: y - 80,
                        left: x - 80,
                        behavior: 'auto'
                    })
                }
                setTimeout(() => {
                    insertLink(editor, (location.state as any)?.state?.link)
                    setCheckLink(true)
                }, 500)
            }
        } catch (error) {
            console.log(error)
        }
    }, [location.state])

    React.useEffect(() => {
        const onEditEnd = setTimeout(() => { if (value !== props.value) { onValueChange(value) } }, 1000)
        return () => clearTimeout(onEditEnd)
    }, [value])


    const getFontScrollOffset = React.useCallback(() => {
        const value = getValue(editor, "fontFamily") ?? defaultFont.family
        const index = fonts.findIndex(f => f.family === value)
        if (index) {
            return 20 * index
        }
        return 0
    }, [getValue, editor, fonts])

    const getSizeScrollOffset = React.useCallback(() => {
        const value = getValue(editor, "fontSize") ?? "1em"
        const index = FONT_SIZES.findIndex(f => f === value)
        if (index) {
            return 20 * index
        }
        return 0
    }, [getValue, editor, FONT_SIZES])

    const getLineScrollOffset = React.useCallback(() => {
        const value = getValue(editor, "lineHeight") ?? "1em"
        const index = LINE_HEIGHTS.findIndex(l => l === value)
        if (index) {
            return 20 * index
        }
        return 0
    }, [getValue, editor, LINE_HEIGHTS])

    const getIdentationScrollOffset = React.useCallback(() => {
        const value = getValue(editor, "textIndent") ?? "0px"
        const index = TEXT_IDENTATIONS.findIndex(t => t === value)
        if (index) {
            return 20 * index
        }
        return 0
    }, [getValue, editor, TEXT_IDENTATIONS])



    return (
        <React.Fragment>
            <Popper
                open={openTools}
                anchorEl={anchorEl}
                placement="top-start"
                modifiers={{
                    preventOverflow: {
                        enabled: true,
                        boundariesElement: 'viewport',
                    },
                    offset: {
                        enabled: true,
                        offset: `10, 10`
                    },
                }}
            >
                <Paper className="flex p-2 flex-col">
                    <div>
                        <ToggleButtonGroup size="small">
                            <MarkButton format="bold" icon="format_bold" />
                            <MarkButton format="italic" icon="format_italic" />
                            <MarkButton format="underline" icon="format_underline" />
                            <MarkButton format="strike" icon="format_strikethrough" />
                        </ToggleButtonGroup>
                        {
                            !props?.link &&
                            <ToggleButtonGroup size="small" className="ml-2 ">
                                <LinkButton />
                                <Tooltip arrow title="Deeplink" placement="left">
                                    <ToggleButton
                                        size="small"
                                        value="restore"
                                        selected={isLinkActive(editor) && (getLink(editor)?.includes(DEEPLINK_TAG) || getLink(editor)?.includes(HASH_PARAM))}
                                        onMouseDown={(e) => {
                                            e.preventDefault()
                                            if (isLinkActive(editor) && getLink(editor)?.includes(DEEPLINK_TAG)) {
                                                unwrapLink(editor)
                                            } else {
                                                if (Boolean(deeplinkAnchorEl)) {
                                                    setTextIdentationAnchorEl(null)
                                                    return
                                                }
                                                setDeeplinkAnchorEl(e.currentTarget)
                                            }
                                        }}
                                    >
                                        <Icon fontSize="small">language</Icon>
                                    </ToggleButton>
                                </Tooltip>
                                <Tooltip arrow title="Entidad" placement="left">
                                    <ToggleButton
                                        size="small"
                                        value="restore"
                                        selected={isLinkActive(editor) && isEntidadSearchLink(editor)}
                                        onMouseDown={(e) => {
                                            e.preventDefault()
                                            setOpenFilters(true)

                                        }}
                                    >
                                        <Icon fontSize="small">store_front</Icon>
                                        <Icon fontSize="small">arrow_drop_down</Icon>
                                    </ToggleButton>
                                </Tooltip>
                            </ToggleButtonGroup>
                        }
                        <ToggleButtonGroup size="small" className="ml-2 ">
                            <ToggleButton
                                onMouseDown={event => {
                                    event.preventDefault()
                                    if (Boolean(colorAnchorEl)) {
                                        setColorAnchorEl(null)
                                        return
                                    }
                                    setColorAnchorEl(event.currentTarget)
                                    setTextAlignAnchorEl(null)
                                    setBackgroundAnchorEl(null)
                                    setFontSizeAnchorEl(null)
                                    setLineHeightAnchorEl(null)
                                    setFontFamilyAnchorEl(null)
                                    setTextIdentationAnchorEl(null)
                                    setDeeplinkAnchorEl(null)
                                }}
                                style={{ color: "#626262" }}
                                size="small"
                                value="color"
                            >
                                <Icon fontSize="small">format_color_text</Icon>
                                <Icon fontSize="small">expand_more</Icon>
                            </ToggleButton>
                            <ToggleButton
                                onMouseDown={event => {
                                    event.preventDefault()
                                    if (Boolean(backgroundAnchorEl)) {
                                        setBackgroundAnchorEl(null)
                                        return
                                    }
                                    setBackgroundAnchorEl(event.currentTarget)
                                    setColorAnchorEl(null)
                                    setTextAlignAnchorEl(null)
                                    setFontSizeAnchorEl(null)
                                    setLineHeightAnchorEl(null)
                                    setFontFamilyAnchorEl(null)
                                    setTextIdentationAnchorEl(null)
                                    setDeeplinkAnchorEl(null)
                                }}
                                style={{ color: "#626262" }}
                                size="small"
                                value="bgColor"
                                ref={backgroundColorRef}
                            >
                                <Icon fontSize="small">format_color_fill</Icon>
                                <Icon fontSize="small">expand_more</Icon>
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <ToggleButtonGroup size="small" className="ml-2 ">
                            <ToggleButton
                                onMouseDown={event => {
                                    event.preventDefault()
                                    if (Boolean(textAlignAnchorEl)) {
                                        setTextAlignAnchorEl(null)
                                        return
                                    }
                                    setTextAlignAnchorEl(event.currentTarget)
                                    setColorAnchorEl(null)
                                    setBackgroundAnchorEl(null)
                                    setFontSizeAnchorEl(null)
                                    setLineHeightAnchorEl(null)
                                    setFontFamilyAnchorEl(null)
                                    setTextIdentationAnchorEl(null)
                                    setDeeplinkAnchorEl(null)
                                }}
                                style={{ color: "#626262" }}
                                size="small"
                                value="align"
                            >
                                <Icon fontSize="small">{getTextAlignIcon()}</Icon>
                                <Icon fontSize="small">expand_more</Icon>
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <ToggleButtonGroup size="small" className="ml-2 ">
                            <BlockButton format="numbered-list" icon="format_list_numbered" />
                            <BlockButton format="bulleted-list" icon="format_list_bulleted" />
                            <BlockButton format="block-quote" icon="format_quote" />
                        </ToggleButtonGroup>
                        <Popper
                            open={Boolean(textAlignAnchorEl)}
                            anchorEl={textAlignAnchorEl ? textAlignAnchorEl : undefined}
                            onBlurCapture={() => setTextAlignAnchorEl(null)}
                        >
                            <Paper>
                                <ToggleButtonGroup
                                    size="small"
                                    orientation="vertical"
                                >
                                    <BlockButton format="align-left" icon="format_align_left" />
                                    <BlockButton format="align-center" icon="format_align_center" />
                                    <BlockButton format="align-right" icon="format_align_right" />
                                    <BlockButton format="align-justify" icon="format_align_justify" />
                                </ToggleButtonGroup>
                            </Paper>
                        </Popper>
                    </div>
                    <div className="mt-2 w-full flex items-center">
                        <ToggleButton
                            value="fontFamily"
                            size="small"
                            onMouseDown={(e) => {
                                e.preventDefault()
                                if (Boolean(fontFamilyAnchorEl)) {
                                    setFontFamilyAnchorEl(null)
                                    return
                                }
                                setFontFamilyAnchorEl(e.currentTarget)
                                setFontSizeAnchorEl(null)
                                setLineHeightAnchorEl(null)
                                setTextAlignAnchorEl(null)
                                setColorAnchorEl(null)
                                setBackgroundAnchorEl(null)
                                setTextIdentationAnchorEl(null)
                                setDeeplinkAnchorEl(null)
                            }}
                            style={{ flexGrow: 1, display: "flex", justifyContent: "start" }}
                        >
                            <Typography
                                style={{ color: "#626262", lineHeight: "inherit", textTransform: "capitalize", flexGrow: 1 }}
                                variant="caption"
                            >
                                {`Fuente: ${getValue(editor, "fontFamily") ? getValue(editor, "fontFamily") : defaultFont.family}`}
                            </Typography>
                            <Icon style={{ color: "#626262" }} fontSize="small">expand_more</Icon>
                        </ToggleButton>
                        <ToggleButton
                            value="fontSize"
                            size="small"
                            onMouseDown={(e) => {
                                e.preventDefault()
                                if (Boolean(fontSizeAnchorEl)) {
                                    setFontSizeAnchorEl(null)
                                    return
                                }
                                setFontSizeAnchorEl(e.currentTarget)
                                setLineHeightAnchorEl(null)
                                setTextAlignAnchorEl(null)
                                setColorAnchorEl(null)
                                setBackgroundAnchorEl(null)
                                setFontFamilyAnchorEl(null)
                                setTextIdentationAnchorEl(null)
                            }}
                            style={{ display: "flex", justifyContent: "start", marginLeft: 6 }}
                        >
                            <Typography
                                style={{ color: "#626262", lineHeight: "inherit", textTransform: "capitalize" }}
                                variant="caption"
                                className="px-2"
                            >
                                {`Tamaño: ${getValue(editor, "fontSize") ? getValue(editor, "fontSize") : "1em"}`}
                            </Typography>
                            <Icon style={{ color: "#626262" }} fontSize="small">expand_more</Icon>
                        </ToggleButton>
                        <ToggleButton
                            value="lineHeight"
                            size="small"
                            onMouseDown={(e) => {
                                e.preventDefault()
                                if (Boolean(lineHeightAnchorEl)) {
                                    setLineHeightAnchorEl(null)
                                    return
                                }
                                setLineHeightAnchorEl(e.currentTarget)
                                setTextIdentationAnchorEl(null)
                                setTextAlignAnchorEl(null)
                                setFontFamilyAnchorEl(null)
                                setColorAnchorEl(null)
                                setBackgroundAnchorEl(null)
                                setFontSizeAnchorEl(null)
                                setDeeplinkAnchorEl(null)
                            }}
                            style={{ display: "flex", justifyContent: "start", marginLeft: 6 }}
                        >
                            <Typography
                                style={{ color: "#626262", lineHeight: "inherit", textTransform: "capitalize" }}
                                variant="caption"
                                className="px-2"
                            >
                                {`Línea: ${getValue(editor, "lineHeight") ? getValue(editor, "lineHeight") : "1em"}`}
                            </Typography>
                            <Icon style={{ color: "#626262" }} fontSize="small">expand_more</Icon>
                        </ToggleButton>
                        <ToggleButton
                            value="textIndent"
                            size="small"
                            onMouseDown={(e) => {
                                e.preventDefault()
                                if (Boolean(textIdentationAnchorEl)) {
                                    setTextIdentationAnchorEl(null)
                                    return
                                }
                                setTextIdentationAnchorEl(e.currentTarget)
                                setLineHeightAnchorEl(null)
                                setTextAlignAnchorEl(null)
                                setFontFamilyAnchorEl(null)
                                setColorAnchorEl(null)
                                setBackgroundAnchorEl(null)
                                setFontSizeAnchorEl(null)
                                setDeeplinkAnchorEl(null)
                            }}
                            style={{ display: "flex", justifyContent: "start", marginLeft: 6 }}
                        >
                            <Typography
                                style={{ color: "#626262", lineHeight: "inherit", textTransform: "capitalize" }}
                                variant="caption"
                                className="px-2"
                            >
                                {`Identación: ${getValue(editor, "textIndent") ? getValue(editor, "textIndent") : "0px"}`}
                            </Typography>
                            <Icon style={{ color: "#626262" }} fontSize="small">expand_more</Icon>
                        </ToggleButton>
                        <Popper
                            open={Boolean(fontFamilyAnchorEl)}
                            anchorEl={fontFamilyAnchorEl ? fontFamilyAnchorEl : undefined}
                            onBlurCapture={() => setFontFamilyAnchorEl(null)}
                        >
                            <Paper>
                                <List
                                    width={220}
                                    height={180}
                                    itemCount={fonts.length}
                                    itemSize={20}
                                    initialScrollOffset={getFontScrollOffset()}
                                >
                                    {FontRow}
                                </List>
                            </Paper>
                        </Popper>
                        <Popper
                            open={Boolean(fontSizeAnchorEl)}
                            anchorEl={fontSizeAnchorEl ? fontSizeAnchorEl : undefined}
                            onBlurCapture={() => setFontSizeAnchorEl(null)}
                        >
                            <Paper>
                                <List
                                    width={100}
                                    height={180}
                                    itemCount={FONT_SIZES.length}
                                    itemSize={20}
                                    initialScrollOffset={getSizeScrollOffset()}
                                >
                                    {SizeRow}
                                </List>
                            </Paper>
                        </Popper>
                        <Popper
                            open={Boolean(lineHeightAnchorEl)}
                            anchorEl={lineHeightAnchorEl ? lineHeightAnchorEl : undefined}
                            onBlurCapture={() => setLineHeightAnchorEl(null)}
                        >
                            <Paper>
                                <List
                                    width={100}
                                    height={180}
                                    itemCount={LINE_HEIGHTS.length}
                                    itemSize={20}
                                    initialScrollOffset={getLineScrollOffset()}
                                >
                                    {LineRow}
                                </List>
                            </Paper>
                        </Popper>
                        <Popper
                            open={Boolean(textIdentationAnchorEl)}
                            anchorEl={textIdentationAnchorEl ? textIdentationAnchorEl : undefined}
                            onBlurCapture={() => setTextIdentationAnchorEl(null)}
                        >
                            <Paper>
                                <List
                                    width={100}
                                    height={180}
                                    itemCount={TEXT_IDENTATIONS.length}
                                    itemSize={20}
                                    initialScrollOffset={getIdentationScrollOffset()}
                                >
                                    {IdentationRow}
                                </List>
                            </Paper>
                        </Popper>
                        <Popper
                            open={Boolean(deeplinkAnchorEl)}
                            anchorEl={deeplinkAnchorEl ? deeplinkAnchorEl : undefined}
                            onBlurCapture={() => setDeeplinkAnchorEl(null)}
                        >
                            <Paper>
                                <MenuItem
                                    onMouseDown={(e) => { e.preventDefault(); handleInternal() }}
                                    dense
                                >
                                    <Icon color="disabled" className="mr-1" fontSize="small">dashboard</Icon>
                                    {"Interno"}
                                </MenuItem>
                                <MenuItem
                                    dense
                                    onMouseDown={(e) => { e.preventDefault(); handleExternal() }}
                                >
                                    <Icon color="disabled" className="mr-1" fontSize="small">web</Icon>
                                    {"Externo"}
                                </MenuItem>
                            </Paper>
                        </Popper>
                    </div>
                </Paper>
                {
                    Boolean(backgroundAnchorEl) &&
                    <Rnd
                        default={{
                            width: "auto",
                            height: "auto",
                            x: 150,
                            y: 40
                        }}
                        disableDragging={true}
                        enableResizing={false}
                    >
                        <Paper onMouseDown={(e) => e.preventDefault()}>
                            <div className={"flex items-center mt-1"}>
                                <Typography align="center" className="pl-4 w-full flex-grow" variant="caption" style={{ letterSpacing: 0.5 }}>
                                    {"Color de fondo texto"}
                                </Typography>
                                <IconButton style={{ padding: 4 }} onMouseDown={(e) => {
                                    e.preventDefault()
                                    setBackgroundAnchorEl(null)
                                }}>
                                    <Icon style={{ fontSize: 15 }}>close</Icon>
                                </IconButton>
                            </div>
                            <SketchPicker
                                color={getValue(editor, "backgroundColor") ? getValue(editor, "backgroundColor") : "rgba(0,0,0,1)"}
                                onChange={(color, event) => {
                                    event.preventDefault()
                                    event.stopPropagation()
                                    addValue(editor, "backgroundColor", `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`)
                                }}
                                onChangeComplete={(color, event) => {
                                    event.preventDefault()
                                }}
                                presetColors={[]}
                            />
                            <div className="text-color-footer py-2 px-4 flex flex-row items-center">
                                <Typography className="pr-4" variant="caption" color="textSecondary">{"HEX:"}</Typography>
                                <ToggleButton size="small"
                                    onMouseDown={(e) => {
                                        e.preventDefault()
                                        let color = window.prompt("Ingresa un color hexadecimal:", `${getValue(editor, "backgroundColor") ? `#${rgbHex(getValue(editor, "backgroundColor")).substring(0, 6)}` : "#000000"}`)
                                        if (!color?.includes("#")) {
                                            color = "#" + color
                                        }
                                        if (color && isHexcolor(color)) {
                                            const rgb = hexRgb(color)
                                            addValue(editor, "backgroundColor", `rgba(${rgb.red},${rgb.green},${rgb.blue},${1})`)
                                        }
                                    }}
                                >
                                    {getValue(editor, "backgroundColor") ? `#${rgbHex(getValue(editor, "backgroundColor")).substring(0, 6)}` : "#FFFFFF"}
                                </ToggleButton>
                                <ToggleButton size="small"
                                    onMouseDown={(e) => {
                                        e.preventDefault()
                                        addValue(editor, "backgroundColor", `rgba(${255},${255},${255},${1})`)
                                    }}
                                    style={{ marginLeft: 8 }}
                                >
                                    <Icon fontSize="small">restore</Icon>
                                </ToggleButton>
                            </div>
                        </Paper>
                    </Rnd>
                }
                {
                    Boolean(colorAnchorEl) &&
                    <Rnd
                        default={{
                            width: "auto",
                            height: "auto",
                            x: 150,
                            y: 40
                        }}
                        disableDragging={true}
                        enableResizing={false}
                    >
                        <Paper onMouseDown={(e) => { e.preventDefault(); e.stopPropagation() }}>
                            <div className={"flex items-center mt-1"}>
                                <Typography align="center" className="pl-4 w-full flex-grow" variant="caption" style={{ letterSpacing: 0.5 }}>
                                    {"Color de texto"}
                                </Typography>
                                <IconButton style={{ padding: 4 }} onMouseDown={(e) => {
                                    e.preventDefault()
                                    setColorAnchorEl(null)
                                }}>
                                    <Icon style={{ fontSize: 15 }}>close</Icon>
                                </IconButton>
                            </div>
                            <SketchPicker
                                color={getValue(editor, "color") ? getValue(editor, "color") : "rgba(0,0,0,1)"}
                                onChange={(color, event) => {
                                    event.preventDefault()
                                    addValue(editor, "color", `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`)
                                }}
                                onChangeComplete={(color, event) => {
                                    event.preventDefault()
                                }}
                                presetColors={[]}
                                className="text-color-picker"
                            />
                            <div className="text-color-footer py-2 px-4 flex flex-row items-center">
                                <Typography className="pr-4" variant="caption" color="textSecondary">{"HEX:"}</Typography>
                                <ToggleButton size="small"
                                    onMouseDown={(e) => {
                                        e.preventDefault()
                                        let color = window.prompt("Ingresa un color hexadecimal:", `${getValue(editor, "color") ? `#${rgbHex(getValue(editor, "color")).substring(0, 6)}` : "#000000"}`)
                                        if (!color?.includes("#")) {
                                            color = "#" + color
                                        }
                                        if (color && isHexcolor(color)) {
                                            const rgb = hexRgb(color)
                                            addValue(editor, "color", `rgba(${rgb.red},${rgb.green},${rgb.blue},${1})`)
                                        }
                                    }}
                                >
                                    {getValue(editor, "color") ? `#${rgbHex(getValue(editor, "color")).substring(0, 6)}` : "#000000"}
                                </ToggleButton>
                                <ToggleButton size="small"
                                    onMouseDown={(e) => {
                                        e.preventDefault()
                                        addValue(editor, "color", `rgba(${0},${0},${0},${1})`)
                                    }}
                                    style={{ marginLeft: 8 }}
                                >
                                    <Icon fontSize="small">restore</Icon>
                                </ToggleButton>
                            </div>
                        </Paper>
                    </Rnd>
                }
            </Popper>
            <Slate
                editor={editor}
                value={value}
                onChange={(newValue: Node[]) => {
                    setValue(newValue)
                }}
            >
                <Editable
                    id={props.id}
                    key={Math.random()}
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    placeholder={props.selected ? "Texto" : ""}
                    style={{ height: "100%", cursor: "text", fontFamily: "Montserrat", display: "flex", flexDirection: "column", justifyContent: props.align }}
                    spellCheck
                    disabled={getLink(editor)}
                    onKeyDown={event => {
                        if ((event.key === "Enter") && Boolean(getLink(editor))) {
                            event.preventDefault()
                            event.stopPropagation()
                            Editor.insertText(editor, " ")
                            setTimeout(() => Editor.insertBreak(editor), 200)
                            return
                        }
                        for (const hotkey in HOTKEYS) {
                            if (isHotkey(hotkey, event as any)) {
                                event.preventDefault()
                                const mark = HOTKEYS[hotkey]
                                toggleMark(editor, mark)
                            }
                        }
                    }}
                    onBlurCapture={() => {
                        handleBlur()
                    }}
                    onKeyUp={(e) => {
                        if (!e.shiftKey || !e.ctrlKey) {
                            if (editor.selection === lastSelection) { return }
                            handleSelect()
                        }
                    }}
                    onMouseUp={handleSelect}
                />
            </Slate>

            <Modal style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}
                open={openFilters}
                onClose={() => setOpenFilters(false)}
            >
                <FilterOptions filters={getFiltersEntitySearchLink(editor) as FilterRequest | undefined}
                    onAccept={() => {
                        const link = REST.rootURL + REST.entitySearchUrl + window.btoa(JSON.stringify(request))
                        wrapEntidadSearchLink(editor, lastSelection, link, request)
                    }}
                    onCancel={() => {
                        unWrapEntidadSearchLink(editor, lastSelection)
                    }}
                    closeHandle={() => setOpenFilters(false)}

                />
            </Modal>

            {
                readOnly &&
                <div
                    style={{
                        height: "100%",
                        width: "100%",
                        position: "absolute",
                        top: 0,
                    }}
                    onDoubleClick={() => setReadOnly(false)}
                />
            }



        </React.Fragment >
    )
})

const withLinks = editor => {
    const { isInline } = editor

    editor.isInline = element => {
        return element.type === 'link' ? true : isInline(element)
    }

    return editor
}

const insertLink = (editor, url) => {
    if (editor.selection) {
        wrapLink(editor, url)
    }
}

const isLinkActive = editor => {
    const [link]: any = Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    })
    return !!link
}

const unwrapLink = editor => {
    Transforms.unwrapNodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    })
}

const wrapLink = (editor, url, deeplink?, entitySearch?: boolean, filters?: FilterRequest) => {
    if (isLinkActive(editor)) {
        unwrapLink(editor)
    }
    const { selection } = editor
    const isCollapsed = selection && Range.isCollapsed(selection)
    const link = {
        type: deeplink ? 'deeplink' : 'link',
        url,
        children: isCollapsed ? [{ text: url }] : [],
        entitySearch: entitySearch ?? false,
        filters: entitySearch ? filters : undefined
    }



    if (isCollapsed) {
        Transforms.insertNodes(editor, [link])
    } else {
        Transforms.wrapNodes(editor, link, { split: true })
        Transforms.collapse(editor, { edge: 'start' })
    }
}

const wrapEntidadSearchLink = (editor, selection, url: string, filters?: FilterRequest) => {
    editor.selection = selection

    if (isLinkActive(editor)) {
        unwrapLink(editor)
    }
    const isCollapsed = selection && Range.isCollapsed(selection)
    const link = {
        type: 'link',
        url,
        children: isCollapsed ? [{ text: url }] : [],
        entitySearch: true,
        filters: filters
    }



    if (isCollapsed) {
        Transforms.insertNodes(editor, [link])
    } else {
        Transforms.wrapNodes(editor, link, { split: true })
        Transforms.collapse(editor, { edge: 'start' })
    }
}

const unWrapEntidadSearchLink = (editor, selection) => {
    editor.selection = selection
    Transforms.unwrapNodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    })
}



const getLink = (editor) => {
    const [link]: any = Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    })
    return link ? link[0].url : ""
}

const getFiltersEntitySearchLink = (editor) => {
    const [link]: any = Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    })

    return link ? link[0].filters : undefined
}

const isEntidadSearchLink = (editor) => {
    const [link]: any = Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    })
    return link ? link[0].entitySearch : false
}


const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(editor, format)
    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
        match: n =>
            LIST_TYPES.includes(
                (!Editor.isEditor(n) && SlateElement.isElement(n) && n.type) as string
            ),
        split: true,
    })
    const newProperties: Partial<SlateElement> = {
        type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    }
    Transforms.setNodes(editor, newProperties)

    if (!isActive && isList) {
        const block = { type: format, children: [] }
        Transforms.wrapNodes(editor, block)
    }
}

const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}

const addMark = (editor, format, value) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
        Editor.removeMark(editor, format)
    }
    Editor.addMark(editor, format, value)
}

const addValue = (editor, format, value) => {
    Editor.addMark(editor, format, value)
}

const getValue = (editor, key): any => {
    try {
        const marks = Editor.marks(editor)
        return marks ? marks[key] : undefined
    } catch (error) {
        return undefined
    }
}

const isBlockActive = (editor, format) => {
    const [match]: any = Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    })

    return !!match
}

const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
}

const Element = ({ attributes, children, element }) => {
    switch (element.type) {
        case 'block-quote':
            return <blockquote {...attributes}>{children}</blockquote>
        case 'bulleted-list':
            return <ul {...attributes}>{children}</ul>
        case 'heading-one':
            return <h1 {...attributes}>{children}</h1>
        case 'heading-two':
            return <h2 {...attributes}>{children}</h2>
        case 'list-item':
            return <li {...attributes}>{children}</li>
        case 'numbered-list':
            return <ol {...attributes}>{children}</ol>
        case 'link':
            const tag = !element.url.includes(DEEPLINK_TAG) ?
                <a
                    className={
                        element.url.includes(HASH_PARAM) && element.url.includes("perxona") ? "internal-link link-no-hover" : element.entitySearch ? "entity-link entity-link-no-hover " : ""
                    }
                    {...attributes}
                    href={element.url}>
                    {children}
                </a> :
                <u className="internal-link">{children}</u>
            return tag
        case 'align-center':
            return <p style={{ textAlign: "center" }} {...attributes}>{children}</p>
        case 'align-right':
            return <p style={{ textAlign: "right" }} {...attributes}>{children}</p>
        case 'align-justify':
            return <p style={{ textAlign: "justify" }} {...attributes}>{children}</p>
        default:
            return <p {...attributes}>{children}</p>
    }
}

const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    if (leaf.color) {
        children = <span style={{ color: leaf.color }}>{children}</span>
    }

    if (leaf.backgroundColor) {
        children = <span style={{ backgroundColor: leaf.backgroundColor }}>{children}</span>
    }

    if (leaf.fontFamily) {
        children = <span style={{ fontFamily: leaf.fontFamily }}>{children}</span>
    }

    if (leaf.fontSize) {
        children = <span style={{ fontSize: leaf.fontSize }}>{children}</span>
    }

    if (leaf.lineHeight) {
        children = <span style={{ lineHeight: leaf.lineHeight }}>{children}</span>
    }

    if (leaf.strike) {
        children = <span style={{ textDecoration: "line-through" }}>{children}</span>
    }

    if (leaf.textIndent) {
        children = <span style={{ textIndent: leaf.textIndent }}>{children}</span>
    }

    return <span {...attributes}>{children}</span>
}

export default TextElement