import React from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { POST_MODE } from 'lib'

interface Props {
    pageId: number | undefined,
    mode: number | undefined,
    generateHtml: boolean,
    hash: string,
    userId: number,
    tabId: string
}

export const LocationContext = React.createContext({} as Props)

const Context = ({ children }: any) => {
    const location = useLocation()
    const [pageId, setPageIdId] = React.useState<number | undefined>(undefined)
    const [mode, setMode] = React.useState<number | undefined>(undefined)
    const [generateHtml, setGenerateHtml] = React.useState<boolean>(false)
    const [hash, setHash] = React.useState<string>("")
    const [userId, setUserId] = React.useState<number>(-1)
    const [tabId, setTabId] = React.useState<string>("")
    React.useEffect(() => {
        const getInitialParams = async () => {
            const params = queryString.parse(location.search)
            if (params.pageId && params.mode) {
                const decodedPageId = parseInt(atob(params.pageId.toString()))
                setPageIdId(decodedPageId)
                const decodedMode = parseInt(atob(params.mode.toString()))
                setMode(decodedMode)
                if (decodedMode === POST_MODE) {
                    if (params.generateHtml) {
                        const decodedGenerateHtml = parseInt(atob(params.generateHtml.toString()))
                        setGenerateHtml(Boolean(decodedGenerateHtml))
                    }
                    if (params.hash) {
                        setHash(params.hash as string)
                    }
                }
                if (params.tabId) {
                    setTabId(params.tabId as string)
                }
            }
            if (params.userId) {
                const decodedUserId = parseInt(atob(params.userId.toString()))
                setUserId(decodedUserId)
            }
        }
        getInitialParams()
    }, [location])

    return (
        <LocationContext.Provider
            value={{
                pageId,
                mode,
                generateHtml,
                hash,
                userId,
                tabId
            }}
        >
            {children}
        </LocationContext.Provider>
    )
}

export default Context