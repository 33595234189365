import React from 'react'
import { LocationProvider, PageProvider, BoardProvider, ConfigProvider, FeedbackProvider } from 'context'
import { ThemeProvider } from '@material-ui/core'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { theme } from 'styles'
import { Design, Post, Test, Selection, Exported, PageError } from 'pages'



const Routes = () => {
    return (
        <Router>
            <ThemeProvider theme={theme}>
                <FeedbackProvider>
                    <ConfigProvider>
                        <BoardProvider>
                            <LocationProvider>
                                <PageProvider>
                                    <Switch>
                                        <Route
                                            path="/"
                                            component={Design}
                                            exact
                                        />
                                        <Route
                                            path="/post"
                                            component={Post}
                                            exact
                                        />
                                        <Route
                                            path="/selection"
                                            component={Selection}
                                            exact
                                        />
                                        <Route
                                            path="/exported"
                                            component={Exported}
                                            exact
                                        />
                                        <Route
                                            path="/page-error"
                                            component={PageError}
                                            exact
                                        />
                                        <Route
                                            path="/test"
                                            component={Test}
                                            exact
                                        />
                                        
                                    </Switch>
                                </PageProvider>
                            </LocationProvider>
                        </BoardProvider>
                    </ConfigProvider>
                </FeedbackProvider>
            </ThemeProvider>
        </Router >
    )
}

export default Routes
