import React from 'react'
import ReactPlayer from 'react-player'
import { ISize } from 'types'

interface Props {
    value: any,
    filesContext: string,
    style: React.CSSProperties,
    size: ISize,
}

const Audio = React.memo((props: Props) => {
    return (
        <React.Fragment>
            {
                props.value[1].includes("ivoox") ?
                    <iframe
                        id={"audio" + Math.random()}
                        allowFullScreen
                        height={props.size.height}
                        src={props.value[1]}
                        style={{ pointerEvents: "none" }}
                    />
                    :
                    <ReactPlayer
                        url={`${props.value[1].includes("http") ? "" : props.filesContext + "/"}${props.value[1].replace("https://w.soundcloud.com/player/?url=", "")}`}
                        width={props.size.width}
                        height={props.size.height}
                        controls={true}
                        style={{ pointerEvents: "none" }}
                    />
            }
        </React.Fragment>
    )
})

export default Audio
