import React from 'react'
import { AlertProps } from '@material-ui/lab/Alert'
import { Loading, Message } from 'components'

interface Props {
    setError: React.Dispatch<boolean>,
    showMessage: (m: string, s: AlertProps["severity"]) => void,
    startLoading: () => void,
    stopLoading: () => void,
    loading: boolean
}

const DEFAULT_MESSAGE = "Cargando información de la página..."

export const FeedbackContext = React.createContext({} as Props)

const Context = ({ children }: any) => {
    const [error, setError] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(true)
    const startLoading = () => setLoading(true)
    const stopLoading = () => setLoading(false)
    const [openMessage, setOpenMessage] = React.useState<boolean>(false)
    const [message, setMessage] = React.useState<string>("")
    const [severity, setSeverity] = React.useState<AlertProps["severity"]>("info")

    const showMessage = (m: string, s: AlertProps["severity"]) => {
        setOpenMessage(false)
        setMessage(m)
        setSeverity(s)
        setOpenMessage(true)
    }

    const handleCloseMessage = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') return
        setOpenMessage(false)
    }

    React.useEffect(() => {

    }, [error])

    return (
        <FeedbackContext.Provider
            value={{
                setError,
                showMessage,
                startLoading,
                stopLoading,
                loading
            }}
        >
            {children}
            <Message
                open={openMessage}
                message={message}
                severity={severity}
                handleClose={handleCloseMessage}
            />
            <Loading
                loading={loading}
                message={DEFAULT_MESSAGE}
            />
        </FeedbackContext.Provider>
    )
}

export default Context
