import { Box } from '@material-ui/core'
import React, { CSSProperties } from 'react'
import { useInView } from 'react-intersection-observer'

type Props = {
  children: React.ReactNode
}

export const ScrollWrapperMessage = (props: Props) => {
  const { ref, inView } = useInView()

  const { children } = props
  return (
    <div
      ref={ref}
      style={{
        height: 'auto',
        minHeight: '50px',
      }}
    >
      {inView && children}
    </div>
  )
}
