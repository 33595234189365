import { Avatar, Checkbox, ListItemIcon, ListItemText, makeStyles, Menu, MenuItem } from '@material-ui/core'
import { IConferenceTheme } from 'types'

type Props = {
  handleClose: () => void

  selectedTheme?: IConferenceTheme
  themes: IConferenceTheme[]
  open: boolean
  anchorEl: HTMLElement | null
  onSelectedTheme: (theme: IConferenceTheme) => void
}
const useStyles = makeStyles({
  root: {
    fontSize: '12px',
    height: '20px',
    width: '20px',
  },
})

export const MenuTheme = (props: Props) => {
  const { anchorEl, open, handleClose, themes, selectedTheme, onSelectedTheme } = props
  const classes = useStyles()

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      PaperProps={{
        elevation: 0,
        style: {
          overflowY: 'auto',
          overflowX: 'hidden',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          marginTop: 2,
          marginLeft: -3,
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {themes.map((theme, index) => {
        return (
          <MenuItem
            key={index}
            onClick={(e) => {
              e.stopPropagation()
              onSelectedTheme(theme)
            }}
          >
            <ListItemIcon>
              <Avatar className={classes.root} style={{ backgroundColor: `#${theme.color}` }}>
                {theme.themeName.substring(0, 1).toUpperCase()}
              </Avatar>
            </ListItemIcon>
            <ListItemText primary={theme.themeName} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }} />
            <Checkbox
              checked={theme.conferenceThemeId === selectedTheme?.conferenceThemeId}
              onChange={(e) => {
                e.stopPropagation()
                e.preventDefault()
                onSelectedTheme(theme)
              }}
            />
          </MenuItem>
        )
      })}
    </Menu>
  )
}
