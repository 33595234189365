import { Backdrop, Button, ButtonGroup, Divider, FormControlLabel, Icon, IconButton, Paper, Popper, Radio, RadioGroup, Switch, TextField, Typography } from "@material-ui/core"
import React from "react"
import { Rnd, ResizableDelta } from "react-rnd"
import { IPosition, ISize } from "types"
import { ResizeDirection } from "re-resizable"
import { transform } from "typescript"

type Direction = "up" | "down" | "left" | "right"
type ResizeMode = "page" | "full" | "custom"

const defaultHeight = 50
const defaultWidth = 200

interface Props {
    accordion: IAccordion,
    updateAccordion: (newAccordion: IAccordion) => void,
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, element: IAccordion) => void,
    selected: IAccordion | undefined,
    pageSize: ISize,
    pagePosition: IPosition,
    deleteAccordion: (deletedAccordion: IAccordion) => void,
    applyAccordion: (accordion: IAccordion) => void,
}

export interface IAccordion {
    id: string,
    position: IPosition,
    direction: Direction,
    size: ISize,
    space: number,
    inverted?: boolean,
    resizeMode: ResizeMode
}

const classes = {
    board: ".board",
    draggable: "attributes-dragable",
}

const attributesMaxWidth = 250

const Accordion = (props: Props) => {
    const { updateAccordion, onClick, selected, pagePosition, pageSize, deleteAccordion, applyAccordion } = props
    const divRef = React.useRef<HTMLDivElement>(null)
    const [position, setPosition] = React.useState<IPosition>(props.accordion.position)
    const [size, setSize] = React.useState<ISize>(props.accordion.size)
    const [direction, setDirection] = React.useState<Direction>(props.accordion.direction)
    const [dragging, setDragging] = React.useState<boolean>(false)
    const [rezising, setRezising] = React.useState<boolean>(false)
    const [resizeMode, setResizeMode] = React.useState<ResizeMode>(props.accordion.resizeMode)
    const [space, setSpace] = React.useState<string>(props.accordion.space.toString())
    const [inverted, setInverted] = React.useState<boolean>(Boolean(props.accordion.inverted))
    const handleDragStop = (e: any, d: IPosition) => { updateAccordion({ ...props.accordion, position: { x: d.x, y: d.y }, direction, size, inverted }); setDragging(false) }
    const isSelected = React.useCallback(() => { return selected?.id === props.accordion.id }, [props.accordion.id, selected])
    const handleResizeStop = (e: any, d: ResizeDirection, ref: HTMLElement, delta: ResizableDelta, p: IPosition) => {
        let newSpace = parseInt(space)
        if (direction === "up" || direction === "down") {
            newSpace = parseInt(ref.style.height.replace("px", ""))
        } else {
            newSpace = parseInt(ref.style.width.replace("px", ""))
        }
        updateAccordion({ ...props.accordion, position: { x: p.x, y: p.y }, direction, space: newSpace, size: { width: parseInt(ref.style.width.replace("px", "")), height: parseInt(ref.style.height.replace("px", "")) } })
        setRezising(false)
    }

    React.useEffect(() => { if (position !== props.accordion.position) { setPosition(props.accordion.position) } }, [props.accordion.position, position])
    React.useEffect(() => { if (direction !== props.accordion.direction) { setDirection(props.accordion.direction) } }, [props.accordion.direction, direction])
    React.useEffect(() => { if (size !== props.accordion.size) { setSize(props.accordion.size) } }, [props.accordion.size, size])
    React.useEffect(() => { if (space !== props.accordion.space.toString()) { setSpace(props.accordion.space.toString()) } }, [props.accordion.space, space])
    React.useEffect(() => { if (inverted !== props.accordion.inverted) { setInverted(Boolean(props.accordion.inverted)) } }, [props.accordion.inverted, inverted])
    React.useEffect(() => { if (resizeMode !== props.accordion.resizeMode) { setResizeMode(props.accordion.resizeMode) } }, [props.accordion.resizeMode, resizeMode])

    const toggleDirection = (selectedDirection: Direction) => {
        let newSpace = parseInt(space)
        const newSize = { ...size }
        if (selectedDirection === "up" || selectedDirection === "down") {
            if (resizeMode === "full" || resizeMode === "page") {
                newSize.height = defaultHeight
            }
            newSpace = newSize.height
        } else {
            if (resizeMode === "full" || resizeMode === "page") {
                newSize.width = defaultWidth
            }
            newSpace = newSize.width
        }
        updateAccordion({ ...props.accordion, position, direction: selectedDirection, space: newSpace, inverted, resizeMode: "custom", size: newSize })
    }

    const handleCommitChanges = () => {
        const intSpace = isNaN(parseInt(space)) || space.includes("-") ? props.accordion.space : parseInt(space) > 0 ? parseInt(space) : props.accordion.space
        const newSize = { ...size }
        if (direction === "up" || direction === "down") {
            newSize.height = intSpace
        } else {
            newSize.width = intSpace
        }
        updateAccordion({ ...props.accordion, position, direction, space: intSpace, size: newSize, inverted, resizeMode })
        setSpace(intSpace.toString())
    }
    const handleReziseModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newResizeMode = ((event.target as HTMLInputElement).value) as ResizeMode
        let newPosition = { ...position }
        let newSize = { ...size }
        let newSpace = space
        switch (direction) {
            case "down":
                if (newResizeMode === "full") {
                    newPosition = { x: -pagePosition.x, y: position.y }
                    newSize = { width: pageSize.width + pagePosition.x * 2, height: defaultHeight }
                }
                if (newResizeMode === "page") {
                    newPosition = { x: 0, y: position.y }
                    newSize = { width: pageSize.width, height: defaultHeight }
                }
                newSpace = defaultHeight.toString()
                break
            case "right":
                if (newResizeMode === "full") {
                    newPosition = { x: position.x, y: -pagePosition.y }
                    newSize = { width: defaultWidth, height: pageSize.height + pagePosition.y * 2 }
                }
                if (newResizeMode === "page") {
                    newPosition = { x: position.x, y: 0 }
                    newSize = { width: defaultWidth, height: pageSize.height }
                }
                newSpace = defaultWidth.toString()
                break
            case "up":
                if (newResizeMode === "full") {
                    newPosition = { x: -pagePosition.x, y: position.y }
                    newSize = { width: pageSize.width + pagePosition.x * 2, height: defaultHeight }
                }
                if (newResizeMode === "page") {
                    newPosition = { x: 0, y: position.y }
                    newSize = { width: pageSize.width, height: defaultHeight }
                }
                newSpace = defaultHeight.toString()
                break
            case "left":
                if (newResizeMode === "full") {
                    newPosition = { x: position.x, y: -pagePosition.y }
                    newSize = { width: defaultWidth, height: pageSize.height + pagePosition.y * 2 }
                }
                if (newResizeMode === "page") {
                    newPosition = { x: position.x, y: 0 }
                    newSize = { width: defaultWidth, height: pageSize.height }
                }
                newSpace = defaultWidth.toString()
                break
        }
        if (newResizeMode === "custom") {
            newSize.width = defaultWidth
            newSize.height = defaultHeight
        }
        updateAccordion({ ...props.accordion, position: newPosition, direction, space: parseInt(newSpace), size: newSize, inverted, resizeMode: newResizeMode })
    }
    return (
        <React.Fragment>
            <Backdrop
                open={isSelected()}
            />
            <Rnd
                onDragStop={handleDragStop}
                className={`selection-element all-pointer-events`}
                onDragStart={() => setDragging(true)}
                onResizeStart={() => setRezising(true)}
                size={size}
                onResizeStop={handleResizeStop}
                bounds={resizeMode !== "full" ? classes.board : undefined}
                position={position}
                disableDragging={!isSelected()}
                enableResizing={{
                    left: (resizeMode === "full" || resizeMode === "page") && (direction === "up" || direction === "down") ? true : true,
                    right: (resizeMode === "full" || resizeMode === "page") && (direction === "up" || direction === "down") ? true : true,
                    top: (resizeMode === "full" || resizeMode === "page") && (direction === "left" || direction === "right") ? false : true,
                    bottom: (resizeMode === "full" || resizeMode === "page") && (direction === "left" || direction === "right") ? false : true,
                }}
            >
                <div
                    className="flex flex-col items-center justify-center h-full"
                    onClick={(e) => onClick(e, { ...props.accordion, position, direction })}
                    ref={divRef}
                    style={{
                        backgroundColor: "rgb(244, 67, 54, 0.35)",
                        borderLeft: (direction === "left" || direction === "right") ? "solid 4px #f44336" : undefined,
                        borderTop: (direction === "up" || direction === "down") ? "solid 4px #f44336" : undefined
                    }}
                >
                    {
                        isSelected() ?
                            <div className="flex items-center">
                                <Icon
                                    fontSize="small"
                                    style={{
                                        color: "#FFF",
                                        marginRight: 5,
                                        transform: inverted ? "rotate(180deg)" : undefined
                                    }}
                                >
                                    {direction === "left" && "arrow_back"}
                                    {direction === "right" && "arrow_forward"}
                                    {direction === "down" && "arrow_downward"}
                                    {direction === "up" && "arrow_upward"}
                                </Icon>
                                <Typography style={{ color: "#FFF" }} color="textSecondary" variant="caption">
                                    {`${space}px`}
                                </Typography>
                            </div>
                            :
                            <Typography style={{ color: "#FFF" }} color="textSecondary" variant="overline">
                                {"Acordeón"}
                            </Typography>
                    }
                </div>
                {
                    direction === "down" && isSelected() && !dragging && !rezising &&
                    <div
                        style={{
                            position: "absolute",
                            height: position.y > 0 ? (pagePosition.y * 2) + pageSize.height - (size.height + position.y) : (pagePosition.y * 2) + pageSize.height - (size.height - Math.abs(position.y)),
                            width: size.width,
                            backgroundColor: "#e57373",
                            opacity: 0.35,
                        }}
                    />
                }
                {
                    direction === "up" && isSelected() && !dragging && !rezising &&
                    <div
                        style={{
                            position: "absolute",
                            height: position.y > 0 ? position.y + pagePosition.y : pagePosition.y - Math.abs(position.y),
                            width: size.width,
                            backgroundColor: "#e57373",
                            opacity: 0.35,
                            top: position.y > 0 ? -(position.y + pagePosition.y) : -(pagePosition.y - Math.abs(position.y))
                        }}
                    />
                }
                {
                    direction === "left" && isSelected() && !dragging && !rezising &&
                    <div
                        style={{
                            position: "absolute",
                            height: size.height,
                            width: position.x > 0 ? (position.x + pagePosition.x) : pagePosition.x - Math.abs(position.x),
                            backgroundColor: "#e57373",
                            opacity: 0.35,
                            top: 0,
                            left: position.x > 0 ? -(position.x + pagePosition.x) : -(pagePosition.x - Math.abs(position.x))
                        }}
                    />
                }
                {
                    direction === "right" && isSelected() && !dragging && !rezising &&
                    <div
                        style={{
                            position: "absolute",
                            height: size.height,
                            width: position.x > 0 ? (pageSize.width + pagePosition.x) - (position.x + size.width) : (pageSize.width + pagePosition.x * 2) - (size.width + pagePosition.x + position.x),
                            backgroundColor: "#e57373",
                            opacity: 0.35,
                            top: 0,
                            left: size.width
                        }}
                    />
                }
            </Rnd>
            {
                isSelected() &&
                <Rnd
                    bounds={classes.board}
                    enableResizing={false}
                    dragHandleClassName={classes.draggable}
                    style={{ pointerEvents: "all" }}
                    default={{
                        x: position.x + size.width > pageSize.width / 2 ? position.x - defaultWidth * 1.25 : position.x + defaultWidth * 1.25,
                        y: position.y,
                        width: "auto",
                        height: "auto"
                    }}
                >
                    <Paper style={{ maxWidth: attributesMaxWidth, width: attributesMaxWidth }}>
                        <div className="px-2 py-1 flex items-center w-full">
                            <div className={`${classes.draggable} cursor-move flex items-center flex-grow`}>
                                <Icon color="primary" style={{ fontSize: 15 }}>
                                    drag_indicator
                                </Icon>
                                <Typography align="center" className="px-2 w-full" variant="caption" style={{ letterSpacing: 0.5 }}>
                                    {"Attributos de acordeón"}
                                </Typography>
                                <IconButton style={{ padding: 4 }} onMouseDown={() => deleteAccordion(props.accordion)}>
                                    <Icon style={{ fontSize: 15 }}>close</Icon>
                                </IconButton>
                            </div>
                        </div>
                        <Divider />
                        <div className="py-1 px-2 mt-2 pb-3">
                            <div className="panel-container px-2 pt-2 pb-1">
                                <Typography color="primary" className="panel-label" variant="caption">{"Dirección"}</Typography>
                                <div className="flex items-center justify-between w-full">
                                    <div className="flex items-center justify-center">
                                        <IconButton
                                            color={direction === "left" ? "primary" : undefined}
                                            onClick={() => toggleDirection("left")}
                                            size="small"
                                        >
                                            <Icon fontSize="default">arrow_back</Icon>
                                        </IconButton>
                                        <div className="flex flex-col">
                                            <IconButton
                                                color={direction === "up" ? "primary" : undefined}
                                                onClick={() => toggleDirection("up")}
                                                size="small"
                                                style={{ marginBottom: 15 }}
                                            >
                                                <Icon fontSize="default">arrow_upward</Icon>
                                            </IconButton>
                                            <IconButton
                                                color={direction === "down" ? "primary" : undefined}
                                                onClick={() => toggleDirection("down")}
                                                size="small"
                                            >
                                                <Icon fontSize="default">arrow_downward</Icon>
                                            </IconButton>
                                        </div>
                                        <IconButton
                                            color={direction === "right" ? "primary" : undefined}
                                            onClick={() => toggleDirection("right")}
                                            size="small"
                                        >
                                            <Icon fontSize="default">arrow_forward</Icon>
                                        </IconButton>
                                    </div>
                                    <RadioGroup onChange={handleReziseModeChange} value={resizeMode} style={{ marginRight: 10 }}>
                                        <FormControlLabel value="full" control={<Radio size="small" color="primary" />} label="Pizarron" />
                                        <FormControlLabel value="page" control={<Radio size="small" color="primary" />} label="Página" />
                                        <FormControlLabel value="custom" control={<Radio size="small" color="primary" />} label="Manual" />
                                    </RadioGroup>
                                </div>
                            </div>
                            <div className="panel-container px-2 pt-2 pb-1">
                                <Typography color="primary" className="panel-label" variant="caption">{"Espacio"}</Typography>
                            </div>
                            <div className="flex items-center">
                                <TextField
                                    size="small"
                                    placeholder="Espacio"
                                    variant="outlined"
                                    classes={{ root: "text-input-sm" }}
                                    value={space}
                                    InputProps={{ type: "number" }}
                                    onChange={(e) => {
                                        const value = e.target.value
                                        const intSpace = isNaN(parseInt(value)) || value.includes("-") ? props.accordion.space : parseInt(value) > 0 ? parseInt(value) : props.accordion.space
                                        let newSpace = parseInt(space)
                                        if (direction === "up" || direction === "down") {
                                            newSpace = size.height
                                        } else {
                                            newSpace = size.width
                                        }
                                        updateAccordion({ ...props.accordion, position, direction, space: intSpace, size, inverted, resizeMode })
                                        setSpace(intSpace.toString())
                                    }}
                                    onBlur={handleCommitChanges}
                                    onKeyDown={(e) => { if (e.key === "Enter") { handleCommitChanges() } }}
                                    style={{ width: "40%" }}
                                />
                                <div className="flex items-center w-full justify-evenly ml-3">
                                    <ButtonGroup size="small" variant="contained" color="primary">
                                        <Button onClick={() => applyAccordion({ ...props.accordion, position, size, space: parseInt(space), inverted: false })}>{"Ampliar"}</Button>
                                        <Button
                                            onClick={() => applyAccordion({ ...props.accordion, position, size, space: parseInt(space), inverted: true })}
                                            style={{
                                                backgroundColor: "#f44336",
                                                color: "#FFF",
                                            }}
                                        >
                                            {"Reducir"}
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </div>
                        </div>
                    </Paper>
                </Rnd>
            }
        </React.Fragment>
    )
}

export default Accordion