import React from 'react'
import { IElement, IHeader, IPosition, ISavePositionConfig, ISize, MoveActions } from 'types'
import { LocationContext } from 'context'
import {
  ELEMENT_TYPES,
  enablePanel,
  EXPORTED_PATH,
  generateElementId,
  getPage as getPageAPI,
  isDarkColor,
  isInRange,
  PAGE_ALIGMENTS,
  getExportedPage as getExportedPageAPI,
  getDefaultPage,
  getCards,
  getHeaderInformation,
} from 'lib'
import { BoardContext } from 'context/Board/Context'
import { FeedbackContext } from 'context/Feedback/Context'
import { ConfigContext } from 'context/Config/Context'
import { useHistory } from 'react-router-dom'
import rgbHex from 'rgb-hex'
import { IAccordion } from 'components'
import REST from 'lib/constants/REST'
import useDetectOffline from 'use-detect-offline'
import { ICardPerxona } from 'components/CardProps/CardProps'
import { Box, Button, Icon, Modal, Typography } from '@material-ui/core'

const editingLocalStorage = 'inteligenxia_editing'

const cloneOffset = 25

interface Props {
  id: number
  style: React.CSSProperties
  size: ISize
  position: IPosition
  postedElements: IElement[]
  designElements: IElement[]
  src: any
  properties: any
  darkBg: boolean
  accordions: IAccordion[]
  savePosition: ISavePositionConfig
  cards: ICardPerxona[]
  headerData: IHeader | undefined
  addElement: (newElement: IElement) => void
  updateElement: (newElement: IElement) => void
  cloneElement: (element: IElement, withLink: boolean) => IElement
  deleteElement: (deletedElement: IElement) => void
  moveElement: (action: MoveActions, element: IElement) => void
  updateElements: () => void
  addAccordion: (newAccordion: IAccordion) => void
  updateAccordion: (newAccordion: IAccordion) => void
  deleteAccordion: (deletedAccordion: IAccordion) => void
  applyAccordion: (accordion: IAccordion) => void
  initSavePositions: () => void
  cancelSavePositions: () => void
  restoreOriginalPositions: () => void
  listCardsFromId: (id: number) => void
  getDataForHeader: (entityId: number, serviceId: number, objectId: number) => void
}

export const PageContext = React.createContext({} as Props)

const Context = ({ children }: any) => {
  const { mode, pageId, userId, tabId } = React.useContext(LocationContext)
  const { setStyle: setBoardStyle } = React.useContext(BoardContext)
  const { stopLoading, loading } = React.useContext(FeedbackContext)
  const { setUsedFonts } = React.useContext(ConfigContext)
  const history = useHistory()
  const [id, setId] = React.useState<number>(0)
  const [style, setStyle] = React.useState<React.CSSProperties>({})
  const [darkBg, setDarkBg] = React.useState<boolean>(false)
  const [size, setSize] = React.useState<ISize>({} as ISize)
  const [position, setPoisition] = React.useState<IPosition>({} as IPosition)
  const [postedElements, setPostedElements] = React.useState<IElement[]>([])
  const [designElements, setDesignElements] = React.useState<IElement[]>([])
  const [accordions, setAccordions] = React.useState<IAccordion[]>([])
  const [src, setSrc] = React.useState<any>()
  const [update, setUpdate] = React.useState<any>()
  const [processingPage, setProcessingPage] = React.useState<boolean>(false)
  const [properties, setProperties] = React.useState<any>()
  const [savePosition, setSavePosition] = React.useState<ISavePositionConfig>({ active: false, positions: [] })
  const [cards, setCards] = React.useState<ICardPerxona[]>([] as ICardPerxona[])
  const [headerData, setHeaderData] = React.useState<IHeader | undefined>(undefined)
  const [showCancelEditModal, setCancelEditModal] = React.useState<boolean>(false)

  const getDataForHeader = React.useCallback(
    async (entityId: number, serviceId: number, objectId: number) => {
      try {
        const response = await getHeaderInformation(entityId, serviceId, objectId, userId)
        setHeaderData(response)
      } catch (error) {
        throw error
      }
    },
    [userId]
  )

  const initSavePositions = () => setSavePosition({ active: true, positions: designElements.map((e) => ({ position: e.position, id: e.id })) })
  const cancelSavePositions = () => {
    enablePanel(id, tabId)
    setSavePosition({ active: false, positions: [] })
  }
  const restoreOriginalPositions = () => {
    const newDesignElements = designElements.map((e) => {
      const originalPosition = savePosition.positions.find((p) => e.id === p.id)
      if (originalPosition) {
        e.position = originalPosition.position
      }
      return e
    })
    setDesignElements(newDesignElements)
    cancelSavePositions()
  }
  const addElement = (newElement: IElement) => setDesignElements([...designElements, newElement])
  const updateElement = (newElement: IElement) => {
    setDesignElements(() => designElements.map((element) => (element.id === newElement.id ? newElement : element)))
  }
  const deleteElement = async (deletedElement: IElement) => {
    if (
      deletedElement.type === ELEMENT_TYPES.image ||
      deletedElement.type === ELEMENT_TYPES.video ||
      deletedElement.type === ELEMENT_TYPES.audio ||
      deletedElement.type === ELEMENT_TYPES.file ||
      deletedElement.type === ELEMENT_TYPES.page
    ) {
      let repeated = false
      try {
        for (const e of designElements.filter((element) => element.id !== deletedElement.id)) {
          if (
            (e.type === ELEMENT_TYPES.image ||
              e.type === ELEMENT_TYPES.video ||
              e.type === ELEMENT_TYPES.audio ||
              e.type === ELEMENT_TYPES.file ||
              e.type === ELEMENT_TYPES.page) &&
            deletedElement.value[0] === e.value[0]
          ) {
            repeated = true
            break
          }
        }
        if (!repeated) {
          setDesignElements(designElements.filter((element) => element.id !== deletedElement.id))
          return
        } else {
          setDesignElements(designElements.filter((element) => element.id !== deletedElement.id))
        }
      } catch (error) {
        console.log(JSON.stringify(error))
        return
      }
    } else {
      setDesignElements(designElements.filter((element) => element.id !== deletedElement.id))
    }
  }

  const cloneElement = (element: IElement, withLink: boolean) => {
    const newElement: IElement = { ...element, link: withLink ? element.link : undefined }
    newElement.id = generateElementId()
    let newPosition: IPosition = { x: element.position.x + cloneOffset, y: element.position.y + cloneOffset }
    if (element.position.x > size.width / 2) {
      newPosition = { ...newPosition, x: element.position.x - cloneOffset / 2 }
    }
    if (element.position.y > size.height / 2) {
      newPosition = { ...newPosition, y: element.position.y - cloneOffset / 2 }
    }
    newElement.position = newPosition
    if (newElement.type === ELEMENT_TYPES.curtain) {
      newElement.value = { ...newElement.value, textValue: element.value.textValue, link: '', tooltip: '', newTab: false }
    }
    setDesignElements([...designElements, newElement])

    return newElement
  }

  const moveElement = (action: MoveActions, element: IElement) => {
    let newDesignElements = [...designElements]
    const index = newDesignElements.findIndex((n) => n.id === element.id)
    if (index > -1) {
      switch (action) {
        case 'fisrt':
          newDesignElements.splice(index, 1)
          newDesignElements.push(element)
          break
        case 'last':
          newDesignElements.splice(index, 1)
          newDesignElements.unshift(element)
          break
        case 'moveUp':
          {
            const tmp = newDesignElements[index]
            newDesignElements[index] = newDesignElements[index + 1]
            newDesignElements[index + 1] = tmp
          }
          break
        case 'moveDown':
          {
            const tmp = newDesignElements[index]
            newDesignElements[index] = newDesignElements[index - 1]
            newDesignElements[index - 1] = tmp
          }
          break
      }
      setDesignElements(newDesignElements)
    }
  }
  const updateElements = () => setUpdate(Math.random())
  const addAccordion = (newAccordion: IAccordion) => setAccordions([...accordions, newAccordion])
  const updateAccordion = (newAccordion: IAccordion) =>
    setAccordions(accordions.map((accordion) => (accordion.id === newAccordion.id ? newAccordion : accordion)))
  const deleteAccordion = (deletedAccordion: IAccordion) => setAccordions(accordions.filter((accordion) => accordion.id !== deletedAccordion.id))
  const applyAccordion = (accordion: IAccordion) => {
    const xMin = accordion.position.x
    const xMax = accordion.position.x + accordion.size.width
    const yMin = accordion.position.y
    const yMax = accordion.position.y + accordion.size.height

    let newDesignElements = [...designElements.filter((element) => element.type !== ELEMENT_TYPES.footer && element.type !== ELEMENT_TYPES.header)]

    if (accordion.inverted) {
      accordion.space = -accordion.space
    }
    switch (accordion.direction) {
      case 'down':
        newDesignElements = newDesignElements.map((element): IElement => {
          let affected = true
          for (let i = element.position.x; i < element.position.x + element.size.width; i++) {
            if (!(isInRange(i, xMin, xMax) && element.position.y >= accordion.position.y)) {
              affected = false
            }
          }
          let newPosition = element.position.y + accordion.space
          const overflow = element.float ? newPosition <= -(element.size.height + position.y) : newPosition < 0 || newPosition >= size.height

          if (overflow) {
            if (element.float) {
              newPosition = position.y * 2 + size.height - element.size.height
            } else {
              newPosition = newPosition < 0 ? 0 : size.height - element.size.height
            }
          }
          return affected ? { ...element, position: { ...element.position, y: newPosition } } : element
        })
        break
      case 'up':
        newDesignElements = newDesignElements.map((element): IElement => {
          let affected = true
          for (let i = element.position.x; i < element.position.x + element.size.width; i++) {
            if (!(isInRange(i, xMin, xMax) && element.position.y <= accordion.position.y)) {
              affected = false
            }
          }
          let newPosition = element.position.y - accordion.space
          const overflow = element.float ? newPosition <= -(element.size.height + position.y) : newPosition <= -element.size.height || newPosition >= size.height
          
          if (overflow) {
            if (element.float) {
              newPosition = -position.y
            } else {
              newPosition = newPosition >= size.height ? size.height - element.size.height : 0
            }
          }
          return affected ? { ...element, position: { ...element.position, y: newPosition } } : element
        })
        break
      case 'right':
        newDesignElements = newDesignElements.map((element): IElement => {
          let affected = true
          for (let i = element.position.y; i < element.position.y + element.size.height; i++) {
            if (!(isInRange(i, yMin, yMax) && element.position.x >= accordion.position.x)) {
              affected = false
            }
          }
          let newPosition = element.position.x + accordion.space
          const overflow = element.float ? newPosition >= element.size.width + position.x * 2 + size.width : newPosition >= size.width || newPosition <= -element.size.width

          if (overflow) {
            if (element.float) {
              newPosition = position.x * 2 + size.width - element.size.width
            } else {
              newPosition = newPosition <= -element.size.width ? 0 : size.width - element.size.width
            }
          }
          return affected ? { ...element, position: { ...element.position, x: newPosition } } : element
        })
        break
      case 'left':
        newDesignElements = newDesignElements.map((element): IElement => {
          let affected = true
          for (let i = element.position.y; i < element.position.y + element.size.height; i++) {
            if (!(isInRange(i, yMin, yMax) && element.position.x <= accordion.position.x)) {
              affected = false
            }
          }
          let newPosition = element.position.x - accordion.space
          const overflow = element.float ? newPosition <= -element.size.width - position.x : newPosition <= -element.size.width || newPosition >= size.width
          
          if (overflow) {
            if (element.float) {
              newPosition = -position.x
            } else {
              newPosition = newPosition >= size.width ? size.width - element.size.width : 0
            }
          }
          return affected ? { ...element, position: { ...element.position, x: newPosition } } : element
        })
        break
    }
    setDesignElements([
      ...newDesignElements,
      ...designElements.filter((element) => element.type === ELEMENT_TYPES.footer || element.type === ELEMENT_TYPES.header),
    ])
  }
  React.useEffect(() => {
    const getPage = async (pageIdParam: number) => {
      setProcessingPage(true)
      const result = history.location.pathname.includes(EXPORTED_PATH) ? await getExportedPageAPI(pageIdParam) : await getPageAPI(pageIdParam)
      setSrc(result)
      await getPageAttributes(result)
      setId(pageIdParam)
      const viewMode = Boolean(mode)
      if (viewMode && mode && pageId && !history.location.pathname.includes(EXPORTED_PATH)) {
        history.replace({
          pathname: 'post',
          search: `?mode=${btoa(mode.toString())}&pageId=${btoa(pageId.toString())}`,
        })
      }
      setTimeout(() => {
        stopLoading()
        setProcessingPage(false)
      }, 50)
    }
    const getPageAttributes = async (result: any) => {
      const designData = JSON.parse(result.dataDiseno)
      setDesignElements(clearData(designData.nodes))
      const postedData = JSON.parse(result.dataJson)
      setPostedElements(postedData.nodes)
      !mode ? setUsedFonts(designData.fonts) : setUsedFonts(postedData.fonts)
      let pageProperties = !mode ? designData.properties : postedData.properties
      setProperties(pageProperties)
      pageProperties = pageProperties.pagina
      setSize({ width: pageProperties.anchoPX, height: pageProperties.altoPX })
      setStyle({
        backgroundColor: pageProperties.color,
        opacity: pageProperties.opacidad,
        borderRadius: pageProperties.esquinas,
        borderColor: '#000000',
        boxShadow: getBoxShadow(pageProperties),
        borderWidth: getBorderWidth(pageProperties),
        position: 'relative',
      })
      getPageProperties(pageProperties)
      const maxX = postedElements
        .map((p) => p.position.x + p.size.width)
        .sort(function (a, b) {
          return b - a
        })
        .shift()
      const maxY = postedElements
        .map((p) => p.position.y + p.size.height)
        .sort(function (a, b) {
          return b - a
        })
        .shift()
      let boardHeight =
        pageProperties.altoPX + 2 * pageProperties.y > window.screen.height ? pageProperties.altoPX + 2 * pageProperties.y : window.screen.height
      let boardWidth = pageProperties.anchoPX + 2 * pageProperties.x > window.screen.width ? pageProperties.anchoPX + 2 * pageProperties.x : '100%'
      if (mode) {
        if (maxY && boardHeight < maxY) {
          boardHeight = maxY + 100
        }
        if (maxX && boardWidth < maxX) {
          boardWidth = maxX + 100
        }
      }
      setBoardStyle({
        width: boardWidth,
        maxWidth: boardWidth,
        height: boardHeight,
        maxHeight: boardHeight,
        backgroundColor: 'transparent',
      })
    }
    const getPageProperties = (pageProperties: any) => {
      const { width: screenWidth, height: screenHeight } = window.screen
      const widthResolutionIsLarger = screenWidth > pageProperties.anchoPX
      const heightResolutionIsLarger = screenHeight > pageProperties.altoPX
      setBoardStyle({
        width: !widthResolutionIsLarger ? pageProperties.anchoPX + 2 * pageProperties.x : window.screen.width,
        maxWidth: !widthResolutionIsLarger ? pageProperties.anchoPX + 2 * pageProperties.x : window.screen.width,
        height: !heightResolutionIsLarger ? pageProperties.altoPX + 2 * pageProperties.y : window.screen.height,
        maxHeight: !heightResolutionIsLarger ? pageProperties.altoPX + 2 * pageProperties.y : window.screen.height,
        backgroundColor: 'transparent',
      })
      let x = pageProperties.x
      let y = pageProperties.y
      switch (pageProperties.centradoPagina) {
        case PAGE_ALIGMENTS.both:
          if (widthResolutionIsLarger) {
            x = (screenWidth - pageProperties.anchoPX) / 2
          }
          if (heightResolutionIsLarger) {
            y = (screenHeight - pageProperties.altoPX) / 2
          }
          break
        case PAGE_ALIGMENTS.horizontal:
          if (widthResolutionIsLarger) {
            x = (screenWidth - pageProperties.anchoPX) / 2
          }
          break
        case PAGE_ALIGMENTS.vertical:
          if (heightResolutionIsLarger) {
            y = (screenHeight - pageProperties.altoPX) / 2
          }
          break
      }
      setPoisition({ x, y })
    }
    const clearData = (elements: IElement[]): IElement[] => {
      const newElements: IElement[] = []
      for (const element of elements) {
        const newElement: IElement = {
          ...element,
          size: { width: parseInt(element.size.width?.toString().replace('px', '')), height: parseInt(element.size.height?.toString().replace('px', '')) },
        }
        newElements.push(newElement)
      }
      return newElements
    }
    const getBoxShadow = (pageProperties: any) => {
      return pageProperties.bordeSombra.split(';')[0]
    }
    const getBorderWidth = (pageProperties: any): string => {
      const styleProps: string[] = pageProperties.bordeSombra.split(';')
      for (const styleProp of styleProps) {
        if (styleProp.includes('border:none')) {
          return '0px'
        }
        if (styleProp.includes('border-width:')) {
          return styleProp.replace('border-width:', '')
        }
      }
      return '1px'
    }
    if (mode !== undefined && pageId !== undefined && !processingPage) {
      getPage(pageId)
    }
  }, [mode, pageId, setBoardStyle, stopLoading, history, update])

  React.useEffect(() => {
    if (style.backgroundColor) {
      setDarkBg(isDarkColor(rgbHex(style.backgroundColor).substring(0, 6)))
    }
  }, [style.backgroundColor])

  React.useEffect(() => {
    const savedItems: string[] = JSON.parse(localStorage.getItem(editingLocalStorage) || '[]')
    const init = async () => {
      if (pageId && !mode) {
        const notPageInEdit = !savedItems.includes(pageId.toString())
        if (notPageInEdit) {
          savedItems.push(pageId.toString())
          localStorage.setItem(editingLocalStorage, JSON.stringify(savedItems))
        } else {
          setCancelEditModal(true)
          // history.push("/page-error")
        }
      }
    }
    init()
    return () => {
      const savedItems: string[] = JSON.parse(localStorage.getItem(editingLocalStorage) || '[]')
      const existSavedItems = savedItems && savedItems.length > 0
      if (existSavedItems) {
        localStorage.setItem(editingLocalStorage, JSON.stringify(savedItems.filter((e) => e !== id.toString())))
      }
    }
  }, [pageId, mode])

  const handleOffline = () => {
    localStorage.setItem(editingLocalStorage, JSON.stringify([]))
    console.log('offline')
    return undefined
  }

  const { offline } = useDetectOffline()

  const listCardsFromId = React.useCallback(
    (id) => {
      const loadCard = async () => {
        const result = await getCards(id)
        const cardsResult = result?.data as ICardPerxona[]
        setCards(cardsResult)
      }
      loadCard()
    },
    [id]
  )

  const handleCancelEditPage = async () => {
    try {
      const result = await getDefaultPage(REST.hostURL, 1)
      window.parent.location.href = result.url
    } catch (error) {
      console.log(JSON.stringify(error))
    }
  }

  const handleContinueEditPage = () => {
    const pageIdString = pageId!.toString()
    console.log(pageIdString, 'pageId')
    const savedItems: string[] = JSON.parse(localStorage.getItem(editingLocalStorage) || '[]')
    const newItems = savedItems.filter((i) => i !== pageIdString)
    newItems.push(pageIdString)
    localStorage.setItem(editingLocalStorage, JSON.stringify(newItems ?? '[]'))
    setCancelEditModal(false)
  }

  return (
    <PageContext.Provider
      value={{
        id,
        size,
        position,
        style,
        postedElements,
        designElements,
        src,
        properties,
        darkBg,
        accordions,
        savePosition,
        cards,
        headerData,
        addElement,
        deleteElement,
        updateElement,
        moveElement,
        cloneElement,
        updateElements,
        addAccordion,
        updateAccordion,
        deleteAccordion,
        applyAccordion,
        initSavePositions,
        cancelSavePositions,
        restoreOriginalPositions,
        listCardsFromId,
        getDataForHeader,
      }}
    >
      {showCancelEditModal && (
        <Modal
          open={showCancelEditModal}
          onClose={() => {
            handleCancelEditPage()
          }}
        >
          <Box
            style={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 600,
              backgroundColor: '#fff',
              border: '2px solid #000',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                margin: '10px',
              }}
            >
              <Typography variant="h6" align="center">
                Al parecer esta Página ya está siendo editada en esta computadora. Revisa todas las pestañas abiertas y si no es asi, presiona el botón
                'Continuar".
              </Typography>
              <Typography variant="h6" align="center">
                Si la encuentras abierta presiona el botón "Cancelar" y continua allí la edición.
              </Typography>

              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                }}
              >
                <Button
                  style={{ backgroundColor: '#f44336', color: '#fff' }}
                  startIcon={<Icon>cancel</Icon>}
                  onClick={() => {
                    handleCancelEditPage()
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  startIcon={<Icon>check</Icon>}
                  style={{
                    backgroundColor: '#0288d1',
                    color: '#fff',
                  }}
                  onClick={() => {
                    handleContinueEditPage()
                  }}
                >
                  Continuar
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
      {offline && handleOffline()}
      {!showCancelEditModal && !loading && children}
    </PageContext.Provider>
  )
}

export default Context
