const hostURL = "react.perxona.com" === window.location.host ? "https://perxona.com" : "https://developer.perxona.com"
const rootURL = "react.perxona.com" === window.location.host ? "https://perxona.com/perxona/" : "https://developer.perxona.com/perxona/"
const baseURL = "react.perxona.com" === window.location.host ? "https://perxona.com/perxona/api/rest/" : "https://developer.perxona.com/perxona/api/rest/"
const appApiURL = "react.perxona.com" === window.location.host ? "https://app.perxona.com/api/" : "https://appdev.perxona.com/api/"
const REST = {
    "baseURL": baseURL,
    "rootURL": rootURL,
    "hostURL": hostURL,
    "appApiURL": appApiURL,
    "entitySearchUrl": '/busqueda?search=',
    "getPageById": "pagina/obtenerObjetoPagina?idPag=",
    "getExportedPageById": "pagina/obtenerObjetoPaginaExportada?idPag=",
    "updatepage": "pagina/editar_pagina",
    "getJSFActions": "pagina/obtenerAccionesReact",
    "saveScrenshot": "pagina/guardar_imagen",
    "createAsssociation": "pagina/generar_asociacion",
    "removeAssociation": "pagina/quitar_objeto",
    "getConfiguration": "pagina/obtenerConfiguraciones",
    "generateHtml": "pagina/generar_captura",
    "saveOnClosePage": "pagina/last_save",
    "sendAction": "sendAction?pagId=",
    "findPageParam": "&action=findPage",
    "findObjectParam": "&action=findObject",
    "findImageParam": "&action=findImage",
    "findVideoParam": "&action=findVideo",
    "findAudioParam": "&action=findAudio",
    "findDocumentParam": "&action=findDocument",
    "findLinkParam": "&action=findLink",
    "deleteLinkParam": "&action=deleteLink",
    "deleteEditorLinkParam": "&action=deleteTextLink",
    "objectIdParam": "&objId=",
    "elementIdParam": "&elementId=",
    "positionParam": "&action=position",
    "enablePanelParam": "&action=enablePanel",
    "disablePanelParam": "&action=disablePanel",
    "refreshBackgroundParam": "&action=refreshBackground",
    "showInfo": "&action=showInfo&objId=",
    "getFolder": "pagina/obtener_folder",
    "getDefaultPage": "pagina/pagina_default",
    "getFloatFolder": "pagina/obtener_info_folder",
    "idFolderParam": "?idFolder=",
    "idUserParam": "&idUser=",
    "xParam": "&x=",
    "yParam": "&y=",
    "permissionParam": "&permission=",
    "addDeepLink": "&action=addDeepLink",
    "deleteDeepLink": "&action=deleteDeepLink",
    "pageIdParam": "&pageId=",
    "objIdParam": "&objId=",
    "pageIdDParam": "&pageIdD=",
    "objIdDParam": "&objIdD=",
    "elementIdDParam": "&elementIdD=",
    "loginValidate": "login-validate?",
    "userParam": "&username=",
    "passwordParam": "&password=",
    "forgotParam": "&forgot=true",
    "otpParam": "&otp=",
    "tabIdParam": "&tabId=",
    "qrPath": "QrLogin/qr",
    "qrTabIdParam": "?tabId=",
    "userPath": "user/",
    "cardsPath": "tarjetas/",
    "statusFriendship": "status-friendship",
    "requestFriendship": "request-friendship",
    "getFilters": '/search/filters',
    "getLocation": '/search/locations',
    'perxonaId': 'perxonaId=',
    'serviceId': 'servicioId=',
    'objectId': 'objetoId=',
    'userId': 'usuarioId=',
    "query": 'query',

}

export const MAX_BEACON_STRING_SIZE = Math.pow(2, 16)

export default REST
