import { Box, Checkbox, Icon, Typography } from "@material-ui/core";
import { EditionCardMode, ICardPerxona } from "components/CardProps/CardProps"
import { PageContext } from "context";
import { CARD_PERXONA_PORTRAIT } from "lib";
import React from "react";
import { IElement } from "types";
const cardPortraitStyles = CARD_PERXONA_PORTRAIT

type Move = "RIGHT" | "LEFT"

const Certificador = ({ certifier }) => (
  <div style={{ ...cardPortraitStyles.certificatorContainer, ...{ display: "block", alignSelf: "auto", width: "100%", marginRight: "5%", marginTop: "2.5%" } }}>
    <div className="row" style={{ display: "flex", alignItems: "center" }}>
      <div className="column" style={{ flex: "0%", display: "flex", justifyContent: "flex-end", marginRight: "3%" }}>
        <img src={certifier.avatar} alt="" style={{ ...cardPortraitStyles.imageCertificator, ...{ position: "relative" } }} />
      </div>
      <div className="column" style={{ flex: "50%", display: " flex", marginLeft: "10%", justifyContent: "flex-end" }}>

        <div className="column" style={{ flex: "50%" }}>
          <div className="row">
            <p style={{ ...cardPortraitStyles.certificatorTittle, ...{ textTransform: "capitalize", fontWeight: "bold" } }}>
              Certificador
            </p>
            <p style={{ ...cardPortraitStyles.certificatorName, ...{ textTransform: "capitalize" } }}>
              {`${certifier.name} ${certifier.lastName ?? ''}`}
            </p>
          </div>
        </div>

      </div>
    </div>
  </div>
)

const CardPortraitPerxona = (
  { card, editMode, cardsChosen, element }:
    { card: ICardPerxona, editMode: EditionCardMode, cardsChosen: number[], element: IElement }) => {

  const { name, lastName, country, cardColor, description, backgroundColor, avatar, affiliateId, certifier, cardId, cardType, cardName } = card;
  const { updateElement } = React.useContext(PageContext)

  const handleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    const newCardsChosen = checked ? [...cardsChosen, cardId] : cardsChosen.filter(id => id !== cardId)
    const elementUpdate = {
      ...element,
      value: {
        ...element.value,
        cardsChosen: newCardsChosen
      }
    }
    updateElement(elementUpdate)

  }, [cardsChosen, cardId, element, updateElement])

  const handleChangePosition = React.useCallback((move: Move) => {

    const index = cardsChosen.indexOf(cardId)
    switch (move) {
      case "LEFT":
        const nextCard = cardsChosen[index - 1]
        const newOrderCardsChossen = [...cardsChosen]
        newOrderCardsChossen[index] = nextCard
        newOrderCardsChossen[index - 1] = cardId
        const elementUpdate = {
          ...element,
          value: {
            ...element.value,
            cardsChosen: newOrderCardsChossen
          }
        }
        updateElement(elementUpdate)
        break;
      case "RIGHT":
        const nextCardRight = cardsChosen[index + 1]
        const newOrderCardsChossenRight = [...cardsChosen]
        newOrderCardsChossenRight[index] = nextCardRight
        newOrderCardsChossenRight[index + 1] = cardId
        const elementUpdateRight = {
          ...element,
          value: {
            ...element.value,
            cardsChosen: newOrderCardsChossenRight
          }
        }
        updateElement(elementUpdateRight)
        break;
    }

  }, [cardsChosen, cardId, element, updateElement])


  return (

    <Box
      className="row"
      style={{ ...cardPortraitStyles.main, ...{ flexDirection: "column", backgroundColor: editMode !== "none" ? 'white' : `#${backgroundColor}`, borderColor: '#bdbdbd', borderStyle: 'solid', borderWidth: '1px', } }}
    >
      <div className="column" style={{ flex: "50%" }} >
        <img src={avatar} alt="" style={cardPortraitStyles.imagePerxona} />
      </div>
      <div className="column" style={{ flex: "50%" }}>
        {editMode !== "none" && <Box style={{ height: "100%" }}>

          {editMode === "selection" &&
            <Box style={{ display: 'flex', flexDirection: 'column', flexWrap: "nowrap", height: '100%', justifyContent: 'center', alignItems: 'center' }}>
              <Typography>
                {cardName}
              </Typography>

              <Checkbox size="medium"
                onChange={handleChange} checked={cardsChosen.find(id => id === cardId) !== undefined ? true : false}
                style={{ color: cardType === 5 ? 'red' : '#0288d1' }} />
            </Box>

          }

          {editMode === "reorder" &&

            <Box style={{ display: 'flex', flexDirection: 'column', flexWrap: "nowrap", height: '100%', justifyContent: 'center' }}>
              <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography>
                  {cardName}
                </Typography>
              </Box>
              <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Box  >
                  {(cardsChosen.indexOf(cardId) !== -1 && cardsChosen.indexOf(cardId) !== 0) &&
                    <Icon fontSize="large" onClick={() => handleChangePosition("LEFT")} >keyboard_double_arrow_left</Icon>}
                </Box>

                <Box>
                  {(cardsChosen.indexOf(cardId) !== -1 && (cardsChosen.indexOf(cardId) < cardsChosen.length - 1)) &&
                    <Icon fontSize="large" onClick={() => handleChangePosition("RIGHT")}>keyboard_double_arrow_right</Icon>}
                </Box>
              </Box>
            </Box>
          }

        </Box>}

        <div className="row">

          {editMode === "none" &&
            <Box style={{ ...cardPortraitStyles.informationContainer, ...{ backgroundColor: `#${cardColor}`, height: certifier !== null ? "175px" : "210px" } }}>

              <Box className="row" style={{ ...cardPortraitStyles.perxonaInformationContainer, }}  >
                <div className="column" style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "170%" }}>
                  <Typography style={{ ...cardPortraitStyles.perxonaName, ...{ fontWeight: "bold" } }} >
                    {name}
                  </Typography>
                  {lastName !== null && <Typography style={{ ...cardPortraitStyles.perxonaFullName, ...{ fontWeight: "bold" } }}>
                    {lastName}
                  </Typography>}
                  <Typography style={{ ...cardPortraitStyles.perxonaNacionality, ...{ fontWeight: "bold" } }}>
                    {country}
                  </Typography>
                  <p style={cardPortraitStyles.perxonaAditionalInfo}>
                    {affiliateId}
                  </p>
                  <div className="descriptionPerxona" style={{ ...cardPortraitStyles.perxonaDescription, ...{ wordWrap: "break-word", textAlign: "left" } }}
                    dangerouslySetInnerHTML={{ __html: description }} />
                </div>
              </Box>

            </Box>}
          {(editMode === "none" && certifier !== null) && <Certificador certifier={certifier}></Certificador>}
        </div>
      </div>
    </Box>


  )
}

export default CardPortraitPerxona