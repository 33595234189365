import { PracticeGroups } from './../../components/Practice/Groups/PracticeGroups'
import { IAccordion } from "components"
import { ELEMENT_NAMES, DEFAULT_TEXT_STYLE, DEFAULT_AUDIO_STYLE, DEFAULT_CURTAIN_STYLE, DEFAULT_FILE_STYLE, DEFAULT_IMAGE_STYLE, DEFAULT_LINE_STYLE, DEFAULT_PAGE_STYLE, DEFAULT_VIDEO_STYLE, DEFAULT_TEXT_VALUE, DEFAULT_CURTAIN_VALUE, DEFAULT_LINE_VALUE, ELEMENT_TYPES, DEFAULT_LOGIN_STYLE, DEFAULT_LOGIN_VALUE, DEFAULT_PRACTICE_CARD_STYLE, DEFAULT_PRACTICE_CARD_VALUE, DEFAULT_PRACTICE_CONFERENCE, DEFAULT_VALUES_CONFERENCE } from "lib"
import randomString from 'random-string'
import { Node } from "slate"
import element from "slate-react/dist/components/element"
import { IElement, IPosition } from "types"
import { DEFAULT_VALUES_HEADER } from 'components/HeaderProps/HeaderProps'

export const getElementNameByType = (type: string | undefined): string => {
    switch (type) {
        case ELEMENT_TYPES.text:
            return ELEMENT_NAMES.text
        case ELEMENT_TYPES.image:
            return ELEMENT_NAMES.image
        case ELEMENT_TYPES.video:
            return ELEMENT_NAMES.video
        case ELEMENT_TYPES.file:
            return ELEMENT_NAMES.file
        case ELEMENT_TYPES.page:
            return ELEMENT_NAMES.page
        case ELEMENT_TYPES.audio:
            return ELEMENT_NAMES.audio
        case ELEMENT_TYPES.line:
            return ELEMENT_NAMES.line
        case ELEMENT_TYPES.curtain:
            return ELEMENT_NAMES.curtain
        case ELEMENT_TYPES.folder:
            return ELEMENT_NAMES.folder
        case ELEMENT_TYPES.login:
            return ELEMENT_NAMES.login
        case ELEMENT_TYPES.practiceCard:
            return ELEMENT_NAMES.practiceCard
        case ELEMENT_TYPES.practiceCardPersonality:
            return ELEMENT_NAMES.practiceCardPersonality
        case ELEMENT_TYPES.practiceCardGroups:
            return ELEMENT_NAMES.practiceCardGroups
        case ELEMENT_TYPES.header:
            return ELEMENT_NAMES.header
        case ELEMENT_TYPES.practiceConference:
            return ELEMENT_NAMES.practiceConference
        default:
            return ""
    }
}

export const generateElementId = () => randomString({ length: 6, numeric: true, letters: true, special: false })
export const generateAccordionId = () => randomString({ length: 3, numeric: true, letters: true, special: false })

export const createElement = (type: string, position: IPosition, userId?: number): IElement => {
    let element = {} as IElement
    element.id = generateElementId()
    element.type = type
    element.sizeUnits = ["px", "px"]
    element.position = position
    element.float = false
    switch (type) {
        case ELEMENT_TYPES.text:
            element.style = DEFAULT_TEXT_STYLE
            element.value = DEFAULT_TEXT_VALUE
            element.newInstance = true
            element.size = { width: 200, height: 40 }
            break
        case ELEMENT_TYPES.image:
            element.style = DEFAULT_IMAGE_STYLE
            element.newInstance = true
            break
        case ELEMENT_TYPES.video:
            element.style = DEFAULT_VIDEO_STYLE
            break
        case ELEMENT_TYPES.file:
            element.style = DEFAULT_FILE_STYLE
            break
        case ELEMENT_TYPES.page:
            element.style = DEFAULT_PAGE_STYLE
            element.newInstance = true
            break
        case ELEMENT_TYPES.audio:
            element.style = DEFAULT_AUDIO_STYLE
            break
        case ELEMENT_TYPES.line:
            element.style = DEFAULT_LINE_STYLE
            element.value = DEFAULT_LINE_VALUE
            element.size = { width: 200, height: 20 }
            break
        case ELEMENT_TYPES.curtain:
            element.style = DEFAULT_CURTAIN_STYLE
            element.value = DEFAULT_CURTAIN_VALUE
            element.size = { width: 200, height: 200 }
            break
        case ELEMENT_TYPES.login:
            element.style = DEFAULT_LOGIN_STYLE
            element.value = DEFAULT_LOGIN_VALUE
            element.size = { width: 346, height: 230 }
            break
        case ELEMENT_TYPES.practiceCard:
            element.style = DEFAULT_PRACTICE_CARD_STYLE
            element.value = { ...DEFAULT_PRACTICE_CARD_VALUE, ...{ perxonaId: userId } }
            element.size = { width: 400, height: 320 }
            break
        case ELEMENT_TYPES.practiceCardPersonality:
            element.style = DEFAULT_PRACTICE_CARD_STYLE
            element.value = { ...DEFAULT_PRACTICE_CARD_VALUE, ...{ perxonaId: userId, tittle: 'Mis personas' } }
            element.size = { width: 400, height: 320 }
            break
        case ELEMENT_TYPES.practiceCardGroups:
            element.style = DEFAULT_PRACTICE_CARD_STYLE
            element.value = { ...DEFAULT_PRACTICE_CARD_VALUE, ...{ perxonaId: userId, tittle: 'Mis Grupos' } }
            element.size = { width: 400, height: 320 }
            break
        case ELEMENT_TYPES.header:
            element.value = DEFAULT_VALUES_HEADER
            element.size = { width: 400, height: 320 }
            element.style = DEFAULT_PRACTICE_CARD_STYLE
            break
        case ELEMENT_TYPES.practiceConference:
            element.value = DEFAULT_VALUES_CONFERENCE
            element.size = { width: 270, height: 255 }
            element.style = DEFAULT_PRACTICE_CONFERENCE
            break

    }
    return element
}

export const createAccordion = (position: IPosition): IAccordion => {
    return { id: generateAccordionId(), direction: "down", position, size: { width: 200, height: 50 }, space: 50, resizeMode: "custom" }
}

export const getFilterArray = (filter: string | undefined) => {
    if (filter) {
        filter = filter.replace(/%/g, "")
        filter = filter.replace(/px/g, "")
        filter = filter.replace("brightness", "")
        filter = filter.replace("grayscale", "")
        filter = filter.replace("blur", "")
        filter = filter.replace("opacity", "")
        filter = filter.replace("sepia", "")
        filter = filter.replace("saturate", "")
        filter = filter.replaceAll("(", "")
        filter = filter.replaceAll(")", "")
        return filter.split(" ")
    }
    return []
}

export const isInRange = (x: number, min: number, max: number): boolean => {
    return ((x - min) * (x - max) <= 0)
}

let counter = 0

export const restartCounter = async () => {
    counter = 0
    return true
}

export const repeatedUrl = (nodes: Node[], url: string): boolean => {
    for (const node of nodes) {
        if (node.type === "link") {
            if (node.url === url) {
                counter++
            }
        }
        if (Array.isArray(node.children)) {
            repeatedUrl(node.children, url)
        }
    }
    return counter > 1
}