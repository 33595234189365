import { ELEMENT_TYPES } from 'lib'
import React from 'react'

interface Props {
    id: string,
    value: any,
    style: React.CSSProperties,
    borderRadiusUnit: string
}

const LineView = React.memo((props: Props) => {
    const getTransforms = React.useCallback(() => { if (props.style.transform) { return JSON.parse(props.style.transform) } }, [props.style.transform])
    return (
        <div
            id={`${ELEMENT_TYPES.line}_${props.id}`}
            style={{
                ...props.style,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                transform: `rotate(${getTransforms()[0]}deg) scaleX(${getTransforms()[1]})`,
                borderTopLeftRadius: props.style.borderTopLeftRadius + props.borderRadiusUnit,
                borderBottomLeftRadius: props.style.borderBottomLeftRadius + props.borderRadiusUnit,
                borderTopRightRadius: props.style.borderTopRightRadius + props.borderRadiusUnit,
                borderBottomRightRadius: props.style.borderBottomRightRadius + props.borderRadiusUnit,
            }}
        >
            <div
                style={{
                    ...props.value,
                    width: props.value?.orientation === undefined || props.value?.orientation === "h" ? "98%" : props.value?.height ? parseInt(props.value.height) : 2,
                    height: props.value?.orientation === "v" ? "98%" : props.value?.height ? parseInt(props.value?.height) : 2,
                    backgroundColor: props.value ? props.value.backgroundColor ? props.value.backgroundColor : "rgba(0,0,0,1)" : "rgba(0,0,0,1)"
                }}
            />
        </div>
    )
})

export default LineView
