import { IConferenceTheme } from './conference'


export type IObject = {
    id: number
    url: string
    urlPlayer: string
    name: string
    fileTypeId: number
    extension: string
    onlyUrl: boolean
    icon: string
    pageUrl: string
    downloadUrl: string
}


export type IMessage = {
    conferenceDetailId: number
    conferenceId: number
    perxonId: number
    conferencePerxonaId: number
    message: string
    senderName: string
    conferenceThemeList: Omit<IConferenceTheme, 'isModerator'>[]
    date: Date
    messageType: string
    objectList: IObject[]
    avatar?: string
}


export enum IObjectType {
    IMAGE = 1,
    VIDEO = 2,
    AUDIO = 3,
    DOCUMENT = 4,
    LINK = 5,
    FOLDER = 6,
    PAGE = 7,

}


