import { getConferenxiaByIdService, getConfernxiasService } from 'lib'
import React from 'react'
import { IConferenxia, IPaginationResponse } from 'types'

type Hook = {
  getConferencesByUserId: (userId: number, page: number) => Promise<void>
  paginationResponse: IPaginationResponse<IConferenxia>
  loading: boolean
  conferenxia: IConferenxia
  getConferenceById: (id: number) => Promise<void>
  resetConferenxia: () => void
}

export const useGetConferenceHelper = (): Hook => {
  const [paginationResponse, setPaginationResponse] = React.useState<IPaginationResponse<IConferenxia>>({
    count: 0,
    limit: 0,
    page: 0,
    result: [],
  } as IPaginationResponse<IConferenxia>)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [conferenxia, setConferenxia] = React.useState<IConferenxia>({} as IConferenxia)

  const resetConferenxia = () => {
    setConferenxia({} as IConferenxia)
    setPaginationResponse({} as IPaginationResponse<IConferenxia>)
  }
  const getConferencesByUserId = async (userId: number, page: number) => {
    try {
      setLoading(true)
      const res = await getConfernxiasService(userId, page)
      setPaginationResponse(res)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const getConferenceById = async (conferenceId: number) => {
    try {
      const res = await getConferenxiaByIdService(conferenceId)
      setConferenxia(res)
    } catch (error) {
      console.log(error)
    }
  }

  return {
    getConferencesByUserId,
    paginationResponse,
    loading,
    conferenxia,
    getConferenceById,
    resetConferenxia,
  }
}
