import React from 'react'
import { Tooltip } from '@material-ui/core'
import { DEEPLINK_TAG, ELEMENT_TYPES, ID_LINK_TAG, openObjectInfo } from 'lib'
import { LocationContext, PageContext } from 'context'
import { useKeyPress } from 'hooks'
import { ICurtainValue } from 'components/CurtainProps/CurtainProps'
import { useLocation } from 'react-router-dom'

interface Props {
    id: string,
    value: any,
    filesContext: string,
    style: React.CSSProperties,
    borderRadiusUnit: string,
    curtain?: boolean,
    curtainValue?: ICurtainValue,
    link?: string,
    openNewTab?: boolean
    exported?: boolean
}

const ImageView = React.memo(((props: Props) => {
    const [open, setOpen] = React.useState(false)
    const { id: pageId } = React.useContext(PageContext)
    const controlPress: boolean = useKeyPress("Control")
    const getTransforms = React.useCallback(() => { if (props.style.transform) { return JSON.parse(props.style.transform) } }, [props.style.transform])
    const { tabId } = React.useContext(LocationContext)



    const handleClick = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e.ctrlKey) {
            await openObjectInfo(pageId, props.value[0], tabId)
            return
        }
        if (props.link) {
            if (props.link.includes(DEEPLINK_TAG)) {
                const element = document.getElementById(props.link.replace(DEEPLINK_TAG, ""))
                if (element) {
                    const y = element.getBoundingClientRect().top + window.scrollY
                    const x = element.getBoundingClientRect().left + window.scrollX
                    window.scroll({
                        top: y,
                        left: x,
                        behavior: 'smooth'
                    })
                }
                return
            }
            const link = props.link.split(ID_LINK_TAG)[0]
            if (!Boolean(props.openNewTab)) {
                window.parent.location.href = link
            } else {
                const win = window.open(link, '_blank')
                win?.focus()
            }
        }
    }
    return (
        <Tooltip open={open && !controlPress} onClose={() => setOpen(false)} onOpen={() => setOpen(Boolean((props.curtain && props.curtainValue?.tooltip) ?? false))} arrow title={props.curtainValue?.tooltip ?? ""}>
            <div
                className={props.curtain ? (props.curtainValue?.type === "appear" || !Boolean(props.curtainValue?.type)) ? "curtain-appear-view" : "curtain-disappear-view" : ""}
                style={{
                    width: "100%",
                    height: "100%",
                }}
            >
                <div
                    id={`${ELEMENT_TYPES.image}_${props.id}`}
                    style={{
                        ...props.style,
                        backgroundImage: `url(${props.filesContext}/${props.value[1]})`,
                        width: "100%",
                        height: "100%",
                        borderTopLeftRadius: props.style.borderTopLeftRadius + props.borderRadiusUnit,
                        borderBottomLeftRadius: props.style.borderBottomLeftRadius + props.borderRadiusUnit,
                        borderTopRightRadius: props.style.borderTopRightRadius + props.borderRadiusUnit,
                        borderBottomRightRadius: props.style.borderBottomRightRadius + props.borderRadiusUnit,
                        transform: `rotate(${getTransforms()[0]}deg) scaleX(${getTransforms()[1]})`,
                        cursor: props.link ? "pointer" : "auto"
                    }}
                    onClick={handleClick}
                >
                    {
                        (controlPress && !props.exported) &&
                        <Tooltip arrow title="Ver información">
                            <div
                                onClick={handleClick}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    position: "absolute",
                                    top: 0,
                                    cursor: "pointer",
                                    backgroundColor: "transparent"
                                }}
                            />
                        </Tooltip>
                    }
                </div>
            </div>
        </Tooltip>
    )
}))

export default ImageView
