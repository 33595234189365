import React, { useCallback, useMemo, useState } from 'react'
import { Editable, withReact, Slate } from 'slate-react'
import {
    createEditor,
    Node,
} from 'slate'
import { withHistory } from 'slate-history'
import { ISize } from 'types'
import { DEEPLINK_TAG, HASH_PARAM } from 'lib'

interface Props {
    value: any,
    style: React.CSSProperties,
    size: ISize,
    borderRadiusUnit: string
}

const Text = (props: Props) => {
    const [value, setValue] = useState<Node[]>(props.value)
    const renderElement = useCallback(elementProps => <Element {...elementProps} />, [])
    const renderLeaf = useCallback(leafProps => <Leaf {...leafProps} />, [])
    const editor = useMemo(() => withHistory(withReact(createEditor())), [])
    const getTransforms = React.useCallback(() => { if (props.style.transform) { return JSON.parse(props.style.transform) } else { return [0, 1] } }, [props.style.transform])
    return (
        <div
            style={{
                ...props.style,
                height: props.size.height,
                borderTopLeftRadius: props.style.borderTopLeftRadius + props.borderRadiusUnit,
                borderBottomLeftRadius: props.style.borderBottomLeftRadius + props.borderRadiusUnit,
                borderTopRightRadius: props.style.borderTopRightRadius + props.borderRadiusUnit,
                borderBottomRightRadius: props.style.borderBottomRightRadius + props.borderRadiusUnit,
                transform: `rotate(${getTransforms()[0]}deg) scaleX(${getTransforms()[1]})`,
                pointerEvents: "none"
            }}
        >
            <Slate editor={editor} value={value} onChange={(newValue: Node[]) => setValue(newValue)}>
                <Editable
                    key={Math.random()}
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    placeholder=""
                    style={{ height: "100%", fontFamily: "Montserrat", display: "flex", flexDirection: "column", justifyContent: props.style.justifyContent }}
                    spellCheck
                    readOnly={true}
                />
            </Slate>
        </div>
    )
}

const Element = ({ attributes, children, element }) => {
    switch (element.type) {
        case 'block-quote':
            return <blockquote {...attributes}>{children}</blockquote>
        case 'bulleted-list':
            return <ul {...attributes}>{children}</ul>
        case 'heading-one':
            return <h1 {...attributes}>{children}</h1>
        case 'heading-two':
            return <h2 {...attributes}>{children}</h2>
        case 'list-item':
            return <li {...attributes}>{children}</li>
        case 'numbered-list':
            return <ol {...attributes}>{children}</ol>
        case 'link':
            const tag = !element.url.includes(DEEPLINK_TAG) ?
                <a className={element.url.includes(HASH_PARAM) && element.url.includes("perxona") ? "internal-link" : ""} {...attributes} href={element.url}>
                    {children}
                </a> :
                <u className="internal-link">{children}</u>
            return tag
        case 'align-center':
            return <p style={{ textAlign: "center" }} {...attributes}>{children}</p>
        case 'align-right':
            return <p style={{ textAlign: "right" }} {...attributes}>{children}</p>
        case 'align-justify':
            return <p style={{ textAlign: "justify" }} {...attributes}>{children}</p>
        default:
            return <p {...attributes}>{children}</p>
    }
}

const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    if (leaf.color) {
        children = <span style={{ color: leaf.color }}>{children}</span>
    }

    if (leaf.backgroundColor) {
        children = <span style={{ backgroundColor: leaf.backgroundColor }}>{children}</span>
    }

    if (leaf.fontFamily) {
        children = <span style={{ fontFamily: leaf.fontFamily }}>{children}</span>
    }

    if (leaf.fontSize) {
        children = <span style={{ fontSize: leaf.fontSize }}>{children}</span>
    }

    if (leaf.lineHeight) {
        children = <span style={{ lineHeight: leaf.lineHeight }}>{children}</span>
    }

    if (leaf.strike) {
        children = <span style={{ textDecoration: "line-through" }}>{children}</span>
    }

    if (leaf.textIndent) {
        children = <span style={{ textIndent: leaf.textIndent }}>{children}</span>
    }

    return <span {...attributes}>{children}</span>
}

export default Text