export const getFontSizes = () => {
          const fonstSizes: string[] = []
          const base: number = 1
          for (let i = 1; i < 10; i++) {
                    fonstSizes.push(0 + i / 10 + "em")
          }
          fonstSizes.push("1em")
          for (let i = 1; i < 51; i++) {
                    fonstSizes.push(base + (i / 10) + "em")
          }
          for (let i = 7; i < 23; i++) {
                    fonstSizes.push(i + "em")
          }
          for (let i = 24; i <= 40; i = i + 2) {
                    fonstSizes.push(i + "em")
          }
          return fonstSizes
}

export const getLineHeights = () => {
          const lineHeights: string[] = []
          for (let i = 0; i < 62; i = i + 2) {
                    lineHeights.push(`${0 + i / 10}em`)
          }
          return lineHeights
}

export const getTextIdentations = () => {
          const textIdentations: string[] = []
          for (let i = 0; i < 20; i = i + 1) {
                    textIdentations.push(`${i * 5}px`)
          }
          return textIdentations
}