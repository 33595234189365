import { Tooltip } from '@material-ui/core'
import { ICurtainValue } from 'components'
import { LocationContext, PageContext } from 'context'
import { useKeyPress } from 'hooks'
import { ELEMENT_TYPES, openObjectInfo } from 'lib'
import React from 'react'
import { useLocation } from 'react-router-dom'

interface Props {
    id: string,
    value: any,
    filesContext: string,
    style: React.CSSProperties,
    borderRadiusUnit: string,
    curtain?: boolean,
    curtainValue?: ICurtainValue
    exported?: boolean
}

const PageView = (props: Props) => {
    const [open, setOpen] = React.useState(false)
    const { id: pageId } = React.useContext(PageContext)
    const controlPress: boolean = useKeyPress("Control")
    const getTransforms = React.useCallback(() => { if (props.style.transform) { return JSON.parse(props.style.transform) } }, [props.style.transform])
    const { tabId } = React.useContext(LocationContext)


    const handleClick = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e.ctrlKey) {
            await openObjectInfo(pageId, props.value[0], tabId)
            return
        }
        if (props.value.length === 4) {
            const win = window.open(props.value[2], '_blank')
            win?.focus()
        } else {
            window.parent.location.href = props.value[2]
        }
    }
    return (
        <Tooltip open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(Boolean((props.curtain && props.curtainValue?.tooltip) ?? false))} arrow title={props.curtainValue?.tooltip ?? ""}>
            <div
                className={props.curtain ? props.curtainValue?.type === "appear" ? "curtain-appear-view" : "curtain-disappear-view" : ""}
                style={{
                    width: "100%",
                    height: "100%",
                }}
            >
                <div
                    id={`${ELEMENT_TYPES.page}_${props.id}`}
                    style={{
                        ...props.style,
                        backgroundImage: `url(${props.filesContext}/${props.value[1]})`,
                        width: "100%",
                        height: "100%",
                        borderTopLeftRadius: props.style.borderTopLeftRadius + props.borderRadiusUnit,
                        borderBottomLeftRadius: props.style.borderBottomLeftRadius + props.borderRadiusUnit,
                        borderTopRightRadius: props.style.borderTopRightRadius + props.borderRadiusUnit,
                        borderBottomRightRadius: props.style.borderBottomRightRadius + props.borderRadiusUnit,
                        transform: `rotate(${getTransforms()[0]}deg) scaleX(${getTransforms()[1]})`,
                        cursor: "pointer"
                    }}
                    onClick={handleClick}
                >
                    {
                        (controlPress && !props.exported) &&
                        <Tooltip arrow title="Ver información">
                            <div
                                onClick={handleClick}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    position: "absolute",
                                    top: 0,
                                    cursor: "pointer",
                                    backgroundColor: "transparent"
                                }}
                            />
                        </Tooltip>
                    }
                </div>
            </div>
        </Tooltip>
    )
}

export default PageView
