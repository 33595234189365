import React from 'react'
import axios from 'axios'
import { theme } from 'styles'

interface Props {
    fonts: IFont[],
    defaultFont: IFont,
    usedFonts: IUsedFont[],
    setUsedFonts: React.Dispatch<IUsedFont[]>
    addUsedFont: (newFont: IFont) => void,
    addStoredFont: (newFont: IUsedFont) => void
}

export interface IUsedFont {
    font: string,
    weights: Array<string | number>
}

export interface IFont {
    family: string,
    variants?: Array<string>,
    subsets?: Array<string>,
    version?: string,
    lastModified?: string,
    files?: object,
    category?: string,
    kind?: string,
}

const FONTS_API_KEY = 'AIzaSyAXDKYUDA9Tgf1AbTbpRPvAt5SMXKDNkL0'

const useFonts = (): Props => {
    const [fonts, setFonts] = React.useState<IFont[]>([])
    const [defaultFont, setDefaultFont] = React.useState<IFont>({} as IFont)
    const [usedFonts, setUsedFonts] = React.useState<IUsedFont[]>([])
    const addUsedFont = (newFont: IFont) => {
        const index = usedFonts.findIndex(u => u.font === newFont.family)
        if (index > -1) return
        const newUsedFonts = [...usedFonts]
        newUsedFonts.push({ font: newFont.family, weights: newFont.variants ? newFont.variants : [] })
        setUsedFonts(newUsedFonts)
    }
    const addStoredFont = (newFont: IUsedFont) => {
        const index = usedFonts.findIndex(u => u.font === newFont.font)
        if (index > -1) return
        const newUsedFonts = [...usedFonts]
        newUsedFonts.push(newFont)
        setUsedFonts(newUsedFonts)
    }
    React.useEffect(() => {
        const loadBaseFonts = async () => { setFonts(await getGoogleFonts()) }
        loadBaseFonts()
    }, [])
    React.useEffect(() => {
        const getDefaultFont = () => {
            for (const font of fonts) {
                if (font.family === "Montserrat") {
                    setDefaultFont(font)
                    return
                }
            }
            setDefaultFont(fonts[0])
        }
        if (fonts.length > 0) {
            getDefaultFont()
        }
    }, [fonts])
    const getGoogleFonts = async () => {
        try {
            const response = await axios.get("https://www.googleapis.com/webfonts/v1/webfonts?key=" + FONTS_API_KEY)
            return response.data.items
        } catch (error: any) {
            throw new Error(error);
        }
    }
    return {
        fonts,
        defaultFont,
        usedFonts,
        addUsedFont,
        addStoredFont,
        setUsedFonts
    }
}

export default useFonts
