import REST from "lib/constants/REST"

import axios, { AxiosError } from 'axios'
import { encrypt } from "./encrypt"
import { RelationShipStatus } from "components/CardProps/CardProps"
import { IConferenxia, IConferenxiaMembers, IMessage, IPaginationResponse } from 'types'

axios.defaults.baseURL = REST.baseURL

export const getPage = async (pageId: number) => {
    try {
        const response = await axios.get(REST.getPageById + pageId)
        return response.data.content
    } catch (error: any) {
        console.log(error)
        throw new Error(error)
    }
}

export const getExportedPage = async (pageId: number) => {
    try {
        const response = await axios.get(REST.getExportedPageById + pageId)
        return response.data.content
    } catch (error: any) {
        console.log(error)
        throw new Error(error)
    }
}

export const getConfigurations = async () => {
    try {
        const response = await axios.get(REST.getConfiguration)
        return response.data.content
    } catch (error: any) {
        console.log(error)
        throw new Error(error)
    }
}

export const updatePage = async (page: any) => {
    try {
        const response = await axios.post(REST.updatepage, page)
        return response.data.content
    } catch (error: any) {
        throw new Error(error)
    }
}

export const findImage = async (pageId: any, tabId: any) => {
    const response = await fetch(REST.rootURL + REST.sendAction + pageId + REST.findImageParam + REST.tabIdParam + tabId)
    return response
}

export const findVideo = async (pageId: any, tabId: any) => {
    const response = await fetch(REST.rootURL + REST.sendAction + pageId + REST.findVideoParam + REST.tabIdParam + tabId)
    return response
}

export const findAudio = async (pageId: any, tabId: any) => {
    const response = await fetch(REST.rootURL + REST.sendAction + pageId + REST.findAudioParam + REST.tabIdParam + tabId)
    return response
}

export const findDocument = async (pageId: any, tabId: any) => {
    const response = await fetch(REST.rootURL + REST.sendAction + pageId + REST.findDocumentParam + REST.tabIdParam + tabId)
    return response
}

export const findPage = async (pageId: any, tabId: any) => {
    const response = await fetch(REST.rootURL + REST.sendAction + pageId + REST.findPageParam + REST.tabIdParam + tabId)
    return response
}

export const findObject = async (pageId: any, tabId: any) => {
    const response = await fetch(REST.rootURL + REST.sendAction + pageId + REST.findObjectParam + REST.tabIdParam + tabId)
    return response
}

export const findLink = async (pageId: any, objId: any, elementId: any, tabId: any) => {
    const response = await fetch(REST.rootURL + REST.sendAction + pageId + REST.findLinkParam + REST.objectIdParam + objId + REST.elementIdParam + elementId + REST.tabIdParam + tabId)
    return response
}

export const sendPosition = async (pageId: any, x: any, y: any, tabId: any) => {
    const response = await fetch(REST.rootURL + REST.sendAction + pageId + REST.positionParam + REST.xParam + x + REST.yParam + y + REST.tabIdParam + tabId)
    return response
}

export const refreshBackground = async (pageId: any, tabId) => {
    const response = await fetch(REST.rootURL + REST.sendAction + pageId + REST.refreshBackgroundParam + REST.tabIdParam + tabId)
    return response
}

export const openObjectInfo = async (pageId: any, objectId: any, tabId: any) => {
    const response = await fetch(REST.rootURL + REST.sendAction + pageId + REST.showInfo + objectId + REST.tabIdParam + tabId)
    return response
}

export const disablePanel = async (pageId: any, tabId) => {
    const response = await fetch(REST.rootURL + REST.sendAction + pageId + REST.disablePanelParam + REST.tabIdParam + tabId)
    return response
}

export const enablePanel = async (pageId: any, tabId) => {
    const response = await fetch(REST.rootURL + REST.sendAction + pageId + REST.enablePanelParam + REST.tabIdParam + tabId)
    return response
}

export const generateHtml = async (html: any, pageId: any) => {
    try {
        const response = await axios.post(REST.generateHtml, { id: pageId, html })
        return response.data.content
    } catch (error: any) {
        throw new Error(error)
    }
}

export const getFloatFolderInfoById = async (id: any) => {
    try {
        const response = await axios.get(REST.getFloatFolder + REST.idFolderParam + id)
        return response.data.content
    } catch (error: any) {
        console.log(error)
        throw new Error(error)
    }
}

export const getFolderInfoById = async (folderId: any, userId: any, permission: any) => {
    try {
        const response = await axios.get(REST.getFolder + REST.idFolderParam + folderId + REST.idUserParam + userId + REST.permissionParam + permission)
        return response.data.content
    } catch (error: any) {
        console.log(error)
        throw new Error(error)
    }
}

export const getDefaultPage = async (context: any, type: any) => {
    try {
        const response = await axios.post(REST.getDefaultPage, { context, type })
        return response.data.content
    } catch (error: any) {
        console.log(error)
    }
}
export const getLoginValidate = async (user: string, password: string, tabId: string) => {
    try {

        const passwordEncode = encrypt(password)
        const userEncode = encrypt(user)
        const path = REST.rootURL + REST.loginValidate + REST.userParam + userEncode + REST.passwordParam + passwordEncode + REST.tabIdParam + tabId
        const response = await axios.get(path)
        return response.data.content
    } catch (error: any) {
        console.log("->>>>>", error)
        throw new Error(error)
    }
}

export const getLoginForgotPassword = async (user: string, tabId: string) => {
    try {
        const userEncode = encrypt(user)
        const path = REST.rootURL + REST.loginValidate + REST.userParam + userEncode + REST.forgotParam + REST.tabIdParam + tabId
        const response = await axios.get(path)
        return response.data.content
    } catch (error: any) {
        console.log("->>>>>", error)
        throw new Error(error)
    }
}

export const getLoginValidateOTP = async (user: string, otp: string, tabId: string) => {
    try {
        const userEncode = encrypt(user)
        const otpEncode = encrypt(otp)
        const path = REST.rootURL + REST.loginValidate + REST.userParam + userEncode + REST.otpParam + otpEncode + REST.tabIdParam + tabId
        const response = await axios.get(path)
        return response.data.content
    } catch (error: any) {

        throw new Error(error)
    }

}
export const getCards = async (userId: number) => {
    try {

        const response = await axios.get(REST.appApiURL + REST.userPath + userId + "/" + REST.cardsPath)
        return response
    } catch (error: any) {

        throw new Error(error)
    }
}
export const getCardPerxona = async (perxonaId: number) => {
    try {
        const response = await axios.get(REST.appApiURL + REST.userPath + "/" + "presentation?perxonaId=" + perxonaId)
        return response
    } catch (error: any) {

    }
}

export const getCardsPersonality = async (userId: number) => {
    try {

        const response = await axios.get(REST.appApiURL + REST.userPath + userId + "/" + "personality/" + REST.cardsPath)
        return response
    } catch (error: any) {

        throw new Error(error)
    }
}

export const getCardsGroup = async (userId: number) => {
    try {

        const response = await axios.get(REST.appApiURL + REST.userPath + userId + "/" + "groups")
        return response

    } catch (error: any) {

        throw new Error(error)

    }
}

export const getPerxonasByGroup = async (groupId: number) => {
    try {

        const response = await axios.get(REST.appApiURL + "groups/" + groupId + '/' + REST.userPath)
        return response

    } catch (error: any) {

        throw new Error(error)

    }
}


export const getStatusFriendShip = async ({ userId, friendId }): Promise<RelationShipStatus> => {
    try {
        const response = await axios.post(REST.appApiURL + REST.userPath + REST.statusFriendship, { userId, friendId })
        return response.data as RelationShipStatus
    } catch (error: any) {
        throw new Error(error)
    }
}

export const requestFriendShip = async ({ userId, friendId }): Promise<number> => {
    try {
        const response = await axios.post(REST.appApiURL + REST.userPath + REST.requestFriendship, { userId, friendId })
        return response.status
    } catch (error: any) {
        throw new Error(error)
    }
}

export const getLocationInfo = async () => {
    try {
        const response = await axios.get(REST.appApiURL + REST.getLocation)
        return response.data

    } catch (error: any) {
        throw new Error(error)
    }
}

export const getFiltersEntidades = async () => {
    try {
        const response = await axios.get(REST.appApiURL + REST.getFilters)
        return response.data
    } catch (error: any) {
        throw new Error(error)
    }
}

export const getHeaderInformation = async (entityId: number, serviceId: number, objectId: number, usuarioId: number) => {
    try {
        const response = await axios.get(REST.appApiURL + REST.query + '?' + REST.perxonaId +
            entityId + '&' + REST.serviceId + serviceId + '&' + REST.objectId + objectId + '&' + `${usuarioId !== -1 ? REST.userId + usuarioId : ''}`)
        console.log(response, 'here')
        return response.data
    } catch (error: any) {
        console.log(error)
        throw new Error(error)
    }
}

export const getMessagesService = async (conferenceId: number | string, page: number, conferenceThemeId?: number, limit?: number): Promise<IPaginationResponse<IMessage>> => {

    try {
        const result = await axios.get(REST.appApiURL + `/conference-detail`, {
            params: {
                conferenceId,
                page,
                conferenceThemeId,
                limit
            }
        })
        return result.data
    } catch (error: any) {
        throw new Error(error)

    }
}

export const getConfernxiasService = async (id: number | string, page: number): Promise<IPaginationResponse<IConferenxia>> => {
    try {


        const result = await axios.get(REST.appApiURL + `/conference`, {
            params: {
                userId: id,
                page,
                executor: 1
            }
        })
        return result.data
    }
    catch (error: any) {
        throw new Error(error)

    }
}

export const getConferenxiaByIdService = async (id: number): Promise<IConferenxia> => {
    try {
        const result = await axios.get(REST.appApiURL + `/conference/${id}`)
        return result.data
    } catch (error: any) {
        throw new Error(error)
    }
}

export const getConferenxiaMembersService = async (conferenceId: number) => {
    try {
        const result = await axios.get(REST.appApiURL + '/conference-perxona', { params: { conferenceId } })
        return result.data as IConferenxiaMembers[]
    } catch (error: any) {
        throw new Error(error)

    }
}


export const getCommandDetailLinkService = async (objectId: number) => {
    try {
        const result = await axios.get(REST.appApiURL + `/commands-detail-object`, { params: { objectId } })
        return result.data as string
    } catch (error) {
        throw error as AxiosError
    }
}