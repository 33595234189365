import React from 'react'
import { Box, Fade, Typography, LinearProgress, makeStyles } from '@material-ui/core'

interface Props {
  loading: boolean
  message: string
}

const Loading = (props: Props) => {
  const { loading, message } = props
  const [progress, setProgress] = React.useState(0)
  const [buffer, setBuffer] = React.useState(10)
  const progressRef = React.useRef(() => {})
  const style = makeStyles({
    barColorPrimary: {
      backgroundColor: '#000',
    },
    dashed: {
      background: 'linear-gradient(90deg, #4b4b4b 0%, #000 50%, #fff 50%, #fff 100%)',
    },
    colorSecondary: {
      backgroundColor: '#4b4b4b',
    },
    colorPrimary: {
      backgroundColor: '#4b4b4b',
    },
  })
  const clases = style()

  React.useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0)
        setBuffer(10)
      } else {
        const diff = Math.random() * 10
        const diff2 = Math.random() * 10
        setProgress(progress + diff)
        setBuffer(progress + diff + diff2)
      }
    }
  })

  React.useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current()
    }, 500)
    return () => {
      clearInterval(timer)
    }
  }, [])
  return (
    <Fade timeout={800} in={loading} mountOnEnter unmountOnExit>
      <div className="flex fixed" style={{ top: 15, right: 20 }}>
        <div style={{ width: 110, height: 110 }} className="bg-center bg-no-repeat bg-contain bg-logo" />
        <div className="flex flex-col justify-center mx-4">
          <Typography variant="h5">
            <Box letterSpacing={1}>{'Inteligenxia'}</Box>
          </Typography>
          <Box letterSpacing={1}>
            <Typography variant="body2">{message}</Typography>
          </Box>
          <LinearProgress variant="buffer" value={progress} classes={clases} valueBuffer={buffer} className="mt-4" />
        </div>
      </div>
    </Fade>
  )
}

export default Loading
function style() {
  throw new Error('Function not implemented.')
}
