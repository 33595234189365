import { Button, ClickAwayListener, Icon, Paper, Popper, Tooltip, Typography } from '@material-ui/core'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import { CloneIcon } from 'components'
import { PageContext } from 'context'
import { ELEMENT_TYPES } from 'lib'
import React from 'react'
import { IElement } from 'types'

interface Props {
  element: IElement
  handleInfo: () => any
  handleChange: () => any
  handleSelectionAndReorder: () => void
}

const Actions = (props: Props) => {
  const { element, handleChange, handleInfo, handleSelectionAndReorder } = props
  const { deleteElement, cloneElement } = React.useContext(PageContext)
  const [confirmLinkAnchorEl, setConfirmLinkAnchorEl] = React.useState<null | HTMLElement>(null)
  const isTextElement = React.useCallback(() => {
    return element.type === ELEMENT_TYPES.text
  }, [element])
  const isLineElement = React.useCallback(() => {
    return element.type === ELEMENT_TYPES.line
  }, [element])
  const isCurtainElement = React.useCallback(() => {
    return element.type === ELEMENT_TYPES.curtain
  }, [element])
  const isFolderElement = React.useCallback(() => {
    return element.type === ELEMENT_TYPES.folder
  }, [element])
  const isPageElement = React.useCallback(() => {
    return element.type === ELEMENT_TYPES.page
  }, [element])
  const isLoginElement = React.useCallback(() => {
    return element.type === ELEMENT_TYPES.login
  }, [element])
  const isPracticoTarjeta = React.useCallback(() => {
    return (
      element.type === ELEMENT_TYPES.practiceCard ||
      element.type === ELEMENT_TYPES.practiceCardPersonality ||
      element.type === ELEMENT_TYPES.practiceCardGroups ||
      element.type === ELEMENT_TYPES.practiceConference
    )
  }, [element])
  const handleClickDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDeleteAnchorEl(event.currentTarget)
    setConfirmLinkAnchorEl(null)
  }
  const handleClone = (withLink: boolean) => {
    cloneElement(element, withLink)
    setConfirmLinkAnchorEl(null)
  }
  const [deleteAnchorEl, setDeleteAnchorEl] = React.useState<null | HTMLElement>(null)
  return (
    <React.Fragment>
      <div className="panel-container px-2 pt-1 pb-2">
        <ToggleButtonGroup size="small">
          {!isLoginElement() && (
            <Tooltip title={isTextElement() || isLineElement() || isCurtainElement() || isPracticoTarjeta() ? 'Copiar' : 'Clonar'} arrow>
              <ToggleButton
                size="small"
                value="first"
                onClick={(event) => {
                  console.log(!Boolean(element.link))
                  if (!Boolean(element.link)) {
                    handleClone(false)
                    return
                  }
                  setConfirmLinkAnchorEl(event.currentTarget)
                }}
              >
                {isTextElement() || isLineElement() || isCurtainElement() ? (
                  <Icon fontSize="small">file_copy</Icon>
                ) : (
                  <CloneIcon style={{ fontSize: '1.25rem' }} />
                )}
              </ToggleButton>
            </Tooltip>
          )}
          <Tooltip arrow title="Eliminar" placement="left">
            <ToggleButton size="small" value="first" onClick={handleClickDelete}>
              <Icon fontSize="small">delete</Icon>
            </ToggleButton>
          </Tooltip>
          {!isLineElement() && !isTextElement() && !isFolderElement() && !isPageElement() && !isLoginElement() && !isPracticoTarjeta() && (
            <Tooltip arrow title="Cambiar" placement="left">
              <ToggleButton size="small" value="first" onClick={handleChange}>
                <Icon fontSize="small">search</Icon>
              </ToggleButton>
            </Tooltip>
          )}
          {!isLineElement() && !isTextElement() && !isLoginElement() && !isPracticoTarjeta() && (
            <Tooltip arrow title="Información" placement="left">
              <ToggleButton size="small" value="first" onClick={handleInfo}>
                <Icon fontSize="small">info</Icon>
              </ToggleButton>
            </Tooltip>
          )}
          {isPracticoTarjeta() && (
            <Tooltip arrow title="Seleccionar" placement="left">
              <ToggleButton size="small" value="first" onClick={handleSelectionAndReorder}>
                <Icon fontSize="small">recent_actors</Icon>
              </ToggleButton>
            </Tooltip>
          )}
        </ToggleButtonGroup>
      </div>
      <Popper open={Boolean(deleteAnchorEl)} anchorEl={deleteAnchorEl}>
        <ClickAwayListener onClickAway={() => setDeleteAnchorEl(null)}>
          <Paper className="p-2 pb-3">
            <Typography className="block" align="center" style={{ letterSpacing: 1 }} variant="caption">
              {'¿Eliminar objeto?'}
            </Typography>
            <div className="flex items-center mt-2">
              <Button
                size="small"
                variant="contained"
                style={{
                  fontSize: '0.7em',
                  padding: 1,
                  textTransform: 'capitalize',
                }}
                color="primary"
                onClick={() => deleteElement(element)}
              >
                <Icon style={{ fontSize: '0.85rem', marginRight: 2 }}>check</Icon>
                {'Si'}
              </Button>
              <Button
                size="small"
                variant="contained"
                style={{
                  backgroundColor: '#f44336',
                  fontSize: '0.7em',
                  padding: 1,
                  color: '#FFF',
                  marginLeft: 8,
                  textTransform: 'capitalize',
                }}
                onClick={() => setDeleteAnchorEl(null)}
              >
                <Icon style={{ fontSize: '0.85rem', marginRight: 2 }}>close</Icon>
                {'No'}
              </Button>
            </div>
          </Paper>
        </ClickAwayListener>
      </Popper>
      <Popper open={Boolean(confirmLinkAnchorEl)} anchorEl={confirmLinkAnchorEl}>
        <Paper className="p-2 pb-3">
          <Typography className="block" align="center" style={{ letterSpacing: 1 }} variant="caption">{`¿${
            isTextElement() || isLineElement() ? 'Copiar' : 'Clonar'
          } link?`}</Typography>
          <div className="flex items-center mt-2">
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => handleClone(true)}
              style={{
                fontSize: '0.7em',
                padding: 1,
                textTransform: 'capitalize',
              }}
            >
              <Icon style={{ fontSize: '0.85rem', marginRight: 2 }}>check</Icon>
              {'Si'}
            </Button>
            <Button
              size="small"
              variant="contained"
              style={{
                backgroundColor: '#f44336',
                fontSize: '0.7em',
                padding: 1,
                color: '#FFF',
                marginLeft: 8,
                textTransform: 'capitalize',
              }}
              onClick={() => handleClone(false)}
            >
              <Icon style={{ fontSize: '0.85rem', marginRight: 2 }}>close</Icon>
              {'No'}
            </Button>
          </div>
        </Paper>
      </Popper>
    </React.Fragment>
  )
}

export default Actions
