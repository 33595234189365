import { Box, Chip, Icon, IconButton, Paper } from '@material-ui/core'
import React from 'react'
import { IConferenceTheme } from 'types'
import { useConferenceProvider } from '../hook/useConferenceProvider'
import { ThemeView } from './ThemeView'

type Props = {
  conferenceTheme: IConferenceTheme[]
  width: number
  conferenceId: number
  loadMode: 'D' | 'A'
  hiddenDate?: boolean
  hiddenName?: boolean
  refresh: boolean
  onEndRefresh: () => void
}
export const ColumnPerThemeView = (props: Props) => {
  const { conferenceTheme, width, conferenceId, hiddenDate, hiddenName } = props

  const navigationRowSize = 100
  const cardSize = 235
  const [limit, setLimit] = React.useState<number>(1)
  const [actualPage, setActualPage] = React.useState<number>(1)
  const [themeToShow, setThemeToShow] = React.useState<IConferenceTheme[]>([])
  const [allThemes, setAllThemes] = React.useState<IConferenceTheme[]>([])

  //   const { getMessages, handlePageChange, loading, messages, paginationResponse } = useConferenceProvider()
  //   React.useEffect(() => {
  //     const rowLimit = Math.floor((width - navigationRowSize) / cardSize)
  //     setLimit(rowLimit)
  //     setThemeToShow(conferenceTheme.slice(0, rowLimit))
  //   }, [])

  React.useEffect(() => {
    const rowLimit = Math.floor((width - navigationRowSize) / cardSize)
    setLimit(rowLimit)

    const themes = [...conferenceTheme]
    setAllThemes(themes)
    setThemeToShow(themes.slice(0, rowLimit))
  }, [width, navigationRowSize, cardSize, conferenceTheme])

  const isLastPage = React.useMemo(() => {
    const lastTheme = conferenceTheme[conferenceTheme.length - 1]
    return lastTheme.conferenceThemeId === themeToShow[themeToShow.length - 1]?.conferenceThemeId
  }, [themeToShow, conferenceTheme])

  return (
    <Box
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: navigationRowSize / 2,
          maxWidth: navigationRowSize / 2,
        }}
      >
        {actualPage !== 1 && (
          <IconButton
            style={{
              backgroundColor: actualPage === 1 ? '#4b4b4b' : '#0288d1',
              borderRadius: '50%',
              color: 'white',
              marginLeft: 10,
              height: 30,
              width: 30,
            }}
            disabled={actualPage === 1}
            onClick={() => {
              if (actualPage === 1) {
                return
              }
              const firstIndex = (actualPage - 2) * limit
              const lastIndex = (actualPage - 1) * limit
              setThemeToShow(allThemes.slice(firstIndex, lastIndex))
              setActualPage((prev) => prev - 1)
            }}
          >
            <Icon>chevron_left</Icon>
          </IconButton>
        )}
      </Box>
      <Box
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          gap: 10,
          justifyContent: 'center',
        }}
      >
        {themeToShow.map((theme) => (
          <Paper
            key={theme.conferenceThemeId}
            style={{
              width: cardSize,
              maxWidth: cardSize,
              minWidth: cardSize,
              height: '100%',
              borderTopLeftRadius: 0,
              borderTopRightRadius: 20,
              borderBottomLeftRadius: 15,
              borderBottomRightRadius: 0,
            }}
          >
            <Box
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <Chip
                  key={theme.conferenceThemeId}
                  label={theme.themeName}
                  style={{
                    color: 'white',
                    backgroundColor: `#${theme.color}`,
                    margin: '2px',
                    height: '30px',
                  }}
                />
              </Box>
              <Box
                style={{
                  height: '100%',
                  width: '100%',
                  overflow: 'hidden',
                }}
                id={`themeView${theme.conferenceThemeId}}`}
              >
                <ThemeView
                  conferenceId={conferenceId}
                  theme={theme.conferenceThemeId === 0 ? undefined : theme}
                  loadMode={props.loadMode}
                  hiddenDate={hiddenDate}
                  hiddenName={hiddenName}
                  refresh={props.refresh}
                  onEndRefresh={props.onEndRefresh}
                />
              </Box>
            </Box>
          </Paper>
        ))}
      </Box>

      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: navigationRowSize / 2,
          maxWidth: navigationRowSize / 2,
        }}
      >
        {!isLastPage && (
          <IconButton
            style={{
              backgroundColor: isLastPage ? '#4b4b4b' : '#0288d1',
              borderRadius: '50%',
              color: 'white',
              marginRight: 10,
              height: 30,
              width: 30,
            }}
            disabled={isLastPage}
            onClick={() => {
              if (isLastPage) {
                return
              }
              const firstIndex = actualPage * limit
              const lastIndex = (actualPage + 1) * limit
              setThemeToShow(allThemes.slice(firstIndex, lastIndex))
              //   getMessages(conferenceId, actualPage + 1, selectedTheme.conferenceThemeId, limit)
              setActualPage((prev) => prev + 1)
            }}
          >
            <Icon
              style={{
                borderRadius: '50%',
              }}
            >
              chevron_right
            </Icon>
          </IconButton>
        )}
      </Box>
    </Box>
  )
}
