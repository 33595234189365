import React from 'react'
import { ELEMENT_TYPES, getElementNameByType } from 'lib'

interface Props {
    value: any,
    filesContext: string,
    style: React.CSSProperties,
    borderRadiusUnit: string
}

const Image = React.memo(((props: Props) => {
    const getTransforms = React.useCallback(() => { if (props.style.transform) { return JSON.parse(props.style.transform) } }, [props.style.transform])
    return (
        <div
            id={`${getElementNameByType(ELEMENT_TYPES.image).toLowerCase()}_${props.value[0]}`}
            style={{
                ...props.style,
                backgroundImage: `url(${props.filesContext}/${props.value[1]})`,
                width: "100%",
                height: "100%",
                borderTopLeftRadius: props.style.borderTopLeftRadius + props.borderRadiusUnit,
                borderBottomLeftRadius: props.style.borderBottomLeftRadius + props.borderRadiusUnit,
                borderTopRightRadius: props.style.borderTopRightRadius + props.borderRadiusUnit,
                borderBottomRightRadius: props.style.borderBottomRightRadius + props.borderRadiusUnit,
                transform: `rotate(${getTransforms()[0]}deg) scaleX(${getTransforms()[1]})`,
                pointerEvents: "none"
            }}
        />
    )
}))

export default Image
