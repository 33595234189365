import FOLDER from 'assets/folder.png'
import DOC from 'assets/doc.png'
import TXT from 'assets/txt.png'
import XLS from 'assets/xls.png'
import PPT from 'assets/ppt.png'
import PDF from 'assets/pdf.png'
import AUDIO from 'assets/audio.png'
import RAR from 'assets/rar.png'
import JSON from 'assets/json.png'



export type Extensions = 'doc' | 'xls' | 'ppt' | 'txt' | 'folder' | 'pdf' | 'audio' | 'rar' | 'json'



export const objectImageByExtension = (extension: Extensions): string => {
	console.log('extension', extension)
	const imageExtensionMap = new Map()
	imageExtensionMap.set('doc', DOC)
	imageExtensionMap.set('xls', XLS)
	imageExtensionMap.set('ppt', PPT)
	imageExtensionMap.set('txt', TXT)
	imageExtensionMap.set('folder', FOLDER)
	imageExtensionMap.set('pdf', PDF)
	imageExtensionMap.set('audio', AUDIO)
	imageExtensionMap.set('rar', RAR)
	imageExtensionMap.set('json', JSON)
	console.log('imageExtensionMap', imageExtensionMap)

	return imageExtensionMap.get(extension) ?? DOC
}
