import { Box, Button, Grid, makeStyles } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { ICardPersonality } from 'components/CardProps/CardProps'
import React from 'react'
import { CardPersonality } from './CardPersonality'
import { IElement } from 'types';
import { Pagination as IPagination } from "components/FolderProps/FolderProps";
import { stylesButtonPerxona } from 'lib'
import { FilterMode, HookOrder, IPageConfig } from 'hooks';

type SelectionMode = 'selection' | 'reorder' | 'none'


const useStyles = makeStyles({

  minimizeButton: {
    color: 'black',
    '&:hover': {
      cursor: 'zoom-out',
    }
  },
  maximizeButton: {
    color: 'black',
    '&:hover': {
      cursor: 'zoom-in',
    }
  }


})

type Props = {
  cards: ICardPersonality[]
  selectionAndReorderActive: boolean;
  cardsChosen: number[]
  hookOrder: HookOrder;
  element: IElement;
  pageConfig: IPageConfig
  mode: SelectionMode
  tittle: string
  paginationPosition: IPagination
  filterCard: (filterMode: FilterMode) => void
  handleChangeItemsPerPage: (itemsPerPage: number) => void
  handleChangePage: (page: number) => void
  changeSelectionMode: (selectionMode: SelectionMode) => void
  isDesign: boolean
  minimize: boolean
}

export const ContainerCardsPersonality = (props: Props) => {
  const { cards, filterCard, selectionAndReorderActive, 
    pageConfig, handleChangePage, mode, changeSelectionMode, tittle, paginationPosition, isDesign, minimize, element } = props
  const [reduceCards, setReduceCards] = React.useState(false)
  const [minimizeContainer, setMinimizeContainer] = React.useState<Boolean>(!isDesign ? minimize : false)

  const handleMinimizeContainer = () => setMinimizeContainer(prevState => !prevState)

  React.useEffect(() => {

    if (selectionAndReorderActive) {
      changeSelectionMode('selection')
      filterCard('all')

    } else {
      changeSelectionMode('none')
      filterCard('filterAndSort')

    }
    handleChangePage(1)

  }, [selectionAndReorderActive])




  const classes = useStyles()

  return (
    <React.Fragment>
      <Grid
        container
        direction="column"
        style={{ height: !minimizeContainer ? element.size.height : '100%' }}
        wrap="nowrap"
        spacing={0}
      >
        <Grid item xs={12} style={{ width: "100%", flexBasis: 0 }}>
          <Box>
            <Grid container direction="row" style={{ flexWrap: 'nowrap', }} >
              <Grid item xs={8} onClick={() => { ((tittle.length > 0 || minimizeContainer)|| !isDesign) && handleMinimizeContainer() }}
                className={minimizeContainer ? classes.maximizeButton : (tittle.length > 0 || !isDesign) ? classes.minimizeButton : ''}>
                <Box fontSize={24} style={{ paddingLeft: 8, paddingTop: 8,paddingBottom:4 }}>
                  {tittle !== undefined ? tittle.length > 0 ? tittle : minimizeContainer ? 'Mis Personas' : isDesign ? 'Mis Personas'  : 'Mis Personas' : 'Mis personas'}
                </Box>
              </Grid>
              {!minimizeContainer && <Grid item xs={4} style={{ display: "flex", justifyContent: "flex-end", marginTop: 8 }}>
                <Button variant="contained" color={
                  mode === 'selection' ?
                    'primary' : (reduceCards && mode === 'none') ?
                      'primary' : 'default'
                }
                  style={{ ...stylesButtonPerxona.buttonPerxona, ...{ marginRight: 8 } }}
                  onClick={() => {
                    if (selectionAndReorderActive) {
                      changeSelectionMode('selection')
                      filterCard('all')
                    } else {
                      setReduceCards(prev => !prev)
                    }

                  }} >
                  {selectionAndReorderActive ? 'Seleccionar' :
                    reduceCards ? 'Ampliar' : ' Reducir'}
                </Button>
                {selectionAndReorderActive && <Button variant="contained" color={mode === 'reorder' ? 'primary' : 'default'}
                  style={{ ...stylesButtonPerxona.buttonPerxona, ...{ marginRight: 8 } }}
                  onClick={() => {
                    changeSelectionMode("reorder")
                    filterCard('filterAndSort')
                  }}>
                  Ordenar
                </Button>}
              </Grid>}
            </Grid>


          </Box>
        </Grid>

        {!minimizeContainer && <Grid item xs={12} style={{ width: "100%", display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', flexBasis: '0%' }}>

          {((paginationPosition === 'top' || paginationPosition === 'both') && (pageConfig.totalPages > 1)) && <Pagination count={pageConfig.totalPages} color="primary" onChange={(e, newPage: number) => {
            handleChangePage(newPage)
          }} style={{ alignSelf: "center" }} page={pageConfig.page}
            showFirstButton showLastButton />}

        </Grid>}

        {!minimizeContainer && <Grid item xs={12} style={{ height: '100%' }} >
          <Grid container spacing={3} justify="center" direction="row" wrap='nowrap' alignItems='center' alignContent='center' style={{ height: '100%' }}>

            {cards.map((cardInfo, index) => (
              <Grid item xs={12} key={index} style={{
                display: 'flex', flexDirection: 'column',
                flexWrap: 'nowrap', alignItems: 'center', maxWidth: 'max-content', height: '400px'
              }}>
                <CardPersonality
                  info={cardInfo}
                  selectionMode={mode}
                  hookOrder={props.hookOrder}
                  element={props.element}
                  minimize={reduceCards}

                />
              </Grid>))}

          </Grid>
        </Grid>}

        {!minimizeContainer && <Grid item xs={12} style={{ width: "100%", display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', flexBasis: '0%' }}>
          {((paginationPosition === 'bottom' || paginationPosition === 'both') && (pageConfig.totalPages > 1)) &&
            <Pagination count={pageConfig.totalPages} color="primary" onChange={(e, newPage: number) => {
              handleChangePage(newPage)
            }} style={{ alignSelf: "center" }} page={pageConfig.page}
              showFirstButton showLastButton />}

        </Grid>}


      </Grid >

    </React.Fragment >
  )

}
