import { ContainerCardGroups, ISubGroup } from "./ContainerCardsGroups"
import React from 'react'
import { getCardsGroup, getPerxonasByGroup } from "lib"
import { ICardGroup, IPerxonaResponse } from "components/CardProps/CardProps"
import { useFilterAndSort } from "hooks/PracticeCard/useFilterAndSort"
import { PageContext } from "context"
import { IPageConfig, IPageSlice, Move, useOrder, usePagination } from "hooks"
import { IElement } from "types"


export type ElementOpen = 'Perxonas'


export type handleCardGroup = {
          handleOnClickGroup: () => void
          onHoverCardGroup: () => void
          onMouseLeveCardGroup: () => void
}


type SelectionMode = 'selection' | 'reorder' | 'none' | 'perxonas'

type Props = {
          selectionAndReorderActive: boolean;
          element: IElement;
          isDesign?: boolean;


}

export type IPaginationConfigPerxona = {
          pageConfigPerxonas: IPageConfig;
          slice: IPageSlice;
}

export type ViewModePerxona = {
          viewMode: 'grid' | 'list',
          handleChangeViewMode: (viewMode: 'grid' | 'list') => void
}

export type SubGroupNavigation = {
          resetSubGroupNavigation: () => void,
          handleAddSubGroupNavigation: (newSubGroup: ISubGroup) => void,
          handleRemoveSubGroupNavigation: (newSubGroup: ISubGroup) => void,
          subGroupNavigation: ISubGroup[],
          handleRemovePreviousSubGroupNavigation: (subGroup: ISubGroup) => void
}

export const PracticeGroups = (props: Props) => {
          const { selectionAndReorderActive, element, isDesign } = props
          const { value, style } = element
          const { cardsChosen, cardPaginationProps, paginationPosition, cardViewMinimizeProps, tittle, perxonaId } = value
          const { itemsPerPage } = cardPaginationProps
          const { updateElement } = React.useContext(PageContext)
          const { minimize } = cardViewMinimizeProps

          const [mode, setMode] = React.useState<SelectionMode>('none')
          const [cardsGroup, setCardsGroup] = React.useState<ICardGroup[]>([])
          const [perxonasGroup, setPerxonasGroup] = React.useState<IPerxonaResponse[]>([])
          const [originGroup, setOriginGroup] = React.useState<ICardGroup | undefined>(undefined)
          const [loading, setLoading] = React.useState<Boolean>(Boolean(true))
          const [viewModePerxonas, setViewModePerxonas] = React.useState<'list' | 'grid'>('grid')

          const [subGroupNavigation, setSubGroupNavigation] = React.useState<ISubGroup[]>([])

          const handleAddSubGroupNavigation = (newSubGroup: ISubGroup) => {

                    setSubGroupNavigation(prevSubGroupNavigation => {
                              if (prevSubGroupNavigation.find(subGroup => subGroup.subGroupId === newSubGroup.subGroupId)) {
                                        return prevSubGroupNavigation
                              }
                              return [...prevSubGroupNavigation, newSubGroup]

                    })
          }

          const resetSubGroupNavigation = () => {
                    setSubGroupNavigation([])
          }
          const handleRemoveSubGroupNavigation = (subGroup: ISubGroup) => {
                    setSubGroupNavigation(prevSubGroupNavigation => prevSubGroupNavigation.filter(prevSubGroup => prevSubGroup.subGroupId !== subGroup.subGroupId))
          }

          const handleRemovePreviousSubGroupNavigation = (subGroup: ISubGroup) => {
                    setSubGroupNavigation(prevSubGroupNavigation => {

                              const index = prevSubGroupNavigation.findIndex(prevSubGroup => prevSubGroup.subGroupId === subGroup.subGroupId)
                              if (index === -1) {
                                        return prevSubGroupNavigation
                              }
                              const newSubGroupNavigation = prevSubGroupNavigation.slice(0, index + 1)
                              return newSubGroupNavigation
                    })
          }

          const perxonaView = React.useRef<HTMLDivElement>(null)

          const handleViewModePerxonas = (viewMode: 'list' | 'grid') => { setViewModePerxonas(viewMode) }

          const getDataGroup = React.useCallback(
                    () => {
                              return getCardsGroup(perxonaId)
                    }, [perxonaId])



          const { cards, filterCard } = useFilterAndSort<ICardGroup>({ chosen: cardsChosen, cards: cardsGroup })


          const { order, handleChangeOrder, handleAddChosen, handleRemoveChosen, getOrder } = useOrder({
                    toOrder: cardsChosen.filter(id => id !== null)
          })


          const {
                    handleChangePage: handleChangePageGroups, handleChangeItemsPerPage, handleChangeTotalItems,
                    pageConfig, slice
          } = usePagination({ itemsPerPage: itemsPerPage, totalItems: cards.length })





          React.useEffect(() => {
                    console.count('changeTotalItems')

                    handleChangeTotalItems(cards.length)
          }, [cards.length])


          const changeSelectionMode = React.useCallback((selectionMode: SelectionMode, cardId?: number) => {
                    if (selectionMode === 'reorder') {
                              filterCard('filterAndSort')
                    } else if (selectionMode === 'selection') {
                              filterCard('filterOnly', (card: ICardGroup) => true)
                    } else if (selectionMode === 'none') {
                              filterCard('filterAndSort')
                    } else if (selectionMode === 'perxonas') {
                              filterCard('filterOnly', (card: ICardGroup) => card.cardId === cardId)
                    }
                    setMode(selectionMode)

          }, [filterCard])

          React.useEffect(() => {
                    console.count('consulta')

                    const consult = async () => {
                              await getDataGroup().then(res => res.data as ICardGroup[]).then(data => {
                                        setCardsGroup(data)
                              }).then(() => {
                                        if (isDesign === undefined && cardsChosen.length === 1) {
                                                  handleSelectGroup(cardsChosen[0])
                                        }
                              }).finally(() => {
                                        setLoading(Boolean(false))
                              })
                    }
                    consult()
                    calculateItemsPerxonasView('grid')
          }, [])


          React.useEffect(() => {
                    console.count('reorder')

                    if (selectionAndReorderActive) {
                              changeSelectionMode('selection')
                    } else if (!selectionAndReorderActive) {
                              changeSelectionMode('none')
                              handleChangePageGroups(1)
                    }
                    resetSubGroupNavigation()
          }, [selectionAndReorderActive])

          React.useEffect(() => {

                    setMode('none')
                    filterCard('filterAndSort')

          }, [cardsGroup])



          React.useEffect(() => {
                    console.count('itemsPerPage')

                    handleChangeItemsPerPage(itemsPerPage)
          }, [itemsPerPage])




          const update = React.useCallback((newCardsChosen: number[]) => {
                    const elementUpdate = {
                              ...element,
                              value: {
                                        ...element.value,
                                        cardsChosen: newCardsChosen
                              }
                    }
                    updateElement(elementUpdate)
          }, [element])

          React.useEffect(() => {
                    console.count('mode')

                    if (mode === 'reorder') {
                              filterCard('filterAndSort')
                    }

          }, [element.value.cardsChosen, mode])

          const handleOnCheck = (checked: boolean, cardId: number) => {
                    if (checked) {
                              handleAddChosen(cardId, update)

                    } else {
                              handleRemoveChosen(cardId, update)
                    }
          }

          const handleOnReorder = React.useCallback((move: Move, cardId: number) => {
                    const newOrder = handleChangeOrder(move, cardId)
                    update(newOrder)
          }, [handleChangeOrder, update])



          const getSubGroupsData = React.useCallback((card: ICardGroup): ISubGroup[] => {
                    return card.subGroupsId.map((subGroupId: number): ISubGroup => {
                              const subGroupData = {
                                        subGroupId: subGroupId,
                                        subGroupName: cardsGroup.find(group => group.cardId === subGroupId)?.groupName

                              } as ISubGroup
                              return subGroupData
                    })

          }, [cardsGroup])

          const calculateTotalItemsPerPagePerxonasGrid = () => {
                    if (perxonaView.current !== null && perxonaView.current !== undefined) {
                              const itemsPerPageVertical = Math.floor(perxonaView.current!.clientHeight / 90) !== 0 ?
                                        Math.floor(perxonaView.current!.clientHeight / 90) : 1
                              const itemsPerPageHorizontal = Math.floor(perxonaView.current!.clientWidth / 90) !== 0 ?
                                        Math.floor(perxonaView.current!.clientWidth / 90) : 1
                              const totalItemsPerPage = itemsPerPageVertical * itemsPerPageHorizontal
                              return totalItemsPerPage
                    } else {
                              const { height: heightElement, width: widthElement } = element.size
                              const itemsPerPageVertical = Math.floor((heightElement - 250) / 90) !== 0 ?
                                        Math.floor((heightElement - 250) / 90) : 1
                              const itemsPerPageHorizontal = Math.floor(widthElement / 90) !== 0 ?
                                        Math.floor(widthElement / 90) : 1
                              const totalItemsPerPage = itemsPerPageVertical * itemsPerPageHorizontal
                              return totalItemsPerPage
                    }
          }
          const calculateTotalItemsPerPagePerxonasList = () => {
                    if (perxonaView.current !== null && perxonaView.current !== undefined) {
                              const itemsPerPageVertical = Math.floor(perxonaView.current!.clientHeight / 92) !== 0 ? Math.floor(perxonaView.current!.clientHeight / 92) : 1
                              const itemsPerPageHorizontal = Math.floor(perxonaView.current!.clientWidth / 316) !== 0 ? Math.floor(perxonaView.current!.clientWidth / 316) : 1
                              const totalItemsPerPage = itemsPerPageVertical * itemsPerPageHorizontal
                              return totalItemsPerPage
                    } else {
                              const { height: heightElement, width: widthElement } = element.size
                              const itemsPerPageVertical = Math.floor((heightElement - 250) / 92) !== 0 ?
                                        Math.floor((heightElement - 250) / 82) : 1
                              const itemsPerPageHorizontal = Math.floor(widthElement / 316) !== 0 ?
                                        Math.floor(widthElement / 316) : 1
                              const totalItemsPerPage = itemsPerPageVertical * itemsPerPageHorizontal
                              return totalItemsPerPage
                    }
          }

          const {
                    handleChangePage: handleChangePagePerxonas, handleChangeItemsPerPage: handleChangeItemsPerPagePerxonas,
                    handleChangeTotalItems: handleChangeTotalItemsPerxonas, pageConfig: pageConfigPerxonas, slice: slicePerxonas
          } =
                    usePagination({ itemsPerPage: 1, totalItems: calculateTotalItemsPerPagePerxonasGrid() })


          const getPerxonasGroup = (groupId) => {
                    setLoading(Boolean(true))
                    return getPerxonasByGroup(groupId)
          }

          const handleSelectGroup = React.useCallback(async (cardId: number) => {

                    const perxonasInGroup = perxonasGroup.find(perxonasInf => perxonasInf.groupId === cardId)
                    if (perxonasInGroup === undefined) {
                              getPerxonasGroup(cardId).then(response => response.data as IPerxonaResponse).then(
                                        data => {
                                                  setPerxonasGroup(prev => [...prev, data])
                                                  handleChangeTotalItemsPerxonas(data.perxonas.length)

                                        }
                              ).finally(() => setLoading(Boolean(false)))

                    } else {
                              handleChangeTotalItemsPerxonas(perxonasInGroup!.perxonas.length)
                    }
                    const currentCard = cardsGroup.find(card => card.cardId === cardId)
                    const card = cardsGroup.find(card => card.cardId === currentCard?.parentGroupId)
                    setOriginGroup(card)
                    //                  changeSelectionMode('perxonas', cardId)

          }, [perxonasGroup, cardsGroup])

          const calculateItemsPerxonasView = (viewMode: 'grid' | 'list') => {
                    if (viewMode === 'grid') {
                              handleChangeItemsPerPagePerxonas(calculateTotalItemsPerPagePerxonasGrid())

                    } else {
                              handleChangeItemsPerPagePerxonas(calculateTotalItemsPerPagePerxonasList())

                    }
          }

          React.useEffect(() => {
                    console.count('calculate')

                    if (perxonaView.current !== null && perxonaView.current !== undefined && viewModePerxonas === 'grid') {
                              calculateItemsPerxonasView('grid')
                              handleChangePagePerxonas(1)

                              return
                    }
                    if (viewModePerxonas === 'list') {
                              calculateItemsPerxonasView('list')
                              handleChangePagePerxonas(1)

                              return
                    }
          }, [element.size, perxonasGroup.length, viewModePerxonas])


          const handleChangePage = React.useCallback((page: number) => {
                    if (mode === 'perxonas') {
                              handleChangePagePerxonas(page)
                    } else {
                              handleChangePageGroups(page)
                    }
          }, [mode])


          return (
                    <div style={{ ...style }} >
                              <ContainerCardGroups
                                        cards={mode !== 'perxonas' ? cards.slice(slice.initSlice, slice.endSlice) : cards.slice(0, 1)}
                                        perxonas={mode === 'perxonas' ?
                                                  perxonasGroup.find(perxonasInf => perxonasInf.groupId === cards.find(group => group.cardId === perxonasInf.groupId)?.cardId)
                                                  ?? {} as IPerxonaResponse :
                                                  {} as IPerxonaResponse}
                                        filterCard={filterCard}
                                        loading={loading}
                                        element={element}
                                        isDesign={isDesign ?? false}
                                        selectionAndReorderActive={selectionAndReorderActive}
                                        mode={mode}
                                        changeSelectionMode={changeSelectionMode}
                                        handleSelectGroup={handleSelectGroup}
                                        pageConfig={pageConfig}
                                        handleChangePage={handleChangePage}
                                        paginationPosition={paginationPosition}
                                        handleOnCheck={handleOnCheck}
                                        hookOrder={{ order, handleChangeOrder, handleAddChosen, handleRemoveChosen, getOrder }}
                                        handleOnReorder={handleOnReorder}
                                        getSubGroupsData={getSubGroupsData}
                                        perxonaViewContainerRef={perxonaView}
                                        paginationConfigPerxona={{ pageConfigPerxonas, slice: slicePerxonas }}
                                        originGroup={originGroup}
                                        tittleContainer={tittle}
                                        viewModePerxona={{
                                                  viewMode: viewModePerxonas,
                                                  handleChangeViewMode: handleViewModePerxonas
                                        }}
                                        minimize={minimize ?? true}
                                        cardsChosen={cardsChosen}
                                        calculateItemsPerxonasView={calculateItemsPerxonasView}
                                        subGroupNavigation={{
                                                  handleAddSubGroupNavigation,
                                                  handleRemoveSubGroupNavigation,
                                                  resetSubGroupNavigation,
                                                  subGroupNavigation,
                                                  handleRemovePreviousSubGroupNavigation
                                        }}
                              ></ContainerCardGroups>
                    </div >
          )
}

