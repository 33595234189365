import React from 'react'
import { ResizableDelta, Rnd } from 'react-rnd'
import { IDesignProps, IPosition, ISize } from 'types'
import { ResizeDirection } from 're-resizable'
import { BORDER_RADIUS_UNITS_OPTIONS, ELEMENT_TYPES } from 'lib'
import { ClickAwayListener, Icon } from '@material-ui/core'
import { ConferencePracticeValues } from 'components/Practice/Conference/types'

const classes = {
  page: '.page',
  dragClassName: 'dragClassName',
}

const Element = React.memo((props: IDesignProps) => {
  const { onClick, selected, updateElement, closeOptions, mouseOnBoard, pageSize, pagePosition, headerVisibleMode, element } = props
  const [position, setPosition] = React.useState<IPosition>(props.element.position)
  const [disableDragging, setDisableDragging] = React.useState<boolean>(false)
  const [enableRezising, setEnableRezising] = React.useState<boolean>(true)
  const [size, setSize] = React.useState<ISize>(props.element.size)
  const [float, setFloat] = React.useState(Boolean(props.element.float))
  const [disableRotate, setDisableRotate] = React.useState<boolean>(false)

  const handleDragStop = (e: any, d: IPosition) => {
    let x = d.x
    let y = d.y
    if (d.y <= -size.height && !float) {
      y = 0
    }
    if (d.y <= -(size.height + pagePosition.y) && float) {
      y = -pagePosition.y
    }
    if (d.y >= pageSize.height && !float) {
      y = pageSize.height - size.height
    }
    if (d.x <= -size.width && !float) {
      x = 0
    }
    if (d.x <= -(size.width + pagePosition.x) && float) {
      x = -pagePosition.x
    }
    if (d.x >= pageSize.width && !float) {
      x = pageSize.width - size.width
    }

    const yCondition = pageSize.width < 500 ? 190 : 100
    const xCondition = pageSize.width < 500 ? pageSize.width : 960
    const yRangeCondition = float ? d.y >= 0 && d.y <= yCondition : d.y <= yCondition
    const xRangeCondition = float ? d.x >= 0 && d.x <= xCondition : d.x <= xCondition
    if (yRangeCondition && xRangeCondition && headerVisibleMode) {
      y = yCondition + 10
    }
    updateElement({ ...props.element, position: { x, y } })
  }

  React.useEffect(() => {
    const { element } = props
    const yCondition = pageSize.width < 500 ? 190 : 100
    const xCondition = pageSize.width < 500 ? pageSize.width : 960
    const YRangeCondition = float ? element.position.y >= 0 && element.position.y <= yCondition : element.position.y <= yCondition
    const xRangeCondition = float ? element.position.x >= 0 && element.position.x <= xCondition : element.position.x <= xCondition

    if (YRangeCondition && xRangeCondition && headerVisibleMode && element.type !== ELEMENT_TYPES.header) {
      updateElement({ ...props.element, position: { x: element.position.x, y: yCondition + 10 } })
    }
  }, [headerVisibleMode, props, pageSize])

  const handleResizeStop = (e: any, direction: ResizeDirection, ref: HTMLElement, delta: ResizableDelta, p: IPosition) => {
    const newHeight = parseInt(ref.style.height.replace('px', ''))
    const newWidth = parseInt(ref.style.width.replace('px', ''))

    const isValidSize = newHeight > 0 && newWidth > 0
    if (!isValidSize) return

    updateElement({
      ...props.element,
      size: { width: newWidth, height: newHeight },
      position: { x: p.x, y: p.y },
    })
  }
  const handleDoubleClick = (e: any) => {
    if (props.element.type === ELEMENT_TYPES.text) {
      setDisableDragging(true)
      setEnableRezising(false)
      setDisableRotate(true)
      closeOptions()
    }
    if (props.element.type === ELEMENT_TYPES.curtain) {
      if (props.element.value.text) {
        setDisableDragging(true)
        setEnableRezising(false)
        setDisableRotate(true)
        closeOptions()
      }
    }
  }
  const isSelected = React.useCallback(() => {
    return selected?.id === props.element.id
  }, [props.element.id, selected])
  const getTransforms = React.useCallback(() => {
    if (props.element.style.transform) {
      return JSON.parse(props.element.style.transform)
    }
  }, [props.element.style.transform])
  React.useEffect(() => {
    if (position !== props.element.position) {
      setPosition(props.element.position)
    }
  }, [props.element.position, position])
  React.useEffect(() => {
    if (size !== props.element.size) {
      setSize(props.element.size)
    }
  }, [props.element.size, size])
  React.useEffect(() => {
    if (float !== props.element.float) {
      setFloat(Boolean(props.element.float))
    }
  }, [props.element.float, float])
  React.useEffect(() => {
    if (!selected) {
      setDisableDragging(false)
      setEnableRezising(true)
    }
  }, [selected])

  return (
    <div className={`${props.element.fixed ? 'fixed-element' : ''}`}>
      <Rnd
        position={position}
        size={{
          ...size,
          width: props.element.type === ELEMENT_TYPES.header ? (headerVisibleMode ? '100%' : '0px') : size.width,
          height:
            props.element.type === ELEMENT_TYPES.header
              ? pageSize.width < 500
                ? '180px'
                : '80px'
              : props.element.type === ELEMENT_TYPES.practiceConference
              ? (element.value as ConferencePracticeValues).initMinimized
                ? 'auto'
                : size.height
              : size.height,
        }}
        onDragStop={handleDragStop}
        onResizeStop={handleResizeStop}
        maxWidth={props.element.type === ELEMENT_TYPES.header ? ' 960px' : undefined}
        minWidth={props.element.value !== undefined ? props.element.value.minWidth ?? '0' : '0'}
        minHeight={props.element.value !== undefined ? props.element.value.minHeight ?? '0' : '0'}
        maxHeight={props.element.value !== undefined ? props.element.value.maxHeight ?? undefined : undefined}
        className={`${isSelected() ? 'selected-element' : props.element.float ? 'float-unselected-element' : ''} all-pointer-events`}
        style={
          props.element.type !== ELEMENT_TYPES.image &&
          props.element.type !== ELEMENT_TYPES.page &&
          props.element.type !== ELEMENT_TYPES.text &&
          props.element.type !== ELEMENT_TYPES.curtain &&
          props.element.type !== ELEMENT_TYPES.video &&
          props.element.type !== ELEMENT_TYPES.file &&
          props.element.type !== ELEMENT_TYPES.folder &&
          props.element.type !== ELEMENT_TYPES.login &&
          props.element.type !== ELEMENT_TYPES.practiceCard &&
          props.element.type !== ELEMENT_TYPES.practiceCardPersonality &&
          props.element.type !== ELEMENT_TYPES.practiceCardGroups &&
          props.element.type !== ELEMENT_TYPES.footer &&
          props.element.type !== ELEMENT_TYPES.header &&
          props.element.type !== ELEMENT_TYPES.practiceConference
            ? {
                ...props.element.style,
                borderTopLeftRadius:
                  props.element.style.borderTopLeftRadius +
                  `${props.element.borderRadiusUnit ? props.element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}`,
                borderBottomLeftRadius:
                  props.element.style.borderBottomLeftRadius +
                  `${props.element.borderRadiusUnit ? props.element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}`,
                borderTopRightRadius:
                  props.element.style.borderTopRightRadius +
                  `${props.element.borderRadiusUnit ? props.element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}`,
                borderBottomRightRadius:
                  props.element.style.borderBottomRightRadius +
                  `${props.element.borderRadiusUnit ? props.element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}`,
              }
            : { cursor: isSelected() ? 'pointer' : 'default' }
        }
        onDoubleClick={handleDoubleClick}
        disableDragging={props.element.type !== ELEMENT_TYPES.footer && props.element.type !== ELEMENT_TYPES.header ? disableDragging : true}
        dragHandleClassName={isSelected() ? undefined : classes.dragClassName}
        enableResizing={props.element.type !== ELEMENT_TYPES.footer && props.element.type !== ELEMENT_TYPES.header ? enableRezising && isSelected() : false}
        resizeHandleClasses={{
          left: 'flex items-center justify-center',
          top: 'flex items-center justify-center',
          bottom: 'flex items-center justify-center',
          right: 'flex items-center justify-center',
          bottomLeft: 'flex items-center justify-center',
          bottomRight: 'flex items-center justify-center',
          topLeft: 'flex items-center justify-center',
          topRight: 'flex items-center justify-center',
        }}
        resizeHandleComponent={
          isSelected()
            ? {
                topLeft: (
                  <Icon color="primary" style={{ fontSize: '0.55rem' }}>
                    stop
                  </Icon>
                ),
                topRight: (
                  <Icon color="primary" style={{ fontSize: '0.55rem' }}>
                    stop
                  </Icon>
                ),
                bottomLeft: (
                  <Icon color="primary" style={{ fontSize: '0.55rem' }}>
                    stop
                  </Icon>
                ),
                bottomRight: (
                  <Icon color="primary" style={{ fontSize: '0.55rem' }}>
                    stop
                  </Icon>
                ),
                left: (
                  <Icon color="primary" style={{ fontSize: '0.55rem' }}>
                    stop
                  </Icon>
                ),
                top: (
                  <Icon color="primary" style={{ fontSize: '0.55rem' }}>
                    stop
                  </Icon>
                ),
                bottom: (
                  <Icon color="primary" style={{ fontSize: '0.55rem' }}>
                    stop
                  </Icon>
                ),
                right: (
                  <Icon color="primary" style={{ fontSize: '0.55rem' }}>
                    stop
                  </Icon>
                ),
              }
            : undefined
        }
      >
        <ClickAwayListener onClickAway={() => setDisableRotate(false)}>
          <div
            className={`${classes.dragClassName} cursor-pointer ${
              !mouseOnBoard && !isSelected() ? 'unselected-element' : props.element.float ? 'float-unselected-element' : ''
            } all-pointer-events`}
            onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => onClick(event, props.element)}
            style={
              props.element.type !== ELEMENT_TYPES.image &&
              props.element.type !== ELEMENT_TYPES.page &&
              props.element.type !== ELEMENT_TYPES.audio &&
              props.element.type !== ELEMENT_TYPES.video &&
              props.element.type !== ELEMENT_TYPES.login &&
              props.element.type !== ELEMENT_TYPES.practiceCard &&
              props.element.type !== ELEMENT_TYPES.practiceCardPersonality &&
              props.element.type !== ELEMENT_TYPES.practiceCardGroups &&
              props.element.type !== ELEMENT_TYPES.footer &&
              props.element.type !== ELEMENT_TYPES.header &&
              props.element.type !== ELEMENT_TYPES.practiceConference
                ? {
                    ...props.element.style,
                    width: '100%',
                    height: '100%',
                    borderTopLeftRadius:
                      props.element.style.borderTopLeftRadius +
                      `${props.element.borderRadiusUnit ? props.element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}`,
                    borderBottomLeftRadius:
                      props.element.style.borderBottomLeftRadius +
                      `${props.element.borderRadiusUnit ? props.element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}`,
                    borderTopRightRadius:
                      props.element.style.borderTopRightRadius +
                      `${props.element.borderRadiusUnit ? props.element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}`,
                    borderBottomRightRadius:
                      props.element.style.borderBottomRightRadius +
                      `${props.element.borderRadiusUnit ? props.element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}`,
                    transform: disableRotate ? 'none' : `rotate(${getTransforms()[0]}deg) scaleX(${getTransforms()[1]})`,
                  }
                : {
                    width: '100%',
                    height: '100%',
                    transform: disableRotate ? 'none' : `rotate(${getTransforms()[0]}deg) scaleX(${getTransforms()[1]})`,
                  }
            }
          >
            {props.children}
          </div>
        </ClickAwayListener>
      </Rnd>
    </div>
  )
})

export default Element
