import { getMessagesService } from 'lib'
import React from 'react'
import { IMessage, IPageConfig, IPaginationResponse } from 'types'
import { usePagination } from './usePagination'

interface Hook {
  messages: IMessage[]
  handlePageChange: (conferenceId: number, page: number, conferenceThemeId?: number) => void
  paginationResponse: IPaginationResponse<IMessage>
  loading: boolean
  getMessages: (conferenceId: number | string, page: number, conferenceThemeId?: number, limit?: number) => Promise<void>
  resetMessages: () => void
}

export const useMessagesHelper = (): Hook => {
  const [paginationResponse, setPaginationResponse] = React.useState<IPaginationResponse<IMessage>>({
    count: 0,
    limit: 32,
    page: 1,
    result: [],
  } as IPaginationResponse<IMessage>)
  const [messages, setMessages] = React.useState<IMessage[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)

  const resetMessages = () => {
    setMessages([])
    setPaginationResponse({
      count: 0,
      limit: 32,
      page: 1,
      result: [],
    } as IPaginationResponse<IMessage>)
  }

  const getMessages = async (conferenceId: number | string, page: number, conferenceThemeId?: number, limit?: number) => {
    try {
      setLoading(true)
      const res = await getMessagesService(conferenceId, page, conferenceThemeId, limit)
      setPaginationResponse(res)
      const clearRepeatMessage = (messages: IMessage[]) =>
        res.result.filter((message) => !messages.some((i) => i.conferenceDetailId === message.conferenceDetailId))
      setMessages((prev) => [...prev, ...clearRepeatMessage(prev)])
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  const handlePageChange = (conferenceId: number, page: number, conferenceThemeId?: number) => {
    getMessages(conferenceId, page, conferenceThemeId)
  }

  return {
    handlePageChange,
    getMessages,
    loading,
    messages,
    paginationResponse,
    resetMessages,
  }
}
