import { IConferenxia, IConferenceTheme } from 'types'
import React from 'react'
import { useConferenceProvider } from './useConferenceProvider'
type Hook = {
  getThemes: (conference: IConferenxia, generalTheme?: boolean) => void
  onSelectTheme: (theme?: IConferenceTheme) => void
  conferenceTheme: IConferenceTheme[]
  selectedTheme?: IConferenceTheme
  resetConferenceTheme: () => void
}
type SelectedTheme = IConferenceTheme | undefined
export const useConferenceThemeHelper = (): Hook => {
  const [conferenceTheme, setConferenceTheme] = React.useState<IConferenceTheme[]>([])
  const [selectedTheme, setSelectedTheme] = React.useState<SelectedTheme>(undefined)
  const { getMessages, resetMessages } = useConferenceProvider()

  const resetConferenceTheme = () => {
    setConferenceTheme([])
    setSelectedTheme(undefined)
  }
  const getThemes = (conference: IConferenxia, generalTheme?: boolean) => {
    const theme = generalTheme ? conference.conferenceThemeList : conference.conferenceThemeList.filter((theme) => theme.themeType !== 'G')
    setConferenceTheme(theme)
    resetMessages()
    getMessages(conference.conferenceId, 1)
  }

  const onSelectTheme = (theme?: IConferenceTheme) => {
    setSelectedTheme(theme)
  }

  return {
    getThemes,
    onSelectTheme,
    conferenceTheme,
    selectedTheme,
    resetConferenceTheme,
  }
}
