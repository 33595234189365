import React from "react"

const useInterval = (callback: any, delay: number) => {
    const savedCallback = React.useRef<any>();

    React.useEffect(() => {
        savedCallback.current = callback
    })

    React.useEffect(() => {
        function tick() {
            savedCallback?.current()
        }
        let id = setInterval(tick, delay)
        return () => clearInterval(id)
    }, [delay])
}

export default useInterval