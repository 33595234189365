import { Box, Button, Grid, Link, makeStyles, Typography, Popper, Paper, Snackbar, Modal } from "@material-ui/core"
import { Alert, Pagination } from "@material-ui/lab";
import { ICardPerxona, CardViewMode, CardViewMinimizeProps, EditionCardMode } from "components/CardProps/CardProps";

import CardPortraitPerxona from "./CardPortraitPerxona";
import React from 'react'
import { ISize } from "types/Physics";
import { IElement } from "types";
import { stylesButtonPerxona } from "lib/constants/STYLES";
import { CardPaginationProps } from "./PracticePerxona";
import CardHorizontalPerxona from "./CardHorizontalPerxona";




const useStyles = makeStyles({

    buttonEmergencyInactive: {
        '&:hover': {
            backgroundColor: 'red',
            color: '#fff',
        },
    },
    buttonEmergencyActive: {
        backgroundColor: 'red',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#bdbdbd',

        },

    },
    buttonEmergencyPermanent: {
        backgroundColor: 'red',
        color: '#fff',
        '&:hover': {
            backgroundColor: 'red',

        },
    },

    minimizeButton: {
        color: 'black',
        '&:hover': {
            cursor: 'zoom-out',
        }
    },
    maximizeButton: {
        color: 'black',
        '&:hover': {
            cursor: 'zoom-in',
        }
    }


})



interface IEditModeHandle {
    editMode: EditionCardMode,
    setEditMode: React.Dispatch<React.SetStateAction<EditionCardMode>>
}
interface EmergenxiaButton {
    enable: boolean;
    permanentActive: boolean;
    appliedFilter: boolean;
    handleFilterEmergenxia: () => void;
}

interface RequestFriendShipButton {
    canRequestFriendShip: boolean;
    handleRequestFriendShip: () => Promise<boolean>
}
interface ViewModes {
    cardViewMode: CardViewMode;
    cardViewMinimizeProps: CardViewMinimizeProps;
}
interface CardViewProps {
    tittle: string;
    design: boolean;
    cards: ICardPerxona[];
    cardPaginationProps: CardPaginationProps;
    viewModes: ViewModes;
    size: ISize;
    selectionAndReorder: boolean;
    cardsChosen: number[];
    element: IElement;
    handleEditMode: IEditModeHandle;
    requestFriendShipButton: RequestFriendShipButton;
    emergenxiaButtonProps: EmergenxiaButton;
}

const Card = ({ cards, numberOfCardsForPage, cardViewMode, page, editMode, cardsChosen, element }:
    {
        cards: ICardPerxona[], numberOfCardsForPage: number, cardViewMode: CardViewMode,
        page: number, editMode: EditionCardMode, cardsChosen: number[], element: IElement
    }) => {

    return (
        <React.Fragment>
            {cards.filter((currentCard, index) => {
                if (page === 1) {
                    const totalCards = cards.length;
                    const finalPosition = numberOfCardsForPage < totalCards ? numberOfCardsForPage : totalCards;
                    return index < finalPosition
                } else {
                    return true
                }
            }).map((currentCard, index) => {
                return (
                    <React.Fragment key={index}>
                        {cardViewMode !== "vertical" ?
                            (
                                <Grid item     >
                                    <CardHorizontalPerxona card={currentCard} editMode={editMode} cardsChosen={cardsChosen} element={element} />
                                </Grid>
                            ) : (
                                <Grid
                                    item   >
                                    <CardPortraitPerxona card={currentCard} editMode={editMode} cardsChosen={cardsChosen} element={element} />
                                </Grid>
                            )}
                    </React.Fragment>
                )

            })}
        </React.Fragment>
    )


}

export const ContainerCardsPerxona = (props: CardViewProps) => {
    const styles = stylesButtonPerxona
    const { cards, cardPaginationProps, viewModes, tittle,
        design, size, selectionAndReorder, cardsChosen: cardsIdChosen, element, handleEditMode,
        emergenxiaButtonProps, requestFriendShipButton } = props;

    const { canRequestFriendShip, handleRequestFriendShip } = requestFriendShipButton;

    const { cardViewMode, cardViewMinimizeProps } = viewModes

    const { enable: enableEmergenxiaButton, permanentActive, appliedFilter: appliedFilterEmergency, handleFilterEmergenxia } = emergenxiaButtonProps

    const { editMode, setEditMode } = handleEditMode

    const { minimize, enableMinimize } = cardViewMinimizeProps;

    const { totalPages, page, numberOfCardsForPage, handlePageChange } = cardPaginationProps;

    const { paginationPosition } = element.value

    const [minimizeCard, setMinimizeCard] = React.useState<boolean>(design ? false : minimize || enableMinimize);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [message, setMessage] = React.useState<string>("")
    const [severity, setSeverity] = React.useState<'success' | 'info' | 'warning' | 'error'>('success')

    const classes = useStyles()
    const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;


    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleSendFriendShip = async (event: React.MouseEvent<HTMLElement>) => {
        if (design) {
            setMessage("No se puede enviar solicitud de amistad en diseño")
            setSeverity("warning")
            setOpenAlert(true)

        } else {

            const result = await handleRequestFriendShip()
            if (result) {
                setMessage("Solicitud enviada")
                setSeverity("success")
                setOpenAlert(true)

            } else {
                setMessage("Error al enviar la solicitud")
                setSeverity("error")
                setOpenAlert(true)

            }
        }
        handleClick(event)
    }



    return (
        <React.Fragment>
            <Grid
                container
                spacing={0}
                style={minimizeCard ? { height: "fit-content" } : size}
                direction="column"
                wrap="nowrap"

            >
                <Grid item xs={12} style={{
                    textAlign: ((numberOfCardsForPage === 1 && cardViewMode !== "horizontal") || (tittle.length > 25 && cardViewMode === "vertical")) ? 'center' : 'start', display: 'flex',
                    flexDirection: ((numberOfCardsForPage === 1 && cardViewMode !== "horizontal") || (tittle.length > 25 && cardViewMode === "vertical")) ? "column" : "row", flexBasis: "0%",
                }}>

                    <Typography variant="h5" component="h2" style={{ marginBottom: 4, flexGrow: 3, marginTop: 8, marginLeft: 8 }}
                        className={minimizeCard ? classes.maximizeButton : classes.minimizeButton}
                        onClick={(e) => {
                            e.preventDefault()
                            setMinimizeCard(prev => !prev)
                        }}
                    >
                        <Link
                            className={minimizeCard ? classes.maximizeButton : classes.minimizeButton}
                            style={{ textDecoration: "none" }}
                        >
                            {tittle !== undefined ? tittle.length > 0 ? tittle : minimizeCard ? 'Mis Tarjetas' : design ? 'Mis Tarjetas' : 'Mis Tarjetas' : 'Mis Tarjetas'}
                        </Link>
                    </Typography>
                    {
                        (!minimizeCard) && (
                            <Box  >
                                {selectionAndReorder ?
                                    <Box style={{ display: 'flex', marginTop: 8, marginRight: 8, justifyContent: "center" }}>
                                        <Button variant="contained" style={{ ...styles.buttonPerxona, ...{ marginRight: "2%" } }}
                                            color={editMode === "selection" ? "primary" : "default"}
                                            onClick={() => setEditMode("selection")}>
                                            Seleccionar
                                        </Button>
                                        <Button variant="contained" style={{ ...styles.buttonPerxona, ...{ marginRight: "2%" } }}
                                            color={editMode === "reorder" ? "primary" : "default"}
                                            onClick={() => setEditMode("reorder")}>
                                            Ordenar
                                        </Button>
                                    </Box>
                                    : <Box style={{ display: 'flex', marginTop: 8, marginRight: 8, justifyContent: "center" }}>
                                        {canRequestFriendShip && <Button variant="contained" style={{ ...styles.buttonPerxona, ...{ marginRight: "2%" } }} color="primary"
                                            onClick={handleClick}
                                        >
                                            Amistad
                                        </Button>}
                                        {enableEmergenxiaButton &&
                                            <Button variant="contained" style={styles.buttonPerxona}
                                                className={!appliedFilterEmergency ?
                                                    permanentActive ? classes.buttonEmergencyPermanent : classes.buttonEmergencyInactive : classes.buttonEmergencyActive}
                                                onClick={() => { !permanentActive && handleFilterEmergenxia() }} >
                                                {!appliedFilterEmergency ? "Emergenxia" : permanentActive ? "Emergenxia" : "Regresar"}
                                            </Button>}
                                    </Box>}

                            </Box>
                        )}
                </Grid>
                {!minimizeCard && <Box display="flex" style={{ justifyContent: 'center', flexBasis: "0%", marginBottom: 4 }}>
                    {(totalPages > 1 && (paginationPosition === "top" || paginationPosition === "both")) &&
                        <Pagination count={totalPages} color="primary" onChange={handlePageChange} page={page} style={{ marginTop: 8, alignSelf: "center" }}
                            showFirstButton showLastButton />}
                </Box>}
                {(cardViewMode === "horizontal" && !minimizeCard) &&
                    <React.Fragment   >
                        <Grid item xs={12} style={{ display: "flex" }}>

                            <Grid container spacing={3} style={{ display: "flex", flexWrap: "nowrap", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>


                                {<Card cards={cards} numberOfCardsForPage={numberOfCardsForPage}
                                    cardViewMode={cardViewMode} page={page} editMode={editMode}
                                    cardsChosen={cardsIdChosen} element={element} />}
                            </Grid>
                        </Grid>

                    </React.Fragment>
                }
                {(cardViewMode === "vertical" && !minimizeCard) &&
                    <Grid item xs={12} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", justifyContent: "center", alignItems: "center" }}>
                        <Grid container spacing={3} style={{ flexWrap: "nowrap", justifyContent: "center" }} >
                            {<Card cards={cards} numberOfCardsForPage={numberOfCardsForPage} cardViewMode={cardViewMode} element={element}
                                page={page} editMode={editMode} cardsChosen={cardsIdChosen} />}
                        </Grid>
                    </Grid>}
                {!minimizeCard && (
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "flex-end", paddingBottom: 10, flexBasis: 0 }}>
                        {(totalPages > 1 && (paginationPosition === "bottom" || paginationPosition === "both")) &&
                            <Pagination count={totalPages} color="primary" onChange={handlePageChange} page={page} showFirstButton showLastButton />}
                    </Grid>)}
            </Grid>

            <Modal id={id} open={open}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}

            >
                <Paper elevation={5}  >
                    <Box style={{ paddingTop: 10, paddingBottom: 10, marginLeft: 10, marginRight: 10 }}>
                        <Typography variant="subtitle1">
                            ¿Solicitar amistad?
                        </Typography>
                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Button variant="contained" style={styles.buttonPerxona} color="primary" onClick={handleSendFriendShip}>
                                Si
                            </Button>
                            <Button variant="contained" style={styles.buttonPerxona} onClick={handleClick}>
                                No
                            </Button>
                        </Box>
                    </Box>
                </Paper>

            </Modal>

            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </React.Fragment >
    )
}