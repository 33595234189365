import { Box, Typography } from '@material-ui/core'
import { ConfigContext, LocationContext } from 'context'
import React from 'react'
import { IConferenceTheme, IMessage } from 'types'
import { MessageConferenxia } from './MessageConferenxia'
// import InfiniteScroll from 'react-infinite-scroll-component'
import { useConferenceProvider } from '../hook/useConferenceProvider'
import { InfiniteScroll } from './InfinityScroll'
import { ScrollWrapperMessage } from './ScrollWrapperMessage'
import { useMessageConfigHelper } from '../hook/useMessageConfigHelper'

type Props = {
  loadMode: 'D' | 'A'
  onlyExistOneTheme: boolean
  height: number
  conferenceId: number
  selectedTheme: IConferenceTheme | undefined
  hiddenDate?: boolean
  hiddenName?: boolean
  refresh: boolean
  onEndRefresh: () => void
}
export const MessageScrollView = (props: Props) => {
  const { height, conferenceId, loadMode, onlyExistOneTheme, selectedTheme, hiddenDate, hiddenName, refresh } = props
  const { messages } = useConferenceProvider()
  const { userId: viewerId } = React.useContext(LocationContext)

  const { getAlign, getTagDate, isSameDate } = useMessageConfigHelper()

  return (
    <Box
      style={{
        height: '100%',
        minHeight: '100%',
      }}
    >
      <InfiniteScroll
        refresh={refresh}
        reverse={loadMode === 'A'}
        conferenceId={conferenceId}
        selectedTheme={selectedTheme}
        onEndRefresh={props.onEndRefresh}
        listItems={messages.map((message, index) => {
          return (
            <ScrollWrapperMessage
              children={
                <Box
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: getAlign(message, viewerId),
                    flexDirection: loadMode === 'D' ? 'column-reverse' : 'column',
                  }}
                  px={1}
                >
                  {index === messages.length - 1 && messages && loadMode === 'A' && !hiddenDate && (
                    <Box
                      width={'100%'}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'sticky',
                        top: 0,
                        zIndex: 99,
                      }}
                    >
                      <Typography
                        variant={'caption'}
                        color={'textSecondary'}
                        id={'tag-date-last-message-load-mode-Asc'}
                        style={{
                          paddingTop: 0.5,
                        }}
                      >
                        {getTagDate(message.date)}
                      </Typography>
                    </Box>
                  )}
                  <Box
                    style={{
                      width: '100%',
                      minWidth: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: getAlign(message, viewerId),
                    }}
                  >
                    <Box
                      style={{
                        minWidth: 200,
                        width: 'auto',
                        maxWidth: '80%',
                      }}
                    >
                      <MessageConferenxia message={message} onlyExistOneTheme={false} idUser={viewerId} hiddenDate={hiddenDate} hiddenName={hiddenName} />
                    </Box>
                  </Box>

                  {loadMode === 'A' && (
                    <React.Fragment>
                      {index !== 0 &&
                        index !== messages.length - 1 &&
                        message &&
                        messages[index - 1] &&
                        !isSameDate(messages[index - 1].date, message.date) &&
                        !hiddenDate && (
                          <Box
                            width={'100%'}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'sticky',
                              top: 0,
                              zIndex: 99,
                            }}
                          >
                            <Typography
                              variant={'caption'}
                              color={'textSecondary'}
                              style={{
                                paddingTop: 1,
                              }}
                              id={'tag-date-dia-diferente-load-mode-Asc'}
                            >
                              {getTagDate(messages[index - 1].date)}
                            </Typography>
                          </Box>
                        )}
                      {index === messages.length - 1 &&
                        messages[index - 1] &&
                        message &&
                        !isSameDate(messages[index - 1].date, message.date) &&
                        !hiddenDate && (
                          <Box
                            width={'100%'}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'sticky',
                              top: 0,
                              zIndex: 99,
                            }}
                          >
                            <Typography
                              variant={'caption'}
                              color={'textSecondary'}
                              style={{
                                paddingTop: 1,
                              }}
                              id={'tag-date-last-message-load-mode-Asc'}
                            >
                              {getTagDate(messages[index - 1].date)}
                            </Typography>
                          </Box>
                        )}
                    </React.Fragment>
                  )}

                  {loadMode === 'D' && (
                    <React.Fragment>
                      {index !== 0 &&
                        index !== messages.length - 1 &&
                        messages[index - 1] !== undefined &&
                        message !== undefined &&
                        !isSameDate(messages[index - 1].date, message.date) &&
                        !hiddenDate && (
                          <Box
                            width={'100%'}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'sticky',
                              top: 0,
                              zIndex: 99,
                            }}
                          >
                            <Typography variant={'caption'} color={'textSecondary'} id={'tag-date-dia-diferente-load-mode-desc'}>
                              {getTagDate(messages[index].date)}
                            </Typography>
                          </Box>
                        )}
                      {index === messages.length - 1 &&
                        messages[index - 1] &&
                        messages &&
                        !isSameDate(messages[index - 1].date, message.date) &&
                        !hiddenDate && (
                          <Box
                            width={'100%'}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'sticky',
                              top: 0,
                              zIndex: 99,
                            }}
                          >
                            <Typography variant={'caption'} color={'textSecondary'} id={'tag-date-last-message-load-mode-desc'}>
                              {getTagDate(message.date)}
                            </Typography>
                          </Box>
                        )}
                    </React.Fragment>
                  )}

                  {index === 0 && (
                    <Box
                      width={'100%'}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        style={{
                          textAlign: 'center',
                          marginTop: 4,
                          marginBottom: 4,
                          paddingTop: 0.5,
                          paddingBottom: 0.5,
                          fontSize: 12,
                        }}
                      >
                        Ultimo menxaje!
                      </Typography>

                      {loadMode === 'D' && message && !hiddenDate && (
                        <Typography variant={'caption'} color={'textSecondary'} id={'tag-date-loadMode-descendent'}>
                          {getTagDate(message.date)}
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
              }
            />
          )
        })}
      />
    </Box>
  )
}
