export const BORDER_STYLE_OPTIONS = [
    {
        value: 'dashed',
        label: 'Línea cortada',
    },
    {
        value: 'dotted',
        label: 'Línea punteada',
    },
    {
        value: 'solid',
        label: 'Sólido',
    },
    {
        value: 'none',
        label: 'Sin borde',
    },
]

export const BORDER_RADIUS_UNITS_OPTIONS = [
    {
        value: '%',
        label: 'Porcentaje',
    },
    {
        value: 'px',
        label: 'Pixeles',
    }
]

export const BACKGROUND_REPEAT_OPTIONS = [
    {
        value: 'no-repeat',
        label: 'Sin repetir',
    },
    {
        value: 'repeat',
        label: 'Repetir',
    },
    {
        value: 'space',
        label: 'Espaciado',
    },
    {
        value: 'repeat-x',
        label: 'Repetir horizontal',
    },
    {
        value: 'repeat-y',
        label: 'Repetir vertical',
    },
]

export const BACKGROUND_SIZE_OPTIONS = [
    {
        value: 'auto',
        label: 'Original',
    },
    {
        value: 'cover',
        label: 'Expandir',
    },
    {
        value: 'contain',
        label: 'Contener',
    },
]

export const BACKGROUND_POSITION_OPTIONS = [
    "left top",
    "center top",
    "right top",
    "left center",
    "center center",
    "right center",
    "left bottom",
    "center bottom",
    "right bottom",
]

export const TEXT_VERTICAL_ALIGN_OPTIONS = [
    {
        value: 'flex-start',
        label: 'Arriba',
    },
    {
        value: 'center',
        label: 'Centro',
    },
    {
        value: 'flex-end',
        label: 'Abajo',
    }
]

export const DEFAULT_TEXT_STYLE: React.CSSProperties = { textAlign: 'left', height: 'auto', backgroundColor: "rgba(255, 255, 255, 0)", borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, filter: 'none', transform: '[0, 1]', borderWidth: 0, borderStyle: 'solid', borderColor: "rgba(0, 0, 0, 1)", opacity: 1 }
export const DEFAULT_VIDEO_STYLE: React.CSSProperties = { backgroundColor: "rgba(255, 255, 255, 0)", borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, filter: 'none', transform: '[0, 1]', borderWidth: 0, borderStyle: 'solid', borderColor: "rgba(0, 0, 0, 1)" }
export const DEFAULT_PAGE_STYLE: React.CSSProperties = { backgroundPosition: 'center center', backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundColor: "rgba(255, 255, 255, 0)", borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, filter: 'none', transform: '[0, 1]', borderWidth: 0, borderStyle: 'solid', borderColor: "rgba(0, 0, 0, 1)" }
export const DEFAULT_LINE_STYLE: React.CSSProperties = { backgroundColor: "rgba(255, 255, 255, 0)", borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, filter: 'none', transform: '[0, 1]', borderWidth: 0, borderStyle: 'solid', borderColor: "rgba(0, 0, 0, 1)" }
export const DEFAULT_IMAGE_STYLE: React.CSSProperties = { backgroundPosition: 'center center', backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundColor: "rgba(255, 255, 255, 0)", borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, filter: 'none', transform: '[0, 1]', borderWidth: 0, borderStyle: 'solid', borderColor: "rgba(0, 0, 0, 1)" }
export const DEFAULT_FILE_STYLE: React.CSSProperties = { backgroundPosition: 'center center', backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundColor: "rgba(255, 255, 255, 0)", borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, filter: 'brightness(100%) grayscale(0%) blur(0px) opacity(100%) sepia(0%) saturate(100%)', transform: '[0, 1]', borderWidth: 0, borderStyle: 'solid', borderColor: "rgba(0, 0, 0, 1)" }
export const DEFAULT_CURTAIN_STYLE: React.CSSProperties = { textAlign: "left", backgroundColor: "rgba(255, 255, 255, 0.5)", borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, filter: 'none', transform: '[0, 1]', borderWidth: 0, borderStyle: 'solid', borderColor: "rgba(0, 0, 0, 1)" }
export const DEFAULT_AUDIO_STYLE: React.CSSProperties = { backgroundColor: "rgba(255, 255, 255, 0)", borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, filter: 'none', transform: '[0, 1]', borderWidth: 0, borderStyle: 'solid', borderColor: "rgba(0, 0, 0, 1)" }
export const DEFAULT_FLOAT_FOLDER_STYLE: React.CSSProperties = { backgroundPosition: 'center center', backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundColor: "rgba(255, 255, 255, 0)", borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, filter: 'none', transform: '[0, 1]', borderWidth: 0, borderStyle: 'solid', borderColor: "rgba(0, 0, 0, 1)" }

export const DEFAULT_LOGIN_STYLE: React.CSSProperties = { backgroundPosition: 'center center', backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundColor: "white", borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, filter: 'none', transform: '[0, 1]', borderWidth: 0, borderStyle: 'solid', borderColor: "rgba(0, 0, 0, 1)" }

export const DEFAULT_PRACTICE_CARD_STYLE: React.CSSProperties = { backgroundPosition: 'center center', backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundColor: "white", borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, filter: 'none', transform: '[0, 1]', borderWidth: 0, borderStyle: 'solid', borderColor: "rgba(0, 0, 0, 1)" }
export const DEFAULT_PRACTICE_CONFERENCE: React.CSSProperties = { backgroundPosition: 'center center', backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundColor: "white", borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, filter: 'none', transform: '[0, 1]', borderWidth: 0, borderStyle: 'solid', borderColor: "rgba(0, 0, 0, 1)" }



export const CARD_PERXONA_HORIZONTAL = {
    main: {
        display: "flex",
        backgroundColor: "blue",
        height: "280px",
        width: "560px",
        borderTopRightRadius: "50px",
        borderBottomLeftRadius: "50px",
        boxShadow: "rgb(0 0 0 / 15%) 10px 10px 5px -5px",
    },
    image: {
        width: "100%", height: "100%",
        borderBottomLeftRadius: "50px",
    },
    informationContainer: {
        flex: "50%",
        position: "relative"
    }, informationCard: {
        right: "5%",
        top: " 14px",
        backgroundColor: " red",
        width: "100%",
        borderTopRightRadius: "50px",
        borderBottomLeftRadius: "50px",
        boxShadow: "rgb(0 0 0 / 15%) 10px 10px 5px -5px",
    }, perxonaInformationContainer: {
        display: "flex",
        alignItems: "center",
        alignContent: "space-around",

        justifyContent: "center",
    }, perxonaName: {
        color: "black",
        fontFamily: " Montserrat, sans-serif",
        fontSize: "16px",
        width: "335px",

        marginTop: "10px",
        marginBottom: "1px",
    }, perxonaFullName: {
        marginBottom: "1px",
        marginTop: "1px",
        color: "black",
        fontFamily: "Montserrat, sans-serif",
        fontSize: "13px",


    }, perxonaNacionality: {
        marginBottom: "1px",
        marginTop: "1px",
        color: "black",
        fontFamily: "Montserrat, sans - serif",
        fontSize: "12px",
        width: "335px",

    }, perxonaAditionalInfo: {
        marginBottom: "1px",
        marginTop: "1px",
        color: "black",
        fontFamily: "Montserrat, sans-serif",
        fontSize: "10px",
        width: "335px",
    }, perxonaDescription: {
        paddingLeft: "15%",
        height: "96px",
        width: "95%",
        background: "transparent",
        overflow: "auto",
        fontSize: "13px",

    }, certificatorContainer: {
        bottom: "30px",
        width: "35%",
        height: "20%",
    }, imageCertificator: {
        top: "20%",
        left: "5px",
        borderTopRightRadius: "20px",
        borderBottomLeftRadius: "20px",
        maxWidth: "70%",
    }, certificatorTittle: {
        marginBottom: "2px",
        marginTop: "2px",
        fontFamily: "Montserrat, sans-serif",
        fontSize: "13px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        color: " #000000",

        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "small-caps",
    }, certificatorName: {
        marginTop: "0px",
        fontFamily: "Montserrat, sans-serif",
        fontSize: "18px",
        letterSpacing: " 0px",
        wordSpacing: "0px",
        color: " #000000",

        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "small-caps",
    }
}

export const CARD_PERXONA_PORTRAIT = {
    main: {
        display: "flex",
        backgroundColor: "blue",
        borderBottomLeftRadius: "30px",
        borderTopRightRadius: "30px",
        width: "220px",
        height: "440px",
        boxShadow: "rgb(0 0 0 / 15%) 10px 10px 5px -5px",
    },
    imagePerxona: {
        width: "100%",
        height: "100%",
        borderTopRightRadius: "30px"
    },
    informationContainer: {
        marginTop: "-10px",
        width: "87%",
        marginLeft: "6%",
        paddingBottom: " 35px",
        paddingLeft: " 10px",
        paddingTop: " 10px",
        paddingRight: " 10px",
        backgroundColor: "red",
        borderTopRightRadius: " 30px",
        borderBottomLeftRadius: " 30px",
        boxShadow: "rgb(0 0 0 / 15%) 10px 10px 5px -5px",


    },
    perxonaInformationContainer: {
        height: "70%"
    },
    perxonaName: {
        color: "black",
        fontFamily: "Montserrat, sans-serif",
        fontSize: "13px",
        width: "80%",

        marginBottom: "1px",
    },
    perxonaFullName: {

        marginTop: "-3.5%",
        color: "black",
        fontFamily: "Montserrat, sans-serif",
        fontSize: "10px",
        width: "335px",

    }
    , perxonaNacionality: {
        marginBottom: "-3%",
        color: "#0288d1",
        fontFamily: "Montserrat, sans-serif",
        fontSize: "11px",
        width: "335px",

    },
    perxonaAditionalInfo: {
        marginBottom: "-0%",
        color: "#0288d1",
        fontFamily: "Montserrat, sans-serif",
        fontSize: "11px",
        width: "335px",

    },
    perxonaDescription: {
        paddingLeft: "5%",
        height: "72%",
        width: "97%",
        background: "transparent",
        overflow: "auto",
        fontSize: "10px",
    },
    imageCertificator: {

        top: "20%",
        left: "15px",
        borderTopRightRadius: "35%",
        borderBottomLeftRadius: "35%",

    }, certificatorTittle: {

        marginBottom: "2px",
        fontFamily: "Montserrat, sans- serif",
        fontSize: "13px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        color: "#000000",
        fontStyle: "bold",
        fontVariant: "small - caps",



    }, certificatorName: {
        marginTop: "0px",
        fontFamily: "Montserrat, sans- serif",
        fontSize: "11px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        color: "#000000",

        fontStyle: "normal",
        fontVariant: "small - caps",
    }, certificatorContainer: {
        width: "35%",
        height: "20%",
    }

}

export const stylesButtonPerxona = {
    buttonPerxona: {
        borderTopRightRadius: "15px", borderBottomLeftRadius: "15px",
        borderTopLeftRadius: "0px", borderBottomRightRadius: "0px",
        width: "125px", height: "30px",
    }
} as { [key: string]: React.CSSProperties }