import { ICardPersonality } from 'components/CardProps/CardProps'
import { LocationContext } from 'context'
import { useCard, useOrder, usePagination } from 'hooks'
import React from 'react'
import { IElement } from 'types/Element'
import { ContainerCardsPersonality } from './ContainerCardsPersonality'



type Props = {

  selectionAndReorderActive: boolean;
  element: IElement;
  isDesign?: boolean;

}
type SelectionMode = 'selection' | 'reorder' | 'none'


export const PracticePersonality = (props: Props) => {
  const { element, selectionAndReorderActive, isDesign } = props
  const { value, style } = element
  const { cardsChosen, cardPaginationProps, paginationPosition, cardViewMinimizeProps } = value
  const { itemsPerPage } = cardPaginationProps
  const { minimize } = cardViewMinimizeProps
  const { userId: viewerPerxonaId } = React.useContext(LocationContext)
  const [mode, setMode] = React.useState<SelectionMode>('none')

  const filterCallBack = (card: ICardPersonality) => {
    return cardsChosen.includes(card.cardId)

  }

  const { cards, getCards, filterCard } = useCard<ICardPersonality>({ filterCallBack, value, perxonaId: viewerPerxonaId })
  const { order, handleChangeOrder, handleAddChosen, handleRemoveChosen, getOrder } = useOrder({
    toOrder: cardsChosen.filter(id => id !== null)
  })
  const { slice, handleChangeItemsPerPage, handleChangeTotalItems, handleChangePage, pageConfig } =
    usePagination({ itemsPerPage, totalItems: order.length })

  const changeSelectionMode = (selectionMode: SelectionMode) => {
    if (selectionMode === 'reorder') {
      handleChangePage(1)
    }
    setMode(selectionMode)
  }


  React.useEffect(() => {
    getCards()
    filterCard('filterAndSort')
  }, [getCards,])



  React.useEffect(() => {
    if (mode === 'reorder') {
      handleChangeTotalItems(order.length)
      filterCard('filterAndSort')

    } else {
      handleChangeTotalItems(cards.length)
    }
  }, [order.length, mode, cards.length, order])

  React.useEffect(() => {
    handleChangeItemsPerPage(itemsPerPage)
  }, [itemsPerPage])



  return (

    <div style={{ ...style }}>
      <ContainerCardsPersonality
        cards={cards.slice(slice.initSlice, slice.endSlice)}
        filterCard={filterCard}
        selectionAndReorderActive={selectionAndReorderActive}
        cardsChosen={cardsChosen}
        hookOrder={{ order, handleChangeOrder, handleAddChosen, handleRemoveChosen, getOrder }}
        element={element}
        handleChangeItemsPerPage={handleChangeItemsPerPage}
        pageConfig={pageConfig}
        handleChangePage={handleChangePage}
        mode={mode}
        changeSelectionMode={changeSelectionMode}
        tittle={value.tittle}
        paginationPosition={paginationPosition}
        isDesign={isDesign ?? false}
        minimize={minimize ?? true}
      ></ContainerCardsPersonality>
    </div>
  )

}