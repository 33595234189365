//create react funtional component called same as file name
import { Box, Button, ButtonGroup, Grid, Icon, IconButton, InputAdornment, Paper, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { DEFAULT_LOGIN_VALUE, getLoginForgotPassword, getLoginValidate, getLoginValidateOTP } from 'lib'
import REST from 'lib/constants/REST'
import React from 'react'
export interface ILoginValues {
  addShowPasswordButton: boolean
  addForgotButton: boolean
  addShowQRButton: boolean
  addShowPinButton: boolean
  inputsStyle: IInputStyle
  buttonsBgColor: string
  fontColor: string
  showPasswordBgColor: string
  labelInputsText: ITextLabel
  colorFontButtons: IColorFontButtons
  minWidth: string
  minHeight: string
  maxHeight: string
}
interface IColorFontButtons {
  buttonValidar: string
  buttonForgot: string
  buttonQR: string
  buttonPin: string
  buttonEnviarPin: string
}
interface ITextLabel {
  labelUser: string
  labelPassword: string
  labelPin: string
  labelOlvide: string
  labelFotoQR: string
  labelIngresoPin: string
  labelValidarUsuario: string
  labelEnviarPin: string
}
interface IInputStyle {
  inputStyle: InputStyles
  size: IInputSize
  backgroundColor: string
  fontColor: string
  placeHolderColor: string
  borderColor: string
}

type IInputSize = 'small' | 'medium'
type InputStyles = 'standard' | 'outlined' | 'filled'
interface ISize {
  width: number
  height: number
}
interface IPropsLogin {
  value: ILoginValues
  style: React.CSSProperties,
  size: ISize
  design: boolean
  tabId?: string
  post?: boolean
}

interface IQRProps {
  showQR: boolean
  setQRCode: string
}

interface IPinProps {
  showPin: boolean
  pin: string
}

interface IPasswordProps {
  value: string
  visible: boolean
}

interface IUserLoginProps {
  user: string
  password: IPasswordProps
  otp: string
}

const getLoginValues = (value: any): boolean => {
  return value.hasOwnProperty('labelInputsText')
}

interface ILoginValues2 extends ILoginValues {
  labelInputs: ILabelInputs
}
interface ILabelInputs {
  labelUser: string
  labelPassword: string
  labelPin: string
}

const LoginTest = ({ props }: { props: IPropsLogin }) => {
  const { value } = props
  if (!getLoginValues(value)) {
    const copyValue: Partial<ILoginValues2> = value as ILoginValues2
    copyValue.maxHeight = DEFAULT_LOGIN_VALUE.maxHeight
    delete copyValue.labelInputs
    copyValue.labelInputsText = DEFAULT_LOGIN_VALUE.labelInputsText
    copyValue.colorFontButtons = DEFAULT_LOGIN_VALUE.colorFontButtons
    value.labelInputsText = copyValue.labelInputsText
    value.maxHeight = copyValue.maxHeight
  }

  const { inputsStyle } = value

  const style = React.useCallback(
    makeStyles({
      root: {
        backgroundColor: inputsStyle.backgroundColor,
        '& label.Mui-focused': {
          color: inputsStyle.placeHolderColor,
        },
        '& .MuiFilledInput-underline:after': {
          borderColor: inputsStyle.borderColor,
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: inputsStyle.borderColor,
        },
        '& .MuiOutlinedInput-underline:after': {
          borderBottomColor: inputsStyle.borderColor,
        },
        '& .MuiOutlinedInput-root.Mui-focused ': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: inputsStyle.borderColor,
          },
        },
        '& fieldset': {
          borderColor: inputsStyle.borderColor,
        },
        '&:hover fieldset': {
          borderColor: inputsStyle.borderColor,
        },
        '&.Mui-focused fieldset': {
          borderColor: inputsStyle.borderColor,
        },
        '& .MuiOutlinedInput-input': {
          color: inputsStyle.fontColor,
        },
        '& .MuiFilledInput-input': {
          color: inputsStyle.fontColor,
        },
        '& .MuiInput-input': {
          color: inputsStyle.fontColor,
        },
        '& .MuiInputLabel-root': {
          color: inputsStyle.placeHolderColor,
        },
        '& .MuiInputLabel-asterisk': {
          display: 'none',
        },
      },
    }),
    [props.value.inputsStyle.backgroundColor, props.value.inputsStyle.borderColor, props.value.inputsStyle.fontColor, props.value.inputsStyle.placeHolderColor]
  )

  const clases = style()

  const { labelUser, labelPassword, labelPin } = props.value.labelInputsText
  const [qrProps, setQRprops] = React.useState<IQRProps>({
    showQR: false,
    setQRCode: '',
  })

  const [pinProps, setPinProps] = React.useState<IPinProps>({
    showPin: false,
    pin: '',
  })

  const [userLoginProps, setUserLoginProps] = React.useState<IUserLoginProps>({
    user: '',
    password: {
      value: '',
      visible: false,
    },
    otp: '',
  })

  const [requiredPassword, setRequiredPassword] = React.useState<boolean>(false)
  const [isRememberPasswordOption, setIsRememberPasswordOption] = React.useState<boolean>(false)

  const isDesignStatus = Boolean(props.design)
  const isPostStatus = Boolean(props.post)

  const GroupLoginOptions = React.useCallback(
    () => (
      <div>
        <Typography
          variant="body1"
          color="textSecondary"
          style={{ fontWeight: 'bold', textTransform: 'none', textAlign: 'center', paddingBottom: 10, color: inputsStyle.fontColor }}
        >
          Ingresa el PIN que se te envió
        </Typography>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Box style={{ flexGrow: 3 }}>
            <TextField
              disabled={props.post ?? false}
              label={labelPin}
              variant={props.value.inputsStyle.inputStyle}
              size={props.value.inputsStyle.size}
              fullWidth
              required={!props.design}
              onChange={(e) => {
                setUserLoginProps((prev) => ({ ...prev, otp: e.target.value }))
              }}
              classes={{
                root: clases.root,
              }}
            />
          </Box>
          <Box style={{ paddingLeft: 2 }}>
            <Button variant="contained" size="medium" type="submit" style={{ backgroundColor: props.value.buttonsBgColor }}>
              <Typography style={{ color: value.colorFontButtons.buttonEnviarPin }}>{props.value.labelInputsText.labelEnviarPin}</Typography>
            </Button>
          </Box>
        </Box>
      </div>
    ),
    [
      props.design,
      props.value.labelInputsText.labelPin,
      props.value.inputsStyle.inputStyle,
      props.value.buttonsBgColor,
      props.value.colorFontButtons.buttonEnviarPin,
      props.value.inputsStyle.size,
      props.value.inputsStyle.fontColor,
      props.value.labelInputsText.labelEnviarPin,
      clases,
      props.post,
    ]
  )

  const ButtonGroupLogin = React.useCallback(() => {
    return (
      <Grid item xs={12}>
        <ButtonGroup disableElevation variant="contained" fullWidth>
          {props.value.addShowQRButton && (
            <Button
              disabled={props.post ?? false}
              variant="contained"
              size="large"
              fullWidth
              style={{
                textTransform: 'none',
                backgroundColor: 'transparent',
                borderTop: `1px solid ${inputsStyle.borderColor} `,
                borderRight: `1px solid ${props.value.addShowPinButton ? inputsStyle.borderColor : 'transparent'}`,
              }}
              onClick={() => {
                setQRprops((prev) => ({ ...prev, showQR: !prev.showQR }))
                setPinProps((prev) => ({ ...prev, showPin: false }))
              }}
            >
              <Typography variant="body1" color="textSecondary" style={{ fontWeight: 'bold', textTransform: 'none', color: value.colorFontButtons.buttonQR }}>
                {props.value.labelInputsText.labelFotoQR}
              </Typography>
            </Button>
          )}

          {props.value.addShowPinButton && props.value.addForgotButton && (
            <Button
              disabled={props.post ?? false}
              variant="contained"
              size="large"
              fullWidth
              style={{ textTransform: 'none', backgroundColor: 'transparent', borderTop: `1px solid ${inputsStyle.borderColor} ` }}
              onClick={() => {
                if (userLoginProps.user !== '') {
                  setPinProps((prev) => ({ ...prev, showPin: !prev.showPin }))
                  setQRprops((prev) => ({ ...prev, showQR: false }))
                } else {
                  if (props.design) {
                    setPinProps((prev) => ({ ...prev, showPin: !prev.showPin }))
                    setQRprops((prev) => ({ ...prev, showQR: false }))
                  } else {
                    setPinProps((prev) => ({ ...prev, showPin: false }))
                  }
                }
              }}
              type="submit"
            >
              <Typography variant="body1" color="textSecondary" style={{ fontWeight: 'bold', textTransform: 'none', color: value.colorFontButtons.buttonPin }}>
                {props.value.labelInputsText.labelIngresoPin}
              </Typography>
            </Button>
          )}
        </ButtonGroup>
      </Grid>
    )
  }, [
    props.value.addShowQRButton,
    props.value.addShowPinButton,
    userLoginProps,
    props.value.addForgotButton,
    props.value.addShowPasswordButton,
    inputsStyle.borderColor,
    value.fontColor,
    props.post,
  ])

  const [qrUrl, setqrUrl] = React.useState<string>('')
  React.useEffect(() => {
    setqrUrl(REST.baseURL + REST.qrPath + REST.qrTabIdParam + props.tabId)
  }, [props.tabId])

  const getTransforms = React.useCallback(() => { if (props.style.transform) { return JSON.parse(props.style.transform) } else { return [0, 1] } }, [props.style.transform])

  return (
    <Paper
      style={{
        ...props.style, 
        padding: 20, 
        margin: 0,
        width: props.size.width, 
        minWidth: '360', 
        opacity: props.post === undefined ? '1' : '0.5',
        transform: !isDesignStatus || isPostStatus? `rotate(${getTransforms()[0]}deg) scaleX(${getTransforms()[1]})` : '',
       }
      }
      elevation={3}
    >
      <Grid container spacing={1}>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            if (!props.design) {
              if (!isRememberPasswordOption) {
                const validar = () => {
                  getLoginValidate(userLoginProps.user, userLoginProps.password.value, props.tabId ?? '')
                }
                if (userLoginProps.user !== '' && userLoginProps.password.value !== '') {
                  validar()
                }
              } else {
                if (userLoginProps.user !== '') {
                  const validar = (user) => getLoginForgotPassword(user, props.tabId ?? '')
                  validar(userLoginProps.user)
                  setIsRememberPasswordOption(false)
                }
              }
            }
          }}
          style={{ width: '100%' }}
        >
          <Grid container spacing={1} style={{ paddingBottom: 10 }}>
            <Grid item xs={12}>
              <TextField
                disabled={props.post ?? false}
                variant={props.value.inputsStyle.inputStyle}
                size={props.value.inputsStyle.size}
                value={userLoginProps.user}
                label={labelUser}
                autoFocus={true}
                fullWidth
                required={!props.design}
                onChange={(e) => {
                  setUserLoginProps((prev) => ({ ...prev, user: e.target.value }))
                }}
                classes={{
                  root: clases.root,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Box style={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}>
                <Box style={{ flexGrow: 3 }}>
                  <TextField
                    disabled={props.post ?? false}
                    label={labelPassword}
                    variant={props.value.inputsStyle.inputStyle}
                    size={props.value.inputsStyle.size}
                    value={userLoginProps.password.value}
                    fullWidth
                    required={requiredPassword}
                    type={userLoginProps.password.visible ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          {props.value.addShowPasswordButton && (
                            <IconButton
                              aria-label="visible"
                              size="small"
                              style={{ paddingLeft: 8 }}
                              onClick={() => {
                                setUserLoginProps((prev) => ({ ...prev, password: { ...prev.password, visible: !prev.password.visible } }))
                              }}
                            >
                              <Icon style={{ color: value.showPasswordBgColor }}>{userLoginProps.password.visible ? 'visibility' : 'visibility_off'}</Icon>
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setUserLoginProps((prev) => ({ ...prev, password: { ...prev.password, value: e.target.value } }))
                    }}
                    classes={{
                      root: clases.root,
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                  {props.value.addForgotButton && (
                    <Button
                      size="small"
                      onClick={() => {
                        if (props.design) {
                          setPinProps((prev) => ({ ...prev, showPin: true }))
                          setQRprops((prev) => ({ ...prev, showQR: false }))
                        } else {
                          setRequiredPassword(false)
                          if (userLoginProps.user !== '' && userLoginProps.password.value === '') {
                            setPinProps((prev) => ({ ...prev, showPin: true }))
                            setQRprops((prev) => ({ ...prev, showQR: false }))
                            setIsRememberPasswordOption(true)
                          } else {
                            setPinProps((prev) => ({ ...prev, showPin: false }))
                          }
                        }
                      }}
                      type={'submit'}
                    >
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        style={{ textDecoration: 'underline', fontWeight: 'bold', textTransform: 'none', color: value.colorFontButtons.buttonForgot }}
                      >
                        {props.value.labelInputsText.labelOlvide}
                      </Typography>
                    </Button>
                  )}
                </Box>

                <Box>
                  <Button
                    variant="contained"
                    size="medium"
                    type="submit"
                    style={{ backgroundColor: props.value.buttonsBgColor }}
                    onClick={() => {
                      if (!props.design) {
                        setRequiredPassword(true)
                      }
                    }}
                  >
                    <Typography style={{ color: value.colorFontButtons.buttonValidar }}>{props.value.labelInputsText.labelValidarUsuario}</Typography>
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>

          {(props.value.addShowPinButton || props.value.addShowQRButton) && <ButtonGroupLogin />}
        </form>

        {(qrProps.showQR || pinProps.showPin) && !props.design ? (
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
            <Box>
              {qrProps.showQR && !pinProps.showPin && <img src={qrUrl} alt="" width="200" height="200" />}
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  getLoginValidateOTP(userLoginProps.user, userLoginProps.otp, props.tabId ?? '')
                }}
                style={{ width: '100%' }}
              >
                {!qrProps.showQR && pinProps.showPin && userLoginProps.user !== '' ? <GroupLoginOptions /> : props.design ? <GroupLoginOptions /> : null}
              </form>
            </Box>
          </Grid>
        ) : props.design ? (
          <Grid item xs={12}>
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
              {qrProps.showQR && !pinProps.showPin && <img src={qrUrl} alt="" width="200" height="200" />}

              {!qrProps.showQR && pinProps.showPin ? <GroupLoginOptions /> : null}
            </Box>
          </Grid>
        ) : (
          <div />
        )}
      </Grid>
    </Paper>
  )
}

export default LoginTest
