import { EVENT_ORIGINS } from "lib"

export const isTrustedEvent = (origin: string): boolean => {
    for (const key in EVENT_ORIGINS) {
        const element = EVENT_ORIGINS[key]
        if (element === origin) {
            return true
        }
    }
    return false
}