import { Box, Checkbox, Icon, Typography } from "@material-ui/core";
import { EditionCardMode, ICardPerxona } from "components/CardProps/CardProps";
import { PageContext } from "context";
import { CARD_PERXONA_HORIZONTAL } from "lib";
import React from 'react'
import { IElement } from "types";

const cardPerxonaStyle = CARD_PERXONA_HORIZONTAL


type Move = "RIGHT" | "LEFT"

export type CardHorizontalPerxonaProps = {
          card: ICardPerxona
}

export const CardHorizontal = (props: CardHorizontalPerxonaProps) => {
          const { name, lastName, country, cardColor, description, backgroundColor, avatar, affiliateId, certifier } = props.card


          const aditionalStyleInformationCard = { position: "absolute", backgroundColor: `#${cardColor}`, height: certifier !== null ? "70%" : "88%" } as React.CSSProperties
          const aditionalStyleForDescriptionPerxona = { wordWrap: "break-word", height: certifier !== null ? "96px" : "125px" } as React.CSSProperties
          return (
                    <Box className="row" style={{
                              ...cardPerxonaStyle.main, ...{
                                        backgroundColor: `#${backgroundColor}`,
                                        borderColor: '#bdbdbd', borderStyle: 'solid', borderWidth: '1px',
                              }
                    }}>
                              <Box className="column" style={{ flex: "50%" }}>
                                        
                                        <img src={avatar} alt="" style={cardPerxonaStyle.image} />
                              </Box>

                              <Box className="column" style={{ flex: "50%", position: "relative", display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>

                                        <Box style={{ ...cardPerxonaStyle.informationCard, ...aditionalStyleInformationCard }}>

                                                  <Box className="row" style={{ ...cardPerxonaStyle.perxonaInformationContainer, ...{ flexDirection: "row", flexWrap: "wrap" } }}>
                                                            <div className="column" style={{ textAlign: "center", }}>
                                                                      <p style={{ ...cardPerxonaStyle.perxonaName, ...{ fontWeight: "bold" } }} >
                                                                                {name}
                                                                      </p>
                                                                      {lastName !== null && <Typography style={{ ...cardPerxonaStyle.perxonaFullName, ...{ fontWeight: "bold" } }}       >
                                                                                {lastName}
                                                                      </Typography>}
                                                                      <p style={{ ...cardPerxonaStyle.perxonaNacionality, ...{ fontWeight: "bold" } }}>
                                                                                {country}
                                                                      </p>
                                                                      <p style={cardPerxonaStyle.perxonaAditionalInfo}>
                                                                                {affiliateId}
                                                                      </p>
                                                            </div>
                                                  </Box>
                                                  <Box>
                                                            <Box className="descriptionPerxona" style={{ ...cardPerxonaStyle.perxonaDescription, ...aditionalStyleForDescriptionPerxona }}>
                                                                      <div dangerouslySetInnerHTML={{ __html: description }} style={{ textAlign: "left" }} />
                                                            </Box>
                                                  </Box>
                                        </Box>
                                        {
                                                  (certifier !== null && certifier !== undefined) ? (
                                                            <Box style={{ ...cardPerxonaStyle.certificatorContainer, ...{ display: "block", alignSelf: "auto", width: "100%", marginRight: "5%", marginTop: "5%" } }}>
                                                                      <div className="row" style={{ display: "flex", alignItems: "center" }}>
                                                                                <div className="column" style={{ flex: "0%", display: "flex", justifyContent: "flex-end", marginRight: "10px" }}>
                                                                                          <img src={certifier.avatar} alt="" style={{ ...cardPerxonaStyle.imageCertificator, ...{ position: "relative" } }} />
                                                                                </div>
                                                                                <div className="column" style={{ flex: "40%", display: " flex", marginRight: "10px", justifyContent: "flex-end" }}>

                                                                                          <div className="column" style={{ flex: "50%" }}>
                                                                                                    <div className="row">
                                                                                                              <p style={{ ...cardPerxonaStyle.certificatorTittle, ...{ textTransform: "capitalize", fontWeight: "bold" } }}>
                                                                                                                        Certificador
                                                                                                              </p>
                                                                                                              <p style={{ ...cardPerxonaStyle.certificatorName, ...{ textTransform: "capitalize" } }}>
                                                                                                                        {`${certifier.name} ${certifier.lastName ?? ''}`}
                                                                                                              </p>
                                                                                                    </div>
                                                                                          </div>

                                                                                </div>
                                                                      </div>
                                                            </Box>
                                                  ) : null
                                        }
                              </Box>


                    </Box >)
}

const CardHorizontalPerxona = (
          { card, editMode, cardsChosen, element }:
                    { card: ICardPerxona, editMode: EditionCardMode, cardsChosen: number[], element: IElement }) => {

          const { name, lastName, country, cardColor, description, backgroundColor, avatar, affiliateId, certifier, cardId, cardName, cardType } = card;
          const { updateElement } = React.useContext(PageContext)

          const handleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
                    const { checked } = event.target
                    const newCardsChosen = checked ? [...cardsChosen, cardId] : cardsChosen.filter(id => id !== cardId)
                    const elementUpdate = {
                              ...element,
                              value: {
                                        ...element.value,
                                        cardsChosen: newCardsChosen
                              }
                    }
                    updateElement(elementUpdate)

          }, [cardsChosen, cardId, element, updateElement])

          const handleChangePosition = React.useCallback((move: Move) => {

                    const index = cardsChosen.indexOf(cardId)
                    switch (move) {
                              case "LEFT":
                                        const nextCard = cardsChosen[index - 1]
                                        const newOrderCardsChossen = [...cardsChosen]
                                        newOrderCardsChossen[index] = nextCard
                                        newOrderCardsChossen[index - 1] = cardId
                                        const elementUpdate = {
                                                  ...element,
                                                  value: {
                                                            ...element.value,
                                                            cardsChosen: newOrderCardsChossen
                                                  }
                                        }
                                        updateElement(elementUpdate)
                                        break;
                              case "RIGHT":
                                        const nextCardRight = cardsChosen[index + 1]
                                        const newOrderCardsChossenRight = [...cardsChosen]
                                        newOrderCardsChossenRight[index] = nextCardRight
                                        newOrderCardsChossenRight[index + 1] = cardId
                                        const elementUpdateRight = {
                                                  ...element,
                                                  value: {
                                                            ...element.value,
                                                            cardsChosen: newOrderCardsChossenRight
                                                  }
                                        }
                                        updateElement(elementUpdateRight)
                                        break;
                    }

          }, [cardsChosen, cardId, element, updateElement])


          const aditionalStyleInformationCard = { position: "absolute", backgroundColor: editMode === 'none' ? `#${cardColor}` : 'white', height: certifier !== null ? "70%" : "88%" } as React.CSSProperties
          const aditionalStyleForDescriptionPerxona = { wordWrap: "break-word", height: certifier !== null ? "96px" : "125px" } as React.CSSProperties

          return (
                    <Box className="row" style={{ ...cardPerxonaStyle.main, ...{ backgroundColor: editMode !== "none" ? 'white' : `#${backgroundColor}`, borderColor: '#bdbdbd', borderStyle: 'solid', borderWidth: '1px', } }}>
                              <Box className="column" style={{ flex: "50%" }}>
                                        <img src={avatar} alt="" style={cardPerxonaStyle.image} />
                              </Box>
                              {editMode === 'none' && <Box className="column" style={{ flex: "50%", position: "relative", display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>

                                        <Box style={{ ...cardPerxonaStyle.informationCard, ...aditionalStyleInformationCard }}>

                                                  <Box className="row" style={{ ...cardPerxonaStyle.perxonaInformationContainer, ...{ flexDirection: "row", flexWrap: "wrap" } }}>
                                                            <div className="column" style={{ textAlign: "center", }}>
                                                                      <p style={{ ...cardPerxonaStyle.perxonaName, ...{ fontWeight: "bold" } }} >
                                                                                {name}
                                                                      </p>
                                                                      {lastName !== null && <Typography style={{ ...cardPerxonaStyle.perxonaFullName, ...{ fontWeight: "bold" } }}       >
                                                                                {lastName}
                                                                      </Typography>}
                                                                      <p style={{ ...cardPerxonaStyle.perxonaNacionality, ...{ fontWeight: "bold" } }}>
                                                                                {country}
                                                                      </p>
                                                                      <p style={cardPerxonaStyle.perxonaAditionalInfo}>
                                                                                {affiliateId}
                                                                      </p>
                                                            </div>
                                                  </Box>
                                                  <Box>
                                                            <Box className="descriptionPerxona" style={{ ...cardPerxonaStyle.perxonaDescription, ...aditionalStyleForDescriptionPerxona }}>
                                                                      <div dangerouslySetInnerHTML={{ __html: description }} style={{ textAlign: "left" }} />
                                                            </Box>
                                                  </Box>
                                        </Box>
                                        {
                                                  (certifier !== null && editMode === 'none') ? (
                                                            <Box style={{ ...cardPerxonaStyle.certificatorContainer, ...{ display: "block", alignSelf: "auto", width: "100%", marginRight: "5%", marginTop: "5%" } }}>
                                                                      <div className="row" style={{ display: "flex", alignItems: "center" }}>
                                                                                <div className="column" style={{ flex: "0%", display: "flex", justifyContent: "flex-end", marginRight: "10px" }}>
                                                                                          <img src={certifier.avatar} alt="" style={{ ...cardPerxonaStyle.imageCertificator, ...{ position: "relative" } }} />
                                                                                </div>
                                                                                <div className="column" style={{ flex: "40%", display: " flex", marginRight: "10px", justifyContent: "flex-end" }}>

                                                                                          <div className="column" style={{ flex: "50%" }}>
                                                                                                    <div className="row">
                                                                                                              <p style={{ ...cardPerxonaStyle.certificatorTittle, ...{ textTransform: "capitalize", fontWeight: "bold" } }}>
                                                                                                                        Certificador
                                                                                                              </p>
                                                                                                              <p style={{ ...cardPerxonaStyle.certificatorName, ...{ textTransform: "capitalize" } }}>
                                                                                                                        {`${certifier.name} ${certifier.lastName ?? ''}`}
                                                                                                              </p>
                                                                                                    </div>
                                                                                          </div>

                                                                                </div>
                                                                      </div>
                                                            </Box>
                                                  ) : null
                                        }
                              </Box>}
                              {editMode !== 'none' &&
                                        <Box className="column" style={{ flex: "50%", position: "relative", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                  {editMode === "selection" &&
                                                            <Box style={{ display: 'flex', flexDirection: 'column', flexWrap: "nowrap", height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                                                      <Typography>
                                                                                {cardName}
                                                                      </Typography>

                                                                      <Checkbox size="medium"
                                                                                onChange={handleChange} checked={cardsChosen.find(id => id === cardId) !== undefined ? true : false}
                                                                                style={{ color: cardType === 5 ? 'red' : '#0288d1' }} />
                                                            </Box>

                                                  }

                                                  {editMode === "reorder" &&

                                                            <Box style={{ display: 'flex', flexDirection: 'column', flexWrap: "nowrap", height: '100%', justifyContent: 'center', width: '100%' }}>
                                                                      <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                                <Typography>
                                                                                          {cardName}
                                                                                </Typography>
                                                                      </Box>
                                                                      <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                                <Box  >
                                                                                          {(cardsChosen.indexOf(cardId) !== -1 && cardsChosen.indexOf(cardId) !== 0) &&
                                                                                                    <Icon fontSize="large" onClick={() => handleChangePosition("LEFT")} >keyboard_double_arrow_left</Icon>}
                                                                                </Box>

                                                                                <Box>
                                                                                          {(cardsChosen.indexOf(cardId) !== -1 && (cardsChosen.indexOf(cardId) < cardsChosen.length - 1)) &&
                                                                                                    <Icon fontSize="large" onClick={() => handleChangePosition("RIGHT")}>keyboard_double_arrow_right</Icon>}
                                                                                </Box>
                                                                      </Box>
                                                            </Box>
                                                  }
                                        </Box >

                              }

                    </Box >


          )

}

export default CardHorizontalPerxona