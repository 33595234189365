import { Box } from '@material-ui/core'
import React from 'react'
import { useInView } from 'react-intersection-observer'
import { IConferenceTheme, IMessage, IPaginationResponse } from 'types'

type Props = {
  listItems: React.ReactNode[]
  reverse?: boolean
  conferenceId: number
  selectedTheme?: IConferenceTheme
  loading: boolean
  paginationResponse: IPaginationResponse<IMessage>
  handlePageChange: (conferenceId: number, page: number, conferenceThemeId?: number | undefined) => void
}

export const InfiniteScrollThemeView = (props: Props) => {
  const { listItems, reverse, conferenceId, selectedTheme, loading, paginationResponse, handlePageChange } = props
  const [lastRowRef, lastRowInView] = useInView()

  React.useEffect(() => {
    const needToLoadMore = lastRowInView && !loading
    if (!needToLoadMore) return
    console.log('needToLoadMore', needToLoadMore)
    const page = paginationResponse.page === 0 ? 1 : paginationResponse.page + 1
    const isLastPage = page === Math.ceil(paginationResponse.count / paginationResponse.limit)
    console.log('isLastPage', isLastPage)
    if (isLastPage) return
    handlePageChange(conferenceId, page + 1, selectedTheme?.conferenceThemeId)
  }, [lastRowInView, conferenceId, paginationResponse, loading, selectedTheme])

  return (
    <Box
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: reverse ? 'column-reverse' : 'column',
        flexWrap: 'nowrap',
        overflowY: 'auto',
        gap: 20,
      }}
      pb={2}
    >
      {listItems.map((listItem, i) => {
        const props = { key: i }
        i === listItems.length - 1 && (props['ref'] = lastRowRef)
        return <div {...props}>{listItem}</div>
      })}
    </Box>
  )
}
