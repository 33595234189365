import React from 'react'

interface Props {
    value: any,
    style: React.CSSProperties,
}

const Line = React.memo((props: Props) => {
    const [style, setStyle] = React.useState<React.CSSProperties>(props.style)
    const [value, setValue] = React.useState<any>(props.value)
    React.useEffect(() => {
        if (props.style !== style) { setStyle(props.style) }
    }, [props.style, style])
    React.useEffect(() => {
        if (props.value !== value) { setValue(props.value) }
    }, [props.value, value])
    return (
        <div
            style={{
                ...props.style,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%"
            }}
        >
            <div
                style={{
                    ...value,
                    width: value?.orientation === undefined || value?.orientation === "h" ? "98%" : value?.height ? parseInt(value.height) : 2,
                    height: value?.orientation === "v" ? "98%" : value?.height ? parseInt(value?.height) : 2,
                    backgroundColor: value ? value.backgroundColor ? value.backgroundColor : "rgba(0,0,0,1)" : "rgba(0,0,0,1)"
                }}
            />
        </div>
    )
})

export default Line
