import React from 'react'
import { Icon } from '@material-ui/core'
import { ELEMENT_NAMES, ELEMENT_TYPES } from 'lib'

interface Props {
  icon: any
  name: string
  type: string
}

const Options: Props[] = [
  {
    icon: <Icon color="secondary">subject</Icon>,
    name: ELEMENT_NAMES.text,
    type: ELEMENT_TYPES.text,
  },
  {
    icon: <Icon color="secondary">remove</Icon>,
    name: ELEMENT_NAMES.line,
    type: ELEMENT_TYPES.line,
  },
  // {
  //   icon: <Icon color="secondary">format_line_spacing</Icon>,
  // name: ELEMENT_NAMES.header,
  //   type: ELEMENT_TYPES.header
  //}

  // {
  //     icon: <Icon color="secondary">crop_square</Icon>,
  //     name: ELEMENT_NAMES.curtain,
  //     type: ELEMENT_TYPES.curtain
  // },
]
export const PracticeOptions: Props[] = [
  {
    icon: <Icon fontSize="small">account_box</Icon>,
    name: ELEMENT_NAMES.practiceCard,
    type: ELEMENT_TYPES.practiceCard,
  },
  {
    icon: <Icon fontSize="small">login</Icon>,
    name: ELEMENT_NAMES.login,
    type: ELEMENT_TYPES.login,
  },
  {
    icon: <Icon fontSize="small">person</Icon>,
    name: ELEMENT_NAMES.practiceCardPersonality,
    type: ELEMENT_TYPES.practiceCardPersonality,
  },
  {
    icon: <Icon fontSize="small">contacts</Icon>,
    name: ELEMENT_NAMES.practiceCardGroups,
    type: ELEMENT_TYPES.practiceCardGroups,
  },
  {
    icon: <Icon fontSize="small">filter_frames</Icon>,
    name: ELEMENT_NAMES.practiceConference,
    type: ELEMENT_TYPES.practiceConference,
  },
]

export default Options
