import React from 'react'
import ReactPlayer from 'react-player'
import { ISize } from 'types'

interface Props {
    value: any,
    filesContext: string,
    style: React.CSSProperties,
    size: ISize,
    borderRadiusUnit: string
}

const Audio = React.memo((props: Props) => {
    const getTransforms = React.useCallback(() => { if (props.style.transform) { return JSON.parse(props.style.transform) } }, [props.style.transform])
    return (
        <div
            style={{
                ...props.style,
                transform: `rotate(${getTransforms()[0]}deg) scaleX(${getTransforms()[1]})`,
                pointerEvents: "none"
            }}
        >
            {
                props.value[1].includes("ivoox") ?
                    <iframe
                        id={"audio" + Math.random()}
                        allowFullScreen
                        height={props.size.height}
                        src={props.value[1]}
                    />
                    :
                    <ReactPlayer
                        url={`${props.value[1].includes("http") ? "" : props.filesContext + "/"}${props.value[1].replace("https://w.soundcloud.com/player/?url=", "")}`}
                        width={props.size.width}
                        height={props.size.height}
                        controls={true}
                    />
            }
        </div>
    )
})

export default Audio
