import React from 'react'

interface Props {
    value: any,
    filesContext: string,
    style: React.CSSProperties,
    borderRadiusUnit: string
}

const Image = React.memo(((props: Props) => {
    return (
        <div
            style={{
                ...props.style,
                backgroundImage: `url(${props.filesContext}/${props.value[1]})`,
                width: "100%",
                height: "100%",
                borderTopLeftRadius: props.style.borderTopLeftRadius + props.borderRadiusUnit,
                borderBottomLeftRadius: props.style.borderBottomLeftRadius + props.borderRadiusUnit,
                borderTopRightRadius: props.style.borderTopRightRadius + props.borderRadiusUnit,
                borderBottomRightRadius: props.style.borderBottomRightRadius + props.borderRadiusUnit,
            }}
        />
    )
}))

export default Image
