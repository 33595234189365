import { createMuiTheme } from '@material-ui/core/styles'
import createCssVar from './export'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0288d1',
      light: '#b3e5fc',
      dark: '#000',
    },
    secondary: {
      main: '#131313',
      light: '#bdbdbd',
    },
  },
  typography: {
    fontFamily: 'Noto Sans',
  },
  overrides: {
    MuiTooltip: {
      arrow: { color: '#0288d1' },
      tooltip: { backgroundColor: '#0288d1' },
    },
    MuiOutlinedInput: {
      adornedEnd: { paddingRight: 2 },
    },
    MuiFormControlLabel: {
      label: { fontSize: '0.725em' },
      root: { marginRight: 0 },
    },
    MuiCheckbox: {
      root: { padding: 3 },
    },
  },
})

const variables = [
  { name: 'toolbarMinHeight', value: theme.mixins.toolbar.minHeight },
  { name: 'primaryMainColor', value: theme.palette.primary.main },
  { name: 'primaryDarkColor', value: theme.palette.primary.dark },
  { name: 'primaryLightColor', value: theme.palette.primary.light },
  { name: 'secondaryMainColor', value: theme.palette.secondary.main },
  { name: 'secondaryLightColor', value: theme.palette.secondary.light },
  { name: 'successMainColor', value: theme.palette.success.main },
  { name: 'warningMainColor', value: theme.palette.warning.main },
  { name: 'drawerZIndex', value: theme.zIndex.drawer },
  { name: 'entitySearchMainColor', value: theme.palette.secondary.main },
  { name: 'entitySearchLightColor', value: theme.palette.secondary.light },
]

for (const variable of variables) {
  createCssVar(variable.name, variable.value)
}

for (let i = 1; i <= 20; i++) {
  createCssVar(`spacing${i}`, `${theme.spacing(i)}px`)
}

export default theme
