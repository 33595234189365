import { BORDER_RADIUS_UNITS_OPTIONS } from 'lib'
import React from 'react'
import ReactPlayer from 'react-player'
import { ISize } from 'types'

interface Props {
    value: any,
    filesContext: string,
    style: React.CSSProperties,
    size: ISize,
    borderRadiusUnit: string
}

const Video = React.memo((props: Props) => {
    return (
        <ReactPlayer
            url={`${props.value[1].includes("http") ? "" : props.filesContext + "/"}${props.value[1]}`}
            width={props.size.width}
            height={props.size.height}
            controls={true}
            style={{
                borderColor: props.style.borderColor,
                borderStyle: props.style.borderStyle,
                borderWidth: props.style.borderWidth + "px",
                pointerEvents: "none",
                borderTopLeftRadius: props.style.borderTopLeftRadius + `${props.borderRadiusUnit ? props.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}`,
                borderBottomLeftRadius: props.style.borderBottomLeftRadius + `${props.borderRadiusUnit ? props.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}`,
                borderTopRightRadius: props.style.borderTopRightRadius + `${props.borderRadiusUnit ? props.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}`,
                borderBottomRightRadius: props.style.borderBottomRightRadius + `${props.borderRadiusUnit ? props.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}`,
                boxShadow: props.style.boxShadow
            }}
        />
    )
})

export default Video
