import { Box, Paper } from '@material-ui/core'
import { PageContext } from 'context'
import React from 'react'
import { IElement } from 'types'
import { ColumnPerThemeView, ConferenceHorizontalView, ConferenceMember, ConferenceSelector, Header, MessageScrollView, ThemeListSelector } from './components'
import { ConferenceOnlyOneThemeView } from './components/ConferenceOnlyOneThemeView'
import { useConferenceThemeHelper, useGetConferenceHelper } from './hook'
import { ConferencePracticeValues, ConferencePracticeViewMode } from './types'
type Props = {
  element: IElement
  isDesign?: boolean
  selectionConferenceMode?: boolean
}

export const Conference = (props: Props) => {
  const { element, isDesign, selectionConferenceMode } = props
  const { style, value } = element
  const { updateElement } = React.useContext(PageContext)
  const [minimized, setMinimized] = React.useState<boolean>(isDesign ? false : (value as ConferencePracticeValues).initMinimized)
  const [showMembers, setShowMembers] = React.useState<boolean>(false)
  const [refresh, setRefresh] = React.useState<boolean>(false)
  React.useEffect(() => {
    if (selectionConferenceMode) {
      setMinimized(false)
    }
  }, [selectionConferenceMode])
  const handleShowMembers = () => {
    setShowMembers((prev) => !prev)
  }

  const onRefreshEnd = () => {
    setRefresh(false)
  }

  const { getConferenceById, conferenxia, resetConferenxia } = useGetConferenceHelper()
  const { conferenceTheme, getThemes, onSelectTheme, selectedTheme, resetConferenceTheme } = useConferenceThemeHelper()

  const handleOnMinimize = () => {
    setMinimized((prev) => !prev)
  }

  React.useEffect(() => {
    const conferenceId = (value as ConferencePracticeValues).selectedConferenceId
    onSelectTheme(undefined)
    if (conferenceId > 0) {
      getConferenceById(conferenceId)
    } else {
      resetConferenxia()
    }
  }, [value])

  React.useEffect(() => {
    if (conferenxia.conferenceId > 0) {
      getThemes(conferenxia, (value as ConferencePracticeValues).showGeneralChat)
    } else {
      resetConferenceTheme()
    }
  }, [conferenxia, value])

  const updateSelectedConference = React.useCallback(
    (selectedConferenceId: number) => {
      console.log('updateSelectedConference', selectedConferenceId)
      const elementUpdate = {
        ...element,
        value: {
          ...element.value,
          selectedConferenceId,
        } as ConferencePracticeValues,
      }
      updateElement(elementUpdate)
    },
    [element, updateElement]
  )

  const yetSelectedConference = React.useMemo(() => (value as ConferencePracticeValues).selectedConferenceId > 0, [value])

  const showViewHorizontal = React.useMemo(
    () =>
      !selectionConferenceMode &&
      (value as ConferencePracticeValues).conferencePracticeViewMode === ConferencePracticeViewMode.horizontal &&
      yetSelectedConference &&
      !showMembers,
    [value, selectedTheme, selectionConferenceMode, yetSelectedConference, showMembers]
  )
  const showViewChat = React.useMemo(
    () =>
      yetSelectedConference &&
      (value as ConferencePracticeValues).conferencePracticeViewMode === ConferencePracticeViewMode.chat &&
      !showMembers &&
      !selectionConferenceMode,
    [value, isDesign, yetSelectedConference, showMembers, selectionConferenceMode]
  )

  const showOnlyOneThemeView = React.useMemo(
    () =>
      !selectionConferenceMode &&
      !showMembers &&
      yetSelectedConference &&
      (value as ConferencePracticeValues).conferencePracticeViewMode === ConferencePracticeViewMode.column &&
      conferenceTheme.length > 0,
    [value, selectionConferenceMode, showMembers, yetSelectedConference, conferenceTheme]
  )

  const showThemeListSelector = React.useMemo(
    () =>
      !selectionConferenceMode &&
      conferenceTheme.length > 0 &&
      (value as ConferencePracticeValues).selectedConferenceId &&
      (showViewChat || showViewHorizontal || showOnlyOneThemeView),
    [selectionConferenceMode, conferenceTheme, value, showViewChat, showViewHorizontal]
  )

  const showColumnPerThemeView = React.useMemo(
    () =>
      !selectionConferenceMode &&
      !showMembers &&
      yetSelectedConference &&
      (value as ConferencePracticeValues).conferencePracticeViewMode === ConferencePracticeViewMode.themeList &&
      conferenceTheme.length > 0,

    [value, selectionConferenceMode, showMembers, yetSelectedConference, conferenceTheme]
  )

  React.useEffect(() => {
    const notShowGeneralChat = !(value as ConferencePracticeValues).showGeneralChat
    if (notShowGeneralChat && selectedTheme?.themeType === 'G') {
      const conferenceThemeSize = conferenceTheme.length
      const conferenceIndex = conferenceThemeSize > 1 ? 1 : 0
      onSelectTheme(conferenceTheme[conferenceIndex])
    }
  }, [value, conferenceTheme])

  return (
    <Paper
      elevation={2}
      style={{
        height: minimized ? 'auto' : '100%',
        ...style,
        overflow: 'hidden',
      }}
      className={'conferenceContainer'}
    >
      <Box
        style={{
          height: '100%',
          maxHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'nowrap',
          gap: 1,
          position: 'relative',
        }}
      >
        <Header
          conferenceTheme={conferenceTheme}
          conferenxia={conferenxia}
          minimized={minimized}
          handleOnMinimize={handleOnMinimize}
          showMembers={(value as ConferencePracticeValues).showMembersNames && (value as ConferencePracticeValues).selectedConferenceId > 0}
          link={conferenxia?.page ?? undefined}
          handleOnShowMembers={handleShowMembers}
          onRefreshConferenxia={() => {
            setRefresh(true)
          }}
        />

        {!minimized && (
          <Box
            style={{
              height: showColumnPerThemeView ? element.size.height : element.size.height - 80,
              display: 'flex',
              flexDirection: 'column',
              overflow: isDesign ? 'hidden' : 'auto',
            }}
            id="content-conference"
          >
            {showThemeListSelector && (
              <Box py={1} px={3}>
                <ThemeListSelector
                  conferenceTheme={conferenceTheme}
                  onSelectTheme={onSelectTheme}
                  selectedTheme={selectedTheme}
                  conferenceId={(value as ConferencePracticeValues).selectedConferenceId}
                  hiddenAllFilter={showColumnPerThemeView || showOnlyOneThemeView}
                />
              </Box>
            )}

            {selectionConferenceMode && (
              <ConferenceSelector
                updateSelectedConference={updateSelectedConference}
                selectedConferenceId={(value as ConferencePracticeValues).selectedConferenceId}
              />
            )}
            {showViewChat && (
              <Box height={'92%'} width={'100%'} maxHeight={'100%'}>
                <MessageScrollView
                  refresh={refresh}
                  height={element.size.height - 10}
                  conferenceId={(value as ConferencePracticeValues).selectedConferenceId}
                  loadMode={conferenxia.loadMode as 'A' | 'D'}
                  onlyExistOneTheme={false}
                  selectedTheme={selectedTheme}
                  hiddenDate={!(value as ConferencePracticeValues).showDates}
                  hiddenName={!(value as ConferencePracticeValues).showMembersNames}
                  onEndRefresh={onRefreshEnd}
                />
              </Box>
            )}
            {showColumnPerThemeView && (
              <Box pb={2} height={'100%'} width={'100%'} maxHeight={'100%'} pt={2}>
                <ColumnPerThemeView
                  refresh={refresh}
                  conferenceTheme={conferenceTheme}
                  conferenceId={conferenxia.conferenceId}
                  width={element.size.width}
                  loadMode={conferenxia.loadMode as 'A' | 'D'}
                  hiddenDate={!(value as ConferencePracticeValues).showDates}
                  hiddenName={!(value as ConferencePracticeValues).showMembersNames}
                  onEndRefresh={onRefreshEnd}
                />
              </Box>
            )}
            {showOnlyOneThemeView && (
              <Box height={element.size.height} width={'100%'} pb={1}>
                <ConferenceOnlyOneThemeView
                  refresh={refresh}
                  theme={selectedTheme ?? conferenceTheme[0]}
                  conferenceId={(value as ConferencePracticeValues).selectedConferenceId}
                  hiddenDate={!(value as ConferencePracticeValues).showDates}
                  hiddenName={!(value as ConferencePracticeValues).showMembersNames}
                  height={element.size.height - 135}
                  loadMode={conferenxia.loadMode as 'A' | 'D'}
                  onEndRefresh={onRefreshEnd}
                />
              </Box>
            )}
            {showViewHorizontal && (
              <Box height={'87%'} width={'100%'} minHeight={'87%'}>
                <ConferenceHorizontalView
                  refresh={refresh}
                  height={element.size.height - 50}
                  width={element.size.width}
                  selectedTheme={selectedTheme}
                  conferenceId={(value as ConferencePracticeValues).selectedConferenceId}
                  showMembers={(value as ConferencePracticeValues).showMembersNames}
                  showDate={(value as ConferencePracticeValues).showDates}
                  onEndRefresh={onRefreshEnd}
                />
              </Box>
            )}
            {showMembers && conferenxia && !selectionConferenceMode && (
              <Box
                px={2}
                py={2}
                style={{
                  width: '100%',
                  height: '100%',
                  overflow: 'auto',
                }}
              >
                <ConferenceMember conferenceId={conferenxia.conferenceId} />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Paper>
  )
}
