import { CardContainerValues } from "components/CardProps/CardProps"
import { ConferencePracticeValues, ConferencePracticeViewMode } from 'components/Practice/Conference/types'
import { DEFAULT_VALUES } from "./VALUES"


export const ELEMENT_TYPES = {
    text: 'text',
    image: 'image',
    video: 'video',
    file: 'file',
    page: 'page',
    audio: "audio",
    line: "line",
    curtain: "curtain",
    folder: "folder",
    login: "login",
    practiceCard: "practiceCard",
    practiceCardPersonality: "practiceCardPersonality",
    practiceCardGroups: "practiceCardGroups",
    footer: 'footer',
    header: 'header',
    practiceConference: 'practiceConference'
}

export const ELEMENT_NAMES = {
    text: 'Texto',
    image: 'Imagen',
    video: 'Video',
    file: 'Archivo',
    page: 'Página',
    audio: "Audio",
    line: "Línea",
    curtain: "Cortina",
    folder: "Folder",
    login: "Login",
    practiceCard: "Tarjetas",
    practiceCardPersonality: "Mis Personas",
    practiceCardGroups: "Grupos",
    header: 'Cabecera',
    practiceConference: 'Conferenxia'

}

export const WORD_EXTENSIONS = ["doc", "docx"]
export const EXCEL_EXTENSIONS = ["xls", "xlsx", "csv"]
export const POWERPOINT_EXTENSIONS = ["ppt", "pptx"]
export const TXT_EXTENSIONS = ["txt"]
export const PDF_EXTENSIONS = ["pdf"]
export const XML_EXTENSIONS = ["xml"]
export const ZIP_EXTENSIONS = ["zip", "rar"]

export const DEFAULT_TEXT_VALUE = [{ type: 'paragraph', children: [{ text: '' }] }]
export const DEFAULT_LINE_VALUE: React.CSSProperties = { height: 1, backgroundColor: "#000", borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0 }
export const DEFAULT_CURTAIN_VALUE = { text: true, textValue: DEFAULT_TEXT_VALUE, newTab: false, link: "", tooltip: "" }

export const DEFAULT_LOGIN_VALUE = DEFAULT_VALUES.login

export const DEFAULT_PRACTICE_CARD_VALUE: CardContainerValues = DEFAULT_VALUES.cardPerxona

export const DEFAULT_VALUES_CONFERENCE: ConferencePracticeValues = {
    conferencePracticeViewMode: ConferencePracticeViewMode.chat,
    ownerId: 0,
    selectedConferenceId: 0,
    initMinimized: false,
    showDates: true,
    showGeneralChat: true,
    showMembersNames: true,
    // minHeight: 600,
    minWidth: 400

}
