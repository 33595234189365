import React from 'react'
import { Icon, Popover, Tooltip, Typography, IconButton, Checkbox, FormControlLabel, FormGroup, TextField, MenuItem, Slider } from '@material-ui/core'
import { IElement, IRGBAColor } from 'types'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { SketchPicker } from 'react-color'
import { BORDER_RADIUS_UNITS_OPTIONS, BORDER_STYLE_OPTIONS, ELEMENT_TYPES } from 'lib'
import { getJSDocReturnType } from 'typescript'

interface Props {
    element: IElement,
    updateElement: (newElement: IElement) => void
}

interface PopoverConfig {
    element: HTMLButtonElement | null,
    id: string
}

interface BorderCorners {
    borderTopLeftRadius: number,
    borderTopRightRadius: number,
    borderBottomRightRadius: number,
    borderBottomLeftRadius: number
}

const marginRight = 8
const popoverElements = {
    bgColor: "bgColor",
    border: "border",
    shadow: "shadow",
    opacity: "opacity"
}
const boxShadowIndex = {
    x: 0,
    y: 1,
    blur: 2,
    spread: 3,
    color: 4
}
const defautlBoxShadow = "0px 0px 0px 0px rgba(0,0,0,1)"

const Styles = React.memo((props: Props) => {
    const { updateElement } = props
    const [bgColor, setBgColor] = React.useState<IRGBAColor>({} as IRGBAColor)
    const [borderColor, setBorderColor] = React.useState<IRGBAColor>({} as IRGBAColor)
    const [borderStyle, setBorderStyle] = React.useState<any>(props.element.style.borderStyle)
    const [borderRadiusUnit, setBorderRadiusUnit] = React.useState<string>(props.element.borderRadiusUnit ? props.element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value)
    const [borderWidth, setBorderWidth] = React.useState<number>(props.element.style.borderWidth ? parseInt(props.element.style.borderWidth.toString()) : 0)
    const [borders, setBorders] = React.useState<BorderCorners>({
        borderBottomLeftRadius: props.element.style.borderBottomLeftRadius ? parseInt(props.element.style.borderBottomLeftRadius.toString()) : 0,
        borderBottomRightRadius: props.element.style.borderBottomRightRadius ? parseInt(props.element.style.borderBottomRightRadius.toString()) : 0,
        borderTopRightRadius: props.element.style.borderTopRightRadius ? parseInt(props.element.style.borderTopRightRadius.toString()) : 0,
        borderTopLeftRadius: props.element.style.borderTopLeftRadius ? parseInt(props.element.style.borderTopLeftRadius.toString()) : 0,
    })
    const [boxShadownX, setBoxShadowX] = React.useState<number>(0)
    const [boxShadownY, setBoxShadowY] = React.useState<number>(0)
    const [boxShadownBlur, setBoxShadowBlur] = React.useState<number>(0)
    const [boxShadownSpread, setBoxShadowSpread] = React.useState<number>(0)
    const [boxShadownColor, setBoxShadowColor] = React.useState<IRGBAColor>({ r: 0, g: 0, b: 0, a: 1, value: "rgab(0,0,0,1)" } as IRGBAColor)
    const [allBorders, setAllborders] = React.useState<number>(0)
    const [opacityPercentage, setOpacityPercentage] = React.useState<number>(props.element.style.opacity ? parseFloat(props.element.style.opacity.toString()) * 100 : props.element.style.opacity === undefined ? 100 : 0)
    const [opacity, setOpacity] = React.useState<number>(props.element.style.opacity ? parseFloat(props.element.style.opacity.toString()) : props.element.style.opacity === undefined ? 1 : 0)
    const [popover, setPopover] = React.useState<PopoverConfig>({} as PopoverConfig)
    const handleClosePopover = () => setPopover({} as PopoverConfig)
    const transformRGBAString = (color: any): IRGBAColor => {
        if (!color) {
            return { r: 255, g: 255, b: 255, a: 0, value: "rgba(255,255,255,0)" }
        }
        const stringValues: string[] = color.replace("rgba", "").replace("(", "").replace(")", "").split(",")
        const numericValues: number[] = []
        for (const stringValue of stringValues) {
            numericValues.push(parseFloat(stringValue))
        }
        return { r: numericValues[0], g: numericValues[1], b: numericValues[2], a: numericValues[3], value: color }
    }
    const getBoxShadowProp = (index: number, boxShadow: string | undefined): number => {
        if (!boxShadow) {
            boxShadow = defautlBoxShadow
        }
        const components = boxShadow.split(" ")
        return parseInt(components[index].replace("px", ""))
    }
    const getBoxShadowColorProp = (boxShadow: string | undefined): string => {
        if (!boxShadow) {
            boxShadow = defautlBoxShadow
        }
        const components = boxShadow.split(" ")
        return components[boxShadowIndex.color].replace("px", "")
    }
    const handleCommitChanges = () => {
        updateElement({
            ...props.element,
            borderRadiusUnit,
            style: {
                ...props.element.style,
                borderColor: borderColor.value,
                backgroundColor: bgColor.value,
                borderStyle: borderStyle,
                borderWidth: borderWidth,
                borderTopLeftRadius: borders.borderTopLeftRadius,
                borderTopRightRadius: borders.borderTopRightRadius,
                borderBottomLeftRadius: borders.borderBottomLeftRadius,
                borderBottomRightRadius: borders.borderBottomRightRadius,
                boxShadow: `${boxShadownX}px ${boxShadownY}px ${boxShadownBlur}px ${boxShadownSpread}px ${boxShadownColor.value}`,
                opacity
            }
        })
    }
    React.useEffect(() => {
        setBgColor(transformRGBAString(props.element.style.backgroundColor))
        setBorderColor(transformRGBAString(props.element.style.borderColor))
        setBorderStyle(props.element.style.borderStyle)
        setBorderWidth((props.element.style.borderWidth ? parseInt(props.element.style.borderWidth.toString()) : 0))
        setOpacity(props.element.style.opacity ? parseFloat(props.element.style.opacity.toString()) : props.element.style.opacity === undefined ? 1 : 0)
        setOpacityPercentage(props.element.style.opacity ? parseFloat(props.element.style.opacity.toString()) * 100 : props.element.style.opacity === undefined ? 100 : 0)
        setBorders({
            borderBottomLeftRadius: props.element.style.borderBottomLeftRadius ? parseInt(props.element.style.borderBottomLeftRadius.toString()) : 0,
            borderBottomRightRadius: props.element.style.borderBottomRightRadius ? parseInt(props.element.style.borderBottomRightRadius.toString()) : 0,
            borderTopRightRadius: props.element.style.borderTopRightRadius ? parseInt(props.element.style.borderTopRightRadius.toString()) : 0,
            borderTopLeftRadius: props.element.style.borderTopLeftRadius ? parseInt(props.element.style.borderTopLeftRadius.toString()) : 0,
        })
        setBoxShadowX(getBoxShadowProp(boxShadowIndex.x, props.element.style.boxShadow))
        setBoxShadowY(getBoxShadowProp(boxShadowIndex.y, props.element.style.boxShadow))
        setBoxShadowSpread(getBoxShadowProp(boxShadowIndex.spread, props.element.style.boxShadow))
        setBoxShadowBlur(getBoxShadowProp(boxShadowIndex.blur, props.element.style.boxShadow))
        setBoxShadowColor(transformRGBAString(getBoxShadowColorProp(props.element.style.boxShadow)))
        setBorderRadiusUnit(props.element.borderRadiusUnit ? props.element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value)
    }, [props.element])
    return (
        <React.Fragment>
            <div className="panel-container px-2 pt-4">
                <Typography color="primary" className="panel-label" variant="caption">{props.element.type !== ELEMENT_TYPES.header ? "Estilo y nivel" : "Estilo"}</Typography>
                <ToggleButtonGroup size="small">
                    <Tooltip arrow title="Color de fondo" placement="left">
                        <ToggleButton size="small" value="bg_color" onClick={(e) => setPopover({ element: e.currentTarget, id: popoverElements.bgColor })}>
                            <Icon fontSize="small">format_color_fill</Icon>
                            <Icon fontSize="small">expand_more</Icon>
                        </ToggleButton>
                    </Tooltip>

                    {
                        props.element.type !== ELEMENT_TYPES.header && <React.Fragment>
                            <Tooltip arrow title="Borde" placement="left">
                                <ToggleButton size="small" value="border_color" onClick={(e) => setPopover({ element: e.currentTarget, id: popoverElements.border })}>
                                    <Icon fontSize="small">border_color</Icon>
                                    <Icon fontSize="small">expand_more</Icon>
                                </ToggleButton>
                            </Tooltip>
                            <Tooltip arrow title="Sombreado" placement="left">
                                <ToggleButton size="small" value="shadow" onClick={(e) => setPopover({ element: e.currentTarget, id: popoverElements.shadow })}>
                                    <Icon fontSize="small">beach_access</Icon>
                                    <Icon fontSize="small">expand_more</Icon>
                                </ToggleButton>
                            </Tooltip>
                            {props.element.type !== ELEMENT_TYPES.login && <Tooltip arrow title="Opacidad" placement="left">
                                <ToggleButton size="small" value="opacity" onClick={(e) => setPopover({ element: e.currentTarget, id: popoverElements.opacity })}>
                                    <Icon fontSize="small">opacity</Icon>
                                    <Icon fontSize="small">expand_more</Icon>
                                </ToggleButton>
                            </Tooltip>}
                        </React.Fragment>
                    }
                </ToggleButtonGroup>
            </div>
            <Popover
                id={popoverElements.bgColor}
                anchorEl={popover.element}
                open={popover.id === popoverElements.bgColor && Boolean(popover.element)}
                onClose={handleClosePopover}
            >
                <div className={"flex items-center mt-1"}>
                    <Typography align="center" className="pl-4 w-full flex-grow" variant="caption" style={{ letterSpacing: 0.5 }}>
                        {"Color de fondo"}
                    </Typography>
                    <IconButton style={{ padding: 4 }} onMouseDown={handleClosePopover}>
                        <Icon style={{ fontSize: 15 }}>close</Icon>
                    </IconButton>
                </div>
                <SketchPicker
                    color={bgColor.value}
                    onChange={(color) => {
                        setBgColor({
                            r: color.rgb.r,
                            g: color.rgb.g,
                            b: color.rgb.g,
                            a: color.rgb.a,
                            value: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
                        })
                    }}
                    onChangeComplete={(color) => {
                        updateElement({
                            ...props.element,
                            borderRadiusUnit,
                            style: {
                                ...props.element.style,
                                backgroundColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`,
                                borderColor: borderColor.value,
                                borderStyle: borderStyle,
                                borderWidth: borderWidth,
                                borderTopLeftRadius: borders.borderTopLeftRadius,
                                borderTopRightRadius: borders.borderTopRightRadius,
                                borderBottomLeftRadius: borders.borderBottomLeftRadius,
                                borderBottomRightRadius: borders.borderBottomRightRadius,
                                boxShadow: `${boxShadownX}px ${boxShadownY}px ${boxShadownBlur}px ${boxShadownSpread}px ${boxShadownColor.value}`,
                                opacity
                            }
                        })
                    }}
                />
                <FormGroup style={{ marginLeft: marginRight }}>
                    <FormControlLabel
                        className="p-2"
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setBgColor({
                                        ...bgColor,
                                        a: e.target.checked ? 0 : 1,
                                        value: `rgba(${bgColor.r}, ${bgColor.g}, ${bgColor.b}, ${e.target.checked ? 0 : 1})`
                                    })
                                    updateElement({
                                        ...props.element,
                                        borderRadiusUnit,
                                        style: {
                                            ...props.element.style,
                                            backgroundColor: `rgba(${bgColor.r}, ${bgColor.g}, ${bgColor.b}, ${e.target.checked ? 0 : 1})`,
                                            borderColor: borderColor.value,
                                            borderStyle: borderStyle,
                                            borderWidth: borderWidth,
                                            borderTopLeftRadius: borders.borderTopLeftRadius,
                                            borderTopRightRadius: borders.borderTopRightRadius,
                                            borderBottomLeftRadius: borders.borderBottomLeftRadius,
                                            borderBottomRightRadius: borders.borderBottomRightRadius,
                                            boxShadow: `${boxShadownX}px ${boxShadownY}px ${boxShadownBlur}px ${boxShadownSpread}px ${boxShadownColor.value}`,
                                            opacity
                                        }
                                    })
                                }}
                                color="primary"
                                size="small"
                                checked={bgColor.a === 0}
                                name="mirror"
                            />}
                        label="Transparente"
                    />
                </FormGroup>
            </Popover>
            <Popover
                id={popoverElements.border}
                anchorEl={popover.element}
                open={popover.id === popoverElements.border && Boolean(popover.element)}
                onClose={handleClosePopover}
                classes={{ paper: "overflow-y-hidden" }}
            >
                <div className={"flex items-center mt-1"}>
                    <Typography align="center" className="pl-4 w-full flex-grow" variant="caption" style={{ letterSpacing: 0.5 }}>
                        {"Estilo del borde"}
                    </Typography>
                    <IconButton style={{ padding: 4 }} onMouseDown={handleClosePopover}>
                        <Icon style={{ fontSize: 15 }}>close</Icon>
                    </IconButton>
                </div>
                <div className="flex">
                    <SketchPicker
                        color={borderColor.value}
                        onChange={(color) => {
                            setBorderColor({
                                r: color.rgb.r,
                                g: color.rgb.g,
                                b: color.rgb.g,
                                a: color.rgb.a,
                                value: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
                            })
                        }}
                        onChangeComplete={(color) => {
                            updateElement({
                                ...props.element,
                                borderRadiusUnit,
                                style: {
                                    ...props.element.style,
                                    borderColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`,
                                    backgroundColor: bgColor.value,
                                    borderStyle: borderStyle,
                                    borderWidth: borderWidth,
                                    borderTopLeftRadius: borders.borderTopLeftRadius,
                                    borderTopRightRadius: borders.borderTopRightRadius,
                                    borderBottomLeftRadius: borders.borderBottomLeftRadius,
                                    borderBottomRightRadius: borders.borderBottomRightRadius,
                                    boxShadow: `${boxShadownX}px ${boxShadownY}px ${boxShadownBlur}px ${boxShadownSpread}px ${boxShadownColor.value}`,
                                    opacity
                                }
                            })
                        }}
                    />
                    <div className="pr-2 mt-2" style={{ maxWidth: 350 }}>
                        <div className="flex">
                            <TextField
                                size="small"
                                label="Estilo"
                                variant="outlined"
                                classes={{ root: "text-input-sm" }}
                                value={borderStyle}
                                select
                                SelectProps={{ IconComponent: (iconProps) => <Icon {...iconProps} style={{ paddingTop: 2 }} fontSize="small">expand_more</Icon> }}
                                fullWidth
                                style={{ marginRight, flex: 0.5 }}
                                onChange={(e) => {
                                    setBorderStyle(e.target.value)
                                    updateElement({
                                        ...props.element,
                                        borderRadiusUnit,
                                        style: {
                                            ...props.element.style,
                                            borderColor: borderColor.value,
                                            backgroundColor: bgColor.value,
                                            borderStyle: e.target.value,
                                            borderWidth: borderWidth,
                                            borderTopLeftRadius: borders.borderTopLeftRadius,
                                            borderTopRightRadius: borders.borderTopRightRadius,
                                            borderBottomLeftRadius: borders.borderBottomLeftRadius,
                                            borderBottomRightRadius: borders.borderBottomRightRadius,
                                            boxShadow: `${boxShadownX}px ${boxShadownY}px ${boxShadownBlur}px ${boxShadownSpread}px ${boxShadownColor.value}`,
                                            opacity
                                        }
                                    })
                                }}
                            >
                                {BORDER_STYLE_OPTIONS.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                size="small"
                                label="Unidades"
                                variant="outlined"
                                classes={{ root: "text-input-sm" }}
                                value={borderRadiusUnit}
                                select
                                SelectProps={{ IconComponent: (iconProps) => <Icon {...iconProps} style={{ paddingTop: 2 }} fontSize="small">expand_more</Icon> }}
                                fullWidth
                                style={{ marginRight, flex: 0.25 }}
                                onChange={(e) => {
                                    setBorderRadiusUnit(e.target.value)
                                    updateElement({
                                        ...props.element,
                                        borderRadiusUnit: e.target.value,
                                        style: {
                                            ...props.element.style,
                                            borderColor: borderColor.value,
                                            backgroundColor: bgColor.value,
                                            borderStyle: borderStyle,
                                            borderWidth: borderWidth,
                                            borderTopLeftRadius: borders.borderTopLeftRadius,
                                            borderTopRightRadius: borders.borderTopRightRadius,
                                            borderBottomLeftRadius: borders.borderBottomLeftRadius,
                                            borderBottomRightRadius: borders.borderBottomRightRadius,
                                            boxShadow: `${boxShadownX}px ${boxShadownY}px ${boxShadownBlur}px ${boxShadownSpread}px ${boxShadownColor.value}`,
                                            opacity
                                        }
                                    })
                                }}
                            >
                                {BORDER_RADIUS_UNITS_OPTIONS.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                size="small"
                                label="Tamaño"
                                variant="outlined"
                                fullWidth
                                classes={{ root: "text-input-sm" }}
                                style={{ flex: 0.25 }}
                                value={borderWidth}
                                InputProps={{ inputProps: { min: 0 } }}
                                type="number"
                                onChange={(e) => {
                                    setBorderWidth(e.target.value ? parseInt(e.target.value.replace("-", "")) : 0)
                                    updateElement({
                                        ...props.element,
                                        borderRadiusUnit,
                                        style: {
                                            ...props.element.style,
                                            borderColor: borderColor.value,
                                            backgroundColor: bgColor.value,
                                            borderStyle: borderStyle,
                                            borderWidth: e.target.value ? parseInt(e.target.value.replace("-", "")) : 0,
                                            borderTopLeftRadius: borders.borderTopLeftRadius,
                                            borderTopRightRadius: borders.borderTopRightRadius,
                                            borderBottomLeftRadius: borders.borderBottomLeftRadius,
                                            borderBottomRightRadius: borders.borderBottomRightRadius,
                                            boxShadow: `${boxShadownX}px ${boxShadownY}px ${boxShadownBlur}px ${boxShadownSpread}px ${boxShadownColor.value}`,
                                            opacity
                                        }
                                    })
                                }}
                            />
                        </div>
                        <Typography align="center" className="w-full flex-grow" variant="caption" style={{ letterSpacing: 0.5 }}>
                            {"Redondeado"}
                        </Typography>
                        <div className="flex flex-col">
                            <Typography className="w-full flex-grow" variant="caption" style={{ fontSize: "0.55em" }}>
                                {"Esquina superior izquierda"}
                            </Typography>
                            <div className="flex items-center">
                                <Icon fontSize="small">border_style</Icon>
                                <div className="flex flex-grow mx-2">
                                    <Slider
                                        className="mr-2"
                                        value={borders.borderTopLeftRadius}
                                        min={0}
                                        max={50}
                                        step={1}
                                        onChange={(e, newValue) => {
                                            setBorders({ ...borders, borderTopLeftRadius: newValue as number })
                                        }}
                                        onChangeCommitted={(e, newValue) => {
                                            updateElement({
                                                ...props.element,
                                                borderRadiusUnit,
                                                style: {
                                                    ...props.element.style,
                                                    borderColor: borderColor.value,
                                                    backgroundColor: bgColor.value,
                                                    borderStyle: borderStyle,
                                                    borderWidth: borderWidth,
                                                    borderTopLeftRadius: newValue as number,
                                                    borderTopRightRadius: borders.borderTopRightRadius,
                                                    borderBottomLeftRadius: borders.borderBottomLeftRadius,
                                                    borderBottomRightRadius: borders.borderBottomRightRadius,
                                                    boxShadow: `${boxShadownX}px ${boxShadownY}px ${boxShadownBlur}px ${boxShadownSpread}px ${boxShadownColor.value}`,
                                                    opacity
                                                }
                                            })
                                        }}
                                    />
                                </div>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    classes={{ root: "text-input-sm" }}
                                    style={{ flex: 0.3 }}
                                    value={borders.borderTopLeftRadius}
                                    onChange={(e) => {
                                        setBorders({ ...borders, borderTopLeftRadius: e.target.value ? parseInt(e.target.value) : 0 })
                                    }}
                                    onBlur={handleCommitChanges}
                                    type="number"
                                    onKeyDown={(e) => { if (e.key === "Enter") { handleCommitChanges() } }}
                                    InputProps={{ inputProps: { min: 0, max: 50 } }}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <Typography className="w-full flex-grow" variant="caption" style={{ fontSize: "0.55em" }}>
                                {"Esquina superior derecha"}
                            </Typography>
                            <div className="flex items-center">
                                <Icon style={{ transform: "rotate(90deg)" }} fontSize="small">border_style</Icon>
                                <div className="flex flex-grow mx-2">
                                    <Slider
                                        className="mr-2"
                                        value={borders.borderTopRightRadius}
                                        min={0}
                                        max={50}
                                        step={1}
                                        onChange={(e, newValue) => {
                                            setBorders({ ...borders, borderTopRightRadius: newValue as number })
                                        }}
                                        onChangeCommitted={(e, newValue) => {
                                            updateElement({
                                                ...props.element,
                                                borderRadiusUnit,
                                                style: {
                                                    ...props.element.style,
                                                    borderColor: borderColor.value,
                                                    backgroundColor: bgColor.value,
                                                    borderStyle: borderStyle,
                                                    borderWidth: borderWidth,
                                                    borderTopRightRadius: newValue as number,
                                                    borderTopLeftRadius: borders.borderTopLeftRadius,
                                                    borderBottomLeftRadius: borders.borderBottomLeftRadius,
                                                    borderBottomRightRadius: borders.borderBottomRightRadius,
                                                    boxShadow: `${boxShadownX}px ${boxShadownY}px ${boxShadownBlur}px ${boxShadownSpread}px ${boxShadownColor.value}`
                                                }
                                            })
                                        }}
                                    />
                                </div>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    classes={{ root: "text-input-sm" }}
                                    style={{ flex: 0.3 }}
                                    value={borders.borderTopRightRadius}
                                    onChange={(e) => {
                                        setBorders({ ...borders, borderTopRightRadius: e.target.value ? parseInt(e.target.value) : 0 })
                                    }}
                                    onBlur={handleCommitChanges}
                                    type="number"
                                    onKeyDown={(e) => { if (e.key === "Enter") { handleCommitChanges() } }}
                                    InputProps={{ inputProps: { min: 0, max: 50 } }}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <Typography className="w-full flex-grow" variant="caption" style={{ fontSize: "0.55em" }}>
                                {"Esquina inferior derecha"}
                            </Typography>
                            <div className="flex items-center">
                                <Icon style={{ transform: "rotate(180deg)" }} fontSize="small">border_style</Icon>
                                <div className="flex flex-grow mx-2">
                                    <Slider
                                        className="mr-2"
                                        value={borders.borderBottomRightRadius}
                                        min={0}
                                        max={50}
                                        step={1}
                                        onChange={(e, newValue) => {
                                            setBorders({ ...borders, borderBottomRightRadius: newValue as number })
                                        }}
                                        onChangeCommitted={(e, newValue) => {
                                            updateElement({
                                                ...props.element,
                                                borderRadiusUnit,
                                                style: {
                                                    ...props.element.style,
                                                    borderColor: borderColor.value,
                                                    backgroundColor: bgColor.value,
                                                    borderStyle: borderStyle,
                                                    borderWidth: borderWidth,
                                                    borderBottomRightRadius: newValue as number,
                                                    borderTopLeftRadius: borders.borderTopLeftRadius,
                                                    borderBottomLeftRadius: borders.borderBottomLeftRadius,
                                                    borderTopRightRadius: borders.borderTopRightRadius,
                                                    boxShadow: `${boxShadownX}px ${boxShadownY}px ${boxShadownBlur}px ${boxShadownSpread}px ${boxShadownColor.value}`,
                                                    opacity
                                                }
                                            })
                                        }}
                                    />
                                </div>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    classes={{ root: "text-input-sm" }}
                                    style={{ flex: 0.3 }}
                                    value={borders.borderBottomRightRadius}
                                    onChange={(e) => {
                                        setBorders({ ...borders, borderBottomRightRadius: e.target.value ? parseInt(e.target.value) : 0 })
                                    }}
                                    onBlur={handleCommitChanges}
                                    type="number"
                                    onKeyDown={(e) => { if (e.key === "Enter") { handleCommitChanges() } }}
                                    InputProps={{ inputProps: { min: 0, max: 50 } }}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <Typography className="w-full flex-grow" variant="caption" style={{ fontSize: "0.55em" }}>
                                {"Esquina inferior izquierda"}
                            </Typography>
                            <div className="flex items-center">
                                <Icon style={{ transform: "rotate(270deg)" }} fontSize="small">border_style</Icon>
                                <div className="flex flex-grow mx-2">
                                    <Slider
                                        className="mr-2"
                                        value={borders.borderBottomLeftRadius}
                                        min={0}
                                        max={50}
                                        step={1}
                                        onChange={(e, newValue) => {
                                            setBorders({ ...borders, borderBottomLeftRadius: newValue as number })
                                        }}
                                        onChangeCommitted={(e, newValue) => {
                                            updateElement({
                                                ...props.element,
                                                borderRadiusUnit,
                                                style: {
                                                    ...props.element.style,
                                                    borderColor: borderColor.value,
                                                    backgroundColor: bgColor.value,
                                                    borderStyle: borderStyle,
                                                    borderWidth: borderWidth,
                                                    borderBottomLeftRadius: newValue as number,
                                                    borderTopLeftRadius: borders.borderTopLeftRadius,
                                                    borderBottomRightRadius: borders.borderBottomRightRadius,
                                                    borderTopRightRadius: borders.borderTopRightRadius,
                                                    boxShadow: `${boxShadownX}px ${boxShadownY}px ${boxShadownBlur}px ${boxShadownSpread}px ${boxShadownColor.value}`,
                                                    opacity
                                                }
                                            })
                                        }}
                                    />
                                </div>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    classes={{ root: "text-input-sm" }}
                                    style={{ flex: 0.3 }}
                                    value={borders.borderBottomLeftRadius}
                                    onChange={(e) => { setBorders({ ...borders, borderBottomLeftRadius: e.target.value ? parseInt(e.target.value) : 0 }) }}
                                    onBlur={handleCommitChanges}
                                    type="number"
                                    onKeyDown={(e) => { if (e.key === "Enter") { handleCommitChanges() } }}
                                    InputProps={{ inputProps: { min: 0, max: 50 } }}
                                />
                            </div>
                        </div>
                        <Typography align="center" className="w-full flex-grow" variant="caption" style={{ letterSpacing: 0.5 }}>
                            {"Aplicar a todos"}
                        </Typography>
                        <div className="flex flex-col">
                            <div className="flex items-center">
                                <Icon fontSize="small">border_all</Icon>
                                <div className="flex flex-grow mx-2">
                                    <Slider
                                        className="mr-2"
                                        min={0}
                                        max={50}
                                        step={1}
                                        value={allBorders}
                                        onChange={(e, newValue) => {
                                            setBorders({ borderBottomLeftRadius: newValue as number, borderBottomRightRadius: newValue as number, borderTopLeftRadius: newValue as number, borderTopRightRadius: newValue as number })
                                            setAllborders(newValue as number)
                                        }}
                                        onChangeCommitted={(e, newValue) => {
                                            updateElement({
                                                ...props.element,
                                                borderRadiusUnit,
                                                style: {
                                                    ...props.element.style,
                                                    borderColor: borderColor.value,
                                                    backgroundColor: bgColor.value,
                                                    borderStyle: borderStyle,
                                                    borderWidth: borderWidth,
                                                    borderBottomLeftRadius: newValue as number,
                                                    borderTopLeftRadius: newValue as number,
                                                    borderBottomRightRadius: newValue as number,
                                                    borderTopRightRadius: newValue as number,
                                                    boxShadow: `${boxShadownX}px ${boxShadownY}px ${boxShadownBlur}px ${boxShadownSpread}px ${boxShadownColor.value}`,
                                                    opacity
                                                }
                                            })
                                        }}
                                    />
                                </div>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    classes={{ root: "text-input-sm" }}
                                    style={{ flex: 0.3 }}
                                    type="number"
                                    value={allBorders}
                                    onChange={(e) => {
                                        setBorders({
                                            borderBottomLeftRadius: e.target.value ? parseInt(e.target.value) : 0,
                                            borderBottomRightRadius: e.target.value ? parseInt(e.target.value) : 0,
                                            borderTopLeftRadius: e.target.value ? parseInt(e.target.value) : 0,
                                            borderTopRightRadius: e.target.value ? parseInt(e.target.value) : 0
                                        })
                                        setAllborders(e.target.value ? parseInt(e.target.value) : 0)
                                    }}
                                    onBlur={handleCommitChanges}
                                    onKeyDown={(e) => { if (e.key === "Enter") { handleCommitChanges() } }}
                                    InputProps={{ inputProps: { min: 0, max: 50 } }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Popover>
            <Popover
                id={popoverElements.shadow}
                anchorEl={popover.element}
                open={popover.id === popoverElements.shadow && Boolean(popover.element)}
                onClose={handleClosePopover}
                classes={{ paper: "overflow-y-hidden" }}
            >
                <div className={"flex items-center mt-1"}>
                    <Typography align="center" className="pl-4 w-full flex-grow" variant="caption" style={{ letterSpacing: 0.5 }}>
                        {"Sombreado"}
                    </Typography>
                    <IconButton style={{ padding: 4 }} onMouseDown={handleClosePopover}>
                        <Icon style={{ fontSize: 15 }}>close</Icon>
                    </IconButton>
                </div>
                <div className="flex">
                    <SketchPicker
                        color={boxShadownColor.value}
                        onChange={(color) => {
                            setBoxShadowColor({
                                r: color.rgb.r,
                                g: color.rgb.g,
                                b: color.rgb.g,
                                a: color.rgb.a,
                                value: `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
                            })
                        }}
                        onChangeComplete={(color) => {
                            updateElement({
                                ...props.element,
                                borderRadiusUnit,
                                style: {
                                    ...props.element.style,
                                    borderColor: borderColor.value,
                                    backgroundColor: bgColor.value,
                                    borderStyle: borderStyle,
                                    borderWidth: borderWidth,
                                    borderTopLeftRadius: borders.borderTopLeftRadius,
                                    borderTopRightRadius: borders.borderTopRightRadius,
                                    borderBottomLeftRadius: borders.borderBottomLeftRadius,
                                    borderBottomRightRadius: borders.borderBottomRightRadius,
                                    boxShadow: `${boxShadownX}px ${boxShadownY}px ${boxShadownBlur}px ${boxShadownSpread}px rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`,
                                    opacity
                                }
                            })
                        }}
                    />
                    <div className="pr-2 mt-2">
                        <div className="flex flex-col">
                            <Typography className="w-full flex-grow" variant="caption" style={{ fontSize: "0.55em" }}>
                                {"Desplazamiento horizontal"}
                            </Typography>
                            <div className="flex items-center">
                                <Icon fontSize="small">swap_horiz</Icon>
                                <div className="flex flex-grow mx-2">
                                    <Slider
                                        value={boxShadownX}
                                        max={50}
                                        min={-50}
                                        step={1}
                                        onChange={(e, newValue) => { setBoxShadowX(newValue as number) }}
                                        onChangeCommitted={(e, newValue) => {
                                            updateElement({
                                                ...props.element,
                                                borderRadiusUnit,
                                                style: {
                                                    ...props.element.style,
                                                    borderColor: borderColor.value,
                                                    backgroundColor: bgColor.value,
                                                    borderStyle: borderStyle,
                                                    borderWidth: borderWidth,
                                                    borderBottomLeftRadius: borders.borderBottomLeftRadius,
                                                    borderTopLeftRadius: borders.borderTopLeftRadius,
                                                    borderBottomRightRadius: borders.borderBottomRightRadius,
                                                    borderTopRightRadius: borders.borderTopRightRadius,
                                                    boxShadow: `${newValue}px ${boxShadownY}px ${boxShadownBlur}px ${boxShadownSpread}px ${boxShadownColor.value}`,
                                                    opacity
                                                }
                                            })
                                        }}
                                    />
                                </div>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    classes={{ root: "text-input-sm" }}
                                    style={{ flex: 0.5 }}
                                    value={boxShadownX}
                                    type="number"
                                    onChange={(e) => { setBoxShadowX(e.target.value ? parseInt(e.target.value) : 0) }}
                                    onBlur={handleCommitChanges}
                                    onKeyDown={(e) => { if (e.key === "Enter") { handleCommitChanges() } }}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <Typography className="w-full flex-grow" variant="caption" style={{ fontSize: "0.55em" }}>
                                {"Desplazamiento vertical"}
                            </Typography>
                            <div className="flex items-center">
                                <Icon fontSize="small">swap_vert</Icon>
                                <div className="flex flex-grow mx-2">
                                    <Slider
                                        value={boxShadownY}
                                        max={50}
                                        min={-50}
                                        step={1}
                                        onChange={(e, newValue) => { setBoxShadowY(newValue as number) }}
                                        onChangeCommitted={(e, newValue) => {
                                            updateElement({
                                                ...props.element,
                                                borderRadiusUnit,
                                                style: {
                                                    ...props.element.style,
                                                    borderColor: borderColor.value,
                                                    backgroundColor: bgColor.value,
                                                    borderStyle: borderStyle,
                                                    borderWidth: borderWidth,
                                                    borderBottomLeftRadius: borders.borderBottomLeftRadius,
                                                    borderTopLeftRadius: borders.borderTopLeftRadius,
                                                    borderBottomRightRadius: borders.borderBottomRightRadius,
                                                    borderTopRightRadius: borders.borderTopRightRadius,
                                                    boxShadow: `${boxShadownX}px ${newValue}px ${boxShadownBlur}px ${boxShadownSpread}px ${boxShadownColor.value}`,
                                                    opacity
                                                }
                                            })
                                        }}
                                    />
                                </div>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    classes={{ root: "text-input-sm" }}
                                    style={{ flex: 0.5 }}
                                    value={boxShadownY}
                                    type="number"
                                    onChange={(e) => { setBoxShadowY(e.target.value ? parseInt(e.target.value) : 0) }}
                                    onBlur={handleCommitChanges}
                                    onKeyDown={(e) => { if (e.key === "Enter") { handleCommitChanges() } }}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <Typography className="w-full flex-grow" variant="caption" style={{ fontSize: "0.55em" }}>
                                {"Difuminación"}
                            </Typography>
                            <div className="flex items-center">
                                <Icon style={{ transform: "rotate(180deg)" }} fontSize="small">blur_on</Icon>
                                <div className="flex flex-grow mx-2">
                                    <Slider
                                        value={boxShadownBlur}
                                        max={100}
                                        min={0}
                                        step={1}
                                        onChange={(e, newValue) => { setBoxShadowBlur(newValue as number) }}
                                        onChangeCommitted={(e, newValue) => {
                                            updateElement({
                                                ...props.element,
                                                borderRadiusUnit,
                                                style: {
                                                    ...props.element.style,
                                                    borderColor: borderColor.value,
                                                    backgroundColor: bgColor.value,
                                                    borderStyle: borderStyle,
                                                    borderWidth: borderWidth,
                                                    borderBottomLeftRadius: borders.borderBottomLeftRadius,
                                                    borderTopLeftRadius: borders.borderTopLeftRadius,
                                                    borderBottomRightRadius: borders.borderBottomRightRadius,
                                                    borderTopRightRadius: borders.borderTopRightRadius,
                                                    boxShadow: `${boxShadownX}px ${boxShadownY}px ${newValue}px ${boxShadownSpread}px ${boxShadownColor.value}`,
                                                    opacity
                                                }
                                            })
                                        }}
                                    />
                                </div>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    classes={{ root: "text-input-sm" }}
                                    style={{ flex: 0.5 }}
                                    value={boxShadownBlur}
                                    type="number"
                                    onChange={(e) => { setBoxShadowBlur(e.target.value ? parseInt(e.target.value) : 0) }}
                                    onBlur={handleCommitChanges}
                                    onKeyDown={(e) => { if (e.key === "Enter") { handleCommitChanges() } }}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <Typography className="w-full flex-grow" variant="caption" style={{ fontSize: "0.55em" }}>
                                {"Propagación"}
                            </Typography>
                            <div className="flex items-center">
                                <Icon style={{ transform: "rotate(270deg)" }} fontSize="small">blur_linear</Icon>
                                <div className="flex flex-grow mx-2">
                                    <Slider
                                        value={boxShadownSpread}
                                        max={50}
                                        min={-50}
                                        step={1}
                                        onChange={(e, newValue) => { setBoxShadowSpread(newValue as number) }}
                                        onChangeCommitted={(e, newValue) => {
                                            updateElement({
                                                ...props.element,
                                                borderRadiusUnit,
                                                style: {
                                                    ...props.element.style,
                                                    borderColor: borderColor.value,
                                                    backgroundColor: bgColor.value,
                                                    borderStyle: borderStyle,
                                                    borderWidth: borderWidth,
                                                    borderBottomLeftRadius: borders.borderBottomLeftRadius,
                                                    borderTopLeftRadius: borders.borderTopLeftRadius,
                                                    borderBottomRightRadius: borders.borderBottomRightRadius,
                                                    borderTopRightRadius: borders.borderTopRightRadius,
                                                    boxShadow: `${boxShadownX}px ${boxShadownY}px ${boxShadownBlur}px ${newValue}px ${boxShadownColor.value}`,
                                                    opacity
                                                }
                                            })
                                        }}
                                    />
                                </div>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    classes={{ root: "text-input-sm" }}
                                    style={{ flex: 0.5 }}
                                    value={boxShadownSpread}
                                    type="number"
                                    onChange={(e) => { setBoxShadowSpread(e.target.value ? parseInt(e.target.value) : 0) }}
                                    onBlur={handleCommitChanges}
                                    onKeyDown={(e) => { if (e.key === "Enter") { handleCommitChanges() } }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Popover>
            <Popover
                id={popoverElements.opacity}
                anchorEl={popover.element}
                open={popover.id === popoverElements.opacity && Boolean(popover.element)}
                onClose={handleClosePopover}
            >
                <div className="px-2 pb-2">
                    <div className={"flex items-center mt-1"}>
                        <Typography align="center" className="pl-4 w-full flex-grow" variant="caption" style={{ letterSpacing: 0.5 }}>
                            {"Opacidad"}
                        </Typography>
                        <IconButton style={{ padding: 4 }} onMouseDown={handleClosePopover}>
                            <Icon style={{ fontSize: 15 }}>close</Icon>
                        </IconButton>
                    </div>
                    <div className="flex items-center">
                        <Icon fontSize="small">opacity</Icon>
                        <div className="flex flex-grow mx-2">
                            <Slider
                                className="mr-2"
                                value={opacityPercentage}
                                max={100}
                                min={0}
                                step={1}
                                onChange={(e, newValue) => {
                                    setOpacityPercentage(newValue as number)
                                    setOpacity(newValue as number / 100)
                                }}
                                onChangeCommitted={() => {
                                    updateElement({
                                        ...props.element,
                                        borderRadiusUnit,
                                        style: {
                                            ...props.element.style,
                                            borderColor: borderColor.value,
                                            backgroundColor: bgColor.value,
                                            borderStyle: borderStyle,
                                            borderWidth: borderWidth,
                                            borderBottomLeftRadius: borders.borderBottomLeftRadius,
                                            borderTopLeftRadius: borders.borderTopLeftRadius,
                                            borderBottomRightRadius: borders.borderBottomRightRadius,
                                            borderTopRightRadius: borders.borderTopRightRadius,
                                            boxShadow: `${boxShadownX}px ${boxShadownY}px ${boxShadownBlur}px ${boxShadownSpread}px ${boxShadownColor.value}`,
                                            opacity
                                        }
                                    })
                                }}
                            />
                        </div>
                        <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            classes={{ root: "text-input-sm" }}
                            style={{ flex: 0.5 }}
                            value={parseInt(opacityPercentage.toString())}
                            type="number"
                            onChange={(e) => {
                                const value = e.target.value
                                if (value.includes("-")) {
                                    setOpacityPercentage(value ? parseInt("0") : 0)
                                    setOpacity(value ? parseFloat("0") / 100 : 0)
                                    return
                                }
                                if (parseInt(value) > 100) {
                                    setOpacityPercentage(value ? parseInt("100") : 0)
                                    setOpacity(value ? parseFloat("100") / 100 : 0)
                                    return
                                }
                                setOpacityPercentage(value ? parseInt(value) : 0)
                                setOpacity(value ? parseFloat(value) / 100 : 0)
                            }}
                            onBlur={handleCommitChanges}
                            onKeyDown={(e) => { if (e.key === "Enter") { handleCommitChanges() } }}
                        />
                    </div>
                </div>
            </Popover>
        </React.Fragment >
    )
})

export default Styles
