import { Box, Grid, Paper, Tooltip } from "@material-ui/core"
import { IPerxona } from "components/CardProps/CardProps"
import React from 'react'
type Props = {
          perxonaData: IPerxona
          viewMode: 'list' | 'grid'
          handleOnClick: (id: number) => void

}

const CardGrid = ({ perxonaData }: { perxonaData: IPerxona }) => {
          const { avatar, namePerxona, idPerxona } = perxonaData
          return (
                    <Tooltip arrow title={namePerxona} >
                              <Box width="80px" height="80px" style={{
                                        backgroundImage: `url(${avatar})`, width: '80px', height: '80px',
                                        padding: 0, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat',
                                        borderTopLeftRadius: '0px', borderTopRightRadius: '20px',
                                        borderBottomLeftRadius: '20px', borderBottomRightRadius: '0px', borderWidth: '2px',
                              }}>
                              </Box>
                    </Tooltip>
          )
}
const CardList = ({ perxonaData }: { perxonaData: IPerxona }) => {
          const { avatar, namePerxona, idPerxona } = perxonaData
          return (

                    <Paper style={{
                              borderTopLeftRadius: '0px', borderTopRightRadius: '20px',
                              borderBottomLeftRadius: '20px', borderBottomRightRadius: '0px', borderWidth: '1px',
                              height: '82px', width: '300px', minWidth: '300px',
                    }} variant="outlined">
                              < Grid container direction="row" style={{ flexWrap: 'nowrap', }}>
                                        <Grid item xs={4} >
                                                  <Box width="80px" height="80px" style={{
                                                            backgroundImage: `url(${avatar})`, width: '80px', height: '80px',
                                                            padding: 0, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat',
                                                            borderTopLeftRadius: '0px', borderTopRightRadius: '0px',
                                                            borderBottomLeftRadius: '20px', borderBottomRightRadius: '0px', borderWidth: '0px',
                                                  }}>
                                                  </Box>
                                        </Grid>
                                        <Grid item xs={8} >
                                                  <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', height: '100%' }}>
                                                            <Box fontSize={14} >
                                                                      {namePerxona}
                                                            </Box>
                                                  </Box>
                                        </Grid>
                              </Grid>
                    </Paper >
          )
}

export const CardPerxonaGroup = (props: Props) => {
          const { perxonaData, viewMode, handleOnClick } = props
          return (
                    <React.Fragment>
                              {viewMode === 'list' ?
                                        <Box onClick={() => handleOnClick(perxonaData.idPerxona)}>
                                                  <CardList perxonaData={perxonaData} />
                                        </Box> :
                                        <Box onClick={() => handleOnClick(perxonaData.idPerxona)}>
                                                  <CardGrid perxonaData={perxonaData} />
                                        </Box>
                              }
                    </React.Fragment>
          )

}