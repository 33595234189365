import {
  AudioView,
  Conference,
  CurtainView,
  FileView,
  FolderView,
  ImageView,
  LineView,
  Login,
  PageView,
  PracticeGroups,
  PracticePersonality,
  TextView,
  VideoView,
} from 'components'
import { HeaderValues } from 'components/HeaderProps/HeaderProps'
import ConferenceProvider from 'components/Practice/Conference/context/ConferenceProvider'
import { Header } from 'components/Practice/Header/Header'
import PracticePerxona from 'components/Practice/Perxona/PracticePerxona'

import { BoardContext, ConfigContext, FeedbackContext, LocationContext, PageContext } from 'context'
import { BORDER_RADIUS_UNITS_OPTIONS, ELEMENT_TYPES, generateHtml as generateHtmlRequest } from 'lib'
import React from 'react'
import { Rnd } from 'react-rnd'
import { useLocation } from 'react-router'

const classes = {
  page: 'page',
  board: 'board',
}

const Post = () => {
  const { postedElements, size, position, style: pageStyle, darkBg, cards, headerData, getDataForHeader } = React.useContext(PageContext)
  const { style: boardStyle } = React.useContext(BoardContext)
  const { filesContext } = React.useContext(ConfigContext)
  const { loading } = React.useContext(FeedbackContext)
  const { generateHtml, pageId, hash } = React.useContext(LocationContext)

  React.useEffect(() => {
    if (!loading) {
      if (generateHtml) {
        setTimeout(() => {
          const html = document.getElementsByTagName('html')[0].innerHTML
          generateHtmlRequest(html, pageId)
        }, 1500)
      }
      if (hash) {
        const element = document.getElementById(hash)
        if (element) {
          const y = element.getBoundingClientRect().top + window.scrollY
          const x = element.getBoundingClientRect().left + window.scrollX
          window.scroll({
            top: y,
            left: x,
            behavior: 'smooth',
          })
        }
      }
    }
  }, [loading])
  React.useEffect(() => {
    const existHeader = postedElements.find((e) => e.type === ELEMENT_TYPES.header) !== undefined ? true : false
    if (existHeader && headerData === undefined) {
      const getValues = async () => {
        const { entityId, objectId, serviceId } = postedElements.find((e) => e.type === ELEMENT_TYPES.header)!.value as HeaderValues
        try {
          await getDataForHeader(entityId, serviceId, objectId)
        } catch (error) {
          console.log(JSON.stringify(error))
        }
      }
      getValues()
    }
  }, [postedElements, headerData])
  return (
    <div className={classes.board} style={{ ...boardStyle }}>
      <div className={classes.page} style={{ ...pageStyle, width: size.width, height: size.height, left: position.x, top: position.y, overflow: 'hidden' }}>
        {postedElements &&
          postedElements.map((element, index) => (
            <React.Fragment>
              {!element.float && (
                <Rnd
                  key={index + 'postedEle'}
                  position={element.position}
                  size={element.size}
                  disableDragging={true}
                  enableResizing={false}
                  className={`${element.fixed ? 'fixed-element' : ''}`}
                >
                  {element.type === ELEMENT_TYPES.text ? (
                    <TextView
                      id={element.id}
                      value={element.value}
                      style={element.style}
                      size={element.size}
                      borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                      curtain={element.curtain}
                      curtainValue={element.curtainValue}
                      link={element.link}
                      openNewTab={element.openNewTab}
                    />
                  ) : undefined}
                  {element.type === ELEMENT_TYPES.image ? (
                    <ImageView
                      id={element.id}
                      value={element.value}
                      filesContext={filesContext}
                      style={element.style}
                      borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                      curtain={element.curtain}
                      curtainValue={element.curtainValue}
                      link={element.link}
                      openNewTab={element.openNewTab}
                    />
                  ) : undefined}
                  {element.type === ELEMENT_TYPES.video ? (
                    <VideoView
                      id={element.id}
                      value={element.value}
                      filesContext={filesContext}
                      style={element.style}
                      size={element.size}
                      borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                    />
                  ) : undefined}
                  {element.type === ELEMENT_TYPES.audio ? (
                    <AudioView
                      id={element.id}
                      value={element.value}
                      filesContext={filesContext}
                      style={element.style}
                      size={element.size}
                      borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                    />
                  ) : undefined}
                  {element.type === ELEMENT_TYPES.page ? (
                    <PageView
                      id={element.id}
                      value={element.value}
                      filesContext={filesContext}
                      style={element.style}
                      borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                      curtain={element.curtain}
                      curtainValue={element.curtainValue}
                    />
                  ) : undefined}
                  {element.type === ELEMENT_TYPES.line ? (
                    <LineView
                      id={element.id}
                      value={element.value}
                      style={element.style}
                      borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                    />
                  ) : undefined}
                  {element.type === ELEMENT_TYPES.file ? (
                    <FileView
                      id={element.id}
                      value={element.value}
                      style={element.style}
                      filesContext={filesContext}
                      darkBg={darkBg}
                      borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                    />
                  ) : undefined}
                  {element.type === ELEMENT_TYPES.curtain ? (
                    <CurtainView
                      id={element.id}
                      value={element.value}
                      style={element.style}
                      size={element.size}
                      borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                    />
                  ) : undefined}
                  {element.type === ELEMENT_TYPES.folder ? (
                    <FolderView
                      id={element.id}
                      filesContext={filesContext}
                      value={element.value}
                      style={element.style}
                      borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                    />
                  ) : undefined}
                  {element.type === ELEMENT_TYPES.login ? (
                    <Login
                      props={{
                        value: element.value,
                        style: element.style,
                        design: true,
                        size: element.size,
                        post: true,
                      }}
                    ></Login>
                  ) : undefined}
                  {element.type === ELEMENT_TYPES.practiceCard ? (
                    <PracticePerxona
                      cards={cards}
                      value={element.value}
                      style={element.style}
                      modePage={'post'}
                      size={element.size}
                      selectionAndReorderActive={false}
                      element={element}
                    />
                  ) : undefined}
                  {element.type === ELEMENT_TYPES.practiceCardPersonality && <PracticePersonality selectionAndReorderActive={false} element={element} />}
                  {element.type === ELEMENT_TYPES.practiceCardGroups ? <PracticeGroups element={element} selectionAndReorderActive={false} /> : undefined}
                  {element.type === ELEMENT_TYPES.practiceConference ? (
                    <ConferenceProvider>
                      <Conference element={element} />
                    </ConferenceProvider>
                  ) : undefined}
                </Rnd>
              )}
            </React.Fragment>
          ))}
      </div>
      <div
        className={classes.page}
        style={{
          ...pageStyle,
          width: size.width,
          height: size.height,
          left: position.x + parseInt((pageStyle.borderWidth as string)?.replace('px', '') ?? '0'),
          top: position.y + parseInt((pageStyle.borderWidth as string)?.replace('px', '') ?? '0'),
          pointerEvents: 'none',
          backgroundColor: 'transparent',
          position: 'absolute',
          borderColor: 'transparent',
          borderWidth: '0px',
          boxShadow: 'none',
        }}
      >
        {postedElements &&
          postedElements.map((element, index) => (
            <React.Fragment>
              {element.float && (
                <Rnd
                  key={index + 'postedEle'}
                  position={element.position}
                  size={element.size}
                  disableDragging={true}
                  enableResizing={false}
                  style={{ pointerEvents: 'all' }}
                  className={`${element.fixed ? 'fixed-element' : ''}`}
                >
                  {element.type === ELEMENT_TYPES.text ? (
                    <TextView
                      id={element.id}
                      value={element.value}
                      style={element.style}
                      size={element.size}
                      borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                      curtain={element.curtain}
                      curtainValue={element.curtainValue}
                      link={element.link}
                      openNewTab={element.openNewTab}
                    />
                  ) : undefined}
                  {element.type === ELEMENT_TYPES.image || element.type === ELEMENT_TYPES.footer ? (
                    <ImageView
                      id={element.id}
                      value={element.value}
                      filesContext={filesContext}
                      style={element.style}
                      borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                      curtain={element.curtain}
                      curtainValue={element.curtainValue}
                      link={element.link}
                      openNewTab={element.openNewTab}
                    />
                  ) : undefined}
                  {element.type === ELEMENT_TYPES.video ? (
                    <VideoView
                      id={element.id}
                      value={element.value}
                      filesContext={filesContext}
                      style={element.style}
                      size={element.size}
                      borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                    />
                  ) : undefined}
                  {element.type === ELEMENT_TYPES.audio ? (
                    <AudioView
                      id={element.id}
                      value={element.value}
                      filesContext={filesContext}
                      style={element.style}
                      size={element.size}
                      borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                    />
                  ) : undefined}
                  {element.type === ELEMENT_TYPES.page ? (
                    <PageView
                      id={element.id}
                      value={element.value}
                      filesContext={filesContext}
                      style={element.style}
                      borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                      curtain={element.curtain}
                      curtainValue={element.curtainValue}
                    />
                  ) : undefined}
                  {element.type === ELEMENT_TYPES.line ? (
                    <LineView
                      id={element.id}
                      value={element.value}
                      style={element.style}
                      borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                    />
                  ) : undefined}
                  {element.type === ELEMENT_TYPES.file ? (
                    <FileView
                      id={element.id}
                      value={element.value}
                      style={element.style}
                      filesContext={filesContext}
                      darkBg={darkBg}
                      borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                    />
                  ) : undefined}
                  {element.type === ELEMENT_TYPES.curtain ? (
                    <CurtainView
                      id={element.id}
                      value={element.value}
                      style={element.style}
                      size={element.size}
                      borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                    />
                  ) : undefined}
                  {element.type === ELEMENT_TYPES.folder ? (
                    <FolderView
                      id={element.id}
                      filesContext={filesContext}
                      value={element.value}
                      style={element.style}
                      borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                    />
                  ) : undefined}
                  {element.type === ELEMENT_TYPES.login ? (
                    <Login
                      props={{
                        value: element.value,
                        style: element.style,
                        design: true,
                        size: element.size,
                        post: true,
                      }}
                    ></Login>
                  ) : undefined}
                  {element.type === ELEMENT_TYPES.practiceCard ? (
                    <PracticePerxona
                      cards={cards}
                      value={element.value}
                      style={element.style}
                      modePage={'post'}
                      size={element.size}
                      selectionAndReorderActive={false}
                      element={element}
                    />
                  ) : undefined}
                  {element.type === ELEMENT_TYPES.practiceCardPersonality && <PracticePersonality selectionAndReorderActive={false} element={element} />}
                  {element.type === ELEMENT_TYPES.practiceCardGroups ? <PracticeGroups element={element} selectionAndReorderActive={false} /> : undefined}

                  {element.type === ELEMENT_TYPES.header ? (
                    <Header
                      element={element}
                      pageSize={size}
                      headerData={headerData}
                      filesContext={filesContext}
                      borderPageWidth={parseInt((pageStyle.borderWidth as string)?.replace('px', '') ?? '0') * 2}
                    />
                  ) : undefined}
                  {element.type === ELEMENT_TYPES.practiceConference ? (
                    <ConferenceProvider>
                      <Conference element={element} />
                    </ConferenceProvider>
                  ) : undefined}
                </Rnd>
              )}
            </React.Fragment>
          ))}
      </div>
    </div>
  )
}

export default Post
