import { Avatar, Box, Icon, IconButton, makeStyles, Modal, Popper, TextField, Tooltip, Typography } from '@material-ui/core'
import { AvatarGroup } from '@material-ui/lab'
import LogoPerxona from 'assets/images/logoPexona.png'
import React from 'react'
import { IConferenceTheme, IConferenxia } from 'types'
import { VIEWS } from '../const/Views'
import { useConferenceProvider } from '../hook/useConferenceProvider'
import { MenuTheme } from './Menu'
type Props = {
  conferenxia: IConferenxia
  handleOnMinimize: () => void
  minimized: boolean
  showMembers?: boolean
  link?: string
  handleOnShowMembers: () => void
  conferenceTheme?: IConferenceTheme[]
  onRefreshConferenxia: () => void
}

export const Header = (props: Props) => {
  const { conferenxia, minimized, handleOnMinimize, showMembers, link, conferenceTheme, onRefreshConferenxia } = props
  const img = React.useMemo(() => conferenxia?.img ?? LogoPerxona, [conferenxia])
  const countMembers = React.useMemo(() => conferenxia?.countMembers ?? 0, [conferenxia])
  const title = React.useMemo(() => conferenxia?.title ?? 'Conferenxia', [conferenxia])
  const communicationTypeDescription = React.useMemo(() => conferenxia?.typeCommunicationDescription ?? 'Conferenxia', [conferenxia])
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(null)
  const [selectedTheme, setSelectedTheme] = React.useState<IConferenceTheme | undefined>(undefined)

  React.useEffect(() => {
    if (conferenceTheme && conferenceTheme.length > 0) {
      setSelectedTheme(conferenceTheme[0])
    }
  }, [conferenceTheme])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const openMenu = Boolean(anchorElMenu)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined
  return (
    <Box
      style={{
        width: 'auto',
        height: 55,
        maxWidth: '100%',
        maxHeight: 55,
        minWidth: 270,
        minHeight: 55,
        backgroundColor: '#ffffff',
        borderRadius: '0px',
        // borderTopRightRadius: '20px',
        // borderBottomLeftRadius: '20px',
        borderColor: '#e5e5e3',
        borderStyle: 'solid',
        borderWidth: '1px',
        boxSizing: 'content-box',
        cursor: minimized ? 'zoom-in' : 'zoom-out',
      }}
      onClick={() => {
        handleOnMinimize()
      }}
    >
      <Box
        style={{
          width: '100%',
          height: '100%',
          minHeight: '100%',
          minWidth: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          gap: 2,
        }}
      >
        <Box
          style={{
            backgroundImage: `url(${img})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            width: '55px',
            height: '55px',
            maxWidth: '55px',
            maxHeight: '55px',
            // borderBottomLeftRadius: '20px',
          }}
        />
        <Box
          style={{
            width: 'auto',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="caption" style={{ fontSize: 10 }}>
            {communicationTypeDescription}
          </Typography>

          <Typography variant="h6" noWrap style={{ fontSize: 14, fontWeight: 600 }}>
            {title}
          </Typography>

          {showMembers && (
            <Typography variant="caption" style={{ fontSize: 10 }}>
              {countMembers} Miembros
            </Typography>
          )}
        </Box>
        <Box
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          {showMembers && (
            <Box
              style={{
                width: 'auto',
                height: 'auto',
              }}
            >
              <Tooltip title="Ver miembros" arrow placement="top">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation()
                    props.handleOnShowMembers()
                  }}
                >
                  <Icon>contacts</Icon>
                </IconButton>
              </Tooltip>
            </Box>
          )}

          {link && (
            <Box
              style={{
                width: 'auto',
                height: 'auto',
              }}
            >
              <Tooltip title="Abrir pagina asociada" arrow placement="top">
                <IconButton
                  onClick={(e) => {
                    window.open(link, '_blank')
                  }}
                >
                  <Icon>link</Icon>
                </IconButton>
              </Tooltip>
            </Box>
          )}

          <Box
            style={{
              width: 'auto',
              height: 'auto',
            }}
          >
            <Tooltip title="Escribir a conferenxia" arrow placement="top">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  handleClick(e)
                }}
              >
                <Icon
                  style={{
                    color: open ? 'rgb(2, 136, 209)' : 'dimgray',
                  }}
                >
                  message
                </Icon>
              </IconButton>
            </Tooltip>
          </Box>
          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            placement="top"
            style={{
              width: 'auto',
              height: 'auto',
              backgroundColor: '#ffffff',
              minWidth: 400,
              maxWidth: 800,
            }}
          >
            <Box
              style={{
                width: 'auto',
                height: 'auto',
              }}
            >
              <TextField
                autoFocus
                fullWidth
                variant="outlined"
                size="small"
                multiline
                rowsMax={4}
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                }}
                InputProps={{
                  endAdornment: (
                    <Box>
                      <IconButton
                        style={{
                          borderRadius: '50%',
                          backgroundColor: `#4b4b4b`,
                          width: '35px',
                          height: '35px',
                        }}
                      >
                        <Icon
                          style={{ color: '#E6E6E6', fontSize: '22px' }}
                          onClick={(e) => {
                            e.stopPropagation()
                            setAnchorEl(null)
                          }}
                        >
                          send
                        </Icon>
                      </IconButton>
                    </Box>
                  ),
                  startAdornment: (
                    <Box>
                      <Tooltip title="Seleccionar Temas">
                        <AvatarGroup max={2}>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation()
                              e.preventDefault()
                              setAnchorElMenu(e.currentTarget)
                            }}
                          >
                            <Avatar
                              style={{
                                width: 20,
                                height: 20,
                                backgroundColor: `#${selectedTheme?.color}`,
                                fontSize: '11px',
                              }}
                            >
                              {selectedTheme && selectedTheme!.themeName.substring(0, 1).toUpperCase()}
                            </Avatar>
                          </IconButton>
                        </AvatarGroup>
                      </Tooltip>
                    </Box>
                  ),
                }}
              />
            </Box>
          </Popper>
          <Box
            style={{
              width: 'auto',
              height: 'auto',
            }}
          >
            <Tooltip title="Refrescar Conferenxias" arrow placement="top">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  onRefreshConferenxia()
                }}
              >
                <Icon>refresh</Icon>
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>

      <MenuTheme
        themes={conferenceTheme ?? []}
        anchorEl={anchorElMenu}
        open={openMenu}
        handleClose={() => {
          setAnchorElMenu(null)
        }}
        selectedTheme={selectedTheme}
        onSelectedTheme={(theme: IConferenceTheme) => {
          setSelectedTheme(theme)
          setAnchorElMenu(null)
        }}
      />
    </Box>
  )
}
