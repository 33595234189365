import React from 'react'
import { Icon, Tooltip, Typography } from '@material-ui/core'
import { IElement, MoveActions } from 'types'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import { ELEMENT_TYPES } from 'lib'

interface Props {
    element: IElement,
    moveElement: (action: MoveActions, element: IElement) => void,
    isFirstElement: boolean,
    isLastElement: boolean
}

const Move = (props: Props) => {
    const { moveElement, isFirstElement, isLastElement } = props
    const [element, setElement] = React.useState<IElement>(props.element)
    React.useEffect(() => {
        setElement(props.element)
    }, [props.element])
    return (
        <div className="panel-container px-2 py-1 mb-2">
            <ToggleButtonGroup size="small">
                <Tooltip arrow title="Traer primero" placement="left">
                    <ToggleButton disabled={isLastElement} size="small" value="first" onClick={() => moveElement("fisrt", element)}>
                        <Icon fontSize="small">flip_to_front</Icon>
                    </ToggleButton>
                </Tooltip>
                <Tooltip arrow title="Traer al frente" placement="left">
                    <ToggleButton disabled={isLastElement} size="small" value="up" onClick={() => moveElement("moveUp", element)}>
                        <Icon fontSize="small">arrow_upward</Icon>
                    </ToggleButton>
                </Tooltip>
                <Tooltip arrow title="Enviar al fondo" placement="left">
                    <ToggleButton disabled={isFirstElement} size="small" value="down" onClick={() => moveElement("moveDown", element)}>
                        <Icon fontSize="small">arrow_downward</Icon>
                    </ToggleButton>
                </Tooltip>
                <Tooltip arrow title="Enviar último" placement="left">
                    <ToggleButton disabled={isFirstElement} size="small" value="last" onClick={() => moveElement("last", element)}>
                        <Icon fontSize="small">flip_to_back</Icon>
                    </ToggleButton>
                </Tooltip>
            </ToggleButtonGroup>
        </div>
    )
}

export default Move
