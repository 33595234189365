export enum ConferencePracticeViewMode {
	"chat", "column", "themeList", "horizontal"
}

export type ConferencePracticeValues = {
	ownerId: number
	selectedConferenceId: number
	conferencePracticeViewMode: ConferencePracticeViewMode
	initMinimized: boolean
	showGeneralChat: boolean
	showDates: boolean
	showMembersNames: boolean
	minHeight?: number
	minWidth: number
}