import { Button, Icon, InputAdornment, TextField, Typography, FormGroup, FormControl, FormControlLabel, Checkbox, Popover, MenuItem, Tooltip } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { PageContext } from 'context'
import React from 'react'
import { IElement, IPosition, ISize } from 'types'

interface Props {
    element: IElement,
    updateElement: (newElement: IElement) => void,
    pagePosition: IPosition
}

interface PopoverConfig {
    element: HTMLButtonElement | null,
    id: string
}

type Aligment = "horizontal" | "center" | "vertical"

const marginRight = 8
const defaultSizeUnits = ["px", "px"]
const unitsIndex = {
    width: 0,
    height: 1
}
const popoverElements = {
    width: "width",
    height: "height",
    align: "align"
}

const Physics = React.memo((props: Props) => {
    const { updateElement, pagePosition } = props
    const { size: pageSize } = React.useContext(PageContext)
    const widthInput = React.useRef<any>(null)
    const heightInput = React.useRef<any>(null)
    const [position, setPosition] = React.useState<IPosition>(props.element.position)
    const [sizeUnits, setSizeUnits] = React.useState(props.element.sizeUnits ? props.element.sizeUnits : defaultSizeUnits)
    const [size, setSize] = React.useState<ISize>(props.element.size)
    const [x, setX] = React.useState<string>(props.element.position.x.toString())
    const [y, setY] = React.useState<string>(props.element.position.y.toString())
    const [float, setFloat] = React.useState<boolean>(Boolean(props.element.float))
    const [fixed, setFixed] = React.useState<boolean>(Boolean(props.element.fixed))
    const [style, setStyle] = React.useState<React.CSSProperties>(props.element.style)
    const [degrees, setDegrees] = React.useState<number>(0)
    const [degreesString, setDegreesString] = React.useState<string>("0")
    const [scaleX, setScaleX] = React.useState<number>(1)
    const [popover, setPopover] = React.useState<PopoverConfig>({} as PopoverConfig)
    const handleClosePopover = () => setPopover({} as PopoverConfig)
    const handleCommitChanges = () => updateElement({ ...props.element, position, size, sizeUnits, style: { ...style, transform: `[${degrees}, ${scaleX}]` }, float, fixed })
    const isPercentage = React.useCallback((index) => { return sizeUnits[index] !== defaultSizeUnits[index] }, [sizeUnits])
    const getPercentage = React.useCallback((measure) => { return Math.ceil(parseFloat(((100 * size[measure].toString()) / pageSize[measure]).toString())) }, [size, pageSize])
    const handleChangeUnits = (value: string, index: number) => {
        let newSizeUnits = [...sizeUnits]
        newSizeUnits[index] = value
        setSizeUnits(newSizeUnits)
        handleClosePopover()
        if (index === unitsIndex.width) { setTimeout(() => { widthInput.current?.select() }, 10) }
        if (index === unitsIndex.height) { setTimeout(() => { heightInput.current?.select() }, 10) }
    }
    const handleAligment = (align: Aligment) => {
        switch (align) {
            case "center":
                updateElement({ ...props.element, position: { x: (pageSize.width / 2) - (size.width / 2), y: (pageSize.height / 2) - (size.height / 2) }, size, sizeUnits, style: { ...style, transform: `[${degrees}, ${scaleX}]` }, float, fixed })
                setPosition({ x: (pageSize.width / 2) - (size.width / 2), y: (pageSize.height / 2) - (size.height / 2) })
                break
            case "horizontal":
                updateElement({ ...props.element, position: { ...position, x: (pageSize.width / 2) - (size.width / 2) }, size, sizeUnits, style: { ...style, transform: `[${degrees}, ${scaleX}]` }, float, fixed })
                setPosition({ ...position, x: (pageSize.width / 2) - (size.width / 2) })
                break
            case "vertical":
                updateElement({ ...props.element, position: { ...position, y: (pageSize.height / 2) - (size.height / 2) }, size, sizeUnits, style: { ...style, transform: `[${degrees}, ${scaleX}]` }, float, fixed })
                setPosition({ ...position, y: (pageSize.height / 2) - (size.height / 2) })
                break
        }
        handleClosePopover()
    }
    React.useEffect(() => {
        setSizeUnits(props.element.sizeUnits ? props.element.sizeUnits : defaultSizeUnits)
        setSize(props.element.size)
        setPosition(props.element.position)
        setX(props.element.position.x.toString())
        setY(props.element.position.y.toString())
        setFloat((Boolean(props.element.float)))
        setFixed((Boolean(props.element.fixed)))
        setStyle(props.element.style)
        const transformArr: number[] = props.element.style.transform ? JSON.parse(props.element.style.transform) : [0, 1]
        setDegrees(transformArr[0])
        setDegreesString(transformArr[0].toString())
        setScaleX(transformArr[1])
    }, [props.element])
    React.useEffect(() => {
        if (parseInt(x) !== position.x && !isNaN(parseInt(x))) {
            if (parseInt(x) <= -size.width && !float) {
                setPosition({ ...position, x: 0 })
                return
            }
            if (parseInt(x) >= position.x + pageSize.width && !float) {
                setPosition({ ...position, x: pageSize.width - size.width })
                return
            }
            if (parseInt(x) <= -(size.width + pagePosition.x) && float) {
                setPosition({ ...position, x: -pagePosition.x })
                return
            }
            if (parseInt(x) >= (size.width + (pagePosition.x * 2) + pageSize.width) && float) {
                setPosition({ ...position, x: (pagePosition.x * 2) + pageSize.width - size.width })
                return
            }
            if (parseInt(x) >= position.x + pageSize.width && !float) {
                setPosition({ ...position, x: pageSize.width - size.width })
                return
            }
            setPosition({ ...position, x: parseInt(x) })
        }
    }, [x])
    React.useEffect(() => {
        if (parseInt(y) <= -size.height && !float) {
            setPosition({ ...position, y: 0 })
            return
        }
        if (parseInt(y) <= -(size.height + pagePosition.y) && float) {
            setPosition({ ...position, y: -pagePosition.y })
            return
        }
        if (parseInt(y) >= (size.height + (pagePosition.y * 2) + pageSize.height) && float) {
            setPosition({ ...position, y: (pagePosition.y * 2) + pageSize.height - size.height })
            return
        }
        if (parseInt(y) >= position.y + pageSize.height && !float) {
            setPosition({ ...position, y: pageSize.height - size.height })
            return
        }
        if (parseInt(y) !== position.y && !isNaN(parseInt(y))) {
            setPosition({ ...position, y: parseInt(y) })
        }
    }, [y])
    return (
        <React.Fragment>
            <div className="panel-container px-2 pt-4 pb-1">
                <Typography color="primary" className="panel-label" variant="caption">{"Tamaño y posición"}</Typography>
                <div className="my-1 flex">
                    <TextField
                        fullWidth
                        inputRef={widthInput}
                        label="Ancho ⮂"
                        value={!isPercentage(unitsIndex.width) ? parseInt(size.width.toString().replace("px", "")) : getPercentage(popoverElements.width)}
                        variant="outlined"
                        size="small"
                        classes={{ root: "adorned-text-input-sm" }}
                        style={{ marginRight }}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="start">
                                    <Button
                                        size="small"
                                        style={{ minWidth: 34, padding: 0, width: 24 }}
                                        onClick={(e) => setPopover({ element: e.currentTarget, id: popoverElements.width })}
                                    >
                                        <Typography style={{ textTransform: "lowercase" }} variant="caption">{sizeUnits[unitsIndex.width]}</Typography>
                                        <Icon fontSize="small">expand_more</Icon>
                                    </Button>
                                </InputAdornment>,
                        }}
                        onChange={(e) => {
                            if (e.target.value.includes("-") || e.target.value === "0" || isNaN(parseInt(e.target.value))) {
                                return
                            }
                            if (!isPercentage(unitsIndex.width)) {
                                setSize({ ...size, width: e.target.value ? parseInt(e.target.value) : 0 })
                            } else {
                                setSize({ ...size, width: e.target.value ? parseInt(((parseInt(e.target.value) * pageSize.width) / 100).toString()) : 0 })
                            }
                        }}
                        onBlur={handleCommitChanges}
                        onKeyDown={(e) => { if (e.key === "Enter") { handleCommitChanges() } }}
                    />
                    <Popover
                        id={popoverElements.width}
                        anchorEl={popover.element}
                        open={popover.id === popoverElements.width && Boolean(popover.element)}
                        onClose={handleClosePopover}
                    >
                        <MenuItem
                            selected={!isPercentage(unitsIndex.width)}
                            dense
                            onClick={() => handleChangeUnits("px", unitsIndex.width)}
                        >
                            <Typography variant="caption">
                                {"Pixeles"}
                            </Typography>
                        </MenuItem>
                        <MenuItem
                            selected={isPercentage(unitsIndex.width)}
                            dense
                            onClick={() => handleChangeUnits("%", unitsIndex.width)}
                        >
                            <Typography variant="caption">
                                {"Porcentaje"}
                            </Typography>
                        </MenuItem>
                    </Popover>
                    {props.element.type !== "login" && <TextField
                        fullWidth
                        inputRef={heightInput}
                        label="Alto ⮁"
                        value={!isPercentage(unitsIndex.height) ? parseInt(size.height.toString().replace("px", "")) : getPercentage(popoverElements.height)}
                        variant="outlined"
                        size="small"
                        classes={{ root: "adorned-text-input-sm" }}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="start">
                                    <Button
                                        size="small"
                                        style={{ minWidth: 34, padding: 0, width: 24 }}
                                        onClick={(e) => setPopover({ element: e.currentTarget, id: popoverElements.height })}
                                    >
                                        <Typography style={{ textTransform: "lowercase" }} variant="caption">{sizeUnits[unitsIndex.height]}</Typography>
                                        <Icon fontSize="small">expand_more</Icon>
                                    </Button>
                                </InputAdornment>,
                        }}
                        onChange={(e) => {
                            if (e.target.value.includes("-") || e.target.value === "0" || isNaN(parseInt(e.target.value))) {
                                return
                            }
                            if (!isPercentage(unitsIndex.height)) {
                                setSize({ ...size, height: e.target.value ? parseInt(e.target.value) : 0 })
                            } else {
                                setSize({ ...size, height: e.target.value ? parseInt(((parseInt(e.target.value) * pageSize.height) / 100).toString()) : 0 })
                            }
                        }}
                        onBlur={handleCommitChanges}
                        onKeyDown={(e) => { if (e.key === "Enter") { handleCommitChanges() } }}
                    />}
                    <Popover
                        id={popoverElements.height}
                        anchorEl={popover.element}
                        open={popover.id === popoverElements.height && Boolean(popover.element)}
                        onClose={handleClosePopover}
                    >
                        <MenuItem
                            selected={!isPercentage(unitsIndex.height)}
                            dense
                            onClick={() => handleChangeUnits("px", unitsIndex.height)}
                        >
                            <Typography variant="caption">
                                {"Pixeles"}
                            </Typography>
                        </MenuItem>
                        <MenuItem
                            selected={isPercentage(unitsIndex.height)}
                            dense
                            onClick={() => handleChangeUnits("%", unitsIndex.height)}
                        >
                            <Typography variant="caption">
                                {"Porcentaje"}
                            </Typography>
                        </MenuItem>
                    </Popover>
                </div>
            </div>
            <div className="panel-container px-2 pb-1">
                <div className="my-1 flex w-full">
                    <TextField
                        label="x 🡢"
                        value={parseInt(x)}
                        variant="outlined"
                        size="small"
                        classes={{ root: "text-input-sm" }}
                        style={{ marginRight }}
                        onChange={(e) => setX(e.target.value)}
                        onBlur={handleCommitChanges}
                        onKeyDown={(e) => { if (e.key === "Enter") { handleCommitChanges() } }}
                    />
                    <TextField
                        label="y 🡣"
                        value={parseInt(y)}
                        variant="outlined"
                        size="small"
                        classes={{ root: "text-input-sm" }}
                        onChange={(e) => setY(e.target.value)}
                        onBlur={handleCommitChanges}
                        onKeyDown={(e) => { if (e.key === "Enter") { handleCommitChanges() } }}
                    />
                </div>
                <div className="mt-2 mb-1 flex w-full">
                    <div className="flex-1" style={{ paddingRight: marginRight }}>
                        <TextField
                            label="Girar"
                            value={degreesString}
                            variant="outlined"
                            size="small"
                            classes={{ root: "text-input-sm" }}
                            style={{ marginRight }}
                            onChange={(e) => {
                                const value = e.target.value
                                if (value === "-") {
                                    setDegreesString(value)
                                    setDegrees(0)
                                }
                                if (value) {
                                    if (!isNaN(parseInt(value))) {
                                        setDegrees(parseInt(e.target.value))
                                        setDegreesString(value)
                                    }
                                } else {
                                    setDegreesString("")
                                    setDegrees(0)
                                }
                            }}
                            onBlur={handleCommitChanges}
                            onKeyDown={(e) => { if (e.key === "Enter") { handleCommitChanges() } }}
                        />
                        <ToggleButtonGroup size="small" className="mt-2">
                            <Tooltip arrow title="Centro horizontal">
                                <ToggleButton onClick={() => handleAligment("horizontal")} size="small">
                                    <Icon fontSize="small">flip</Icon>
                                </ToggleButton>
                            </Tooltip>
                            <Tooltip arrow title="Centro">
                                <ToggleButton onClick={() => handleAligment("center")} size="small">
                                    <Icon fontSize="small">center_focus_strong</Icon>
                                </ToggleButton>
                            </Tooltip>
                            <Tooltip arrow title="Centro vertical">
                                <ToggleButton onClick={() => handleAligment("vertical")} size="small">
                                    <Icon style={{ transform: "rotate(90deg)" }} fontSize="small">flip</Icon>
                                </ToggleButton>
                            </Tooltip>
                        </ToggleButtonGroup>
                    </div>
                    <FormControl className="flex-1" component="fieldset" style={{ display: "flex", justifyContent: "center" }}>
                        <FormGroup style={{ marginLeft: marginRight }}>
                            <div className="flex flex-row items-center">
                                <Checkbox
                                    onChange={(e) => {
                                        const checked = e.target.checked
                                        setFloat(checked)
                                        updateElement({
                                            ...props.element,
                                            float: checked,
                                            fixed,
                                            position,
                                            size,
                                            sizeUnits,
                                            style: { ...style, transform: `[${degrees}, ${scaleX}]` }
                                        })
                                    }}
                                    color="primary"
                                    size="small"
                                    checked={float}
                                    name="float"
                                />
                                <Typography variant="caption">{"Flotar"}</Typography>
                            </div>
                            {props.element.type !== 'login' &&
                                <div className="flex flex-row items-center">
                                    <Checkbox
                                        onChange={(e) => {
                                            setScaleX(e.target.checked ? -1 : 1)
                                            updateElement({
                                                ...props.element,
                                                position,
                                                size,
                                                fixed,
                                                sizeUnits,
                                                style: { ...style, transform: `[${degrees}, ${e.target.checked ? -1 : 1}]` }
                                            })
                                        }}
                                        color="primary"
                                        size="small"
                                        checked={scaleX < 0}
                                        name="mirror"
                                    />
                                    <Typography variant="caption">{"Espejo"}</Typography>
                                </div>
                            }
                            <div className="flex flex-row items-center">
                                <Checkbox
                                    onChange={(e) => {
                                        const checked = e.target.checked
                                        const y = document.documentElement.scrollTop
                                        const newPosition = checked ? { ...position, y: position.y - y } : position
                                        setFixed(checked)
                                        updateElement({
                                            ...props.element,
                                            float,
                                            position: newPosition,
                                            size,
                                            sizeUnits,
                                            style: { ...style, transform: `[${degrees}, ${scaleX}]` },
                                            fixed: checked
                                        })
                                    }}
                                    color="primary"
                                    size="small"
                                    checked={fixed}
                                    name="fixed"
                                />
                                <Typography variant="caption">{"Fijo"}</Typography>
                            </div>
                        </FormGroup>
                    </FormControl>
                </div>
            </div>
        </React.Fragment >
    )
})

export default Physics
