import React from 'react'
import { Pagination, Skeleton } from '@material-ui/lab'
import { ELEMENT_TYPES, getDocumentIcon, getFolderInfoById, openObjectInfo } from 'lib'
import { Breadcrumbs, Button, ButtonGroup, Icon, IconButton, Tooltip, Typography } from '@material-ui/core'
import moment from 'moment'
import 'moment/locale/es'
import { LocationContext, PageContext } from 'context'
import { useKeyPress } from 'hooks'
import REST from 'lib/constants/REST'
import { Filter, IContent, ICount, IFloatFolderValue, INavigation } from './Folder'
import { View, Pagination as TPagination, Permission } from 'components/FolderProps/FolderProps'
import audio from '../../assets/images/audio.svg'
import folder from '../../assets/images/folder.png'
import { useLocation } from 'react-router-dom'

moment.locale("es")

interface Props {
    value: any,
    filesContext: string,
    style: React.CSSProperties,
    borderRadiusUnit: string,
    id: any
    exported?: boolean
}

const FloatFolderView = (props: Props) => {
    const { userId, tabId } = React.useContext(LocationContext)
    const [value, setValue] = React.useState<IFloatFolderValue | undefined>(undefined)
    const [error, setError] = React.useState<boolean>(false)
    const [showcase, setShowcase] = React.useState<boolean>(false)
    const [filter, setFilter] = React.useState<Filter>("all")
    const [currentContent, setCurrentContent] = React.useState<IContent[]>([])
    const [navigation, setNavigation] = React.useState<INavigation[]>([])
    const [itemsPerPage, setItemsPerPage] = React.useState<number>(4)
    const [page, setPage] = React.useState<number>(0)
    const [view, setView] = React.useState<View>("G")
    const [pagination, setPagination] = React.useState<TPagination>("bottom")
    const [count, setCount] = React.useState<ICount>({} as ICount)
    const handleResetNav = () => {
        setNavigation([])
        setPage(0)
        if (value) {
            setCurrentContent(value.content)
            setCount(value.count)
        }
    }
    const handleNav = (index: number) => {
        const newNav = [...navigation].slice(0, index + 1)
        setNavigation(newNav)
        setCurrentContent(newNav[index].content)
        setCount(newNav[index].count)
        setPage(0)
    }
    const controlPress: boolean = useKeyPress("Control")
    const { id: pageId } = React.useContext(PageContext)
    React.useEffect(() => {
        setShowcase(Boolean(props.value[2]))
        setItemsPerPage(props.value[3] ?? 4)
        setView(props.value[4] ?? "G")
        setPagination(props.value[5] ?? "bottom")
    }, [props.value])
    const countAll = React.useCallback(() => {
        return (count.audio ?? 0) + (count.documents ?? 0) + (count.folders ?? 0) + (count.image ?? 0) + (count.links ?? 0) + (count.pages ?? 0) + (count.tools ?? 0) + (count.video ?? 0)
    }, [count])
    React.useEffect(() => {
        const init = async () => {
            try {
                if (value === undefined) { setValue(await getFolderInfoById(props.value[0], userId, (props.value[6] as Permission) === "F" ? true : false)) }
            } catch (error) {
                setError(true)
            }
        }
        init()
    }, [])
    React.useEffect(() => { if (value) { setCurrentContent(value.content); setCount(value.count) } }, [value])
    const handleOpenClick = async () => {
        const win = window.open(REST.hostURL + value?.url, '_blank')
        win?.focus()
    }


    const getTransforms = React.useCallback(() => { if (props.style.transform) { return JSON.parse(props.style.transform) } }, [props.style.transform])
    const handleClick = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e.ctrlKey) {
            await openObjectInfo(pageId, props.value[0], tabId)
            return
        }
    }
    const handleObjectClick = (content: IContent) => {
        if (content.type === "folder" && content.content) {
            setPage(0)
            setCurrentContent(content.content)
            setCount(content.count)
            //@ts-ignore
            setNavigation(prev => [...prev, { content: content.content, name: content.title, count: content.count }])
        }
    }
    const getCurrentContent = React.useCallback(() => {
        if (filter !== "all") {
            return currentContent.filter(c => c.type === filter)
        }
        return currentContent
    }, [currentContent, filter])
    const handleOpenContent = (url: string, external: boolean) => {
        const win = window.open(`${external ? url : REST.hostURL + url}`, '_blank')
        win?.focus()
    }
    const handleOpenInfo = async (id: any) => {
        await openObjectInfo(pageId, id, tabId)
    }
    return (
        <div
            className="w-full h-full"
            id={`${ELEMENT_TYPES.folder}_${props.id}`}
            style={{
                ...props.style,
                borderTopLeftRadius: props.style.borderTopLeftRadius + props.borderRadiusUnit,
                borderBottomLeftRadius: props.style.borderBottomLeftRadius + props.borderRadiusUnit,
                borderTopRightRadius: props.style.borderTopRightRadius + props.borderRadiusUnit,
                borderBottomRightRadius: props.style.borderBottomRightRadius + props.borderRadiusUnit,
                transform: `rotate(${getTransforms()[0]}deg) scaleX(${getTransforms()[1]})`,
            }}
            onClick={handleClick}
        >
            {
                Boolean(value) ?
                    <React.Fragment>
                        {
                            !showcase ?
                                <div className="w-full flex h-full p-2">
                                    <div className="folder-icon relative">
                                        {
                                            value?.img &&
                                            <div className="absolute bg-center bg-contain bg-no-repeat" style={{ width: "60%", height: 50, top: 25, left: "20%", backgroundImage: `url(${props.filesContext}/${value?.img})` }} />
                                        }
                                    </div>
                                    <div className="ml-3 w-full h-full">
                                        <div className="w-full flex items-center">
                                            <Typography className="flex-grow" variant="caption" color="primary">{value?.editor}</Typography>
                                            <Typography variant="caption" color="primary">{moment(new Date(value?.createdAt ?? "").toISOString()).format('DD MMM YYYY').toString().replaceAll(".", "")}</Typography>
                                            <div className="mx-2" style={{ backgroundColor: "var(--primaryMainColor)", height: 10, width: 1 }} />
                                            <Typography variant="caption" color="primary">{moment(new Date(value?.lastUpdate ?? "").toISOString()).format('DD MMM YYYY').toString().replaceAll(".", "")}</Typography>
                                        </div>
                                        <Typography variant="h6">{value?.title}</Typography>
                                        <Typography color="textSecondary" variant="body2">{value?.detail}</Typography>
                                        <Typography variant="caption" color="primary">{value?.keyWords.split(",").join(", ")}</Typography>
                                    </div>
                                </div>
                                :
                                <div className="w-full flex h-full">
                                    <div className="w-full flex h-full p-2 flex-col">
                                        <Typography style={{ overflow: "visible" }} variant="h6" className="text-truncate pb-1">{value?.title}</Typography>
                                        <div className="flex-grow w-full flex flex-col">
                                            <div className="w-full flex flex-wrap items-center">
                                                <Typography style={filter === "all" ? { textDecoration: "underline", fontWeight: 600 } : undefined} className="hover-underline cursor-pointer" onClick={() => { setFilter("all"); setPage(0) }} color={filter === "all" ? "primary" : "initial"} variant="caption">{`Todos ${countAll()}`}</Typography>
                                                <div className="mx-2" style={{ backgroundColor: "var(--primaryMainColor)", height: 10, width: 1 }} />
                                                <Typography style={filter === "page" ? { textDecoration: "underline", fontWeight: 600 } : undefined} onClick={Boolean(count?.pages) ? () => { setFilter("page"); setPage(0) } : undefined} color={Boolean(count?.pages) ? filter === "page" ? "primary" : "initial" : "textSecondary"} variant="caption" className="pr-2 hover-underline cursor-pointer">{`Páginas ${Boolean(count?.pages) ? count?.pages : ""}`}</Typography>
                                                <Typography style={filter === "folder" ? { textDecoration: "underline", fontWeight: 600 } : undefined} onClick={Boolean(count?.folders) ? () => { setFilter("folder"); setPage(0) } : undefined} color={Boolean(count?.folders) ? filter === "folder" ? "primary" : "initial" : "textSecondary"} variant="caption" className="pr-2 hover-underline cursor-pointer">{`Folders ${Boolean(count?.folders) ? count?.folders : ""}`}</Typography>
                                                <Typography style={filter === "document" ? { textDecoration: "underline", fontWeight: 600 } : undefined} onClick={Boolean(count?.documents) ? () => { setFilter("document"); setPage(0) } : undefined} color={Boolean(count?.documents) ? filter === "document" ? "primary" : "initial" : "textSecondary"} variant="caption" className="pr-2 hover-underline cursor-pointer">{`Docs ${Boolean(count?.documents) ? count?.documents : ""}`}</Typography>
                                                <Typography style={filter === "image" ? { textDecoration: "underline", fontWeight: 600 } : undefined} onClick={Boolean(count?.image) ? () => { setFilter("image"); setPage(0) } : undefined} color={Boolean(count?.image) ? filter === "image" ? "primary" : "initial" : "textSecondary"} variant="caption" className="pr-2 hover-underline cursor-pointer">{`Imágenes ${Boolean(count?.image) ? count?.image : ""}`}</Typography>
                                                <Typography style={filter === "video" ? { textDecoration: "underline", fontWeight: 600 } : undefined} onClick={Boolean(count?.video) ? () => { setFilter("video"); setPage(0) } : undefined} color={Boolean(count?.video) ? filter === "video" ? "primary" : "initial" : "textSecondary"} variant="caption" className="pr-2 hover-underline cursor-pointer">{`Videos ${Boolean(count?.video) ? count?.video : ""}`}</Typography>
                                                <Typography style={filter === "audio" ? { textDecoration: "underline", fontWeight: 600 } : undefined} onClick={Boolean(count?.audio) ? () => { setFilter("audio"); setPage(0) } : undefined} color={Boolean(count?.audio) ? filter === "audio" ? "primary" : "initial" : "textSecondary"} variant="caption" className="pr-2 hover-underline cursor-pointer">{`Audios ${Boolean(count?.audio) ? count?.audio : ""}`}</Typography>
                                                <Typography style={filter === "link" ? { textDecoration: "underline", fontWeight: 600 } : undefined} onClick={Boolean(count?.links) ? () => { setFilter("link"); setPage(0) } : undefined} color={Boolean(count?.links) ? filter === "link" ? "primary" : "initial" : "textSecondary"} variant="caption" className="pr-2 hover-underline cursor-pointer">{`Links ${Boolean(count?.links) ? count?.links : ""}`}</Typography>
                                            </div>
                                            <div className="w-full flex flex-wrap items-center">
                                                <Breadcrumbs separator="›" aria-label="breadcrumb">
                                                    {
                                                        navigation && navigation.length > 0 ?
                                                            <Typography onClick={handleResetNav} className="hover-underline cursor-pointer" variant="caption">{value?.title}</Typography>
                                                            :
                                                            <br />
                                                    }
                                                    {
                                                        navigation.map((item, index) => (
                                                            <Typography onClick={() => { handleNav(index) }} className="hover-underline cursor-pointer" key={"nav" + index} variant="caption">{item.name}</Typography>
                                                        ))
                                                    }
                                                </Breadcrumbs>
                                            </div>
                                            {
                                                (pagination === "both" || pagination === "top") && Math.ceil(getCurrentContent().length / itemsPerPage) > 1 &&
                                                <div className="flex w-full justify-center">
                                                    <Pagination
                                                        count={Math.ceil(getCurrentContent().length / itemsPerPage)}
                                                        page={page + 1}
                                                        size="small"
                                                        color="primary"
                                                        onChange={(e, value) => setPage(value - 1)}
                                                    />
                                                </div>
                                            }
                                            <div style={{ backgroundColor: "rgba(255,255,255,0.15)" }} className={`w-full flex flex-wrap flex-grow justify-start items-start ${view === "G" ? "flex-row" : "flex-col"}`}>
                                                {
                                                    (itemsPerPage > 0
                                                        ? getCurrentContent().slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage)
                                                        : getCurrentContent()
                                                    ).map((content, index) => (
                                                        <div
                                                            key={content.url + index.toString()}
                                                            className={`${view === "G" ? "folder-content-row items-center justify-center flex-col" : "folder-content-col"} flex text-truncate folder-content relative`}
                                                        >
                                                            {
                                                                view === "G" &&
                                                                <div style={{ height: 75 }} className="text-truncate clamp-2 flex flex-col justify-center items-center mb-2">
                                                                    <Typography align={view === "G" ? "center" : "left"} variant="caption" className="text-truncate flex-grow flex items-center items-center">
                                                                        {content.title}
                                                                    </Typography>
                                                                    <Typography color="primary" align={view === "G" ? "center" : "left"} variant="caption" className="text-truncate">
                                                                        {content.editor}
                                                                    </Typography>
                                                                    {
                                                                        view !== "G" &&
                                                                        <Typography variant="caption" color="textSecondary">{moment(new Date(content.lastUpdate ?? "").toISOString()).format('DD MMM YYYY').toString().replaceAll(".", "")}</Typography>
                                                                    }
                                                                </div>
                                                            }
                                                            {
                                                                content.type === "folder" &&
                                                                <img style={{ objectFit: "contain", maxHeight: view === "G" ? "60%" : "100%", zIndex: 2 }} src={folder} className={view === "G" ? "flex-grow" : "w-1/4 absolute"} />
                                                            }
                                                            <img
                                                                style={{
                                                                    objectFit: view === "G" ? "cover" : "contain",
                                                                    maxHeight: view === "G" ? "50%" : "100%",
                                                                    zIndex: 3,
                                                                    width: view === "G" ? "70%" : "30%",
                                                                    bottom: view === "G" ? "20%" : undefined,
                                                                    paddingRight: view === "G" ? undefined : content.type === "folder" ? "8%" : undefined
                                                                }}
                                                                src={content.type !== "video" ? content.type === "document" ? getDocumentIcon(content.img) : content.type === "audio" ? audio : content.type === "link" ? content.img : props.filesContext + "/" + content.img : content.img}
                                                                className={`${content.type === "folder" && view === "G" ? "absolute folder-content-img-row" : content.type === "folder" ? "folder-content-img-col" : ""} ${view === "G" ? "flex-grow" : "w-1/4"}`}
                                                            />
                                                            {
                                                                view !== "G" &&
                                                                <div className="text-truncate clamp-2 flex flex-col justify-center ml-2">
                                                                    <Typography align={"left"} variant="caption" className="text-truncate">
                                                                        {content.title}
                                                                    </Typography>
                                                                    <Typography color="primary" align={"left"} variant="caption" className="text-truncate">
                                                                        {content.editor}
                                                                    </Typography>
                                                                    <Typography style={{ fontSize: "0.65em" }} variant="caption" color="textSecondary">{`Creada: ${moment(new Date(content.createdAt ?? "").toISOString()).format('DD MMM YYYY').toString().replaceAll(".", "")}`}</Typography>
                                                                    <Typography style={{ fontSize: "0.65em" }} variant="caption" color="textSecondary">{`Última actualización: ${moment(new Date(content.lastUpdate ?? "").toISOString()).format('DD MMM YYYY').toString().replaceAll(".", "")}`}</Typography>
                                                                </div>
                                                            }
                                                            {
                                                                value?.access &&
                                                                <div className={`absolute folder-content-hover w-full bottom-0 ${view === "G" ? "justify-center items-center" : "pl-6"} flex-col`} style={{ zIndex: 10, width: view === "G" ? "100%" : "30%" }}>
                                                                    <div className="flex items-center justify-center">
                                                                        {
                                                                            content.type === "folder" &&
                                                                            <Tooltip arrow title="Abrir folder">
                                                                                <IconButton onClick={() => handleObjectClick(content)} color="secondary" size="small">
                                                                                    <Icon className="white-icon-hover-primary">edit</Icon>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        }
                                                                        {
                                                                            !props.exported &&
                                                                            <React.Fragment>
                                                                                <Tooltip arrow title="ver información">
                                                                                    <IconButton onClick={() => handleOpenInfo(content.id)} color="secondary" size="small">
                                                                                        <Icon className="white-icon-hover-primary">info</Icon>
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                <Tooltip arrow title="Abrir en nueva pestaña">
                                                                                    <IconButton onClick={() => {
                                                                                        console.log(content.type)
                                                                                        console.log(content.external)
                                                                                        handleOpenContent(content.url, content.external)
                                                                                    }} color="secondary" size="small">
                                                                                        <Icon className="white-icon-hover-primary">open_in_new</Icon>
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </React.Fragment>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            {
                                                (pagination === "both" || pagination === "bottom") && Math.ceil(getCurrentContent().length / itemsPerPage) > 1 &&
                                                <div className="flex w-full justify-center">
                                                    <Pagination
                                                        count={Math.ceil(getCurrentContent().length / itemsPerPage)}
                                                        page={page + 1}
                                                        size="small"
                                                        color="primary"
                                                        onChange={(e, value) => setPage(value - 1)}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                        }
                        {
                            value?.access &&
                            <div className="absolute bottom-0 left-0">
                                <IconButton style={{ backgroundColor: "rgba(255,255,255,0.25)" }} onClick={handleOpenClick} size="small">
                                    <Icon>add</Icon>
                                </IconButton>
                            </div>
                        }
                    </React.Fragment>
                    :
                    <div className="w-full flex h-full items-center">
                        <Skeleton width={100} height={100} className="ml-2" />
                        <div className="w-full h--full flex flex-col justify-center">
                            <Skeleton width={"92%"} height={20} className="ml-2" />
                            <Skeleton width={"92%"} height={20} className="ml-2" />
                            <Skeleton width={"92%"} height={20} className="ml-2" />
                        </div>
                    </div>
            }
            {
                (controlPress && !props.exported) &&
                <Tooltip arrow title="Ver información">
                    <div
                        onClick={handleClick}
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                            top: 0,
                            cursor: "pointer",
                            backgroundColor: "transparent"
                        }}
                    />
                </Tooltip>
            }
        </div>
    )
}

export default FloatFolderView
