import React from 'react'
import { CardContainerValues, EditionCardMode, ICardPersonality, ICardPerxona, RelationShipStatus, } from "components/CardProps/CardProps";

import { ISize } from 'types/Physics';
import { LocationContext, PageContext } from 'context';
import { IElement, ModePage } from 'types';
import { getStatusFriendShip, requestFriendShip } from 'lib';
import { ContainerCardsPerxona } from './ContainerCardsPerxona';



export interface CardPaginationProps {
          totalPages: number;
          page: number;
          handlePageChange: (event: React.ChangeEvent<unknown>, newPage: number) => void;
          numberOfCardsForPage: number;
}

interface Props {
          cards: ICardPerxona[];
          style: {};
          value: CardContainerValues;
          modePage: ModePage
          size: ISize
          selectionAndReorderActive: boolean;
          element: IElement;
}




const PracticePerxona = (cardsProps: Props) => {
          const { cards, style, value, modePage: design, size, selectionAndReorderActive, element } = cardsProps
          const { cardViewMinimizeProps, cardPaginationProps, cardsChosen: cardIdsChosen, tittle: titulo, perxonaId: ownerCardsId } = value
          const { itemsPerPage: numberOfCardsForPage, cardViewMode } = cardPaginationProps
          const [page, setPage] = React.useState<number>(1)
          const [appliedFilterEmergency, setAppliedFilterEmergency] = React.useState<boolean>(false)
          const [canRequestFriendShip, setCanRequestFriendShip] = React.useState<boolean>(true)
          const [editMode, setEditMode] = React.useState<EditionCardMode>("none")

          const { listCardsFromId } = React.useContext(PageContext)
          const { userId: viewerPerxonaId } = React.useContext(LocationContext)



          React.useEffect(() => {
                    setEditMode(selectionAndReorderActive !== undefined ? selectionAndReorderActive ? "selection" : "none" : "none")
          }, [selectionAndReorderActive])

          React.useEffect(() => {
                    const verifiedFriendShip = async () => {

                              const existAllParmsForRequest = ownerCardsId !== undefined && viewerPerxonaId !== -1

                              const isPostMode = design === "post"
                              const isExportedMode = design === "exported"
                              const isDesignMode = design === "design"

                              const needVerificationRelationsShip = isPostMode && existAllParmsForRequest

                              if (needVerificationRelationsShip) {
                                        const status: RelationShipStatus = await getStatusFriendShip({ userId: viewerPerxonaId, friendId: ownerCardsId })
                                        const canRequest = status === "no son amigos!"
                                        setCanRequestFriendShip(canRequest)
                              } else if (isExportedMode) {
                                        setCanRequestFriendShip(false)
                              } else if (isDesignMode) {
                                        setCanRequestFriendShip(true)
                              }

                              if (ownerCardsId !== undefined) {
                                        listCardsFromId(ownerCardsId)
                              }



                    }
                    verifiedFriendShip()
          }, [design, ownerCardsId, viewerPerxonaId, listCardsFromId])

          const handleRequestFriendShip = React.useCallback(async (): Promise<boolean> => {
                    if (design === 'post' && viewerPerxonaId !== -1 && ownerCardsId !== undefined) {
                              const responseStatus = await requestFriendShip({ userId: viewerPerxonaId, friendId: ownerCardsId })
                              if (responseStatus) {
                                        return responseStatus === 200
                              }
                              return false
                    }
                    return false
          }, [viewerPerxonaId, ownerCardsId, design])

          const sortedCards = React.useCallback((first: ICardPerxona, second: ICardPerxona) => {
                    const firstIndex = cardIdsChosen.indexOf(first.cardId)
                    const secondIndex = cardIdsChosen.indexOf(second.cardId)
                    return firstIndex - secondIndex
          }, [cardIdsChosen])

          const filterOnlyCardsChosen = React.useCallback((card: ICardPerxona) => cardIdsChosen.includes(card.cardId)
                    , [cardIdsChosen])

          const filterOnlyEmergencyCards = React.useCallback((card: ICardPerxona) => cardIdsChosen.includes(card.cardId) && card.cardType === 5, [cardIdsChosen])

          const calculatePage = React.useCallback((cards: ICardPerxona[]) =>
                    Math.ceil(cards.length / numberOfCardsForPage)
                    , [numberOfCardsForPage])

          const sortedCardsWithFilter = (
                    cards: ICardPerxona[],
                    sorted: (first: ICardPerxona, second: ICardPerxona) => number,
                    filter: (card: ICardPerxona) => boolean
          ) => cards.filter(filter).sort(sorted)

          const getCardsFilterChosenAndSorted = React.useCallback((): ICardPerxona[] => sortedCardsWithFilter([...cards], sortedCards, filterOnlyCardsChosen), [cards, sortedCards, filterOnlyCardsChosen])

          const getCardsFilterEmergencyAndSorted = React.useCallback((): ICardPerxona[] => sortedCardsWithFilter([...cards], sortedCards, filterOnlyEmergencyCards), [cards, sortedCards, filterOnlyEmergencyCards])


          const totalPageAppliedFilter = React.useCallback(() =>
                    !appliedFilterEmergency ?
                              selectionAndReorderActive ?
                                        editMode === "selection" ?
                                                  calculatePage([...cards]) :
                                                  calculatePage(getCardsFilterChosenAndSorted()) :
                                        calculatePage(getCardsFilterChosenAndSorted()) :
                              calculatePage(getCardsFilterEmergencyAndSorted())
                    , [cards, getCardsFilterChosenAndSorted, getCardsFilterEmergencyAndSorted, appliedFilterEmergency, selectionAndReorderActive, calculatePage, editMode])



          const totalPages = totalPageAppliedFilter()


          const [cardsToShow, setCardsToShow] = React.useState<ICardPerxona[]>(
                    !appliedFilterEmergency ?
                              selectionAndReorderActive ?
                                        editMode === "selection" ?
                                                  [...cards] :
                                                  getCardsFilterChosenAndSorted() :
                                        getCardsFilterChosenAndSorted() :
                              getCardsFilterEmergencyAndSorted()
          )

          const handlePageChange = React.useCallback((event: React.ChangeEvent<unknown>, newPage) => {
                    setPage(() => {
                              const isLastPage = newPage === totalPages;
                              const initSlice = numberOfCardsForPage * (newPage - 1);
                              const endSlice = isLastPage ? cards.length : initSlice + numberOfCardsForPage;
                              const cardsOrder = !appliedFilterEmergency ?
                                        selectionAndReorderActive ?
                                                  editMode === "selection" ?
                                                            [...cards] :
                                                            getCardsFilterChosenAndSorted() :
                                                  getCardsFilterChosenAndSorted() :
                                        getCardsFilterEmergencyAndSorted()

                              setCardsToShow(cardsOrder.slice(initSlice, endSlice));
                              return newPage
                    })
          }, [cards, numberOfCardsForPage, totalPages, appliedFilterEmergency, selectionAndReorderActive, getCardsFilterChosenAndSorted, getCardsFilterEmergencyAndSorted, editMode])

          const handleFilterEmergenxia = () => setAppliedFilterEmergency(prev => !prev)


          React.useEffect(() => {

                    const isLastPage = page === totalPages;
                    const initSlice = numberOfCardsForPage * (page - 1);

                    if (initSlice <= cards.length) {
                              const endSlice = isLastPage ? cards.length : initSlice + numberOfCardsForPage;
                              const cardsOrder = !appliedFilterEmergency ?
                                        selectionAndReorderActive ?
                                                  editMode === "selection" ?
                                                            [...cards] :
                                                            getCardsFilterChosenAndSorted() :
                                                  getCardsFilterChosenAndSorted() :
                                        getCardsFilterEmergencyAndSorted()
                              setCardsToShow(cardsOrder.slice(initSlice, endSlice));
                    }
          }, [cards.length, cards, page, numberOfCardsForPage, appliedFilterEmergency, selectionAndReorderActive, getCardsFilterChosenAndSorted, getCardsFilterEmergencyAndSorted, editMode])

          React.useEffect(() => {
                    setPage(currentPage => {
                              const isLastPage = currentPage === totalPages;

                              const cardsOrder = !appliedFilterEmergency ?
                                        selectionAndReorderActive ?
                                                  editMode === "selection" ?
                                                            [...cards] :
                                                            getCardsFilterChosenAndSorted() :
                                                  getCardsFilterChosenAndSorted() :
                                        getCardsFilterEmergencyAndSorted()

                              const initSlice = numberOfCardsForPage * (currentPage - 1);
                              const endSlice = isLastPage ? cardsOrder.length : initSlice + numberOfCardsForPage;

                              if (initSlice > cardsOrder.length) {
                                        return 1
                              } else if (endSlice > cardsOrder.length) {
                                        return 1
                              }
                              return currentPage

                    })
          }, [cards.length, numberOfCardsForPage, cards, totalPages, appliedFilterEmergency, selectionAndReorderActive, getCardsFilterChosenAndSorted, getCardsFilterEmergencyAndSorted, editMode])

          const enableEmergenxiaButton = React.useCallback((): boolean =>
                    cards.filter(card => cardIdsChosen.includes(card.cardId) && card.cardType === 5).length > 0,
                    [cards, cardIdsChosen])

          const permanentActiveEmergencyFilter = React.useCallback((): boolean => {
                    return cards.filter(card => cardIdsChosen.includes(card.cardId) && card.cardType === 5).length ===
                              cards.filter(card => cardIdsChosen.includes(card.cardId)).length
          }, [cards, cardIdsChosen])


          return (


                    <div style={{ ...style }} >
                              <ContainerCardsPerxona
                                        cards={cardsToShow}
                                        viewModes={{ cardViewMode, cardViewMinimizeProps }}
                                        cardPaginationProps={{ totalPages, page, handlePageChange, numberOfCardsForPage }}
                                        tittle={titulo}
                                        design={design === 'design' || design === 'exported' ? true : false}
                                        size={size}
                                        selectionAndReorder={selectionAndReorderActive}
                                        cardsChosen={cardIdsChosen}
                                        element={element}
                                        requestFriendShipButton={{ canRequestFriendShip, handleRequestFriendShip }}
                                        handleEditMode={{ editMode, setEditMode }}
                                        emergenxiaButtonProps={{
                                                  enable: enableEmergenxiaButton(),
                                                  permanentActive: permanentActiveEmergencyFilter(),
                                                  appliedFilter: appliedFilterEmergency,
                                                  handleFilterEmergenxia
                                        }}
                              />

                    </div>


          )
}

export default PracticePerxona;