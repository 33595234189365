import { Divider, Icon, IconButton, Paper, Typography } from '@material-ui/core'
import { ImageProps, PageProps, Move, Physics, Styles, TextProps, FileProps, FolderProps, LoginProps, ConferenceProps } from 'components'
import Actions from 'components/Actions/Actions'
import CardProps from 'components/CardProps/CardProps'
import CurtainProps from 'components/CurtainProps/CurtainProps'
import { HeaderProps } from 'components/HeaderProps/HeaderProps'
import LineProps from 'components/LineProps/LineProps'
import { getElementNameByType, ELEMENT_TYPES } from 'lib'
import React from 'react'
import { Rnd } from 'react-rnd'
import { IElement, IPosition, ISize, MoveActions } from 'types'

interface Props {
  element: IElement
  onClose: () => void
  updateElement: (newElement: any) => void
  moveElement: (action: MoveActions, element: IElement) => void
  lastClickPosition: IPosition
  pageSize: ISize
  isFirstElement: boolean
  isLastElement: boolean
  filesContext: string
  pagePosition: IPosition
  pageId: number
  handleInfo: () => any
  handleChange: () => any
  handleSelectionAndReorder: () => void
}

const classes = {
  draggable: 'attributes-dragable',
  board: '.board',
}

const attributesMaxWidth = 260

const Attributes = (props: Props) => {
  const { pagePosition, handleChange, handleInfo, handleSelectionAndReorder } = props
  const menuRef = React.useRef<Rnd>(null)
  const { onClose, updateElement, moveElement, isFirstElement, isLastElement, filesContext, pageId } = props
  const e = document.querySelector('body')
  const bodyRect = document.body.getBoundingClientRect()
  const elemRect = e?.getBoundingClientRect()
  const y = Math.abs(elemRect?.top ?? 0 - bodyRect.top) + 100
  const [position, setPosition] = React.useState<IPosition>({ x: props.element.position.x, y })
  const [element, setElement] = React.useState<IElement>(props.element)
  const [lastElementId, setLastElementId] = React.useState<string>('')
  const handleDragStop = (e: any, d: IPosition) => {
    setPosition({ x: d.x, y: d.y })
  }

  React.useEffect(() => {
    const scrollX = document.documentElement.scrollLeft
    const screenWidth = window.innerWidth
    setLastElementId(props.element.id)
    if (lastElementId === props.element.id) {
      return
    }
    const e = document.querySelector('body')
    const bodyRect = document.body.getBoundingClientRect()
    const elemRect = e?.getBoundingClientRect()
    const y = Math.abs(elemRect?.top ?? 0 - bodyRect.top) + 20
    let x = scrollX + screenWidth - (attributesMaxWidth + 60)
    if (props.element.position.x > (scrollX + screenWidth) / 2) {
      x = scrollX + 60
    }
    setPosition({ x, y })
  }, [props.element])

  const attributesTitle = React.useCallback(() => {
    return `Atributos de ${getElementNameByType(props.element.type)}`
  }, [props.element])
  React.useEffect(() => {
    if (element !== props.element) {
      setElement(props.element)
    }
  }, [props.element])
  return (
    <Rnd ref={menuRef} position={position} onDragStop={handleDragStop} enableResizing={false} dragHandleClassName={classes.draggable} bounds={classes.board}>
      <Paper style={{ maxWidth: attributesMaxWidth }}>
        <div className={`${classes.draggable} draggable-mask`} />
        <div className="px-2 py-1 flex items-center w-full">
          <div className={`${classes.draggable} cursor-move flex items-center flex-grow`}>
            <Icon color="primary" style={{ fontSize: 15 }}>
              drag_indicator
            </Icon>
            <Typography align="center" className="px-2 w-full" variant="caption" style={{ letterSpacing: 0.5 }}>
              {attributesTitle()}
            </Typography>
          </div>
          <IconButton style={{ padding: 4 }} onMouseDown={onClose}>
            <Icon style={{ fontSize: 15 }}>close</Icon>
          </IconButton>
        </div>
        <Divider />
        <div className="py-1 px-2 mt-2">
          {element.type !== ELEMENT_TYPES.header && (
            <Actions element={element} handleChange={handleChange} handleInfo={handleInfo} handleSelectionAndReorder={handleSelectionAndReorder} />
          )}
          {element.type !== ELEMENT_TYPES.header && <Physics element={element} updateElement={updateElement} pagePosition={pagePosition} />}
          <Styles element={element} updateElement={updateElement} />
          {element.type !== ELEMENT_TYPES.header && (
            <Move element={element} moveElement={moveElement} isFirstElement={isFirstElement} isLastElement={isLastElement} />
          )}
          {element.type === ELEMENT_TYPES.text && <TextProps element={element} updateElement={updateElement} pageId={pageId} />}
          {element.type === ELEMENT_TYPES.image && <ImageProps element={element} updateElement={updateElement} filesContext={filesContext} pageId={pageId} />}
          {element.type === ELEMENT_TYPES.page && <PageProps element={element} updateElement={updateElement} />}
          {element.type === ELEMENT_TYPES.line && <LineProps element={element} updateElement={updateElement} />}
          {(element.type === ELEMENT_TYPES.text || element.type === ELEMENT_TYPES.page || element.type === ELEMENT_TYPES.image) && (
            <CurtainProps element={element} updateElement={updateElement} pageId={pageId} />
          )}
          {element.type === ELEMENT_TYPES.file && <FileProps element={element} updateElement={updateElement} />}
          {element.type === ELEMENT_TYPES.folder && <FolderProps element={element} updateElement={updateElement} />}
          {element.type === ELEMENT_TYPES.login && <LoginProps element={element} updateElement={updateElement} pageId={pageId} />}
          {(element.type === ELEMENT_TYPES.practiceCard ||
            element.type === ELEMENT_TYPES.practiceCardPersonality ||
            element.type === ELEMENT_TYPES.practiceCardGroups) && <CardProps element={element} updateElement={updateElement} />}
          {element.type === ELEMENT_TYPES.header && <HeaderProps element={element} updateElement={updateElement} pageId={pageId} />}
          {element.type === ELEMENT_TYPES.practiceConference && <ConferenceProps element={element} updateElement={updateElement} />}
        </div>
      </Paper>
    </Rnd>
  )
}

export default Attributes
