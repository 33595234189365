import React from 'react'
import { Pagination, Skeleton } from '@material-ui/lab'
import { getDocumentIcon, getFolderInfoById, openObjectInfo } from 'lib'
import { Breadcrumbs, Button, ButtonGroup, Icon, IconButton, Tooltip, Typography } from '@material-ui/core'
import moment from 'moment'
import 'moment/locale/es'
import { LocationContext, PageContext } from 'context'
import { useKeyPress } from 'hooks'
import REST from 'lib/constants/REST'
import { Filter, IContent, ICount, IFloatFolderValue, INavigation } from './Folder'
import { View, Pagination as TPagination, Permission } from 'components/FolderProps/FolderProps'
import audio from '../../assets/images/audio.svg'
import link from '../../assets/images/link.svg'

moment.locale("es")

interface Props {
    value: any,
    filesContext: string,
    style: React.CSSProperties,
    borderRadiusUnit: string
}

const FolderSelection = (props: Props) => {
    const { userId } = React.useContext(LocationContext)
    const [value, setValue] = React.useState<IFloatFolderValue | undefined>(undefined)
    const [error, setError] = React.useState<boolean>(false)
    const [showcase, setShowcase] = React.useState<boolean>(false)
    const [filter, setFilter] = React.useState<Filter>("all")
    const [currentContent, setCurrentContent] = React.useState<IContent[]>([])
    const [navigation, setNavigation] = React.useState<INavigation[]>([])
    const [itemsPerPage, setItemsPerPage] = React.useState<number>(4)
    const [page, setPage] = React.useState<number>(0)
    const [view, setView] = React.useState<View>("G")
    const [count, setCount] = React.useState<ICount>({} as ICount)
    const [pagination, setPagination] = React.useState<TPagination>("bottom")
    React.useEffect(() => {
        setShowcase(Boolean(props.value[2]))
        setItemsPerPage(props.value[3] ?? 4)
        setView(props.value[4] ?? "G")
        setPagination(props.value[5] ?? "bottom")
    }, [props.value])
    const countAll = React.useCallback(() => {
        if (count.video) { return count.audio + count.documents + count.folders + count.image + count.links + count.pages + count.tools + count.video }
        return 0
    }, [count])
    React.useEffect(() => {
        const init = async () => {
            try {
                if (value === undefined) { setValue(await getFolderInfoById(props.value[0], userId, (props.value[6] as Permission) === "F" ? true : false)) }
            } catch (error) {
                setError(true)
            }
        }
        init()
    }, [])
    React.useEffect(() => { if (value) { setCurrentContent(value.content); setCount(value.count) } }, [value])
    const handleOpenClick = async () => {
        const win = window.open(REST.hostURL + value?.url, '_blank')
        win?.focus()
    }
    const getTransforms = React.useCallback(() => { if (props.style.transform) { return JSON.parse(props.style.transform) } }, [props.style.transform])
    const getCurrentContent = React.useCallback(() => {
        if (filter !== "all") {
            return currentContent.filter(c => c.type === filter)
        }
        return currentContent
    }, [currentContent, filter])
    return (
        <div
            className="w-full h-full"
            style={{
                ...props.style,
                borderTopLeftRadius: props.style.borderTopLeftRadius + props.borderRadiusUnit,
                borderBottomLeftRadius: props.style.borderBottomLeftRadius + props.borderRadiusUnit,
                borderTopRightRadius: props.style.borderTopRightRadius + props.borderRadiusUnit,
                borderBottomRightRadius: props.style.borderBottomRightRadius + props.borderRadiusUnit,
                transform: `rotate(${getTransforms()[0]}deg) scaleX(${getTransforms()[1]})`,
            }}
        >
            {
                Boolean(value) ?
                    <React.Fragment>
                        {
                            !showcase ?
                                <div className="w-full flex h-full p-2">
                                    <div className="folder-icon relative">
                                        {
                                            value?.img &&
                                            <div className="absolute bg-center bg-contain bg-no-repeat" style={{ width: "60%", height: 50, top: 25, left: "20%", backgroundImage: `url(${props.filesContext}/${value?.img})` }} />
                                        }
                                    </div>
                                    <div className="ml-3 w-full h-full">
                                        <div className="w-full flex items-center">
                                            <Typography className="flex-grow" variant="caption" color="primary">{value?.editor}</Typography>
                                            <Typography variant="caption" color="primary">{moment(new Date(value?.createdAt ?? "").toISOString()).format('DD MMM YYYY').toString().replaceAll(".", "")}</Typography>
                                            <div className="mx-2" style={{ backgroundColor: "var(--primaryMainColor)", height: 10, width: 1 }} />
                                            <Typography variant="caption" color="primary">{moment(new Date(value?.lastUpdate ?? "").toISOString()).format('DD MMM YYYY').toString().replaceAll(".", "")}</Typography>
                                        </div>
                                        <Typography variant="h6">{value?.title}</Typography>
                                        <Typography color="textSecondary" variant="body2">{value?.detail}</Typography>
                                        <Typography variant="caption" color="primary">{value?.keyWords.split(",").join(", ")}</Typography>
                                    </div>
                                    <div className="absolute bottom-0 left-0">
                                        <IconButton style={{ backgroundColor: "rgba(255,255,255,0.25)" }} onClick={handleOpenClick} size="small">
                                            <Icon>add</Icon>
                                        </IconButton>
                                    </div>
                                </div>
                                :
                                <div className="w-full flex h-full">
                                    <div className="w-full flex h-full p-2 flex-col">
                                        <Typography style={{ overflow: "visible" }} variant="h6" className="text-truncate pb-1">{value?.title}</Typography>
                                        <div className="flex-grow w-full flex flex-col">
                                            <div className="w-full flex flex-wrap items-center">
                                                <Typography className="hover-underline cursor-pointer" color="primary" variant="caption">{`Todos ${countAll()}`}</Typography>
                                                <div className="mx-2" style={{ backgroundColor: "var(--primaryMainColor)", height: 10, width: 1 }} />
                                                <Typography color={Boolean(count?.pages) ? "primary" : "initial"} variant="caption" className="pr-2 hover-underline cursor-pointer">{`Páginas ${Boolean(count?.pages) ? count?.pages : ""}`}</Typography>
                                                <Typography color={Boolean(count?.folders) ? "primary" : "initial"} variant="caption" className="pr-2 hover-underline cursor-pointer">{`Folders ${Boolean(count?.folders) ? count?.folders : ""}`}</Typography>
                                                <Typography color={Boolean(count?.documents) ? "primary" : "initial"} variant="caption" className="pr-2 hover-underline cursor-pointer">{`Docs ${Boolean(count?.documents) ? count?.documents : ""}`}</Typography>
                                                <Typography color={Boolean(count?.image) ? "primary" : "initial"} variant="caption" className="pr-2 hover-underline cursor-pointer">{`Imágenes ${Boolean(count?.image) ? count?.image : ""}`}</Typography>
                                                <Typography color={Boolean(count?.video) ? "primary" : "initial"} variant="caption" className="pr-2 hover-underline cursor-pointer">{`Videos ${Boolean(count?.video) ? count?.video : ""}`}</Typography>
                                                <Typography color={Boolean(count?.audio) ? "primary" : "initial"} variant="caption" className="pr-2 hover-underline cursor-pointer">{`Audios ${Boolean(count?.audio) ? count?.audio : ""}`}</Typography>
                                                <Typography color={Boolean(count?.links) ? "primary" : "initial"} variant="caption" className="pr-2 hover-underline cursor-pointer">{`Links ${Boolean(count?.links) ? count?.links : ""}`}</Typography>
                                            </div>
                                            <div className="w-full flex flex-wrap items-center">
                                                <Breadcrumbs separator="›" aria-label="breadcrumb">
                                                    <Typography className="hover-underline cursor-pointer" variant="caption">{value?.title}</Typography>
                                                    {
                                                        navigation.map((item, index) => (
                                                            <Typography className="hover-underline cursor-pointer" key={"nav" + index} variant="caption">{item.name}</Typography>
                                                        ))
                                                    }
                                                </Breadcrumbs>
                                            </div>
                                            {
                                                (pagination === "both" || pagination === "top") &&
                                                <div className="flex w-full justify-center">
                                                    <Pagination
                                                        count={Math.ceil(getCurrentContent().length / itemsPerPage)}
                                                        page={page + 1}
                                                        size="small"
                                                        color="primary"
                                                        onChange={(e, value) => setPage(value - 1)}
                                                    />
                                                </div>
                                            }
                                            <div style={{ backgroundColor: "rgba(255,255,255,0.15)" }} className={`w-full flex flex-wrap flex-grow justify-center items-start ${view === "G" ? "flex-row" : "flex-col"}`}>
                                                {
                                                    (itemsPerPage > 0
                                                        ? getCurrentContent().slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage)
                                                        : getCurrentContent()
                                                    ).map((content, index) => (
                                                        <div
                                                            key={content.url + index.toString()}
                                                            className={`${view === "G" ? "folder-content-row items-center justify-center flex-col" : "folder-content-col"} flex text-truncate folder-content`}
                                                        >
                                                            <img style={{ objectFit: "contain", maxHeight: view === "G" ? "50%" : "100%" }} src={content.type !== "video" ? content.type === "document" ? getDocumentIcon(content.img) : content.type === "audio" ? audio : content.type === "link" ? link : props.filesContext + "/" + content.img : content.img} className={view === "G" ? "flex-grow" : "w-1/4"} />
                                                            <div style={{ height: 75 }} className="text-truncate clamp-2 flex flex-col justify-center items-center mb-2">
                                                                <Typography align={view === "G" ? "center" : "left"} variant="caption" className="text-truncate flex items-center">
                                                                    {content.title}
                                                                </Typography>
                                                                <Typography color="primary" align={view === "G" ? "center" : "left"} variant="caption" className="text-truncate">
                                                                    {content.editor}
                                                                </Typography>
                                                                {
                                                                    view !== "G" &&
                                                                    <React.Fragment>
                                                                        <Typography style={{ fontSize: "0.65em" }} variant="caption" color="textSecondary">{`Creada: ${moment(new Date(content.createdAt ?? "").toISOString()).format('DD MMM YYYY').toString().replaceAll(".", "")}`}</Typography>
                                                                        <Typography style={{ fontSize: "0.65em" }} variant="caption" color="textSecondary">{`Última actualización: ${moment(new Date(content.lastUpdate ?? "").toISOString()).format('DD MMM YYYY').toString().replaceAll(".", "")}`}</Typography>
                                                                    </React.Fragment>
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            {
                                                (pagination === "both" || pagination === "bottom") &&
                                                <div className="flex w-full justify-center">
                                                    <Pagination
                                                        count={Math.ceil(getCurrentContent().length / itemsPerPage)}
                                                        page={page + 1}
                                                        size="small"
                                                        color="primary"
                                                        onChange={(e, value) => setPage(value - 1)}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                        }
                    </React.Fragment>
                    :
                    <div className="w-full flex h-full items-center">
                        <Skeleton width={100} height={100} className="ml-2" />
                        <div className="w-full h--full flex flex-col justify-center">
                            <Skeleton width={"92%"} height={20} className="ml-2" />
                            <Skeleton width={"92%"} height={20} className="ml-2" />
                            <Skeleton width={"92%"} height={20} className="ml-2" />
                        </div>
                    </div>
            }
        </div>
    )
}

export default FolderSelection