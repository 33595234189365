import React from 'react'
import { Dispatch } from 'react'

interface Props {
    key: string,
}

interface IStorageHook {
    lastSaved: any[],
    saveItems: (items: any[]) => void,
    saved: boolean,
    setSaved: Dispatch<boolean>
}

const useStorage = (props: Props): IStorageHook => {
    const { key } = props
    const [lastSaved, setLastSaved] = React.useState<any[]>(JSON.parse(localStorage.getItem("inteligenxia_86") || "[]"))
    const [saved, setSaved] = React.useState<boolean>(true)

    const saveItems = async (items: any[]) => {
        if (items !== lastSaved) {
            setLastSaved(items)
            setSaved(false)
        }
    }

    return {
        lastSaved,
        saveItems,
        saved,
        setSaved
    }
}

export default useStorage
