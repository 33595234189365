import { Typography } from '@material-ui/core'
import { PageContext } from 'context'
import { disablePanel, enablePanel } from 'lib'
import React, { useContext } from 'react'
import Lottie from 'react-lottie'
import notFound from '../../assets/animations/not_found.json'

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: notFound,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
}

const PageError = () => {
    return (
        <div className="w-screen h-screen flex items-center flex-col justify-center">
            <Typography variant="h4" className="py-8" style={{ backgroundColor: "rgba(255,255,255,0.5)" }}>
                {"La página que estas queriendo editar esta abierta en otro tab"}
            </Typography>
            <Lottie
                options={defaultOptions}
                height={300}
                width={300}
            />
        </div>
    )
}

export default PageError
