import { Box, Checkbox, createStyles, FormControl, FormControlLabel, FormGroup, Icon, IconButton, makeStyles, MenuItem, Popover, Snackbar, Switch, TextField, Tooltip, Typography } from "@material-ui/core";
import { Alert, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { ILoginValues } from "components";
import { LocationContext } from "context";
import { findLink, ID_LINK_TAG } from "lib";
import React, { Fragment } from "react";
import { ColorResult, SketchPicker } from "react-color";
import { IElement, IRGBAColor } from "types";

interface Props {
    element: IElement;
    updateElement: (newElement: IElement) => void;
    pageId: number
}
const marginRight = 8
const popoverElements = {
    inputsStyle: "inputsStyle",
    buttonsStyle: "buttonsStyle",
    fontStyle: "fontStyle",
    showPasswordStyle: "rememberPasswordStyle",
    textProperties: "textProperties"
}

interface PopoverConfig {
    element: HTMLButtonElement | null,
    id: string
}
type inputOptionColorChange = "border" | "background" | "font" | "placeHolder";

enum ColorButtonsOption {
    BUTTON_VALIDAR = "Botón Validar",
    BUTTON_FORGOT = "Botón Olvidar",
    BUTTON_QR = "Botón QR",
    BUTTON_PIN = "Botón Pin",
    BUTTON_ENVIAR_PIN = "Botón Enviar Pin",
}

enum KeyOfLabels {
    USER = "labelUser", PASSWORD = "labelPassword", PIN_INPUT = "labelPin",
    OLVIDE = "labelOlvide", FOTOQR = "labelFotoQR", OPCION_PIN = "labelIngresoPin", BOTON_VALIDAR_USUARIO = "labelValidarUsuario", BOTON_ENVIAR_PIN = "labelEnviarPin"
}

enum KeyOfColorFontButtons {
    BUTTON_VALIDAR = "buttonValidar",
    BUTTON_FORGOT = "buttonForgot",
    BUTTON_QR = "buttonQR",
    BUTTON_PIN = "buttonPin",
    BUTTON_ENVIAR_PIN = "buttonEnviarPin",
}

enum ValidLinkPerxona {
    PERXONAPRO = "perxona.com",
    PERXONADEV = "dev.perxona.com",
    PERXONA = "perxona"
}


const LoginProps = (props: Props) => {
    const { updateElement, element, pageId } = props
    const { tabId } = React.useContext(LocationContext)
    const [inputColorChange, setInputColorChange] = React.useState<inputOptionColorChange | undefined>(undefined);
    const [fontColorInput, setFontColorInput] = React.useState<IRGBAColor>({ value: element.value.inputsStyle['fontColor'] } as IRGBAColor)
    const [placeHolderColor, setPlaceHolderColor] = React.useState<IRGBAColor>({ value: element.value.inputsStyle['placeHolderColor'] } as IRGBAColor)
    const [buttonBgColor, setButtonBgColor] = React.useState<IRGBAColor>({ value: element.value['buttonsBgColor'] } as IRGBAColor)
    const [InputBgColor, setInputBgColor] = React.useState<IRGBAColor>({ value: element.value.inputsStyle['backgroundColor'] } as IRGBAColor)
    const [borderColor, setBorderColor] = React.useState<IRGBAColor>({ value: element.value.inputsStyle['borderColor'] } as IRGBAColor)
    const [showPasswordColor, setShowPasswordColor] = React.useState<IRGBAColor>({ value: element.value['showPasswordBgColor'] } as IRGBAColor)
    const [popover, setPopover] = React.useState<PopoverConfig>({} as PopoverConfig)
    const [buttonColorSelected, selectButtonColorSelected] = React.useState<ColorButtonsOption>
        (ColorButtonsOption.BUTTON_VALIDAR)

    const getButtonColor = React.useMemo(() => {
        switch (buttonColorSelected) {
            case ColorButtonsOption.BUTTON_VALIDAR:
                return element.value.colorFontButtons[KeyOfColorFontButtons.BUTTON_VALIDAR]
            case ColorButtonsOption.BUTTON_FORGOT:
                return element.value.colorFontButtons[KeyOfColorFontButtons.BUTTON_FORGOT]
            case ColorButtonsOption.BUTTON_QR:
                return element.value.colorFontButtons[KeyOfColorFontButtons.BUTTON_QR]
            case ColorButtonsOption.BUTTON_PIN:
                return element.value.colorFontButtons[KeyOfColorFontButtons.BUTTON_PIN]
            case ColorButtonsOption.BUTTON_ENVIAR_PIN:
                return element.value.colorFontButtons[KeyOfColorFontButtons.BUTTON_ENVIAR_PIN]
            default:
                return '#fff'
        }

    }, [buttonColorSelected, element])

    const getColor = React.useMemo(() => {
        switch (inputColorChange) {
            case 'background':
                return InputBgColor
            case 'border':
                return borderColor
            case 'font':
                return fontColorInput
            case 'placeHolder':
                return placeHolderColor
        }
    }, [inputColorChange, InputBgColor, borderColor, fontColorInput, placeHolderColor])

    const getTittle = React.useMemo(() => {
        switch (inputColorChange) {
            case 'background':
                return "Color de Fondo"
            case 'border':
                return "Color de Borde"
            case 'font':
                return "Color de Fuente"
            case 'placeHolder':
                return "Color de PlaceHolder"
            default:
                return ""
        }
    }, [inputColorChange])

    const handleClosePopover = () => setPopover({} as PopoverConfig)
    const handlerChangeLabel = (value: string, labelProp: string) => {
        const prevElement = { ...element }
        const newValue = { ...prevElement.value.labelInputsText, [labelProp]: value }
        prevElement.value.labelInputsText = newValue
        updateElement(prevElement)
    }

    const [link, setLink] = React.useState<string>(props.element.link ?? "")


    const handleLink = () => {
        findLink(pageId, props.element.value[0], props.element.id, tabId)
    }
    const handleDeleteLink = () => {
        updateElement({
            ...element,
            link: undefined
        })
    }
    React.useEffect(() => {
        if (link !== props.element.link) { setLink(props.element.link ?? "") }
    }, [props.element.link])


    const [open, setOpen] = React.useState(false);



    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleOnPasteLink = React.useCallback((e) => {

        const linkPaste = e.clipboardData.getData('Text')
        const isValidLink =
            linkPaste.includes(ValidLinkPerxona.PERXONA) ||
            linkPaste.includes(ValidLinkPerxona.PERXONADEV) ||
            linkPaste.includes(ValidLinkPerxona.PERXONAPRO)

        if (isValidLink) {
            updateElement({
                ...element,
                link: linkPaste
            })
        } else {
            setOpen(true);
        }

    }, [element])

    const handleOnColorChange = React.useCallback((color: ColorResult) => {

        switch (inputColorChange) {
            case 'background':
                setInputBgColor({
                    r: color.rgb.r,
                    g: color.rgb.g,
                    b: color.rgb.g,
                    a: color.rgb.a,
                    value: color.hex
                })
                break;
            case 'border':
                setBorderColor({
                    r: color.rgb.r,
                    g: color.rgb.g,
                    b: color.rgb.g,
                    a: color.rgb.a,
                    value: color.hex
                })
                break;
            case 'font':
                setFontColorInput({
                    r: color.rgb.r,
                    g: color.rgb.g,
                    b: color.rgb.g,
                    a: color.rgb.a,
                    value: color.hex
                })
                break;
            case 'placeHolder':
                setPlaceHolderColor({
                    r: color.rgb.r,
                    g: color.rgb.g,
                    b: color.rgb.g,
                    a: color.rgb.a,
                    value: color.hex
                })
                break;
            default:
                break;
        }
    }, [inputColorChange])

    return (
        <Fragment>
            <div className="panel-container px-0 pt-4">
                <Typography color="primary" className="panel-label" variant="caption"
                    style={{ marginTop: 4 }}>{"Propiedades Login"}</Typography>
                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: 6 }}>
                    <Box>
                        <ToggleButtonGroup size="small" color="primary">
                            <Tooltip arrow title="Opción mostrar contraseña " placement="left">
                                <ToggleButton size="small" value="show_password"
                                    style={{ backgroundColor: element.value.addShowPasswordButton ? '#0000001f' : '#f5f5f5' }}
                                    onClick={(e) => {
                                        setPopover({ element: e.currentTarget, id: popoverElements.showPasswordStyle })
                                    }}>
                                    <Icon fontSize="small">preview</Icon>
                                    <Icon fontSize="small">expand_more</Icon>
                                </ToggleButton>
                            </Tooltip>
                            <Tooltip arrow title="Opción olvidé " placement="left">
                                <ToggleButton size="small" value="remember"
                                    style={{ backgroundColor: element.value.addForgotButton ? '#0000001f' : '#f5f5f5' }}
                                    onClick={() => {
                                        const elementValue = element.value as ILoginValues;
                                        const showAddForgotButtonValue = !elementValue.addForgotButton;
                                        const newValue = { addForgotButton: showAddForgotButtonValue }
                                        element.value = { ...elementValue, ...newValue }
                                        updateElement(element);
                                    }}>
                                    <Icon fontSize="small">remember_me</Icon>
                                </ToggleButton>
                            </Tooltip>
                            <Tooltip arrow title="Opción inicio con QR " placement="left">
                                <ToggleButton size="small" value="show_qr" style={{ backgroundColor: element.value.addShowQRButton ? '#0000001f' : '#f5f5f5' }}
                                    onClick={() => {
                                        const elementValue = element.value as ILoginValues;
                                        const showAddShowQRButton = !elementValue.addShowQRButton;
                                        const newValue = { addShowQRButton: showAddShowQRButton }
                                        element.value = { ...elementValue, ...newValue }
                                        updateElement(element);
                                    }}>
                                    <Icon fontSize="small">qr_code</Icon>
                                </ToggleButton>
                            </Tooltip>
                            <Tooltip arrow title="Opción inicio con PIN " placement="left">
                                <ToggleButton size="small" value="show_pin" style={{ backgroundColor: element.value.addShowPinButton ? '#0000001f' : '#f5f5f5' }}
                                    onClick={() => {
                                        const elementValue = element.value as ILoginValues;
                                        const showAddShowPinButton = !elementValue.addShowPinButton;
                                        const newValue = { addShowPinButton: showAddShowPinButton }
                                        element.value = { ...elementValue, ...newValue }
                                        updateElement(element);
                                    }}>
                                    <Icon fontSize="small">fiber_pin</Icon>
                                </ToggleButton>
                            </Tooltip>
                            <Tooltip arrow title="Estilos de los Inputs " placement="left">
                                <ToggleButton size="small" value="inputsStyle" onClick={(e) => setPopover({ element: e.currentTarget, id: popoverElements.inputsStyle })}>
                                    <Icon fontSize="small">smart_button</Icon>
                                    <Icon fontSize="small">expand_more</Icon>
                                </ToggleButton>
                            </Tooltip>
                        </ToggleButtonGroup >
                    </Box>
                    <Box >
                        <ToggleButtonGroup size="small" color="primary" style={{ paddingTop: 8 }}>
                            <Tooltip arrow title="Color Fuente " placement="left">
                                <ToggleButton size="small" value="fontStyle" onClick={(e) => setPopover({ element: e.currentTarget, id: popoverElements.fontStyle })}>
                                    <Icon fontSize="small">format_color_text</Icon>
                                    <Icon fontSize="small">expand_more</Icon>
                                </ToggleButton>
                            </Tooltip>
                            <Tooltip arrow title="Estilos Botones " placement="left">
                                <ToggleButton size="small" value="ButtonsStyle" onClick={(e) => setPopover({ element: e.currentTarget, id: popoverElements.buttonsStyle })}>
                                    <Icon fontSize="small">gamepad</Icon>
                                    <Icon fontSize="small">expand_more</Icon>
                                </ToggleButton>
                            </Tooltip>
                            <Tooltip arrow title="Propiedades de los textos " placement="left">
                                <ToggleButton size="small" value="TextProperties" onClick={(e) => setPopover({ element: e.currentTarget, id: popoverElements.textProperties })}>
                                    <Icon fontSize="small">text_format</Icon>
                                    <Icon fontSize="small">expand_more</Icon>
                                </ToggleButton>
                            </Tooltip>
                        </ToggleButtonGroup>

                    </Box>
                    <Box>
                        <Typography color="primary" className="pt-4" variant="caption">{"Link"}</Typography>
                        <ToggleButtonGroup size="small" color="primary" style={{ paddingTop: 8 }}>
                            <Tooltip arrow title={Boolean(link) ? link : 'Agregar Link'}>
                                <IconButton
                                    size="small"
                                    style={{ marginRight: 10, width: 50, maxWidth: 140 }}
                                    onClick={handleLink}
                                    disabled={Boolean(link)}
                                >
                                    <Icon fontSize="small">language</Icon>
                                </IconButton>

                            </Tooltip>

                            <Tooltip arrow title={Boolean(link) ? link : 'Pega un Link'}>
                                <TextField
                                    classes={{ root: "text-input-sm" }}
                                    size="small"
                                    value={Boolean(link) ? link.split(ID_LINK_TAG)[0] : ""}
                                    onPaste={handleOnPasteLink}
                                />
                            </Tooltip>
                            {
                                Boolean(link) &&
                                <Tooltip arrow title="Eliminar link">
                                    <IconButton
                                        onClick={handleDeleteLink}
                                        style={{ marginRight: 10 }}
                                        size="small"
                                    >
                                        <Icon
                                            fontSize="small"
                                            color="action"
                                        >
                                            close
                                        </Icon>
                                    </IconButton>
                                </Tooltip>
                            }

                        </ToggleButtonGroup>




                    </Box>
                </Box>


                <Popover
                    id={popoverElements.inputsStyle}
                    anchorEl={popover.element}
                    open={popover.id === popoverElements.inputsStyle && Boolean(popover.element)}
                    onClose={handleClosePopover}
                >
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>

                        <Box>
                            <div className={"flex items-center mt-1"}>
                                <Typography align="center" className="pl-4 w-full flex-grow" variant="caption" color="primary" style={{ letterSpacing: 0.5 }}>
                                    {"Estilo de los Inputs"}
                                </Typography>
                                <IconButton style={{ padding: 4 }} onMouseDown={handleClosePopover}>
                                    <Icon style={{ fontSize: 15 }}>close</Icon>
                                </IconButton>
                            </div>

                            <FormGroup style={{ marginLeft: marginRight, display: 'flex', flexDirection: 'row', justifyContent: "center" }}>
                                <FormControlLabel
                                    className="p-2"
                                    control={
                                        <Checkbox
                                            onChange={(e) => {
                                                const prevElement = { ...element }
                                                const newValue = { ...prevElement.value.inputsStyle, inputStyle: 'standard' }
                                                prevElement.value.inputsStyle = newValue
                                                updateElement(prevElement)
                                            }}
                                            color="primary"
                                            size="small"
                                            checked={element.value.inputsStyle.inputStyle === 'standard'}
                                            name="mirror"
                                        />

                                    }
                                    label="Standard"
                                />
                                <FormControlLabel
                                    className="p-2"
                                    control={
                                        <Checkbox
                                            onChange={(e) => {
                                                const prevElement = { ...element }
                                                const newValue = { ...prevElement.value.inputsStyle, inputStyle: 'outlined' }
                                                prevElement.value.inputsStyle = newValue
                                                updateElement(prevElement)
                                            }}
                                            color="primary"
                                            size="small"
                                            checked={element.value.inputsStyle.inputStyle === 'outlined'}
                                            name="mirror"
                                        />

                                    }
                                    label="Outlined"
                                />
                                <FormControlLabel
                                    className="p-2"
                                    control={
                                        <Checkbox
                                            onChange={(e) => {
                                                const prevElement = { ...element }
                                                const newValue = { ...prevElement.value.inputsStyle, inputStyle: 'filled' }
                                                prevElement.value.inputsStyle = newValue
                                                updateElement(prevElement)
                                            }}
                                            color="primary"
                                            size="small"
                                            checked={element.value.inputsStyle.inputStyle === 'filled'}
                                            name="mirror"
                                        />

                                    }
                                    label="Filled"
                                />
                            </FormGroup>
                            <div className={"flex items-center mt-1"}>
                                <Typography align="center" className="pl-4 w-full flex-grow" variant="caption" color="primary" style={{ letterSpacing: 0.5 }}>
                                    {"Tamaño de los Inputs"}
                                </Typography>

                            </div>

                            <FormGroup style={{ marginLeft: marginRight, display: 'flex', flexDirection: 'row', alignContent: 'center', justifyContent: 'center' }}>
                                <FormControlLabel
                                    className="p-2"
                                    control={
                                        <Checkbox
                                            onChange={(e) => {
                                                const prevElement = { ...element }
                                                const newValue = { ...prevElement.value.inputsStyle, size: 'small' }
                                                prevElement.value.inputsStyle = newValue
                                                updateElement(prevElement)
                                            }}
                                            color="primary"
                                            size="small"
                                            checked={element.value.inputsStyle.size === 'small'}
                                            name="mirror"
                                        />

                                    }
                                    label="Small"
                                />
                                <FormControlLabel
                                    className="p-2"
                                    control={
                                        <Checkbox
                                            onChange={(e) => {
                                                const prevElement = { ...element }
                                                const newValue = { ...prevElement.value.inputsStyle, size: 'medium' }
                                                prevElement.value.inputsStyle = newValue
                                                updateElement(prevElement)
                                            }}
                                            color="primary"
                                            size="small"
                                            checked={element.value.inputsStyle.size === 'medium'}
                                            name="mirror"
                                        />

                                    }
                                    label="Medium"
                                />
                            </FormGroup>

                        </Box>
                        <Box style={{ flexGrow: 2 }}>
                            <Box style={{ display: 'flex', alignItems: 'center', alignContent: "center", flexDirection: 'column', paddingBottom: 10 }}>
                                <Typography align="center" color="primary" variant="caption" style={{ letterSpacing: 0.5 }}>
                                    {"Colores Inputs"}
                                </Typography>
                                <FormGroup style={{ marginLeft: marginRight, display: 'flex', flexDirection: 'row', alignContent: 'center', justifyContent: 'center' }}>
                                    <FormControlLabel
                                        className="p-2"
                                        control={
                                            <Checkbox
                                                onChange={(e) => {
                                                    setInputColorChange('background')
                                                }}
                                                color="primary"
                                                size="small"
                                                checked={inputColorChange === 'background'}
                                                name="mirror"
                                            />
                                        }
                                        label="Fondo"
                                    />
                                    <FormControlLabel
                                        className="p-2"
                                        control={
                                            <Checkbox
                                                onChange={(e) => {
                                                    setInputColorChange('border')

                                                }}
                                                color="primary"
                                                size="small"
                                                checked={inputColorChange === 'border'}
                                                name="mirror"
                                            />
                                        }
                                        label="Borde"
                                    />
                                    <FormControlLabel
                                        className="p-2"
                                        control={
                                            <Checkbox
                                                onChange={(e) => {
                                                    setInputColorChange('font')

                                                }}
                                                color="primary"
                                                size="small"
                                                checked={inputColorChange === 'font'}
                                                name="mirror"
                                            />
                                        }
                                        label="Fuente"
                                    />
                                    <FormControlLabel
                                        className="p-2"
                                        control={
                                            <Checkbox
                                                onChange={(e) => {
                                                    setInputColorChange('placeHolder')

                                                }}
                                                color="primary"
                                                size="small"
                                                checked={inputColorChange === 'placeHolder'}
                                                name="mirror"
                                            />
                                        }
                                        label="Label"
                                    />
                                </FormGroup>
                            </Box  >
                            <Box style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Box>
                                    <div className={"flex items-center mt-1"}>
                                        <Typography align="center" className="pl-4 w-full flex-grow" variant="caption" color="primary" style={{ letterSpacing: 0.5 }}>
                                            {getTittle}
                                        </Typography>
                                    </div>
                                    {inputColorChange && <SketchPicker
                                        color={
                                            getColor?.value
                                        }
                                        onChange={(color) => {
                                            handleOnColorChange(color)
                                        }}
                                        onChangeComplete={(color) => {
                                            const prevElement = { ...element }
                                            const colorHowChange = inputColorChange === 'background' ? 'backgroundColor' : inputColorChange === 'border' ? 'borderColor' : inputColorChange === 'font' ? 'fontColor' : 'placeHolderColor'
                                            prevElement.value.inputsStyle[colorHowChange] = color.hex;
                                            updateElement(prevElement)
                                        }}
                                    />}

                                </Box>

                            </Box>

                        </Box>

                    </Box>

                </Popover>

                <Popover
                    id={popoverElements.buttonsStyle}
                    anchorEl={popover.element}
                    open={popover.id === popoverElements.buttonsStyle && Boolean(popover.element)}
                    onClose={handleClosePopover}
                >
                    <div className={"flex items-center mt-1"}>
                        <Typography align="center" className="pl-4 w-full flex-grow" color="primary" variant="caption" style={{ letterSpacing: 0.5 }}>
                            {"Estilo de los Botones"}
                        </Typography>
                        <IconButton style={{ padding: 4 }} onMouseDown={handleClosePopover}>
                            <Icon style={{ fontSize: 15 }}>close</Icon>
                        </IconButton>
                    </div>

                    <SketchPicker
                        color={buttonBgColor.value}
                        onChange={(color) => {
                            setButtonBgColor({
                                r: color.rgb.r,
                                g: color.rgb.g,
                                b: color.rgb.g,
                                a: color.rgb.a,
                                value: color.hex
                            })
                        }}
                        onChangeComplete={(color) => {
                            const prevElement = { ...element }
                            prevElement.value.buttonsBgColor = color.hex;
                            updateElement(prevElement)
                        }}
                    />

                </Popover>
                <Popover
                    id={popoverElements.fontStyle}
                    anchorEl={popover.element}
                    open={popover.id === popoverElements.fontStyle && Boolean(popover.element)}
                    onClose={handleClosePopover}
                >
                    <div className={"flex items-center mt-1"}>
                        <Typography align="center" className="pl-4 w-full flex-grow" color="primary" variant="caption" style={{ letterSpacing: 0.5 }}>
                            {"Color de Fuente"}
                        </Typography>
                        <IconButton style={{ padding: 4 }} onMouseDown={handleClosePopover}>
                            <Icon style={{ fontSize: 15 }}>close</Icon>
                        </IconButton>
                    </div>
                    <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: "center" }}>
                        <FormGroup style={{ marginLeft: marginRight, display: 'flex', flexDirection: 'row', alignContent: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                            <FormControlLabel
                                className="p-2"
                                control={
                                    <Checkbox
                                        onChange={(e) => {
                                            selectButtonColorSelected(ColorButtonsOption.BUTTON_ENVIAR_PIN)
                                        }}
                                        color="primary"
                                        size="small"
                                        checked={buttonColorSelected === ColorButtonsOption.BUTTON_ENVIAR_PIN}
                                        name="mirror"
                                    />
                                }
                                label={ColorButtonsOption.BUTTON_ENVIAR_PIN}
                            />
                            <FormControlLabel
                                className="p-2"
                                control={
                                    <Checkbox
                                        onChange={(e) => {
                                            selectButtonColorSelected(ColorButtonsOption.BUTTON_FORGOT)
                                        }}
                                        color="primary"
                                        size="small"
                                        checked={buttonColorSelected === ColorButtonsOption.BUTTON_FORGOT}
                                        name="mirror"
                                    />
                                }
                                label={ColorButtonsOption.BUTTON_FORGOT}
                            />
                            <FormControlLabel
                                className="p-2"
                                control={
                                    <Checkbox
                                        onChange={(e) => {
                                            selectButtonColorSelected(ColorButtonsOption.BUTTON_PIN)

                                        }}
                                        color="primary"
                                        size="small"
                                        checked={buttonColorSelected === ColorButtonsOption.BUTTON_PIN}
                                        name="mirror"
                                    />
                                }
                                label={ColorButtonsOption.BUTTON_PIN}
                            />
                        </FormGroup>
                        <FormGroup style={{ marginLeft: marginRight, display: 'flex', flexDirection: 'row', alignContent: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                            <FormControlLabel
                                className="p-2"
                                control={
                                    <Checkbox
                                        onChange={(e) => {
                                            selectButtonColorSelected(ColorButtonsOption.BUTTON_QR)
                                        }}
                                        color="primary"
                                        size="small"
                                        checked={buttonColorSelected === ColorButtonsOption.BUTTON_QR}
                                        name="mirror"
                                    />
                                }
                                label={ColorButtonsOption.BUTTON_QR}
                            />
                            <FormControlLabel
                                className="p-2"
                                control={
                                    <Checkbox
                                        onChange={(e) => {
                                            selectButtonColorSelected(ColorButtonsOption.BUTTON_VALIDAR)
                                        }}
                                        color="primary"
                                        size="small"
                                        checked={buttonColorSelected === ColorButtonsOption.BUTTON_VALIDAR}
                                        name="mirror"
                                    />
                                }
                                label={ColorButtonsOption.BUTTON_VALIDAR}
                            />
                        </FormGroup>

                        <SketchPicker
                            color={
                                getButtonColor
                            }
                            onChange={(color) => {
                                const prevElement = { ...element }
                                const keyOfChange = buttonColorSelected === ColorButtonsOption.BUTTON_ENVIAR_PIN ? KeyOfColorFontButtons.BUTTON_ENVIAR_PIN :
                                    buttonColorSelected === ColorButtonsOption.BUTTON_FORGOT ? KeyOfColorFontButtons.BUTTON_FORGOT :
                                        buttonColorSelected === ColorButtonsOption.BUTTON_PIN ? KeyOfColorFontButtons.BUTTON_PIN :
                                            buttonColorSelected === ColorButtonsOption.BUTTON_QR ? KeyOfColorFontButtons.BUTTON_QR :
                                                buttonColorSelected === ColorButtonsOption.BUTTON_VALIDAR ? KeyOfColorFontButtons.BUTTON_VALIDAR :
                                                    KeyOfColorFontButtons.BUTTON_ENVIAR_PIN
                                prevElement.value.colorFontButtons[keyOfChange] = color.hex;
                                updateElement(prevElement)
                            }}
                            onChangeComplete={(color) => {
                                const prevElement = { ...element }
                                const keyOfChange = buttonColorSelected === ColorButtonsOption.BUTTON_ENVIAR_PIN ? KeyOfColorFontButtons.BUTTON_ENVIAR_PIN :
                                    buttonColorSelected === ColorButtonsOption.BUTTON_FORGOT ? KeyOfColorFontButtons.BUTTON_FORGOT :
                                        buttonColorSelected === ColorButtonsOption.BUTTON_PIN ? KeyOfColorFontButtons.BUTTON_PIN :
                                            buttonColorSelected === ColorButtonsOption.BUTTON_QR ? KeyOfColorFontButtons.BUTTON_QR :
                                                buttonColorSelected === ColorButtonsOption.BUTTON_VALIDAR ? KeyOfColorFontButtons.BUTTON_VALIDAR :
                                                    KeyOfColorFontButtons.BUTTON_ENVIAR_PIN
                                prevElement.value.colorFontButtons[keyOfChange] = color.hex;
                                updateElement(prevElement)
                            }}
                        />

                    </Box>

                </Popover>
                <Popover
                    id={popoverElements.showPasswordStyle}
                    anchorEl={popover.element}
                    open={popover.id === popoverElements.showPasswordStyle && Boolean(popover.element)}
                    onClose={handleClosePopover}
                >

                    <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: "center", justifyContent: "center" }}>
                        <div className={"flex items-center mt-1"}>
                            <Typography align="center" color="primary" className="pl-4 w-full flex-grow" variant="caption" style={{ letterSpacing: 0.5 }}>
                                {"Estilos Mostrar Contraseña"}
                            </Typography>
                            <IconButton style={{ padding: 4 }} onMouseDown={handleClosePopover}>
                                <Icon style={{ fontSize: 15 }}>close</Icon>
                            </IconButton>
                        </div>
                        <Box>

                            <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        value="showPassword"
                                        control={<Switch
                                            color="primary"
                                            checked={element.value.addShowPasswordButton}
                                            onChange={(e) => {
                                                const elementValue = element.value as ILoginValues;
                                                const showPasswordValue = e.target.checked;
                                                const newValue = { addShowPasswordButton: showPasswordValue }
                                                element.value = { ...elementValue, ...newValue }
                                                updateElement(element);
                                            }}
                                        />}
                                        label="Habilitar "
                                        labelPlacement="bottom"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Box>
                        <Box>

                            <div className={"flex items-center mt-1"}>
                                <Typography align="center" className="pl-4 w-full flex-grow" variant="caption" color="primary" style={{ letterSpacing: 0.5 }}>
                                    {"Color de Fondo"}
                                </Typography>

                            </div>

                            <SketchPicker
                                color={showPasswordColor?.value}
                                onChange={(color) => {
                                    setShowPasswordColor({
                                        r: color.rgb.r,
                                        g: color.rgb.g,
                                        b: color.rgb.g,
                                        a: color.rgb.a,
                                        value: color.hex
                                    })
                                }}
                                onChangeComplete={(color) => {
                                    const prevElement = { ...element }
                                    prevElement.value.showPasswordBgColor = color.hex;
                                    updateElement(prevElement)
                                }}
                            />

                        </Box>


                    </Box>

                </Popover>
                <Popover
                    id={popoverElements.textProperties}
                    anchorEl={popover.element}
                    open={popover.id === popoverElements.textProperties && Boolean(popover.element)}
                    onClose={handleClosePopover}
                >

                    <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: "center", justifyContent: "center", paddingBottom: 5 }}>
                        <div className={"flex items-center mt-2 mb-2"}>
                            <Typography align="center" color="primary" className="pl-4 w-full flex-grow" variant="caption" style={{ letterSpacing: 0.5 }}>
                                {"Propiedades de los textos"}
                            </Typography>
                            <IconButton style={{ padding: 4 }} onMouseDown={handleClosePopover}>
                                <Icon style={{ fontSize: 15 }}>close</Icon>
                            </IconButton>
                        </div>

                        <Box style={{ display: 'flex', alignItems: 'center', alignContent: "center", flexDirection: 'column', paddingBottom: 10, width: "" }}>
                            <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                                <TextField
                                    label="Usuario"
                                    value={element.value.labelInputsText.labelUser}
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    onChange={(e) => {
                                        handlerChangeLabel(e.target.value, KeyOfLabels.USER);
                                    }}
                                    onBlur={(e) => {
                                        handlerChangeLabel(e.target.value, KeyOfLabels.USER);
                                    }}
                                />
                            </div>
                            <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                                <TextField
                                    label="Contraseña"
                                    value={element.value.labelInputsText.labelPassword}
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    onChange={(e) => {
                                        handlerChangeLabel(e.target.value, KeyOfLabels.PASSWORD);
                                    }}
                                    onBlur={(e) => {
                                        handlerChangeLabel(e.target.value, KeyOfLabels.PASSWORD);
                                    }}
                                />
                            </div>
                            <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                                <TextField
                                    label="Recordar Contraseña"
                                    value={element.value.labelInputsText.labelOlvide}
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    onChange={(e) => {
                                        handlerChangeLabel(e.target.value, KeyOfLabels.OLVIDE);
                                    }}
                                    onBlur={(e) => {
                                        handlerChangeLabel(e.target.value, KeyOfLabels.OLVIDE);
                                    }}
                                />
                            </div>
                            <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                                <TextField
                                    label="Botón Validar Usuario"
                                    value={element.value.labelInputsText.labelValidarUsuario}
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    onChange={(e) => {
                                        handlerChangeLabel(e.target.value, KeyOfLabels.BOTON_VALIDAR_USUARIO);
                                    }}
                                    onBlur={(e) => {
                                        handlerChangeLabel(e.target.value, KeyOfLabels.BOTON_VALIDAR_USUARIO);
                                    }}
                                />
                            </div>
                            {element.value.addShowQRButton && (<div style={{ paddingLeft: 16, paddingRight: 16 }}>
                                <TextField
                                    label="Opción QR"
                                    value={element.value.labelInputsText.labelFotoQR}
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    onChange={(e) => {
                                        handlerChangeLabel(e.target.value, KeyOfLabels.FOTOQR);
                                    }}
                                    onBlur={(e) => {
                                        handlerChangeLabel(e.target.value, KeyOfLabels.FOTOQR);
                                    }}
                                />
                            </div>)}
                            {element.value.addShowPinButton && (<div style={{ paddingLeft: 16, paddingRight: 16 }}>
                                <TextField
                                    label="Botón Enviar Pin"
                                    value={element.value.labelInputsText.labelEnviarPin}
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    onChange={(e) => {
                                        handlerChangeLabel(e.target.value, KeyOfLabels.BOTON_ENVIAR_PIN);
                                    }}
                                    onBlur={(e) => {
                                        handlerChangeLabel(e.target.value, KeyOfLabels.BOTON_ENVIAR_PIN);
                                    }}
                                />
                            </div>)}
                            {element.value.addShowPinButton && (
                                <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                                    <TextField
                                        label="Opción Ingreso Por Pin"
                                        value={element.value.labelInputsText.labelIngresoPin}
                                        variant="outlined"
                                        size="small"
                                        margin="dense"
                                        onChange={(e) => {
                                            handlerChangeLabel(e.target.value, KeyOfLabels.OPCION_PIN);
                                        }}
                                        onBlur={(e) => {
                                            handlerChangeLabel(e.target.value, KeyOfLabels.OPCION_PIN);
                                        }}
                                    />
                                </div>)}

                            {element.value.addShowPinButton && (<div style={{ paddingLeft: 16, paddingRight: 16 }}>
                                <TextField
                                    label="Pin Input"
                                    value={element.value.labelInputsText.labelPin}
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    onChange={(e) => {
                                        handlerChangeLabel(e.target.value, KeyOfLabels.PIN_INPUT);
                                    }}
                                    onBlur={(e) => {
                                        handlerChangeLabel(e.target.value, KeyOfLabels.PIN_INPUT);
                                    }}
                                />
                            </div>)}
                        </Box>
                    </Box>

                </Popover>
                <Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="info" style={{ width: '100%' }} >
                        {"Solo enlaces de Perxona"}
                    </Alert>
                </Snackbar>
            </div>
        </Fragment >
    )

}



export default LoginProps;


