import React from 'react'
import { IMessage, IPaginationResponse } from 'types'
import { useMessagesHelper } from '../hook'

type Props = {
  messages: IMessage[]
  handlePageChange: (conferenceId: number, page: number, conferenceThemeId?: number | undefined) => void
  paginationResponse: IPaginationResponse<IMessage>
  loading: boolean
  getMessages: (conferenceId: string | number, page: number, conferenceThemeId?: number | undefined, limit?: number) => Promise<void>
  resetMessages: () => void
}
export const ConferenceContext = React.createContext({} as Props)

const Provider = ({ children }: { children: React.ReactNode }) => {
  const { handlePageChange, loading, messages, paginationResponse, getMessages, resetMessages } = useMessagesHelper()

  return (
    <ConferenceContext.Provider
      value={{
        handlePageChange,
        loading,
        messages,
        paginationResponse,
        getMessages,
        resetMessages,
      }}
    >
      {children}
    </ConferenceContext.Provider>
  )
}

export default Provider
