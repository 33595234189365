import React from 'react'
interface Hook {
          pageConfig: IPageConfig
          handleChangePage: (page: number) => void
          handleChangeItemsPerPage: (itemsPerPage: number) => void
          handleChangeTotalItems: (items: number) => void
          slice: IPageSlice
}
export interface IPageConfig {
          page: number
          totalPages: number
}

export interface IPageSlice {
          initSlice: number,
          endSlice: number
}

interface Props {
          itemsPerPage: number
          totalItems: number
}

export const usePagination = (props: Props): Hook => {
          const [pageConfig, setPageConfig] = React.useState<IPageConfig>({ page: 1, totalPages: 2 } as IPageConfig)

          const [paginationConfig, setPaginationConfig] = React.useState<Props>(props)

          const [slice, setSlice] = React.useState<IPageSlice>({ initSlice: 0, endSlice: 0 } as IPageSlice)

          const handleChangePage = (page: number) => {
                    setPageConfig(prev => ({ ...prev, page }))
          }


          const handleChangeItemsPerPage = (itemsPerPage: number) => {
                    setPaginationConfig(prev => ({ ...prev, itemsPerPage }))
          }

          const handleChangeTotalItems = (items: number) => {
                    setPaginationConfig(prev => ({ ...prev, totalItems: items }))
          }

          const getSliceForPagination = React.useCallback(() => {
                    const isLastPage = pageConfig.page === pageConfig.totalPages;
                    const initSlice = paginationConfig.itemsPerPage * (pageConfig.page - 1);
                    const endSlice = isLastPage ? paginationConfig.totalItems : initSlice + paginationConfig.itemsPerPage;
                    setSlice({ initSlice, endSlice })
          }, [pageConfig, paginationConfig])

          React.useEffect(() => {
                    const totalPages = Math.ceil(paginationConfig.totalItems / paginationConfig.itemsPerPage)
                    setPageConfig(prev => ({ ...prev, totalPages }))
          }, [paginationConfig])

          React.useEffect(() => {
                    getSliceForPagination()
          }, [getSliceForPagination])

          return {
                    handleChangePage,
                    handleChangeItemsPerPage,
                    handleChangeTotalItems,
                    pageConfig,
                    slice
          }
}

