import { Tooltip, Box } from '@material-ui/core'
import { getConferenxiaMembersService } from 'lib'
import React from 'react'
import { IConferenxiaMembers } from 'types'
const CardGrid = ({ avatar, namePerxona, idPerxona }) => {
  return (
    <Tooltip arrow title={namePerxona}>
      <Box
        width="80px"
        height="80px"
        style={{
          backgroundImage: `url(${avatar})`,
          width: '80px',
          height: '80px',
          padding: 0,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '20px',
          borderBottomLeftRadius: '20px',
          borderBottomRightRadius: '0px',
          borderWidth: '2px',
        }}
      ></Box>
    </Tooltip>
  )
}

type Props = {
  conferenceId: number
}
export const ConferenceMember = (props: Props) => {
  const { conferenceId } = props
  const [members, setMembers] = React.useState<IConferenxiaMembers[]>([])

  const getMembers = React.useCallback(async () => {
    try {
      const res = await getConferenxiaMembersService(conferenceId)
      setMembers(res)
    } catch (e) {
      console.log(e)
    }
  }, [conferenceId])

  React.useEffect(() => {
    getMembers()
  }, [getMembers])

  return (
    <Box
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
        gap: 10,
      }}
    >
      {members.map((member) => {
        return <CardGrid avatar={member.avatar} namePerxona={member.perxonaName} idPerxona={member.perxonaId} />
      })}
    </Box>
  )
}
