import { Box, Paper } from '@material-ui/core'
import { IConferenceTheme } from 'types'
import { ThemeView } from './ThemeView'
import React from 'react'
type Props = {
  conferenceId: number
  theme?: IConferenceTheme
  loadMode: 'D' | 'A'
  hiddenDate?: boolean
  hiddenName?: boolean
  height: number
  refresh: boolean
  onEndRefresh: () => void
}

export const ConferenceOnlyOneThemeView = (props: Props) => {
  const { conferenceId, loadMode, theme, hiddenDate, hiddenName, height, refresh } = props
  React.useEffect(() => {
    console.log(theme, 'theme')
  }, [theme])
  return (
    <Box
      style={{
        height: '100%',
        maxHeight: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
      id={`themeView`}
    >
      <Box
        style={{
          width: '100%',
          height: height,
          borderTopRightRadius: 30,
          borderBottomLeftRadius: 30,
        }}
      >
        <ThemeView
          conferenceId={conferenceId}
          theme={theme}
          loadMode={loadMode}
          hiddenDate={hiddenDate}
          hiddenName={hiddenName}
          disableAutoWidth
          refresh={refresh}
          onEndRefresh={props.onEndRefresh}
        />
      </Box>
    </Box>
  )
}
