import React from 'react'

interface Props {
    style: React.CSSProperties,
    setStyle: React.Dispatch<React.CSSProperties>
}

export const BoardContext = React.createContext({} as Props)

const Context = ({ children }: any) => {
    const [style, setStyle] = React.useState<React.CSSProperties>({})

    return (
        <BoardContext.Provider
            value={{
                style,
                setStyle
            }}
        >
            {children}
        </BoardContext.Provider>
    )
}

export default Context