import { Box, Checkbox, Grid, Icon, Paper } from '@material-ui/core'
import { ICardPersonality } from 'components/CardProps/CardProps'
import React from 'react'
import { PageContext } from 'context'
import { IElement } from 'types'
import { HookOrder } from 'hooks'

type SelectionMode = 'selection' | 'reorder' | 'none'

type Props = {
  info: ICardPersonality
  selectionMode: SelectionMode
  hookOrder: HookOrder
  element: IElement
  minimize: boolean
}

export const CardPersonality = (props: Props) => {
  const { info, selectionMode, hookOrder, element, minimize } = props
  const { order, handleChangeOrder, handleAddChosen, handleRemoveChosen } = hookOrder
  const { updateElement } = React.useContext(PageContext)
  const { cardId, personality, title, phrase, description, image } = info

  const update = React.useCallback(
    (newCardsChosen: number[]) => {
      const elementUpdate = {
        ...element,
        value: {
          ...element.value,
          cardsChosen: newCardsChosen,
        },
      }
      updateElement(elementUpdate)
    },
    [element, updateElement]
  )

  const handleCheck = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target
      if (checked) {
        handleAddChosen(cardId, update)
      } else {
        handleRemoveChosen(cardId, update)
      }
    },
    [handleAddChosen, handleRemoveChosen, cardId, order]
  )

  return (
    <Box width="100%" height="fit-content" style={{ height: '100%', display: 'flex', alignItems: 'flex-start' }}>
      <Paper
        style={{
          backgroundColor: 'white',
          borderTopRightRadius: '30px',
          borderBottomLeftRadius: '20px',
          borderTopLeftRadius: '0px',
          borderBottomRightRadius: '0px',
          width: '100%',
          boxShadow: 'rgb(0 0 0 / 15%) 10px 10px 5px -5px',
          borderColor: '#bdbdbd',
          borderStyle: 'solid',
          borderWidth: '1px',
        }}
      >
        <Grid
          container
          spacing={0}
          direction="column"
          wrap="nowrap"
          alignItems="center"
          style={{
            width: '100%',
            height: 'fit-content',
            maxWidth: '220px',
          }}
        >
          <Grid item xs={12} style={{ height: '155px', maxHeight: '155px', width: '100%', minHeight: '155px' }}>
            <Box style={{ position: 'relative', opacity: '75%', width: '100%' }}>
              <Box style={{ position: 'absolute', backgroundColor: 'rgb(245 244 244)', padding: 4, fontWeight: 'bold' }}>{`${personality}`}</Box>
            </Box>
            <Box style={{ height: '155px', minHeight: '155px', maxHeight: '155px', overflow: 'hidden' }}>
              <img
                src={`${image}`}
                style={{
                  borderTopRightRadius: '30px',
                  minWidth: '220px',
                  objectFit: 'scale-down',
                  minHeight: '100%',
                }}
              ></img>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ width: '100%' }}>
            <Box
              style={{
                backgroundColor: 'rgb(245 244 244)',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '55px',
                minHeight: '40px',
                maxHeight: '55px',
              }}
            >
              <Box
                textAlign="center"
                fontSize={12}
                style={{
                  fontWeight: 'bold',
                  boxSizing: 'content-box',
                }}
              >
                {`${phrase}`}
              </Box>
            </Box>
          </Grid>
          {!minimize && (
            <Grid item xs={12} style={{ width: '100%', backgroundColor: 'white' }}>
              <Box
                className="descriptionPerxona"
                textAlign="center"
                fontSize={13}
                height="142px"
                style={{
                  paddingLeft: 8,
                  paddingRight: 8,
                  wordWrap: 'break-word',
                  overflow: 'auto',
                  width: '100%',
                  minHeight: '142px',
                  maxHeight: '142px',
                }}
              >
                {selectionMode === 'none' && <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: description }} />}
                {selectionMode === 'reorder' && (
                  <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100%' }}>
                    <Box>
                      <Icon
                        fontSize="large"
                        onClick={() => {
                          const doUpdate = async () => {
                            await update(newOrder)
                          }
                          const newOrder = handleChangeOrder('LEFT', cardId)
                          doUpdate()
                        }}
                      >
                        {order.indexOf(cardId) !== 0 && 'keyboard_double_arrow_left'}
                      </Icon>
                    </Box>

                    <Box>
                      <Icon
                        fontSize="large"
                        onClick={() => {
                          const doUpdate = async () => {
                            await update(newOrder)
                          }
                          const newOrder = handleChangeOrder('RIGHT', cardId)
                          doUpdate()
                        }}
                      >
                        {order.indexOf(cardId) !== order.length - 1 && 'keyboard_double_arrow_right'}
                      </Icon>
                    </Box>
                  </Box>
                )}
                {selectionMode === 'selection' && (
                  <Box style={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <Checkbox size="medium" checked={order.includes(cardId)} onChange={handleCheck} />
                  </Box>
                )}
              </Box>
            </Grid>
          )}
          <Grid item xs={12} style={{ width: '100%' }}>
            <Box
              style={{
                backgroundColor: minimize ? 'white' : 'rgb(245 244 244)',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '45px',
                borderBottomLeftRadius: '20px',
                minHeight: '45px',
                maxHeight: '45px',
              }}
            >
              {(!minimize || selectionMode === 'none') && (
                <Box
                  textAlign="center"
                  fontSize={11}
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {`${title}`}
                </Box>
              )}

              {minimize && selectionMode === 'reorder' && (
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100%' }}>
                  <Box>
                    <Icon
                      fontSize="large"
                      onClick={() => {
                        const doUpdate = async () => {
                          await update(newOrder)
                        }
                        const newOrder = handleChangeOrder('LEFT', cardId)
                        doUpdate()
                      }}
                    >
                      {order.indexOf(cardId) !== 0 && 'keyboard_double_arrow_left'}
                    </Icon>
                  </Box>
                  <Box>
                    <Icon
                      fontSize="large"
                      onClick={() => {
                        const doUpdate = async () => {
                          await update(newOrder)
                        }
                        const newOrder = handleChangeOrder('RIGHT', cardId)
                        doUpdate()
                      }}
                    >
                      {order.indexOf(cardId) !== order.length - 1 && 'keyboard_double_arrow_right'}
                    </Icon>
                  </Box>
                </Box>
              )}
              {minimize && selectionMode === 'selection' && (
                <Box style={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                  <Checkbox size="medium" checked={order.includes(cardId)} onChange={handleCheck} />
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}
