import { IObject, IObjectType } from 'types'
import React from 'react'
import { Box, Icon } from '@material-ui/core'
import { Extensions, objectImageByExtension } from 'lib/utils/objectImage'

type Props = {
  object: IObject
  handleOnClick: () => void
  width: string
  height: string
  backgroundSize?: string
}

export const ObjectPreview = (props: Props) => {
  const { object, handleOnClick, width, height, backgroundSize } = props

  return (
    <React.Fragment>
      {object.fileTypeId === IObjectType.LINK && (
        <Box
          style={{
            minWidth: '100px',
            minHeight: '100px',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
        >
          <Box
            onClick={() => handleOnClick()}
            style={{
              borderTopRightRadius: '25px',
              borderBottomLeftRadius: '25px',
              width: '80px',
              height: '80px',
              backgroundColor: '#4b4b4b',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <Box
              style={{
                position: 'absolute',
                top: '2px',
                right: '10px',
              }}
            >
              <Icon
                style={{
                  color: 'white',
                }}
              >
                link
              </Icon>
            </Box>
            <Box
              style={{
                backgroundImage: `url(${object.icon})`,
                backgroundSize: backgroundSize ?? 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                width: 25,
                minWidth: 25,
                maxWidth: 25,
                height: 25,
                maxHeight: 25,
                cursor: 'pointer',
              }}
              className={'linkGalleryPreview'}
            />
          </Box>
        </Box>
      )}

      {object.fileTypeId === IObjectType.IMAGE && (
        <Box
          onClick={() => handleOnClick()}
          style={{
            backgroundImage: `url(${object.url})`,
            backgroundSize: backgroundSize ?? 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            width: width,
            minWidth: width,
            maxWidth: width,
            height: height,
            maxHeight: height,
            cursor: 'pointer',
            borderTopRightRadius: '25px',
          }}
          className={'imageGalleryPreview'}
        />
      )}

      {object.fileTypeId === IObjectType.VIDEO && object.onlyUrl && object.url !== 'none' && (
        <Box
          style={{
            backgroundImage: `url(${object.url})`,
            backgroundSize: backgroundSize ?? 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            width: width,
            minWidth: width,
            maxWidth: width,
            height: height,
            maxHeight: height,
            cursor: 'pointer',
          }}
          onClick={() => handleOnClick()}
          className={'imageGViedoPreview'}
        />
      )}
      {object.fileTypeId === IObjectType.VIDEO && object.onlyUrl && object.url === 'none' && (
        <object className={'videoExternalGalleryPreview'} width={width} height={height} data={`${object.urlPlayer}`} onClick={() => handleOnClick()}></object>
      )}
      {object.fileTypeId === IObjectType.VIDEO && !object.onlyUrl && (
        <Box>
          <video
            width={width}
            height={height}
            style={{ cursor: 'pointer', pointerEvents: 'none' }}
            controls={false}
            className={'videoGalleryPreview'}
            onClick={() => handleOnClick()}
          >
            <source src={`${object.url}`} type="video/mp4" />
          </video>
        </Box>
      )}

      {object.fileTypeId === IObjectType.DOCUMENT && (
        <Box
          onClick={() => handleOnClick()}
          className={'documentGalleryPreview'}
          style={{
            backgroundImage: `url(${objectImageByExtension(object.extension as Extensions)})`,
            backgroundSize: backgroundSize ?? 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            width: width,
            minWidth: width,
            maxWidth: width,
            height: height,
            maxHeight: height,
          }}
        />
      )}

      {object.fileTypeId === IObjectType.PAGE && (
        <Box
          style={{
            backgroundImage: `url(${object.url})`,
            backgroundSize: backgroundSize ?? 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            width: width,
            minWidth: width,
            maxWidth: width,
            height: height,
            maxHeight: height,
            cursor: 'pointer',
            borderTopRightRadius: '15px',
            borderBottomLeftRadius: '15px',
          }}
          onClick={() => handleOnClick()}
        />
      )}
      {object.fileTypeId === IObjectType.AUDIO && (
        <Box
          onClick={() => handleOnClick()}
          style={{
            backgroundImage: `url(${objectImageByExtension('audio')})`,
            backgroundSize: backgroundSize ?? 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            width: width,
            minWidth: width,
            maxWidth: width,
            height: height,
            maxHeight: height,
            cursor: 'pointer',
          }}
        />
      )}
      {object.fileTypeId === IObjectType.FOLDER && (
        <Box
          onClick={() => handleOnClick()}
          style={{
            backgroundImage: `url(${objectImageByExtension('folder')})`,
            backgroundSize: backgroundSize ?? 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            width: width,
            minWidth: width,
            maxWidth: width,
            height: height,
            maxHeight: height,
            cursor: 'pointer',
          }}
        />
      )}
    </React.Fragment>
  )
}
