import { Box, Icon, IconButton, Link, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core'
import { HeaderValues } from 'components/HeaderProps/HeaderProps'
import { ID_LINK_TAG } from 'lib'
import React from 'react'
import { IElement, IHeader, ISize } from 'types'
type Props = {
  element: IElement
  pageSize: ISize
  headerData?: IHeader
  filesContext: string
  borderPageWidth?: number
}

type PropsHeaderCompleteView = {
  headerData?: IHeader
  value: any
  showLinksButton?: boolean
}

const LinkMenu = ({ value }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOnClick = (e) => {
    setAnchorEl(e.currentTarget)
  }
  return (
    <React.Fragment>
      <Tooltip title="Ver links asociados" arrow>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          style={{ width: '20px', height: '20px', marginTop: '3%', marginRight: '10px' }}
          onClick={handleOnClick}
        >
          <Icon>more_vert_icon</Icon>
        </IconButton>
      </Tooltip>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        style={{ marginTop: '15px' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          style: {
            maxHeight: 80 * 4.5,
            width: '20ch',
          },
        }}
      >
        {(value as HeaderValues).links.length > 0 ? (
          <React.Fragment>
            {(value as HeaderValues).links.map((link, index) => (
              <MenuItem key={index}>
                <Link
                  href={link.link.includes(ID_LINK_TAG) ? link.link.slice(0, link.link.indexOf(ID_LINK_TAG)) : link.link}
                  target="_blanc"
                  style={{ fontSize: '12px' }}
                  onClick={(e) => {
                    e.preventDefault()
                    window.open(link.link.includes(ID_LINK_TAG) ? link.link.slice(0, link.link.indexOf(ID_LINK_TAG)) : link.link, '_blank')
                    handleClose()
                  }}
                >
                  {link.label}
                </Link>
              </MenuItem>
            ))}
          </React.Fragment>
        ) : (
          <MenuItem>
            <Typography style={{ fontSize: '12px' }}>No hay links asociados</Typography>
          </MenuItem>
        )}
      </Menu>
    </React.Fragment>
  )
}

const HeaderCompleteView = (props: PropsHeaderCompleteView) => {
  const { headerData, value, showLinksButton } = props
  const [hover, setHover] = React.useState<'flag' | 'location' | 'none'>('none')
  return (
    <React.Fragment>
      {console.log(headerData)}
      <Box style={{ alignSelf: !showLinksButton! ? 'flex-start' : 'center', flexGrow: 1, marginLeft: !showLinksButton! ? '20px' : '0px' }}>
        <Box style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Box style={{ display: 'flex', alignItems: 'center' }} onMouseEnter={() => setHover('flag')} onMouseLeave={() => setHover('none')}>
            <img
              src={headerData?.flagUrl ?? ''}
              width={50}
              height={30}
              style={{ marginRight: '5px', filter: hover === 'flag' ? 'grayscale(0%)' : 'grayscale(100%)' }}
            ></img>

            <Typography style={{ fontSize: '11px' }}>
              {`${
                hover === 'flag'
                  ? headerData?.location ?? ''
                  : headerData?.location
                      ?.split('-')
                      .filter((_, i) => i > 1)
                      .reduce((prev, actual) => `${prev}-${actual}`, '') ?? ''
              }`}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box style={{ alignSelf: !showLinksButton! ? 'flex-start' : 'center', flexGrow: 1, marginLeft: !showLinksButton! ? '20px' : '0px' }}>
        <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-end', marginRight: '10px' }}>
          <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Icon style={{ marginRight: '5px', color: headerData !== undefined ? (headerData!.favorite ? 'red' : 'gray') : 'gray' }}>favorite</Icon>
            <Typography style={{ fontSize: '15px' }}>{`${headerData?.description ?? ''}`}</Typography>
          </Box>
          <Typography style={{ fontSize: '11px', color: '#0288d1', fontWeight: 'bold' }}>{`${headerData?.acronym ?? ''}`}</Typography>
        </Box>
      </Box>
      {showLinksButton && <LinkMenu value={value} />}
    </React.Fragment>
  )
}
//Principal componente del header
export const Header = (props: Props) => {
  const { element, pageSize, headerData, filesContext, borderPageWidth } = props
  const { style, value } = element
  const [minimize, setMinimize] = React.useState((value as HeaderValues).minimize)

  React.useEffect(() => {
    setMinimize((value as HeaderValues).minimize)
  }, [value])

  const getHeaderSize = React.useCallback(() => {
    const { width } = pageSize

    if (width > 960) {
      return `960px`
    } else {
      return `${width}px`
    }
  }, [pageSize])

  return (
    <Box
      onMouseLeave={() => {
        ;(value as HeaderValues).minimize && setMinimize(true)
      }}
    >
      <Box
        style={{
          width: (value as HeaderValues).minimize ? (minimize ? '240px' : getHeaderSize()) : '100%',
          maxWidth: `${960 - (borderPageWidth ?? 0)}px`,
          display: 'flex',
          backgroundColor: style.backgroundColor,
          height: '80px',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          borderTopRightRadius: '25px',
          borderBottomLeftRadius: pageSize.width < 500 ? '0px' : '25px',
          borderTop: (value as HeaderValues).minimize ? (!minimize ? '1px solid #000' : 'none') : 'none',
          borderLeft: (value as HeaderValues).minimize ? (!minimize ? '1px solid #000' : 'none') : 'none',
          borderRight: (value as HeaderValues).minimize ? (!minimize ? '1px solid #000' : 'none') : 'none',
          borderBottom: (value as HeaderValues).minimize ? (!minimize && pageSize.width > 500 ? '1px solid #000' : 'none') : 'none',
        }}
      >
        <Box style={{ position: 'relative', flex: 1, maxWidth: '80px', minWidth: '80px' }}>
          <div
            style={{
              backgroundImage: `url(${filesContext}/${headerData?.image})`,
              width: 80,
              height: 70,
              position: 'absolute',
              left: '-40px',
              top: '5px',
              bottom: '50px',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
            onMouseEnter={() => {
              ;(value as HeaderValues).minimize && setMinimize(false)
            }}
          ></div>
        </Box>

        <Box style={{ alignSelf: 'center', marginLeft: '-20px' }}>
          <Box style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
            <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography style={{ fontSize: '20px', fontWeight: 'bold' }}>{headerData?.name ?? ''}</Typography>
              {headerData !== undefined && headerData.perxonaName.length > 30 && (
                <Tooltip title={`${headerData?.perxonaName ?? ''}`}>
                  <Typography style={{ fontSize: '11px' }}>{headerData!.perxonaName.slice(0, 30).concat('...')}</Typography>
                </Tooltip>
              )}
              {headerData !== undefined && headerData.perxonaName.length <= 30 && (
                <Typography style={{ fontSize: '11px' }}>{headerData!.perxonaName}</Typography>
              )}
            </Box>
            {pageSize.width < 500 && (
              <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-start' }}>
                <LinkMenu value={value} />
              </Box>
            )}
          </Box>
        </Box>

        {!minimize && pageSize.width > 500 && <HeaderCompleteView headerData={headerData} value={value} showLinksButton={true} />}
      </Box>
      {!minimize && pageSize.width < 500 && (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            gap: '20px',
            paddingTop: 8,
            backgroundColor: style.backgroundColor,
            borderBottomLeftRadius: '25px',
            width: (value as HeaderValues).minimize ? (minimize ? '180px' : getHeaderSize()) : '100%',
            borderLeft: (value as HeaderValues).minimize ? (!minimize ? '1px solid #000' : 'none') : 'none',
            borderRight: (value as HeaderValues).minimize ? (!minimize ? '1px solid #000' : 'none') : 'none',
            borderBottom: (value as HeaderValues).minimize ? (!minimize ? '1px solid #000' : 'none') : 'none',
          }}
        >
          <HeaderCompleteView headerData={headerData} value={value} showLinksButton={false} />
        </Box>
      )}
    </Box>
  )
}
