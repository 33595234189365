import { Paper, Grid, Box, Tooltip, Avatar, Typography, makeStyles, Icon } from '@material-ui/core'
import { AvatarGroup } from '@material-ui/lab'
import React from 'react'
import { IMessage, IObjectType } from 'types'
import LogoPerxona from 'assets/images/logoPexona.png'
import { Extensions, objectImageByExtension } from 'lib/utils/objectImage'
import { ImageGallery } from './ImageGallery'
import { ObjectPreview } from './ObjectPreview'

type Props = {
  message: IMessage
  onlyExistOneTheme: boolean
  idUser: number
  showCardImage?: boolean
  hiddenDate?: boolean
  hiddenName?: boolean
  showPreviewImage?: boolean
  showLabelDate?: boolean
}

const useStyles = makeStyles({
  avatar: {
    fontSize: '12px',
    height: '20px',
    width: '20px',
  },
})

const useGalleryStyles = makeStyles({
  root: {
    '&:before': {
      content: '""',
      opacity: 0.5,
      width: '100%',
      height: '100%',
      position: 'absolute',
      backgroundColor: '#000',
      borderTopRightRadius: '15px',
      borderBottomLeftRadius: '15px',
    },
    '&:after': {
      content: '"+"',
      position: 'absolute',
      opacity: 1,
      top: '50%',
      left: '50%',
      color: '#000',
      borderTopRightRadius: '15px',
      borderBottomLeftRadius: '15px',
      p: 5,
      backgroundColor: '#fff',
      borderRadius: '50%',
      fontSize: '20px',
      transform: 'translate(-50%, -50%)',
    },
  },
})

export const MessageConferenxia = (props: Props) => {
  const { message, onlyExistOneTheme, idUser, hiddenDate, hiddenName, showCardImage, showPreviewImage, showLabelDate } = props
  const classes = useStyles()
  const galleryClasses = useGalleryStyles()

  const [showMoreTheme, setShowMoreTheme] = React.useState<boolean>(false)
  const [openGallery, setOpenGallery] = React.useState<boolean>(false)

  const isSystemMessage = React.useMemo(() => {
    return message.messageType !== 'U'
  }, [message])

  const titlePosition = React.useMemo(() => {
    return message.message.indexOf('**')
  }, [message])

  const messageContent = React.useMemo(() => {
    if (titlePosition > -1) {
      return message.message.slice(titlePosition + 2, message.message.length)
    } else {
      return message.message
    }
  }, [message, titlePosition])

  const title = React.useMemo(() => {
    if (titlePosition > -1) {
      return message.message.slice(0, titlePosition)
    }
    return undefined
  }, [message, titlePosition])

  const getTagDate = (dateFormat: Date) => {
    const date = new Date(dateFormat)
    const hours = date.getHours()
    const minutes: number | string = date.getMinutes()
    const ampm = hours >= 12 ? 'PM' : 'AM'
    const hour12 = hours % 12 || 12
    const minutesString = minutes.toString().length === 1 ? `0${minutes.toString()}` : minutes.toString()
    return `${hour12}:${minutesString} ${ampm}`
  }
  const dateContent = React.useMemo(() => {
    return getTagDate(message.date)
  }, [message])

  const backgroundColor = React.useMemo(() => {
    const isMessageUser = message.messageType === 'U'
    const isOwnMessage = message.perxonId === idUser && !isSystemMessage
    const existAlertTheme = message.conferenceThemeList.some((theme) => theme.themeType === 'A')
    return existAlertTheme ? '#e69e9e' : isOwnMessage ? '#D6EFED' : isMessageUser ? '#fff' : '#DDDDDD'
  }, [message, isSystemMessage, idUser])

  const fontColor = React.useMemo(() => {
    return message.conferenceThemeList.some((theme) => theme.themeType === 'A') ? 'red' : `#000`
  }, [message])

  const getTagFullDate = (dateFormat: Date) => {
    const date = new Date(dateFormat).toLocaleDateString('es-ES', {
      dateStyle: 'full',
    })
    return date
  }
  return (
    <Paper
      style={{
        backgroundColor,
        width: '100%',
        minHeight: '70px',
        borderRadius: 0,
        borderBottomLeftRadius: '25px',
        borderTopRightRadius: '25px',
        border: '1px solid #9c9c9c',
      }}
      elevation={3}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {showCardImage && message.objectList.length > 0 && (
          <Box
            style={{
              paddingTop: 1,
            }}
          >
            <ObjectPreview
              object={message.objectList[0]}
              width={'100%'}
              height={'150px'}
              handleOnClick={() => {
                setOpenGallery(true)
              }}
              backgroundSize={'contain'}
            />
          </Box>
        )}

        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '10px',
            paddingBottom: 0.5,
          }}
        >
          <Grid container spacing={1}>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingRight: '20px',
                paddingLeft: '10px',
                width: '100%',
                gap: 1,
              }}
            >
              {!isSystemMessage && (
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1,
                    alignItems: 'center',
                  }}
                >
                  {message.conferenceThemeList && !isSystemMessage && (
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <AvatarGroup
                        max={showMoreTheme ? 20 : 6}
                        classes={{
                          avatar: classes.avatar,
                        }}
                        onMouseEnter={() => message.conferenceThemeList.length > 6 && setShowMoreTheme(true)}
                        onMouseLeave={() => message.conferenceThemeList.length > 6 && setShowMoreTheme(false)}
                      >
                        {!onlyExistOneTheme &&
                          message.conferenceThemeList.map((theme, index) => (
                            <Tooltip key={index} title={theme.themeName}>
                              <Avatar
                                style={{
                                  width: 20,
                                  height: 20,
                                  backgroundColor: `#${theme.color}`,
                                  fontSize: '12px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                {theme.themeName.charAt(0).toUpperCase()}
                              </Avatar>
                            </Tooltip>
                          ))}
                      </AvatarGroup>
                    </Box>
                  )}
                  {!isSystemMessage && !showMoreTheme && !hiddenName && (
                    <Typography
                      variant="caption"
                      style={{
                        fontSize: 11,
                        fontWeight: 600,
                        lineHeight: 1,
                      }}
                    >
                      {message.senderName}
                    </Typography>
                  )}
                </Box>
              )}

              {isSystemMessage && (
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <img
                    src={LogoPerxona}
                    style={{
                      width: 20,
                      height: 20,
                    }}
                  />
                </Box>
              )}
              {!showMoreTheme && !hiddenDate && !showLabelDate && (
                <Tooltip arrow title={getTagFullDate(message.date)} placement={'right'}>
                  <Typography
                    variant="caption"
                    style={{
                      fontSize: 11,
                    }}
                  >
                    {dateContent}
                  </Typography>
                </Tooltip>
              )}
              {!showMoreTheme && !hiddenDate && showLabelDate && (
                <Typography
                  variant="caption"
                  style={{
                    fontSize: 11,
                  }}
                >
                  {dateContent}
                </Typography>
              )}
            </Box>
          </Grid>
        </Box>
        {showLabelDate && (
          <Box>
            <Box pl={1} pt={0.5}>
              <Typography variant="h3" style={{ color: fontColor, fontSize: 12 }}>
                {getTagFullDate(message.date).toLocaleUpperCase().charAt(0) +
                  getTagFullDate(message.date).slice(1, getTagFullDate(message.date).toLocaleUpperCase().length)}
              </Typography>
            </Box>
          </Box>
        )}
        {title && (
          <Box>
            <Box px={1.5}>
              <Typography variant="h3" style={{ color: fontColor, fontSize: 13, fontWeight: 700 }}>
                {title}
              </Typography>
            </Box>
          </Box>
        )}
        <Box style={{ overflowWrap: 'anywhere' }}>
          <Box
            px={1.5}
            pt={0.5}
            pb={message.objectList.length > 0 ? 0.5 : 1}
            style={{
              cursor: 'default',
              // '&:hover': {
              //   cursor: 'default',
              // },
              userSelect: 'text',
            }}
          >
            <Typography
              variant="body1"
              style={{
                cursor: 'default',
                userSelect: 'text',
                fontSize: 12,
              }}
            >
              {messageContent}
            </Typography>
          </Box>
        </Box>

        {showPreviewImage && message.objectList.length > 0 && (
          <Box>
            <Box
              display={'flex'}
              flexDirection={'row'}
              flexWrap="noWrap"
              px={1.5}
              pb={0.5}
              style={{
                gap: 2,
              }}
            >
              {message.objectList.slice(0, 3).map((object) => {
                return (
                  <Tooltip
                    key={`${object.id}-preview-image`}
                    arrow
                    title={
                      object.fileTypeId === IObjectType.IMAGE ? (
                        <Box>
                          <img
                            src={`${object.url}`}
                            style={{ width: '200px', height: 'auto', marginTop: '3px', borderTopRightRadius: '15px', borderBottomLeftRadius: '15px' }}
                            key={`${object.id}-preview-image${new Date().toString()}`}
                          />
                        </Box>
                      ) : (
                        <Typography style={{ cursor: 'default', fontSize: 12 }}>Ver galería</Typography>
                      )
                    }
                  >
                    <Box
                      onClick={() => {
                        setOpenGallery(true)
                      }}
                    >
                      {object.fileTypeId === IObjectType.LINK && (
                        <Box
                          style={{
                            position: 'relative',
                            borderTopRightRadius: '15px',
                            borderBottomLeftRadius: '15px',
                            backgroundColor: '#4b4b4b',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minWidth: '50px',
                            minHeight: '50px',
                          }}
                        >
                          <Box
                            style={{
                              position: 'absolute',
                              top: '2px',
                              right: '8px',
                            }}
                          >
                            <Icon
                              style={{
                                color: 'white',
                                fontSize: '16px',
                              }}
                            >
                              link
                            </Icon>
                          </Box>
                          <Box
                            style={{
                              backgroundImage: `url(${object.icon})`,
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat',
                              width: '25px',
                              minWidth: '25px',
                              maxWidth: '25px',
                              height: '25px',
                              maxHeight: '25px',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setOpenGallery(true)
                            }}
                            className={'linkGalleryPreview'}
                          />
                        </Box>
                      )}

                      {object.fileTypeId === IObjectType.IMAGE && (
                        <Box
                          style={{
                            backgroundImage: `url(${object.url})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            width: '50px',
                            minWidth: '50px',
                            maxWidth: '50px',
                            height: '50px',
                            maxHeight: '50px',
                            cursor: 'pointer',
                            borderTopRightRadius: '15px',
                            borderBottomLeftRadius: '15px',
                          }}
                          className={'imageGalleryPreview'}
                        />
                      )}

                      {object.fileTypeId === IObjectType.VIDEO && object.onlyUrl && object.url !== 'none' && (
                        <Box
                          style={{
                            backgroundImage: `url(${object.url})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            width: '50px',
                            minWidth: '50px',
                            maxWidth: '50px',
                            height: '50px',
                            maxHeight: '50px',
                            cursor: 'pointer',
                            borderTopRightRadius: '15px',
                            borderBottomLeftRadius: '15px',
                          }}
                          onClick={() => {
                            setOpenGallery(true)
                          }}
                          className={'imageGViedoPreview'}
                        />
                      )}
                      {object.fileTypeId === IObjectType.VIDEO && object.onlyUrl && object.url === 'none' && (
                        <object
                          className={'videoExternalGalleryPreview'}
                          width="50px"
                          height="50px"
                          data={`${object.urlPlayer}`}
                          onClick={() => {
                            setOpenGallery(true)
                          }}
                        ></object>
                      )}
                      {object.fileTypeId === IObjectType.VIDEO && !object.onlyUrl && (
                        <Box>
                          <video
                            width={'50px'}
                            height={'50px'}
                            style={{ cursor: 'pointer', pointerEvents: 'none' }}
                            controls={false}
                            className={'videoGalleryPreview'}
                            onClick={() => {
                              setOpenGallery(true)
                            }}
                          >
                            <source src={`${object.url}`} type="video/mp4" />
                          </video>
                        </Box>
                      )}

                      {object.fileTypeId === IObjectType.DOCUMENT && (
                        <Box
                          className={'documentGalleryPreview'}
                          style={{
                            backgroundImage: `url(${objectImageByExtension(object.extension as Extensions)})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            width: '50px',
                            minWidth: '50px',
                            maxWidth: '50px',
                            height: '50px',
                          }}
                        />
                      )}

                      {object.fileTypeId === IObjectType.PAGE && (
                        <Box
                          style={{
                            backgroundImage: `url(${object.url})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            width: '50px',
                            height: '50px',
                            maxWidth: '50px',
                            maxHeight: '50px',
                            cursor: 'pointer',
                            borderTopRightRadius: '15px',
                            borderBottomLeftRadius: '15px',
                          }}
                        />
                      )}
                      {object.fileTypeId === IObjectType.AUDIO && (
                        <Box
                          onClick={() => {
                            setOpenGallery(true)
                          }}
                          style={{
                            backgroundImage: `url(${objectImageByExtension('audio')})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            width: '50px',
                            minWidth: '50px',
                            maxWidth: '50px',
                            height: '50px',
                            maxHeight: '50px',
                            cursor: 'pointer',
                          }}
                        />
                      )}
                      {object.fileTypeId === IObjectType.FOLDER && (
                        <Box
                          onClick={() => {
                            setOpenGallery(true)
                          }}
                          style={{
                            backgroundImage: `url(${objectImageByExtension('folder')})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            width: '50px',
                            minWidth: '50px',
                            maxWidth: '50px',
                            height: '50px',
                            maxHeight: '50px',
                            cursor: 'pointer',
                          }}
                        />
                      )}
                    </Box>
                  </Tooltip>
                )
              })}
              {message.objectList.length > 3 && (
                <Tooltip placement="left" title={'Ver galería'} arrow>
                  <Box
                    style={{
                      width: '50px',
                      height: '50px',
                      maxWidth: '50px',
                      maxHeight: '50px',
                      marginRight: '5px',
                      borderTopRightRadius: '15px',
                      borderBottomLeftRadius: '15px',
                      position: 'relative',
                    }}
                    className={galleryClasses.root}
                    onClick={() => {
                      setOpenGallery(true)
                    }}
                  >
                    {message.objectList[3].fileTypeId !== IObjectType.LINK ? (
                      <ObjectPreview
                        object={message.objectList[3]}
                        width={'50px'}
                        height={'50px'}
                        handleOnClick={() => {
                          setOpenGallery(true)
                        }}
                      />
                    ) : (
                      <Box
                        style={{
                          borderTopRightRadius: '15px',
                          borderBottomLeftRadius: '15px',
                          width: '50px',
                          height: '50px',
                          backgroundColor: '#4b4b4b',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          position: 'relative',
                        }}
                      >
                        <Box
                          style={{
                            position: 'absolute',
                            top: '2px',
                            right: '10px',
                          }}
                        >
                          <Icon
                            style={{
                              color: 'white',
                              fontSize: '15px',
                            }}
                          >
                            link
                          </Icon>
                        </Box>
                        <Box
                          style={{
                            backgroundImage: `url(${message.objectList[3].icon})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            width: 25,
                            minWidth: 25,
                            maxWidth: 25,
                            height: 25,
                            maxHeight: 25,
                            cursor: 'pointer',
                          }}
                          className={'linkGalleryPreview'}
                        />
                      </Box>
                    )}
                  </Box>
                </Tooltip>
              )}

              {openGallery && (
                <ImageGallery
                  open={openGallery}
                  onClose={() => {
                    setOpenGallery(false)
                  }}
                  objects={message.objectList}
                  extraComponent={
                    message.message.trim().length > 0 ? (
                      <MessageConferenxia message={message} onlyExistOneTheme={onlyExistOneTheme} idUser={idUser} />
                    ) : undefined
                  }
                />
              )}
            </Box>
          </Box>
        )}

        {/* {message.objectUrl && (
          <Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 10,
              }}
            >
              <Tooltip title={<img src={`${awsConfigContext}/${message.objectUrl}`} style={{ width: '200px', height: 'auto', marginTop: '3px' }} />}>
                <Box
                  style={{
                    backgroundImage: `url(${awsConfigContext}/${message.objectUrl})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    width: '50px',
                    height: '50px',
                    maxWidth: '50px',
                    maxHeight: '50px',
                    marginRight: '5px',
                    borderTopRightRadius: '15px',
                    borderBottomLeftRadius: '15px',
                  }}
                />
              </Tooltip>
            </Box>
          </Box>
        )} */}
      </Box>
    </Paper>
  )
}
