import React from 'react'
import { Typography, FormControl, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core'
import { IElement } from 'types'

interface Props {
    element: IElement,
    updateElement: (newElement: IElement) => void,
}

const FileProps = (props: Props) => {
    const { updateElement } = props
    const [style, setStyle] = React.useState<React.CSSProperties>(props.element.style)
    React.useEffect(() => {
        if (props.element.style !== style) { setStyle(props.element.style) }
    }, [props.element.style, style])
    return (
        <div className="panel-container px-2 pt-4 pb-2">
            <Typography color="primary" className="panel-label" variant="caption">{"Archivo"}</Typography>
            <FormControl className="flex-1" component="fieldset" style={{ display: "flex", justifyContent: "center" }}>
                <FormGroup className="pl-2">
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    e.target.checked ? updateElement({ ...props.element, style: { ...style, flexDirection: "row" } }) : updateElement({ ...props.element, style: { ...style, flexDirection: "column" } })
                                }}
                                color="primary"
                                size="small"
                                checked={style.flexDirection === "row" || !style.flexDirection}
                                name="flexDirection"
                            />
                        }
                        label="Baldosa"
                    />
                </FormGroup>
            </FormControl>
        </div>
    )
}

export default FileProps
