import { Box, Chip, Icon } from '@material-ui/core'
import { IConferenceTheme } from 'types'
import { useConferenceProvider } from '../hook/useConferenceProvider'

type Props = {
  conferenceTheme: IConferenceTheme[]
  onSelectTheme: (theme?: IConferenceTheme) => void
  selectedTheme?: IConferenceTheme
  conferenceId: number
  hiddenAllFilter?: boolean
}

export const ThemeListSelector = (props: Props) => {
  const { conferenceTheme, onSelectTheme, selectedTheme, conferenceId, hiddenAllFilter } = props
  const { getMessages, resetMessages } = useConferenceProvider()

  return (
    <Box
      style={{
        width: '100%',
        height: '30px',
        maxHeight: '30px',
        overflowX: 'auto',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
      }}
    >
      {!hiddenAllFilter && (
        <Chip
          label={'Todos'}
          style={{
            color: 'white',
            backgroundColor: `#4b4b4b`,
            margin: '2px',
            height: '20px',
          }}
          icon={selectedTheme === undefined ? <Icon style={{ color: 'white' }}>check</Icon> : undefined}
          onClick={() => {
            onSelectTheme(undefined)
            resetMessages()
            getMessages(conferenceId, 1)
          }}
        />
      )}
      {conferenceTheme.map((theme, index) => (
        <Chip
          key={theme.conferenceThemeId}
          label={theme.themeName}
          style={{
            color: 'white',
            backgroundColor: `#${theme.color}`,
            margin: '2px',
            height: '20px',
          }}
          icon={selectedTheme?.conferenceThemeId === theme.conferenceThemeId ? <Icon style={{ color: 'white' }}>check</Icon> : undefined}
          onClick={() => {
            onSelectTheme(theme)
            resetMessages()
            getMessages(conferenceId, 1, theme.conferenceThemeId)
          }}
        />
      ))}
    </Box>
  )
}
