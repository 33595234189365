import React from 'react'
import { Tooltip } from '@material-ui/core'
import { TextView } from 'components'
import { ISize } from 'types'
import { ICurtainValue } from 'components/CurtainProps/CurtainProps'
import { BORDER_RADIUS_UNITS_OPTIONS, DEEPLINK_TAG, ELEMENT_TYPES } from 'lib'

interface Props {
    id: string,
    value: ICurtainValue
    style: React.CSSProperties,
    size: ISize,
    borderRadiusUnit: string
}

const CurtainView = (props: Props) => {
    const [open, setOpen] = React.useState(false)
    return (
        <React.Fragment>
            <Tooltip open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(Boolean(props.value.tooltip))} arrow title={props.value.tooltip ? props.value.tooltip : ""}>
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                    }}
                    className={`curtain-view`}
                    id={`${ELEMENT_TYPES.text}_${props.id}`}
                >
                    {
                        
                    }
                </div>
            </Tooltip>
        </React.Fragment>
    )
}

export default CurtainView
