import { Tooltip } from '@material-ui/core'
import { LocationContext, PageContext } from 'context'
import { useKeyPress } from 'hooks'
import { ELEMENT_TYPES, getFileIcon, openObjectInfo } from 'lib'
import React from 'react'
import { useLocation } from 'react-router-dom'

interface Props {
    id: string,
    value: any,
    filesContext: string,
    style: React.CSSProperties,
    darkBg: boolean,
    borderRadiusUnit: string
    exported?: boolean
}

const FileView = React.memo((props: Props) => {
    const { id: pageId } = React.useContext(PageContext)
    const controlPress: boolean = useKeyPress("Control")
    const getTransforms = React.useCallback(() => { if (props.style.transform) { return JSON.parse(props.style.transform) } }, [props.style.transform])
    const location = useLocation()
    const { tabId } = React.useContext(LocationContext)

    const handleClick = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e.ctrlKey) {
            await openObjectInfo(pageId, props.value[0], tabId)
            return
        }
        const link = document.createElement("a")
        link.setAttribute('download', props.value[0])
        link.href = props.filesContext + "/" + props.value[1]
        link.target = "_blank"
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

    return (
        <div
            id={`${ELEMENT_TYPES.file}_${props.id}`}
            style={{
                ...props.style,
                width: "100%",
                height: "100%",
                display: "flex",
                transform: `rotate(${getTransforms()[0]}deg) scaleX(${getTransforms()[1]})`,
                justifyContent: "center",
                alignItems: "center",
                flexDirection: props.style.flexDirection,
                cursor: "pointer",
                borderTopLeftRadius: props.style.borderTopLeftRadius + props.borderRadiusUnit,
                borderBottomLeftRadius: props.style.borderBottomLeftRadius + props.borderRadiusUnit,
                borderTopRightRadius: props.style.borderTopRightRadius + props.borderRadiusUnit,
                borderBottomRightRadius: props.style.borderBottomRightRadius + props.borderRadiusUnit,
            }}
            onClick={handleClick}
        >
            <div className={`file-icon bg-center bg-no-repeat bg-contain ${getFileIcon(props.value)}`} />
            <div style={{ flexGrow: 1, height: "100%", width: "100%" }}>
                <p className={`file-text flex justify-start items-center text-center ${props.darkBg && "file-dark-contrast-text"}`}>
                    <span style={{ padding: 5, backgroundColor: "#333", color: "#FFF", borderRadius: 4, marginRight: 5, marginLeft: 5 }}>{"D"}</span>
                    {props.value[2]}
                </p>
            </div>
            {
                (controlPress && !props.exported) &&
                <Tooltip arrow title="Ver información">
                    <div
                        onClick={handleClick}
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                            top: 0,
                            cursor: "pointer",
                            backgroundColor: "transparent"
                        }}
                    />
                </Tooltip>
            }
        </div>
    )
})

export default FileView
