import { Button, Typography } from '@material-ui/core'
import { AudioSelection, CurtainSelection, FileSelection, FolderSelection, ImageSelection, LineSelection, PageSelection, TextSelection, VideoSelection } from 'components'
import { BoardContext, ConfigContext, LocationContext, PageContext } from 'context'
import { IUsedFont } from 'hooks'
import { BORDER_RADIUS_UNITS_OPTIONS, DEEPLINK_TAG, disablePanel, ELEMENT_TYPES, enablePanel, generateElementId, getPage, HASH_PARAM, PAGE_ALIGMENTS, refreshBackground } from 'lib'
import React from 'react'
import { Rnd } from 'react-rnd'
import { useHistory, useLocation } from 'react-router-dom'
import { IElement, ISize, IPosition } from 'types'

const classes = {
    page: "page",
    board: "board"
}

const Post = () => {
    const { designElements, size: designSize, position: designPosition, style: designStyle, darkBg, updateElement, id, addElement } = React.useContext(PageContext)
    const { tabId } = React.useContext(LocationContext)
    const { style: designBoardStyle } = React.useContext(BoardContext)
    const { filesContext, selectionState, copyExternalState, setCopyExternalState, addStoredFont, generalLink } = React.useContext(ConfigContext)
    const [selectedElement, setSelectedElement] = React.useState<IElement | undefined>()
    const history = useHistory()
    const location = useLocation()
    const [selectionElements, setSelectionElements] = React.useState<IElement[]>([])
    const [size, setSize] = React.useState<ISize>({} as ISize)
    const [fonts, setFonts] = React.useState<IUsedFont[]>([])
    const [position, setPosition] = React.useState<IPosition>({} as IPosition)
    const [style, setStyle] = React.useState<React.CSSProperties>({})
    const [boardStyle, setBoardStyle] = React.useState<React.CSSProperties>({})
    const handleSelect = async (selectedElement: IElement) => {
        console.log("yes")
        console.log(selectedElement)

        if (Boolean(copyExternalState) && copyExternalState) {
            console.log("yes2")
            addElement({ ...selectedElement, position: copyExternalState, id: generateElementId() })
            if (selectedElement.type === ELEMENT_TYPES.text) {
                console.log("yes3")
                for (const font of fonts) {
                    addStoredFont(font)
                }
            }
            setCopyExternalState(undefined)
            refreshBackground(id,tabId)
            enablePanel(id,tabId)
            history.goBack()
            return
        }
        console.log("-pre")
        const external = (location.state as any).external
        const url = (location.state as any).url
        setSelectedElement(selectedElement)
        const { state }: any = location
        const element: IElement = state.element
        const elementFirstValue = (element.type !== ELEMENT_TYPES.text && element.type !== ELEMENT_TYPES.line) ? [...element.value]?.shift() : -1
        const selectedElementFirstValue = (selectedElement.type !== ELEMENT_TYPES.text && selectedElement.type !== ELEMENT_TYPES.line) ? [...selectedElement.value]?.shift() : -1
        const deeplink = external ? `${url}${HASH_PARAM}${selectedElement.type}_${selectedElement.id}` : `${DEEPLINK_TAG}${selectedElement.type}_${selectedElement.id}`
        console.log(element)
        if (element.type === ELEMENT_TYPES.image) {
            console.log("yes4")
            element.link = deeplink
        }
        if(element.type === ELEMENT_TYPES.login) {
            console.log("yes loginnnn")
            element.link = deeplink
        }
        if (element.type === ELEMENT_TYPES.text) {
            enablePanel(id,tabId)
            if (!generalLink) {
                history.push(`/${Boolean(external) ? selectionState.url : state.element.url}`, {
                    state: {
                        id: state.element.id,
                        selection: external ? selectionState.selection : state.element.selection,
                        link: deeplink
                    }
                })
                if (external) {
                    console.log("yes7")
                    refreshBackground(id,tabId)
                }
                return
            } else {
                console.log("yes8")
                element.link = deeplink
            }
        }
        if (external) {
            console.log("yes9")
            refreshBackground(id,tabId)
        }
        console.log("yes10")
        enablePanel(id,tabId)
        console.log(element)
        console.log(selectedElement)
        updateElement(element)
        history.goBack()
    }
    React.useEffect(() => {
        const initSelection = async () => {
            const id = (location.state as any).id
            const page = await getPage(id)
            const postedData = JSON.parse(page.dataJson)
            const pageProperties = postedData.properties.pagina
            const postedFonts = postedData.fonts
            disablePanel(id,tabId)
            setFonts(postedFonts)
            setSelectionElements(postedData.nodes)
            setSize({ width: pageProperties.anchoPX, height: pageProperties.altoPX })
            setStyle({
                backgroundColor: pageProperties.color,
                opacity: pageProperties.opacidad,
                borderRadius: pageProperties.esquinas,
                borderColor: "#000000",
                boxShadow: getBoxShadow(pageProperties),
                borderWidth: getBorderWidth(pageProperties),
                position: "relative"
            })
            getPageProperties(pageProperties)
        }
        const getBoxShadow = (pageProperties: any) => {
            return pageProperties.bordeSombra.split(";")[0]
        }
        const getBorderWidth = (pageProperties: any): string => {
            const styleProps: string[] = pageProperties.bordeSombra.split(";")
            for (const styleProp of styleProps) {
                if (styleProp.includes("border:none")) {
                    return "0px"
                }
                if (styleProp.includes("border-width:")) {
                    return styleProp.replace("border-width:", "")
                }
            }
            return "1px"
        }
        const getPageProperties = (pageProperties: any) => {
            const { width: screenWidth, height: screenHeight } = window.screen
            const widthResolutionIsLarger = screenWidth > pageProperties.anchoPX
            const heightResolutionIsLarger = screenHeight > pageProperties.altoPX
            setBoardStyle({
                width: !widthResolutionIsLarger ? pageProperties.anchoPX + 2 * pageProperties.x : window.screen.width,
                maxWidth: !widthResolutionIsLarger ? pageProperties.anchoPX + 2 * pageProperties.x : window.screen.width,
                height: !heightResolutionIsLarger ? pageProperties.altoPX + 2 * pageProperties.y : window.screen.height,
                maxHeight: !heightResolutionIsLarger ? pageProperties.altoPX + 2 * pageProperties.y : window.screen.height,
                backgroundColor: "transparent",
            })
            let x = pageProperties.x
            let y = pageProperties.y
            switch (pageProperties.centradoPagina) {
                case PAGE_ALIGMENTS.both:
                    if (widthResolutionIsLarger) {
                        x = (screenWidth - pageProperties.anchoPX) / 2
                    }
                    if (heightResolutionIsLarger) {
                        y = (screenHeight - pageProperties.altoPX) / 2
                    }
                    break
                case PAGE_ALIGMENTS.horizontal:
                    if (widthResolutionIsLarger) {
                        x = (screenWidth - pageProperties.anchoPX) / 2
                    }
                    break
                case PAGE_ALIGMENTS.vertical:
                    if (heightResolutionIsLarger) {
                        y = (screenHeight - pageProperties.altoPX) / 2
                    }
                    break
            }
            setPosition({ x, y })
        }
        const external = (location.state as any).external
        if (external) {
            initSelection()
        } else {
            setSelectionElements(designElements)
            setSize(designSize)
            setPosition(designPosition)
            setStyle(designStyle)
            setBoardStyle(designBoardStyle)
        }
    }, [])
    return (
        <div
            className={classes.board}
            style={{ ...boardStyle }}
        >
            <div
                className={classes.page}
                style={{ ...style, width: size.width, height: size.height, left: position.x, top: position.y, overflow: "hidden" }}
            >
                {
                    selectionElements && selectionElements.map((element, index) => (
                        <React.Fragment>
                            {
                                !element.float && (location.state as any)?.element?.id !== element.id &&
                                <Rnd
                                    key={index + "postedEle"}
                                    position={element.position}
                                    size={element.size}
                                    disableDragging={true}
                                    enableResizing={false}
                                    className={`selection-element ${selectedElement?.id === element.id && "selected-element"}`}
                                    onClick={() => handleSelect(element)}
                                >
                                    {
                                        element.type === ELEMENT_TYPES.text ?
                                            <TextSelection
                                                value={element.value}
                                                style={element.style}
                                                size={element.size}
                                                borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                                            />
                                            : undefined
                                    }
                                    {
                                        element.type === ELEMENT_TYPES.image ?
                                            <ImageSelection
                                                value={element.value}
                                                filesContext={filesContext}
                                                style={element.style}
                                                borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                                            />
                                            : undefined
                                    }
                                    {
                                        element.type === ELEMENT_TYPES.video ?
                                            <VideoSelection
                                                value={element.value}
                                                filesContext={filesContext}
                                                style={element.style}
                                                size={element.size}
                                                borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                                            />
                                            : undefined
                                    }
                                    {
                                        element.type === ELEMENT_TYPES.audio ?
                                            <AudioSelection
                                                value={element.value}
                                                filesContext={filesContext}
                                                style={element.style}
                                                size={element.size}
                                                borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                                            />
                                            : undefined
                                    }
                                    {
                                        element.type === ELEMENT_TYPES.page ?
                                            <PageSelection
                                                value={element.value}
                                                filesContext={filesContext}
                                                style={element.style}
                                                borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                                            />
                                            : undefined
                                    }
                                    {
                                        element.type === ELEMENT_TYPES.line ?
                                            <LineSelection
                                                value={element.value}
                                                style={element.style}
                                                borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                                            />
                                            : undefined
                                    }
                                    {
                                        element.type === ELEMENT_TYPES.file ?
                                            <FileSelection
                                                value={element.value}
                                                style={element.style}
                                                filesContext={filesContext}
                                                darkBg={darkBg}
                                                borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                                            />
                                            : undefined
                                    }
                                    {
                                        element.type === ELEMENT_TYPES.curtain ?
                                            <CurtainSelection
                                                value={element.value}
                                                style={element.style}
                                                size={element.size}
                                                borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                                            />
                                            : undefined
                                    }
                                    {
                                        element.type === ELEMENT_TYPES.folder ?
                                            <FolderSelection
                                                filesContext={filesContext}
                                                value={element.value}
                                                style={element.style}
                                                borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                                            />
                                            : undefined
                                    }
                                </Rnd>
                            }
                        </React.Fragment>
                    ))
                }
            </div>
            <div
                className={classes.page}
                style={{ ...style, width: size.width, height: size.height, left: position.x, top: position.y, pointerEvents: "none", backgroundColor: "transparent", position: "absolute" }}
            >
                {
                    selectionElements && selectionElements.map((element, index) => (
                        <React.Fragment>
                            {
                                element.float && (location.state as any)?.element?.id !== element.id &&
                                <Rnd
                                    key={index + "postedEle"}
                                    position={element.position}
                                    size={element.size}
                                    disableDragging={true}
                                    enableResizing={false}
                                    style={{ pointerEvents: "all" }}
                                    className={`selection-element ${selectedElement?.id === element.id && "selected-element"}`}
                                    onClick={() => handleSelect(element)}
                                >
                                    {
                                        element.type === ELEMENT_TYPES.text ?
                                            <TextSelection
                                                value={element.value}
                                                style={element.style}
                                                size={element.size}
                                                borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                                            />
                                            : undefined
                                    }
                                    {
                                        element.type === ELEMENT_TYPES.image ?
                                            <ImageSelection
                                                value={element.value}
                                                filesContext={filesContext}
                                                style={element.style}
                                                borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                                            />
                                            : undefined
                                    }
                                    {
                                        element.type === ELEMENT_TYPES.video ?
                                            <VideoSelection
                                                value={element.value}
                                                filesContext={filesContext}
                                                style={element.style}
                                                size={element.size}
                                                borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                                            />
                                            : undefined
                                    }
                                    {
                                        element.type === ELEMENT_TYPES.audio ?
                                            <AudioSelection
                                                value={element.value}
                                                filesContext={filesContext}
                                                style={element.style}
                                                size={element.size}
                                                borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                                            />
                                            : undefined
                                    }
                                    {
                                        element.type === ELEMENT_TYPES.page ?
                                            <PageSelection
                                                value={element.value}
                                                filesContext={filesContext}
                                                style={element.style}
                                                borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                                            />
                                            : undefined
                                    }
                                    {
                                        element.type === ELEMENT_TYPES.line ?
                                            <LineSelection
                                                value={element.value}
                                                style={element.style}
                                                borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                                            />
                                            : undefined
                                    }
                                    {
                                        element.type === ELEMENT_TYPES.file ?
                                            <FileSelection
                                                value={element.value}
                                                style={element.style}
                                                filesContext={filesContext}
                                                darkBg={darkBg}
                                                borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                                            />
                                            : undefined
                                    }
                                    {
                                        element.type === ELEMENT_TYPES.curtain ?
                                            <CurtainSelection
                                                value={element.value}
                                                style={element.style}
                                                size={element.size}
                                                borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                                            />
                                            : undefined
                                    }
                                    {
                                        element.type === ELEMENT_TYPES.folder ?
                                            <FolderSelection
                                                filesContext={filesContext}
                                                value={element.value}
                                                style={element.style}
                                                borderRadiusUnit={element.borderRadiusUnit ? element.borderRadiusUnit : BORDER_RADIUS_UNITS_OPTIONS[0].value}
                                            />
                                            : undefined
                                    }
                                </Rnd>
                            }
                        </React.Fragment>
                    ))
                }
            </div>
            <div className="selection-menu flex flex-col px-4 rounded flex flex-col items-center justify-center pb-2">
                <Typography className="py-2" variant="caption">{"Seleciona un elemento."}</Typography>
                <Button
                    onClick={() => {
                        setCopyExternalState(undefined)
                        if ((location.state as any).external) {
                            refreshBackground(id,tabId)
                        }
                        enablePanel(id,tabId)
                        history.goBack()
                    }}
                    variant="contained"
                    size="small"
                    style={{
                        backgroundColor: "#f44336",
                        fontSize: "0.7em",
                        padding: 1,
                        color: "#FFF",
                        marginLeft: 8,
                        textTransform: "capitalize"
                    }}
                >
                    {"Cancelar"}
                </Button>
            </div>
        </div>
    )
}

export default Post
