import { Box, Breadcrumbs, Button, ButtonGroup, Chip, Grid, Icon, InputAdornment, Modal, Paper, TextField, Tooltip, Typography, withStyles } from "@material-ui/core"
import { Alert, Autocomplete } from "@material-ui/lab"
import { ConfigContext } from "context";
import React, { useContext } from 'react'


type TerritorialLevel = {
          name: string,
          key: string,
          id: number
}
interface Nivel1 {
          name: string;
          key: string;
          id: number;
}

interface Nivel2 {
          root: string;
          name: string;
          key: string;
          id: number;
}

interface Nivel3 {
          root: string;
          name: string;
          id: number;
}


export interface FilterDetails {
          id: number;
          name: string;
}

export type Filters = {
          nombreFiltro: string
          filtros: FilterDetails[]
          multiSelect: boolean
          requestKey: keyof FilterRequest
}


export type TerritorialOrganization = {
          country: string
          id: number
          niveles: string[]
          nivel1: Nivel1[]
          nivel2: Nivel2[]
          nivel3: Nivel3[]
}

type Props = {
          filters?: FilterRequest
          onAccept: () => void
          onCancel: () => void
          closeHandle: () => void
}



type OrganizationLevel = {
          country: number,
          levelOne: number,
          levelTwo: number,
          levelThree: number,
}

type Hook = {
          territorialOrganizationChoice: OrganizationLevel
          handleChangeTerritorialSelection: (key: keyof OrganizationLevel, value: number) => void
          handleChangeTerritorialSelectionAll: (territorial: OrganizationLevel) => void
}



const useTerritorialOrganization = (): Hook => {

          const [territorialOrganizationChoice, setTerritorialOrganizationChoice] = React.useState<OrganizationLevel>({ country: 0, levelOne: 0, levelTwo: 0, levelThree: 0 })

          const handleChangeTerritorialSelection = (key: keyof OrganizationLevel, value: number) => {
                    setTerritorialOrganizationChoice(prev => ({ ...prev, [key]: value }))
          }

          const handleChangeTerritorialSelectionAll = (territorial: OrganizationLevel) => {
                    setTerritorialOrganizationChoice(territorial)
          }
          return ({
                    territorialOrganizationChoice,
                    handleChangeTerritorialSelection,
                    handleChangeTerritorialSelectionAll
          })

}

export type HookFilterRequest = {
          editValueForRequestEntidadesObject: (key: keyof FilterRequest, value: any, action?: "add" | "remove" | undefined) => void
          request: FilterRequest
          changeRequest: (filterRequest: FilterRequest) => void
          setDefaultRequest: () => void
}




type levelOption = 0 | 1 | 2 | 3
export type FilterRequest = {
          nombreComercial: string
          siglaBolxa: string
          paisId: number
          nivel: levelOption
          idNivel: number
          artesano: number
          iniciativa: number
          franquisiador: number
          corporacion: number
          multinacional: number
          tipoAgrupacion: number
          privadaEstatal: number[]
          incorporacion: number[]
          tipoEmpresa: number[]
          sectorEconomico: number[]
          seDetallado: number[]
          organizationLevel: OrganizationLevel
}
const filterRequestDefault: FilterRequest = {
          nombreComercial: "",
          siglaBolxa: "",
          paisId: 0,
          nivel: 0,
          idNivel: 0,
          artesano: 0,
          iniciativa: 0,
          franquisiador: 0,
          corporacion: 0,
          multinacional: 0,
          tipoAgrupacion: 0,
          privadaEstatal: [],
          incorporacion: [],
          tipoEmpresa: [],
          sectorEconomico: [],
          seDetallado: [],
          organizationLevel: {
                    country: 0,
                    levelOne: 0,
                    levelTwo: 0,
                    levelThree: 0
          } as OrganizationLevel
}

type ButtonGroupHandler = {
          handleOnClick: (value: any) => void
          label: string
          value: number
          selected: boolean

}
type PropsGroup = {
          buttons: ButtonGroupHandler[]
}


export const useFilterObject = (): HookFilterRequest => {

          const [request, setRequest] = React.useState<FilterRequest>(filterRequestDefault)

          const changeRequest = (filterRequest: FilterRequest) => {
                    setRequest(filterRequest)
          }
          const setDefaultRequest = () => {
                    setRequest(filterRequestDefault)
          }
          const editValueForRequestEntidadesObject = (key: keyof FilterRequest, value: any, action?: 'add' | 'remove') => {

                    setRequest(prev => {

                              if (action === undefined) {
                                        const newRequest = { ...prev, [key]: value }
                                        return newRequest
                              }
                              else if (action === 'add') {
                                        const values = [...prev[key] as number[]]
                                        values.push(value)
                                        const newRequest = { ...prev, [key]: values }
                                        return newRequest
                              } else if (action === 'remove') {

                                        const newRequest = { ...prev, [key]: value }
                                        return newRequest
                              }
                              return prev
                    })


          }

          return ({
                    request,
                    editValueForRequestEntidadesObject,
                    changeRequest,
                    setDefaultRequest
          })
}




const sortFilterDetailsByName = (first: FilterDetails, second: FilterDetails) => {
          if (first.name.toUpperCase() < second.name.toUpperCase()) {
                    return -1
          }
          if (first.name.toUpperCase() > second.name.toUpperCase()) {
                    return 1
          }
          return 0
}

const GroupButtons = (props: PropsGroup) => {
          const { buttons } = props
          return (
                    <ButtonGroup size="small" aria-label="small outlined button group" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
                              {buttons.map((button, index) => (
                                        <Button key={`${index}-${button.label}`}
                                                  onMouseDown={(e) => {
                                                            e.preventDefault()
                                                            button.handleOnClick(button.value)
                                                  }}
                                                  color={button.selected ? "primary" : "default"}
                                                  variant={button.selected ? "contained" : "outlined"}
                                                  style={{ minWidth: 70, minHeight: 20 }}
                                        >
                                                  <Typography variant="caption" style={{ fontSize: 10 }}>
                                                            {button.label}
                                                  </Typography>
                                        </Button>
                              ))}
                    </ButtonGroup>

          )

}


type LocationSelect = {
          locationName: string
          locationOptions: TerritorialLevel[]
          open: boolean
          keyRequest: keyof FilterRequest
          nivel: number
          defaultLocationOption?: TerritorialLevel
}


type Information = {
          severity: 'error' | 'info' | 'success' | 'warning'
          message: string
          show: boolean
}

type TerritorialSelection = {
          countryId: number
          firstLevelId: number
          secondLevelId: number
          thirdLevelId: number
}


export const FilterOptions = (props: Props) => {

          const [information, setInformation] = React.useState<Information>({ severity: 'info', message: '', show: false } as Information)

          const [locationSelect, setLocationSelect] = React.useState<LocationSelect>({ open: false } as LocationSelect)



          const { territorialOrganization, entityFilters, hookFilterRequest } = React.useContext(ConfigContext)

          const { editValueForRequestEntidadesObject, request: requestEntity, changeRequest } = hookFilterRequest



          const isSelected = React.useCallback((key: keyof FilterRequest, value: any): boolean => {
                    return requestEntity[key] === value
          }, [requestEntity])
          const { handleChangeTerritorialSelection, territorialOrganizationChoice, handleChangeTerritorialSelectionAll } = useTerritorialOrganization()

          React.useEffect(() => {
                    if (props.filters !== undefined) {
                              changeRequest({ ...props.filters! })
                    }
          }, [])

          React.useEffect(() => {
                    handleChangeTerritorialSelectionAll(requestEntity.organizationLevel)
          }, [requestEntity.organizationLevel])

          const getSelected = React.useCallback((requestKey: keyof FilterRequest) => {
                    if (props.filters === undefined) return []

                    const value = entityFilters.filter(filter => filter.multiSelect === true).map(
                              filter => {
                                        return ({
                                                  requestKey: filter.requestKey, value: filter.filtros.filter(
                                                            filterDetail => {
                                                                      return (props.filters![filter.requestKey] as number[]).includes(filterDetail.id)
                                                            }
                                                  )
                                        })
                              }
                    ).find(
                              item => item.requestKey === requestKey
                    )!.value
                    return value
          }, [entityFilters, props.filters])

          return (
                    <Paper elevation={3} style={{ padding: 10, width: "65%", maxHeight: "95%", overflow: 'auto', overflowX: 'hidden' }}>
                              {information.show && <Alert severity={information.severity}>{information.message}</Alert>}
                              <Grid container spacing={2} style={{ margin: 6, display: 'flex', }} >
                                        <Grid item xs={12} style={{ height: 'fit-content', margin: '-12px' }}>
                                                  <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', height: 'fit-content' }}>
                                                            <Button style={{ marginTop: '-10px', marginRight: '-20px' }} onClick={props.closeHandle}>
                                                                      <Icon style={{ marginRight: 4, color: "#ff0000" }}>cancel</Icon>
                                                            </Button>
                                                  </Box>
                                        </Grid>
                                        {/* TITULO*/}
                                        <Grid item xs={12} >
                                                  <Box style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', width: '95%', justifyContent: 'space-between' }}>
                                                            <Typography variant="h6">
                                                                      <span style={{ fontWeight: 'bold' }}>Buxqueda</span>  de Entidades
                                                            </Typography>

                                                            <Box  >
                                                                      <Button color="primary" variant="contained"
                                                                                onMouseDown={(e) => {
                                                                                          e.preventDefault()
                                                                                          props.onAccept()
                                                                                          setInformation({ severity: 'success', message: 'Se ha guardado la búsqueda', show: true })
                                                                                          setTimeout(() => {
                                                                                                    setInformation({ severity: 'info', message: '', show: false })
                                                                                                    props.closeHandle()
                                                                                          }, 1000)
                                                                                }}
                                                                                style={{
                                                                                          borderBottomLeftRadius: '15px', borderTopLeftRadius: '0px',
                                                                                          borderTopRightRadius: '15px', borderBottomRightRadius: '0px', marginRight: 8,
                                                                                          color: 'white'
                                                                                }}>
                                                                                Aceptar
                                                                      </Button>

                                                                      <Button variant="contained"
                                                                                onMouseDown={(e) => {
                                                                                          e.preventDefault()
                                                                                          props.onCancel()
                                                                                          setInformation({ severity: 'success', message: 'No se ha guardado la búsqueda', show: true })
                                                                                          setTimeout(() => {
                                                                                                    setInformation({ severity: 'info', message: '', show: false })
                                                                                                    props.closeHandle()

                                                                                          }, 1000)

                                                                                }}
                                                                                style={{
                                                                                          borderBottomLeftRadius: '15px', borderTopLeftRadius: '0px',
                                                                                          borderTopRightRadius: '15px', borderBottomRightRadius: '0px',
                                                                                          backgroundColor: "#ff0000 ", color: "#fff"
                                                                                }}>
                                                                                Eliminar Link
                                                                      </Button>
                                                            </Box>

                                                  </Box>

                                        </Grid>
                                        {/* FIN TITULO*/}


                                        {/* FILTRO NOMBRE COMERXIAL|sigla de bolxa*/}

                                        <Grid item xs={12}>
                                                  <Box style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', width: '100%' }}>

                                                            <Box style={{ width: '50%', display: 'flex', justifyContent: 'flex-start', marginLeft: '-6px' }}>
                                                                      <TextField variant="outlined" label="Nombres / Eslogan / Palabras Clave" style={{ width: '90%', backgroundColor: '#FFFCD6' }}
                                                                                value={requestEntity.nombreComercial}
                                                                                size="small" margin="none"
                                                                                onChange={(e) => editValueForRequestEntidadesObject('nombreComercial', e.target.value)}
                                                                      />
                                                            </Box>


                                                            <Box style={{ width: '50%', display: 'flex', justifyContent: 'flex-start' }} >
                                                                      <TextField variant="outlined" label="Siglas Bolxa" style={{ width: '92.5%', backgroundColor: '#FFFCD6' }}
                                                                                value={requestEntity.siglaBolxa}
                                                                                size="small" margin="none"
                                                                                onChange={(e) => editValueForRequestEntidadesObject('siglaBolxa', e.target.value.slice(0, 7).toUpperCase())}
                                                                                InputProps={{
                                                                                          endAdornment: <InputAdornment position="end">
                                                                                                    <Typography variant="subtitle1" style={{ paddingRight: 4, fontSize: 12 }}>{`${requestEntity.siglaBolxa.length}`} /7</Typography>
                                                                                          </InputAdornment>
                                                                                }}
                                                                      />

                                                            </Box>

                                                  </Box>
                                        </Grid>

                                        {/* FIN FILTRO NOMBRE COMERXIAL*/}

                                        {
                                                  entityFilters.filter(filter => filter.multiSelect === false).map((filter, index) => {
                                                            return (
                                                                      <Grid item xs={6} key={`${index}${filter.nombreFiltro}`}>
                                                                                <Box style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                                                                          <Box style={{ width: "42%" }}>
                                                                                                    <Typography style={{ fontSize: 14 }} variant="subtitle1" >
                                                                                                              {filter.nombreFiltro}
                                                                                                    </Typography>
                                                                                          </Box>
                                                                                          <Box style={{ width: "45%" }}>
                                                                                                    <GroupButtons buttons={
                                                                                                              filter.filtros.sort(sortFilterDetailsByName).map(detail => ({
                                                                                                                        handleOnClick: (e) => {
                                                                                                                                  editValueForRequestEntidadesObject(filter.requestKey, detail.id)
                                                                                                                        },
                                                                                                                        label: detail.name,
                                                                                                                        value: detail.id,
                                                                                                                        selected: isSelected(filter.requestKey, detail.id)
                                                                                                              }))
                                                                                                    }
                                                                                                    />
                                                                                          </Box>
                                                                                </Box>

                                                                      </Grid>
                                                            )
                                                  })
                                        }


                                        <Grid item xs={12} >
                                                  <Box style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                                                            <Box style={{ width: "20%" }}>
                                                                      <Typography style={{ fontSize: 14 }} variant="subtitle1" >
                                                                                Localización
                                                                      </Typography>
                                                            </Box>
                                                            <Box style={{ width: "70%" }}>
                                                                      <ButtonGroup size="small" aria-label="small outlined button group"
                                                                                style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', paddingLeft: 4 }}>

                                                                                <Button
                                                                                          onMouseDown={(e) => {
                                                                                                    e.preventDefault()

                                                                                                    const territorialOption: TerritorialLevel[] = territorialOrganization.map(territorialOrganization => ({
                                                                                                              name: territorialOrganization.country,
                                                                                                              key: territorialOrganization.id.toString(),
                                                                                                              id: territorialOrganization.id
                                                                                                    }))
                                                                                                    const defaultLocationOption = territorialOption.find(option => option.id === requestEntity.organizationLevel.country)
                                                                                                    const locationOption = {
                                                                                                              locationName: 'País',
                                                                                                              locationOptions: territorialOption,
                                                                                                              open: true,
                                                                                                              keyRequest: 'paisId',
                                                                                                              nivel: 0,
                                                                                                              defaultLocationOption: defaultLocationOption
                                                                                                    } as LocationSelect

                                                                                                    setLocationSelect(locationOption)

                                                                                          }}
                                                                                          color={requestEntity.organizationLevel.country === 0 ? "primary" : "default"}
                                                                                          variant={requestEntity.organizationLevel.country == 0 ? "contained" : "outlined"}
                                                                                          style={{ minWidth: 70, minHeight: 20 }}


                                                                                >
                                                                                          <Typography variant="caption" style={{ fontSize: 10 }}>
                                                                                                    {requestEntity.organizationLevel.country !== 0 ?
                                                                                                              territorialOrganization.find(territorial => territorial.id === requestEntity.organizationLevel.country)!.country : "País"}
                                                                                          </Typography>
                                                                                </Button>
                                                                                {requestEntity.organizationLevel.country !== 0 && <Button
                                                                                          onMouseDown={(e) => {
                                                                                                    e.preventDefault()

                                                                                                    const territorial = territorialOrganization.find(territorialOrganization => territorialOrganization.id === requestEntity.paisId)!
                                                                                                    const territorialOption: TerritorialLevel[] = territorial.nivel1
                                                                                                    const defaultLocationOption = territorialOption.find(option => option.id === requestEntity.organizationLevel.levelOne)
                                                                                                    const locationOption = {
                                                                                                              locationName: territorial.niveles[0],
                                                                                                              locationOptions: territorialOption,
                                                                                                              open: true,
                                                                                                              keyRequest: 'idNivel',
                                                                                                              nivel: 1,
                                                                                                              defaultLocationOption
                                                                                                    } as LocationSelect


                                                                                                    setLocationSelect(locationOption)

                                                                                          }}
                                                                                          color={requestEntity.organizationLevel.levelOne === 0 ? "primary" : "default"}
                                                                                          variant={requestEntity.organizationLevel.levelOne == 0 ? "contained" : "outlined"}
                                                                                          style={{ minWidth: 70, minHeight: 20 }}
                                                                                >
                                                                                          <Typography variant="caption" style={{ fontSize: 10 }}>
                                                                                                    {requestEntity.organizationLevel.levelOne !== 0 ?
                                                                                                              territorialOrganization.find(territorial => territorial.id === requestEntity.organizationLevel.country)!.nivel1.find(
                                                                                                                        nivel1 => nivel1.id === requestEntity.organizationLevel.levelOne)!.name :
                                                                                                              territorialOrganization.find(territorial => territorial.id === requestEntity.paisId)!.niveles[0]}
                                                                                          </Typography>
                                                                                </Button>}
                                                                                {requestEntity.organizationLevel.levelOne !== 0 && <Button
                                                                                          onMouseDown={(e) => {
                                                                                                    e.preventDefault()

                                                                                                    const territorial = territorialOrganization.find(territorialOrganization => territorialOrganization.id === requestEntity.paisId)!

                                                                                                    const territorialOption: TerritorialLevel[] = territorial.nivel2.filter(
                                                                                                              nivel2 => nivel2.root === territorial.nivel1.find(nivel => nivel.id === requestEntity.organizationLevel.levelOne)!.key
                                                                                                    )

                                                                                                    const defaultLocationOption = territorialOption.find(option => option.id === requestEntity.organizationLevel.levelTwo)

                                                                                                    const locationOption = {
                                                                                                              locationName: territorial.niveles[1],
                                                                                                              locationOptions: territorialOption,
                                                                                                              open: true,
                                                                                                              keyRequest: 'idNivel',
                                                                                                              nivel: 2,
                                                                                                              defaultLocationOption
                                                                                                    } as LocationSelect


                                                                                                    setLocationSelect(locationOption)
                                                                                          }}
                                                                                          color={requestEntity.organizationLevel.levelTwo === 0 ? "primary" : "default"}
                                                                                          variant={requestEntity.organizationLevel.levelTwo == 0 ? "contained" : "outlined"}
                                                                                          style={{ minWidth: 70, minHeight: 20 }}
                                                                                >
                                                                                          <Typography variant="caption" style={{ fontSize: 10 }}>
                                                                                                    {requestEntity.organizationLevel.levelTwo !== 0 ?
                                                                                                              territorialOrganization.find(territorial => territorial.id === requestEntity.paisId)!.nivel2.find(
                                                                                                                        nivel2 => nivel2.id === requestEntity.organizationLevel.levelTwo)!.name :
                                                                                                              territorialOrganization.find(territorial => territorial.id === requestEntity.paisId)!.niveles[1]}
                                                                                          </Typography>
                                                                                </Button>}
                                                                                {requestEntity.organizationLevel.levelTwo !== 0 && <Button
                                                                                          onMouseDown={(e) => {
                                                                                                    e.preventDefault()

                                                                                                    const territorial = territorialOrganization.
                                                                                                              find(territorialOrganization => territorialOrganization.id === requestEntity.paisId)!

                                                                                                    const territorialOption: TerritorialLevel[] = territorial.nivel3.filter(
                                                                                                              nivel => nivel.root === territorial.nivel2.find(nivel => nivel.id === requestEntity.organizationLevel.levelTwo)!.key
                                                                                                    )!.map(nivel3 => ({
                                                                                                              name: nivel3.name,
                                                                                                              key: nivel3.id.toString(),
                                                                                                              id: nivel3.id
                                                                                                    }))

                                                                                                    const defaultLocationOption = territorialOption.find(option => option.id === requestEntity.organizationLevel.levelThree)
                                                                                                    const locationOption = {
                                                                                                              locationName: territorial.niveles[2],
                                                                                                              locationOptions: territorialOption,
                                                                                                              open: true,
                                                                                                              keyRequest: 'idNivel',
                                                                                                              nivel: 3,
                                                                                                              defaultLocationOption
                                                                                                    } as LocationSelect


                                                                                                    setLocationSelect(locationOption)
                                                                                          }}
                                                                                          color={requestEntity.organizationLevel.levelThree === 0 ? "primary" : "default"}
                                                                                          variant={requestEntity.organizationLevel.levelThree == 0 ? "contained" : "outlined"}
                                                                                          style={{ minWidth: 70, minHeight: 20 }}
                                                                                >
                                                                                          <Typography variant="caption" style={{ fontSize: 10 }}>
                                                                                                    {requestEntity.organizationLevel.levelThree !== 0 ?
                                                                                                              territorialOrganization.find(territorial => territorial.id === requestEntity.paisId)!.nivel3.find(
                                                                                                                        nivel3 => nivel3.id === requestEntity.organizationLevel.levelThree)!.name :
                                                                                                              territorialOrganization.find(territorial => territorial.id === requestEntity.paisId)!.niveles[2]}
                                                                                          </Typography>
                                                                                </Button>}
                                                                      </ButtonGroup>
                                                            </Box>
                                                  </Box>

                                        </Grid>

                                        {
                                                  entityFilters.filter(filter => filter.multiSelect === true).map((filter, index) => {
                                                            return (
                                                                      <Grid item xs={6} key={`${index}${filter.nombreFiltro}`}>
                                                                                <Box style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>

                                                                                          <Box style={{ width: "90%" }}>
                                                                                                    <Autocomplete
                                                                                                              multiple
                                                                                                              limitTags={4}
                                                                                                              options={
                                                                                                                        entityFilters.filter(item => filter.nombreFiltro === item.nombreFiltro).
                                                                                                                                  map(item => item.filtros).flat().sort(sortFilterDetailsByName).map(detail => detail)
                                                                                                              }
                                                                                                              groupBy={detail => detail.name.charAt(0)}
                                                                                                              getOptionLabel={(option) => option.name}
                                                                                                              freeSolo
                                                                                                              defaultValue={
                                                                                                                        getSelected(filter.requestKey)
                                                                                                              }
                                                                                                              renderTags={(value: FilterDetails[], getTagProps) => {
                                                                                                                        const render = entityFilters.filter(item => filter.nombreFiltro === item.nombreFiltro).
                                                                                                                                  map(item => item.filtros).flat().filter(detail => {
                                                                                                                                            return (requestEntity[filter.requestKey] as number[]).
                                                                                                                                                      some(e => e === detail.id)
                                                                                                                                  })
                                                                                                                        return render.map((option: FilterDetails, index: number) => (
                                                                                                                                  <Tooltip title={option.name}>
                                                                                                                                            <Chip variant="outlined" size="small"
                                                                                                                                                      label={option.name.length > 10 ? option.name.substring(0, 10) + '...' : option.name}
                                                                                                                                                      {...getTagProps({ index })} />
                                                                                                                                  </Tooltip>
                                                                                                                        ))
                                                                                                              }
                                                                                                              }
                                                                                                              renderInput={(params) => (
                                                                                                                        <TextField {...params} variant="filled" size="small"
                                                                                                                                  style={{ backgroundColor: '#FFFCD6' }}
                                                                                                                                  label={filter.nombreFiltro} />
                                                                                                              )}

                                                                                                              onChange={(event, value: any | any[], reason: string) => {
                                                                                                                        if (reason === 'select-option') {
                                                                                                                                  console.log(value)
                                                                                                                                  editValueForRequestEntidadesObject(filter.requestKey, (value as FilterDetails[]).slice(-1).pop()?.id, 'add')
                                                                                                                        } else if (reason === 'remove-option') {
                                                                                                                                  editValueForRequestEntidadesObject(filter.requestKey,
                                                                                                                                            value.map((item: FilterDetails) => item.id), 'remove')
                                                                                                                        } else if (reason === 'clear') {
                                                                                                                                  editValueForRequestEntidadesObject(filter.requestKey, [], 'remove')
                                                                                                                        }
                                                                                                              }}


                                                                                                    />
                                                                                          </Box>
                                                                                </Box>

                                                                      </Grid>
                                                            )
                                                  })
                                        }




                              </Grid>

                              <Modal
                                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}
                                        open={locationSelect.open}>
                                        <Paper style={{ width: '30%' }}>
                                                  <Box style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', margin: 8, justifyContent: 'flex-end' }}>
                                                            <Box  >
                                                                      <Button color="primary" variant="contained" size="small"
                                                                                style={{
                                                                                          borderBottomLeftRadius: '15px', borderTopLeftRadius: '0px',
                                                                                          borderTopRightRadius: '15px', borderBottomRightRadius: '0px', marginRight: 8, color: 'white'
                                                                                }}
                                                                                onClick={() => {
                                                                                          setLocationSelect({ ...locationSelect, open: false })
                                                                                          editValueForRequestEntidadesObject('organizationLevel', territorialOrganizationChoice)
                                                                                }}
                                                                      >
                                                                                Aceptar
                                                                      </Button>

                                                                      <Button variant="contained" size="small"
                                                                                style={{
                                                                                          borderBottomLeftRadius: '15px', borderTopLeftRadius: '0px',
                                                                                          borderTopRightRadius: '15px', borderBottomRightRadius: '0px',
                                                                                          backgroundColor: "#ff0000 ", color: "#fff"
                                                                                }}
                                                                                onClick={() => {
                                                                                          setLocationSelect({ ...locationSelect, open: false })
                                                                                          // editValueForRequestEntidadesObject('paisId', 0)

                                                                                }}
                                                                      >
                                                                                Cerrar
                                                                      </Button>
                                                            </Box>
                                                  </Box>
                                                  <Box style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', margin: 8 }}>
                                                            <Autocomplete
                                                                      fullWidth
                                                                      onChange={(event, value: any | any[], reason: string) => {
                                                                                if (reason === 'select-option') {
                                                                                          editValueForRequestEntidadesObject(locationSelect.keyRequest, value.id as number)
                                                                                          editValueForRequestEntidadesObject('nivel', locationSelect.nivel)
                                                                                          switch (locationSelect.nivel) {
                                                                                                    case 0:
                                                                                                              handleChangeTerritorialSelection("country", value.id as number)
                                                                                                              handleChangeTerritorialSelection("levelOne", 0)
                                                                                                              handleChangeTerritorialSelection("levelTwo", 0)
                                                                                                              handleChangeTerritorialSelection("levelThree", 0)
                                                                                                              break;
                                                                                                    case 1:
                                                                                                              handleChangeTerritorialSelection("levelOne", value.id as number)
                                                                                                              handleChangeTerritorialSelection("levelTwo", 0)
                                                                                                              handleChangeTerritorialSelection("levelThree", 0)
                                                                                                              break;
                                                                                                    case 2:
                                                                                                              handleChangeTerritorialSelection("levelTwo", value.id as number)
                                                                                                              handleChangeTerritorialSelection("levelThree", 0)
                                                                                                              break;
                                                                                                    case 3:
                                                                                                              handleChangeTerritorialSelection("levelThree", value.id as number)
                                                                                                              break;
                                                                                          }
                                                                                }
                                                                                if (reason === 'clear') {
                                                                                          switch (locationSelect.nivel) {
                                                                                                    case 0:
                                                                                                              handleChangeTerritorialSelection("country", 0)
                                                                                                              handleChangeTerritorialSelection("levelOne", 0)
                                                                                                              handleChangeTerritorialSelection("levelTwo", 0)
                                                                                                              handleChangeTerritorialSelection("levelThree", 0)
                                                                                                              break;
                                                                                                    case 1:
                                                                                                              handleChangeTerritorialSelection("levelOne", 0)
                                                                                                              handleChangeTerritorialSelection("levelTwo", 0)
                                                                                                              handleChangeTerritorialSelection("levelThree", 0)
                                                                                                              break;
                                                                                                    case 2:
                                                                                                              handleChangeTerritorialSelection("levelTwo", 0)
                                                                                                              handleChangeTerritorialSelection("levelThree", 0)
                                                                                                              break;
                                                                                                    case 3:
                                                                                                              handleChangeTerritorialSelection("levelThree", 0)
                                                                                                              break;
                                                                                          }
                                                                                }
                                                                      }}
                                                                      defaultValue={locationSelect.defaultLocationOption}
                                                                      options={locationSelect.locationOptions}
                                                                      getOptionLabel={option => option.name}
                                                                      renderInput={params => <TextField {...params} label={`${locationSelect.locationName}`} variant='outlined' ></TextField>}
                                                            ></Autocomplete>
                                                  </Box>
                                        </Paper>
                              </Modal >



                    </Paper >
          )
}