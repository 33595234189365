import { useMessagesHelper } from '../hook'

import { Box, Typography } from '@material-ui/core'
import { ConfigContext, LocationContext } from 'context'
import React from 'react'
import { IConferenceTheme } from 'types'
import { useMessageConfigHelper } from '../hook/useMessageConfigHelper'
import { InfiniteScrollThemeView } from './InfinityScrollThems'
import { MessageConferenxia } from './MessageConferenxia'
import { ScrollWrapperMessage } from './ScrollWrapperMessage'

type Props = {
  conferenceId: number
  theme?: IConferenceTheme
  loadMode: 'D' | 'A'
  hiddenDate?: boolean
  hiddenName?: boolean
  disableAutoWidth?: boolean
  refresh: boolean
  onEndRefresh: () => void
}

export const ThemeView = (props: Props) => {
  const { conferenceId, theme, loadMode, hiddenDate, hiddenName, disableAutoWidth, refresh } = props
  const { getMessages, handlePageChange, messages, paginationResponse, loading, resetMessages } = useMessagesHelper()
  const { getAlign, getTagDate, isSameDate } = useMessageConfigHelper()

  const { filesContext } = React.useContext(ConfigContext)

  React.useEffect(() => {
    if (!refresh) {
      return
    }
    resetMessages()
    if (theme) {
      getMessages(conferenceId, 1, theme.conferenceThemeId)
    } else {
      getMessages(conferenceId, 1)
    }
    props.onEndRefresh()
  }, [refresh])

  React.useEffect(() => {
    resetMessages()
    if (theme) {
      getMessages(conferenceId, 1, theme.conferenceThemeId)
    } else {
      getMessages(conferenceId, 1)
    }
  }, [conferenceId, theme])

  return (
    <InfiniteScrollThemeView
      reverse={loadMode === 'A'}
      conferenceId={conferenceId}
      selectedTheme={theme}
      listItems={
        messages.length > 0
          ? messages.map((message, index) => {
              return (
                <ScrollWrapperMessage
                  children={
                    <Box
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: loadMode === 'D' ? 'column-reverse' : 'column',
                      }}
                      px={1}
                    >
                      {index === messages.length - 1 && messages && loadMode === 'A' && !hiddenDate && (
                        <Box
                          width={'100%'}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'sticky',
                            top: 0,
                            zIndex: 99,
                          }}
                        >
                          <Typography
                            variant={'caption'}
                            color={'textSecondary'}
                            id={'tag-date-last-message-load-mode-Asc'}
                            style={{
                              paddingTop: 0.5,
                            }}
                          >
                            {getTagDate(message.date)}
                          </Typography>
                        </Box>
                      )}
                      <Box
                        style={{
                          width: '100%',
                          minWidth: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                        }}
                      >
                        <Box
                          style={{
                            minWidth: 200,
                            width: disableAutoWidth ? '95%' : 'auto',
                            maxWidth: disableAutoWidth ? '95%' : '80%',
                          }}
                        >
                          <MessageConferenxia message={message} onlyExistOneTheme={false} idUser={0} hiddenDate={hiddenDate} hiddenName={hiddenName} />
                        </Box>
                      </Box>

                      {loadMode === 'A' && (
                        <React.Fragment>
                          {index !== 0 &&
                            index !== messages.length - 1 &&
                            message &&
                            messages[index - 1] &&
                            !isSameDate(messages[index - 1].date, message.date) &&
                            !hiddenDate && (
                              <Box
                                width={'100%'}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  position: 'sticky',
                                  top: 0,
                                  zIndex: 99,
                                }}
                              >
                                <Typography
                                  variant={'caption'}
                                  color={'textSecondary'}
                                  style={{
                                    paddingTop: 1,
                                  }}
                                  id={'tag-date-dia-diferente-load-mode-Asc'}
                                >
                                  {getTagDate(messages[index - 1].date)}
                                </Typography>
                              </Box>
                            )}
                          {index === messages.length - 1 &&
                            messages[index - 1] &&
                            message &&
                            !isSameDate(messages[index - 1].date, message.date) &&
                            !hiddenDate && (
                              <Box
                                width={'100%'}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  position: 'sticky',
                                  top: 0,
                                  zIndex: 99,
                                }}
                              >
                                <Typography
                                  variant={'caption'}
                                  color={'textSecondary'}
                                  style={{
                                    paddingTop: 1,
                                  }}
                                  id={'tag-date-last-message-load-mode-Asc'}
                                >
                                  {getTagDate(messages[index - 1].date)}
                                </Typography>
                              </Box>
                            )}
                        </React.Fragment>
                      )}

                      {loadMode === 'D' && (
                        <React.Fragment>
                          {index !== 0 &&
                            index !== messages.length - 1 &&
                            messages[index - 1] !== undefined &&
                            message !== undefined &&
                            !isSameDate(messages[index - 1].date, message.date) &&
                            !hiddenDate && (
                              <Box
                                width={'100%'}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  position: 'sticky',
                                  top: 0,
                                  zIndex: 99,
                                }}
                              >
                                <Typography variant={'caption'} color={'textSecondary'} id={'tag-date-dia-diferente-load-mode-desc'}>
                                  {getTagDate(messages[index].date)}
                                </Typography>
                              </Box>
                            )}
                          {index === messages.length - 1 &&
                            messages[index - 1] &&
                            messages &&
                            !isSameDate(messages[index - 1].date, message.date) &&
                            !hiddenDate && (
                              <Box
                                width={'100%'}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  position: 'sticky',
                                  top: 0,
                                  zIndex: 99,
                                }}
                              >
                                <Typography variant={'caption'} color={'textSecondary'} id={'tag-date-last-message-load-mode-desc'}>
                                  {getTagDate(message.date)}
                                </Typography>
                              </Box>
                            )}
                        </React.Fragment>
                      )}

                      {index === 0 && (
                        <Box
                          width={'100%'}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            style={{
                              textAlign: 'center',
                              marginTop: 4,
                              marginBottom: 4,
                              paddingTop: 0.5,
                              paddingBottom: 0.5,
                              fontSize: 12,
                            }}
                          >
                            Ultimo menxaje!
                          </Typography>

                          {loadMode === 'D' && message && !hiddenDate && (
                            <Typography variant={'caption'} color={'textSecondary'} id={'tag-date-loadMode-descendent'}>
                              {getTagDate(message.date)}
                            </Typography>
                          )}
                        </Box>
                      )}
                    </Box>
                  }
                />
              )
            })
          : [
              <Typography
                variant="h3"
                color="textSecondary"
                style={{
                  textAlign: 'center',
                  fontSize: 14,
                  marginTop: 4,
                }}
              >
                No existe mensajes
              </Typography>,
            ]
      }
      loading={loading}
      paginationResponse={paginationResponse}
      handlePageChange={handlePageChange}
    />
  )
}
