import React, { Dispatch } from 'react'
import { Backdrop, Icon } from '@material-ui/core'
import { SpeedDial, SpeedDialAction } from '@material-ui/lab'
import Options, { PracticeOptions } from './Options'
import { IElement, IPosition } from 'types'
import { createAccordion, createElement, disablePanel, ELEMENT_TYPES, findObject } from 'lib'
import FormatLineSpacingIcon from '@material-ui/icons/FormatLineSpacing'
import DashboardIcon from '@material-ui/icons/Dashboard'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import { IAccordion } from 'components'
import { ConfigContext, LocationContext, PageContext } from 'context'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
interface Props {
  open: boolean
  position: IPosition
  onClose: () => void
  addElement: (newElement: IElement) => void
  pagePosition: IPosition
  addAccordion: (newAccordion: IAccordion) => void
  setSelectedAccordion: Dispatch<IAccordion | undefined>
  pageId: number
  existLoginElement: boolean
}

interface OptionsProps {
  icon: any
  name: string
  type: string
}

type DirectionTypes = 'up' | 'down'

const windowHeight = window.innerHeight

const MasterLogin = (props: Props) => {
  const { setCopyExternalState } = React.useContext(ConfigContext)
  const { initSavePositions } = React.useContext(PageContext)
  const { userId, tabId } = React.useContext(LocationContext)
  const { open, onClose, position, addElement, pagePosition, addAccordion, setSelectedAccordion, pageId, existLoginElement } = props
  const menu = React.useRef<HTMLElement>()
  const [verticalDirection, setVerticalDirection] = React.useState<DirectionTypes>('down')
  const [hiddenPracticos, setHiddenPracticos] = React.useState<boolean>(true)
  const menuHeight = menu.current?.clientHeight || 0
  const handleClose = (event: object, reason: string) => {
    if (reason !== 'mouseLeave' && onClose) {
      onClose()
      setHiddenPracticos(true)
    }
  }
  const handleClick = (option: OptionsProps) => {
    if (
      option.type === ELEMENT_TYPES.practiceCard ||
      option.type === ELEMENT_TYPES.practiceCardPersonality ||
      option.type === ELEMENT_TYPES.practiceCardGroups ||
      option.type === ELEMENT_TYPES.practiceConference
    ) {
      addElement(createElement(option.type, { x: position.x - pagePosition.x, y: position.y - pagePosition.y }, userId))
    } else if (option.type === ELEMENT_TYPES.header) {
      addElement(createElement(option.type, { x: 0, y: 0 }))
    } else {
      addElement(createElement(option.type, { x: position.x - pagePosition.x, y: position.y - pagePosition.y }))
    }
    onClose()
  }
  const handleAccordion = () => {
    const newAccordion = createAccordion({ x: position.x - pagePosition.x, y: position.y - pagePosition.y })
    addAccordion(newAccordion)
    setSelectedAccordion(newAccordion)
    onClose()
  }
  const handleCopyExternal = () => {
    setCopyExternalState({ x: position.x - pagePosition.x, y: position.y - pagePosition.y })
    onClose()
    findObject(pageId, tabId)
    disablePanel(props.pageId, tabId)
  }
  const handleInitSavePositions = () => {
    onClose()
    initSavePositions()
    disablePanel(props.pageId, tabId)
  }
  React.useEffect(() => {
    position.y + menuHeight > windowHeight ? setVerticalDirection('up') : setVerticalDirection('down')
  }, [position, open, menuHeight])
  return (
    <React.Fragment>
      <Backdrop open={open}></Backdrop>
      <SpeedDial
        ref={menu}
        ariaLabel="Designer master button"
        hidden={!open}
        icon={<Icon fontSize="small">close</Icon>}
        direction={verticalDirection}
        onClose={handleClose}
        open={open}
        style={{
          left: position.x,
          position: 'absolute',
          top: verticalDirection === 'down' ? position.y : position.y - menuHeight,
        }}
      >
        {Options &&
          hiddenPracticos &&
          Options.map((option: OptionsProps) => (
            <SpeedDialAction
              key={option.name}
              icon={option.icon}
              tooltipTitle={option.name}
              tooltipPlacement={position.x < 150 ? 'right' : 'left'}
              tooltipOpen
              classes={{
                staticTooltipLabel: 'select-none',
                staticTooltip: 'hidden',
              }}
              onClick={() => handleClick(option)}
            />
          ))}
        {hiddenPracticos && (
          <SpeedDialAction
            key={'accordion'}
            icon={<FormatLineSpacingIcon />}
            tooltipTitle={'Acordeón'}
            tooltipPlacement={position.x < 150 ? 'right' : 'left'}
            tooltipOpen
            classes={{
              staticTooltipLabel: 'text-sm',
            }}
            onClick={handleAccordion}
          />
        )}
        {hiddenPracticos && (
          <SpeedDialAction
            key={'external'}
            icon={<DashboardIcon />}
            tooltipTitle={'Copiar externo'}
            tooltipPlacement={position.x < 150 ? 'right' : 'left'}
            tooltipOpen
            classes={{
              staticTooltipLabel: 'select-none',
            }}
            onClick={handleCopyExternal}
          />
        )}
        {hiddenPracticos && (
          <SpeedDialAction
            key={'save-positions'}
            icon={<SaveAltIcon />}
            tooltipTitle={'Guardar posiciones'}
            tooltipPlacement={position.x < 150 ? 'right' : 'left'}
            tooltipOpen
            classes={{
              staticTooltipLabel: 'select-none',
            }}
            onClick={handleInitSavePositions}
          />
        )}

        <SpeedDialAction
          key={'Practicos'}
          icon={hiddenPracticos ? <MoreHorizIcon /> : <ArrowBackIcon />}
          tooltipTitle={hiddenPracticos ? 'Prácticos' : 'Todos'}
          tooltipOpen
          tooltipPlacement={position.x < 150 ? 'right' : 'left'}
          classes={{
            staticTooltipLabel: 'select-none',
          }}
          onClick={() => setHiddenPracticos((prev) => !prev)}
        />

        {PracticeOptions &&
          !hiddenPracticos &&
          PracticeOptions.map((option: OptionsProps) => {
            return !existLoginElement && option.type === ELEMENT_TYPES.login ? (
              <SpeedDialAction
                key={option.name}
                icon={option.icon}
                tooltipTitle={option.name}
                tooltipPlacement={position.x < 150 ? 'right' : 'left'}
                tooltipOpen
                classes={{
                  staticTooltipLabel: 'select-none',
                  staticTooltip: 'hidden',
                }}
                onClick={() => handleClick(option)}
              />
            ) : option.type !== ELEMENT_TYPES.login ? (
              <SpeedDialAction
                key={option.name}
                icon={option.icon}
                tooltipTitle={option.name}
                tooltipPlacement={position.x < 150 ? 'right' : 'left'}
                tooltipOpen
                classes={{
                  staticTooltipLabel: 'select-none',
                  staticTooltip: 'hidden',
                }}
                onClick={() => handleClick(option)}
              />
            ) : null
          })}
      </SpeedDial>
    </React.Fragment>
  )
}

export default MasterLogin
