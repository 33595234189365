import { Tooltip } from '@material-ui/core'
import { LocationContext, PageContext } from 'context'
import { useKeyPress } from 'hooks'
import { ELEMENT_TYPES, openObjectInfo } from 'lib'
import React from 'react'
import ReactPlayer from 'react-player'
import { useLocation } from 'react-router-dom'
import { ISize } from 'types'

interface Props {
    id: string,
    value: any,
    filesContext: string,
    style: React.CSSProperties,
    size: ISize,
    borderRadiusUnit: string
    exported?: boolean
}

const Video = React.memo((props: Props) => {
    const { id: pageId } = React.useContext(PageContext)
    const controlPress: boolean = useKeyPress("Control")
    const location = useLocation()
    const { tabId } = React.useContext(LocationContext)

    const handleClick = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e.ctrlKey) {
            await openObjectInfo(pageId, props.value[0], tabId)
            return
        }
    }
    return (
        <div id={`${ELEMENT_TYPES.video}_${props.id}`}>
            <ReactPlayer
                url={`${props.value[1].includes("http") ? "" : props.filesContext + "/"}${props.value[1]}`}
                width={props.size.width}
                height={props.size.height}
                controls={true}
                style={{
                    ...props.style,
                    borderWidth: props.style.borderWidth + "px",
                    transform: `rotate(${JSON.parse(props.style.transform?.toString() ? props.style.transform?.toString() : "[]")[0]}deg) scaleX(${JSON.parse(props.style.transform?.toString() ? props.style.transform?.toString() : "[]")[1]})`,
                    boxShadow: props.style.boxShadow,
                    borderTopLeftRadius: props.style.borderTopLeftRadius + props.borderRadiusUnit,
                    borderBottomLeftRadius: props.style.borderBottomLeftRadius + props.borderRadiusUnit,
                    borderTopRightRadius: props.style.borderTopRightRadius + props.borderRadiusUnit,
                    borderBottomRightRadius: props.style.borderBottomRightRadius + props.borderRadiusUnit,
                }}
            />
            {
                (controlPress && !props.exported) &&
                <Tooltip arrow title="Ver información">
                    <div
                        onClick={handleClick}
                        style={{
                            ...props.style,
                            borderWidth: props.style.borderWidth + "px",
                            transform: `rotate(${JSON.parse(props.style.transform?.toString() ? props.style.transform?.toString() : "[]")[0]}deg) scaleX(${JSON.parse(props.style.transform?.toString() ? props.style.transform?.toString() : "[]")[1]})`,
                            boxShadow: props.style.boxShadow,
                            width: props.size.width,
                            height: props.size.height,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                            top: 0,
                            cursor: "pointer",
                            backgroundColor: "transparent",
                        }}
                    />
                </Tooltip>
            }
        </div>
    )
})

export default Video
